import { gql } from '@apollo/client';

const GET_BATTERY_DATABASE = gql`
  query GetBatteryDatabase($filter: BatteryDatabaseFilterInput) {
    getBatteryDatabase(filter: $filter) {
      batteryPackModelName
      dataSource
      oemName
      oemCountry
      batteryPackLengthMm
      batteryPackWidthMm
      batteryPackHeightMm
      batteryPackWeightKg
      disassemblyInstructions
      lidLengthMm
      lidWidthMm
      lidHeightMm
      lidMaterial
      sealingType
      casingWeightKg
      batteryPackDesign
      energyAh
      socWindows
      nominalVoltageV
      maxVoltageV
      minVoltageV
      nominalCapacityAh
      nModules
      moduleWeightKg
      moduleLengthMm
      moduleWidthMm
      moduleHeightMm
      busBarConnectionMaterial
      busBarConnectionType
      cellsPerPack
      cellToPackRatio
      dismantlingTimeAutomatedMin
      dismantlingTimeManualMin
      dataSource
    }
    getInternalDataFilledPercentage(filter: $filter)
    getInternalDataCount(filter: $filter)
  }
`;

export const GET_BATTERY_PACK_WITH_ALL_FIELDS = gql`
  query GetBatteryDatabase($filter: BatteryDatabaseFilterInput) {
    getBatteryDatabase(filter: $filter) {
      batteryPackModelName
      batteryPackModelType
      oemName
      oemCountry
      batteryPackLengthMm
      batteryPackWidthMm
      batteryPackHeightMm
      batteryPackWeightKg
      disassemblyInstructions
      lidLengthMm
      lidWidthMm
      lidHeightMm
      lidMaterial
      sealingType
      casingWeightKg
      batteryPackDesign
      energyAh
      socWindows
      nominalVoltageV
      maxVoltageV
      minVoltageV
      nominalCapacityAh
      nModules
      moduleWeightKg
      moduleLengthMm
      moduleWidthMm
      moduleHeightMm
      busBarConnectionMaterial
      busBarConnectionType
      cellsPerPack
      cellToPackRatio
      dismantlingTimeAutomatedMin
      dismantlingTimeManualMin
      dataSource
    }
  }
`;

export const GET_OEM_META_DATA = gql`
  query GetBatteryOemMetaData($filter: OEMMetaDataFilterInput) {
    getBatteryOemMetaData(filter: $filter) {
      oemName
      batteryPacksCount
    }
  }
`;

export default GET_BATTERY_DATABASE;
