import React, { createContext, useContext, useState } from 'react';

interface DrawerContextType {
  drawerOpen: boolean;
  toggleDrawer: (open: boolean) => void;
  drawerHeader: string | null;
  drawerContent: React.ReactNode;
  setDrawerHeader?: (drawerHeader: string) => void;
  setDrawerContent?: (drawerContent: React.ReactNode) => void;
}

const defaultDrawerContext: DrawerContextType = {
  drawerOpen: false,
  toggleDrawer: open => console.error('Unimplemented function', open),
  drawerHeader: null,
  drawerContent: null,
  setDrawerHeader: () => console.error('Unimplemented function'),
  setDrawerContent: () => console.error('Unimplemented function'),
};

const DrawerContext = createContext<DrawerContextType>(defaultDrawerContext);

interface DrawerContextProps {
  children: React.ReactNode;
}

const DrawerProvider = ({ children }: DrawerContextProps) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [drawerHeader, setDrawerHeader] = useState<string | null>('Sample header');
  const [drawerContent, setDrawerContent] = useState<React.ReactNode>(null);

  const toggleDrawer = (open: boolean) => setDrawerOpen(open);

  return (
    <DrawerContext.Provider
      value={{
        drawerOpen,
        drawerHeader,
        drawerContent,
        toggleDrawer,
        setDrawerHeader,
        setDrawerContent,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

const useDrawer = () => {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error('useDrawer must be used within a DrawerProvider');
  }
  return context;
};

export { DrawerProvider, useDrawer };

export default DrawerContext;
