import { useEffect } from 'react';
import ReactGA, { EventArgs } from 'react-ga4';

interface GoogleAnalyticsHook {
  trackPageView: (path: string) => void;
  trackEvent: (eventName: string, eventCategory: string, eventLabel: string) => void;
}

function useGoogleAnalytics(): GoogleAnalyticsHook {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID as string);
  }, []);

  function trackPageView(path: string) {
    ReactGA.send({ hitType: 'pageview', page: path, title: path.split('/')[1] });
  }

  function trackEvent(eventName: string, eventCategory: string, eventLabel: string) {
    const eventArgs: EventArgs = {
      event_category: eventCategory,
      event_action: eventName,
      event_label: eventLabel,
    };
    ReactGA.event(eventArgs);
  }

  return { trackPageView, trackEvent };
}

export default useGoogleAnalytics;
