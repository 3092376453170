import * as React from 'react';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { dateStrToTimestamp, getFormattedDay } from 'src/utils/Date';
import { TEXT_CONTENTS } from 'src/constants';

interface NewsType {
  title: string;
  url: string;
  description: string;
  media: string;
  date: string;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const NewsCard: React.FC<NewsType> = ({ title, url, date, media, description }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expanded, setExpanded] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleCopyLink = async (link: string) => {
    try {
      // Use the Clipboard API to write text to clipboard
      await navigator.clipboard.writeText(link);

      // Show the tooltip
      setTooltipOpen(true);

      // Hide the tooltip after 2 seconds
      setTimeout(() => {
        setTooltipOpen(false);
      }, 2000);
    } catch (err) {
      console.error('Failed to copy link:', err);
    }
  };

  return (
    <Card elevation={10}>
      <CardMedia component="img" height="200" image={media} alt="green iguana" />
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          color={theme.palette.secondary.light}
          sx={{ marginBottom: theme.spacing(1) }}
        >
          {title}
        </Typography>
        {!isMobile && (
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        )}
        {isMobile && (
          <Typography variant="body2" color="text.secondary">
            {expanded ? description : description.split('\n').slice(0, 2).join('\n')}
          </Typography>
        )}
        <Typography
          variant="subtitle2"
          color="text.primary"
          sx={{ marginTop: theme.spacing(2), fontSize: '12px' }}
          gutterBottom
        >
          {TEXT_CONTENTS.NEWS.PUBLISHED_ON} {getFormattedDay(dateStrToTimestamp(date))}
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Tooltip
          open={tooltipOpen}
          onClose={() => setTooltipOpen(false)}
          title="Link Copied!"
          placement="top"
          arrow
        >
          <Button
            size="small"
            variant="contained"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexGrow: 1,
              marginLeft: theme.spacing(1),
              marginRight: theme.spacing(1),
            }}
            onClick={() => handleCopyLink(url)}
          >
            Copy Link
          </Button>
        </Tooltip>
        <Button
          size="small"
          variant="contained"
          href={url}
          target="_blank"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexGrow: 1,
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
          }}
        >
          Read More
        </Button>
        {isMobile && (
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        )}
      </CardActions>
    </Card>
  );
};

export default NewsCard;
