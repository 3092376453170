import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTheme, Grid } from '@mui/material';
import { TEXT_CONTENTS } from 'src/constants';
import { SetStateAction } from 'react';
import Row, { StyledTableCell } from './Common';
import { ProcessFlowSimulation } from 'src/@types/DataTypes';

const BatteryPackSimulation: React.FC<{
  setOpenImageModal: React.Dispatch<SetStateAction<boolean>>;
  batteryPackSimulationData: ProcessFlowSimulation;
}> = ({ setOpenImageModal, batteryPackSimulationData }) => {
  const theme = useTheme();
  return (
    <Grid container spacing={2} sx={{ marginTop: theme.spacing(2) }}>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
      ></Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper} sx={{ maxHeight: '50vh' }}>
          <Table stickyHeader aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell />
                <StyledTableCell>Batch ID</StyledTableCell>
                <StyledTableCell align="right">Weight&nbsp;(Kg)</StyledTableCell>
                <StyledTableCell align="right">Delivery Date</StyledTableCell>
                <StyledTableCell align="right">Exit time</StyledTableCell>
                <StyledTableCell align="right">Pre-diagnostic</StyledTableCell>
                <StyledTableCell align="right">Image</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {TEXT_CONTENTS.CLIENTS.BATTERY_PACK_SIMULATION.DATA.slice(
                0,
                batteryPackSimulationData.qrScan
              ).map((row, index) => {
                const showWeight = index <= batteryPackSimulationData.weight - 1;
                const showDischargeData = index <= batteryPackSimulationData.dischargeData - 1;

                return (
                  <Row
                    key={row.name}
                    name={row.name}
                    weight={showWeight ? row.weight : '-'}
                    deliveryDate={row.deliveryDate}
                    exitTime={showDischargeData ? row.exitTime : '-'}
                    image={row.image}
                    dischargeData={showDischargeData ? row.dischargeData : []}
                    setOpenImageModal={setOpenImageModal}
                    preDiagnostics={row.preDiagnostics}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default BatteryPackSimulation;
