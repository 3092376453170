export const chipOptions = [
  'Cell Chemistry',
  'Cell Models Per Application',
  'State of Health of Cells',
  'Recycle vs Reuse potential',
];

export const frequencyOptions = [
  'Every couple of days',
  'Every couple of weeks',
  'Monthly',
  'Quarterly',
  'Yearly',
];
