import React from 'react';
import { PageContainer } from 'src/components/Structure';
import { HomeCard } from 'src/components/Base';
import { useDataInsights } from 'src/components/hooks';
import {
  Condition,
  ConditionBox,
  FacilityBox,
  Map,
  MaterialsAvailable,
  MonthlyProcessed,
  Tendency,
} from 'src/components/Charts';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { TEXT_CONTENTS } from 'src/constants';
import { CellCondition } from 'src/@types/DataTypes';
import ROUTES from 'src/constants/routes';

const Home: React.FC = (): JSX.Element => {
  const { loading, cellInsights } = useDataInsights();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  if (loading) return <Typography variant="body1">{TEXT_CONTENTS.COMMON.LOADING}</Typography>;

  const accumulatedData = cellInsights?.cellCondition?.reduce(
    (acc, curr) => {
      if (!curr) return acc;

      acc.reusablePct += +curr.reusablePct * curr.totalCells;
      acc.recycleablePct += +curr.recycleablePct * curr.totalCells;
      acc.totalCells += +curr.totalCells;

      return acc;
    },
    {
      reusablePct: 0,
      recycleablePct: 0,
      totalCells: 0,
    } as Omit<CellCondition, 'client' | 'batteryCategory' | 'batteryType'>
  );

  return (
    <PageContainer>
      <Box mt={2}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <HomeCard title={TEXT_CONTENTS.OVERVIEW.TITLE}>
              <FacilityBox />
              <Map />
            </HomeCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <HomeCard title={TEXT_CONTENTS.BATTERY.TITLE} link={ROUTES.BATTERY_ANALYTICS}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box minHeight={50}>
                    <Typography variant="body1" sx={{ fontSize: '18px' }}>
                      {TEXT_CONTENTS.CHARTS.BATTERY_INSIGHTS.YEARLY.TITLE}
                    </Typography>
                  </Box>
                  <Tendency
                    row={!(isMobile || isTablet)}
                    selectedBatteryCategory={'Total'}
                    selectedBatteryType={'Total'}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box minHeight={50} mb={2}>
                    <Typography variant="body1" sx={{ fontSize: '18px' }}>
                      {TEXT_CONTENTS.BATTERY.TENDENCY_SUBTITLE}
                    </Typography>
                  </Box>
                  <MonthlyProcessed
                    selectedBatteryType={'Total'}
                    selectedBatteryCategory={'Total'}
                  />
                </Grid>
              </Grid>
            </HomeCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <HomeCard title={TEXT_CONTENTS.CELL.TITLE} link={ROUTES.CELL_ANALYTICS}>
              <Box my={1}>
                <Typography variant="body1" sx={{ fontSize: '18px' }}>
                  {TEXT_CONTENTS.CELL.SUBTITLE}
                </Typography>
              </Box>
              <ConditionBox aggregatedConditionData={accumulatedData} />
              <Condition aggregateConditionData={accumulatedData} />
            </HomeCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <HomeCard title={TEXT_CONTENTS.MATERIAL.TITLE} link={ROUTES.MATERIAL_ANALYTICS}>
              <Box my={1}>
                <Typography variant="body1" sx={{ fontSize: '18px' }}>
                  {TEXT_CONTENTS.MATERIAL.SUBTITLE}
                </Typography>
              </Box>
              <MaterialsAvailable selectedBatteryCategory={'Total'} />
            </HomeCard>
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
};

export default Home;
