import { EV_DATA, HYBRID_BATTERY_PACK_DATA, MODULE_DATA, PT_MM_DATA } from './batteryDetails';
import {
  BATTERY_PACK_IMAGES,
  BATTERY_PACK_SIMULATION,
  MODULE_IMAGES,
  MODULE_SIMULATION,
} from 'src/constants/clients';
import ROUTES from 'src/constants/routes';

export const TEXT_CONTENTS = {
  COMMON: {
    LOADING: 'Loading...',
    ERROR: 'Error fetching data at the moment',
    NOT_AUTHORISED: 'You are not authorised to view this page',
    CIRCU_LI_ION_TEXT: 'Circu Li-ion',
    DATA_NOT_AVAILABLE: 'Data not available',
    ACCOUNT_APPROVAL_NEEDED_MESSAGE:
      'You do not have access to this page as your account is yet to be approved. Please wait for the admin to approve your account.',
  },
  MOBILE_WARNING: {
    TITLE: 'Device size not supported',
    DISCLAIMER:
      'For best experience of the Circu Li-ion Battery Dashboard, open this page in a desktop browser.',
  },
  FEEDBACK: {
    TOOLBAR: {
      TITLE: 'Do you want to help us improve Battery Data Insights? Participate in a short survey!',
    },
    MODAL: {
      TITLE: 'Please help us improve BatDat by answering a few questions.',
      INTERESTED_IN: 'What battery data are you interested in?',
      MISSING_FEATURES: 'What data you feel is missing?',
      SUGGESTED_PRICE: 'How much are you willing to pay for battery data per year? (0 - 20,000 €)',
      USAGE_FREQUENCY: 'How frequently would you access the Dashboard?',
      ADAPTION_TO_FEATURES: 'How can BatDat be adapted to fit your processes?',
      ASK_RATING: 'How would you rate the user interface? (Rating 1-5)',
      RATING_ERROR: 'Please provide a rating',
    },
  },
  META: {
    TITLE: 'Welcome back',
    DESCRIPTION: 'Next generation of sustainability',
  },
  IMAGES_ALT_TEXT: {
    CIRCULI_ION_LOGO: 'Circu Li-ion Logo',
  },
  NOT_FOUND: {
    TITLE: 'Page not found',
    TEXT: 'Sorry, we could not find the page you are looking for. Return to the home screen now.',
    LINK_TEXT: 'Return to Homescreen',
  },
  LOGIN: {
    TITLE: 'Nice to see you @BatDat!',
    SIGNIN_HEADER: 'Sign In to Circu Li-ion',
    CAPTION: 'One-stop shop for battery data insights',
    SUBTITLE: 'Sign in to continue',
    GOOGLE: 'Sign in with Google',
    MICROSOFT: 'Sign in with Microsoft',
    FORGOT_PASSWORD: 'Forgot Password?',
    ERROR: 'Error signing in at the moment',
    LINK_TO_MAIN_PAGE_TEXT: 'Go back to circuli-ion.com',
    LINK_TO_MAIN_PAGE_URL: 'https://circuli-ion.com',
    LINK_TO_LOGIN_TEXT: 'Already a user? Login here!',
  },
  SIGNUP: {
    TITLE: 'Nice to see you @BatDat!',
    ERROR: 'Error signing up at the moment',
    SIGNUP_HEADER: 'Sign Up',
    LINK_TEXT: "Don't have an account? Sign up now!",
    PERSONAL_INFORMATION_GROUP_HEADER: '1. Personal Information',
    COMPANY_DETAILS_GROUP_HEADER: '2. Company Details',
    DATA_GROUP_HEADER: '3. Data',
    KIND_OF_DATA: 'What kind of data are you looking for?',
    INTERESTED_DATA: 'What kind of data would you like to find?',
    USAGE_AND_FREQUENCY_GROUP_HEADER: '4. Use case & Frequency',
    DESCRIBE_USECASE: 'Describe a typical use case for this data below:',
  },
  RESET_PASSWORD: {
    TITLE: 'Reset Your Password',
    BUTTON_TEXT: 'Send Password Reset Email',
    LINK_TEXT: 'Remember your password? Back to Login',
    SUCCESS_TITLE: 'Password reset email sent!',
    SUCCESS_MESSAGE:
      'Password reset email sent successfully! Sometimes this email ends up in spam or junk. Please check your spam or junk folder.',
    ERROR_TITLE: 'Error sending password reset email!',
    ERROR_MESSAGE:
      'We are not able to send the password reset email at the moment. Please try again later.',
  },
  NAVIGATION: {
    TITLE: 'Battery Data Insights',
    LINKS: [
      // { NAME: 'Production', URL: '/production' },
      { NAME: 'News', URL: ROUTES.NEWS, APPROVAL_REQUIRED: false },
    ],
    ANALYTICS_SUBMENU: [
      { NAME: 'Battery Analytics', URL: ROUTES.BATTERY_ANALYTICS, APPROVAL_REQUIRED: true },
      { NAME: 'Cell Analytics', URL: ROUTES.CELL_ANALYTICS, APPROVAL_REQUIRED: true },
      { NAME: 'Material Analytics', URL: ROUTES.MATERIAL_ANALYTICS, APPROVAL_REQUIRED: true },
    ],
    OPERATIONS_SUBMENU: [
      { NAME: 'Inventory Tracking', URL: ROUTES.INVENTORY, APPROVAL_REQUIRED: true },
      { NAME: 'Lot Tracking', URL: ROUTES.LOTS, APPROVAL_REQUIRED: true },
    ],
    INTELLIGENCE_SUBMENU: [
      { NAME: 'EV Market Insights', URL: ROUTES.MARKET_INSIGHTS, APPROVAL_REQUIRED: true },
      { NAME: 'Battery Map', URL: ROUTES.BATTERY_MAP, APPROVAL_REQUIRED: false },
      { NAME: 'Upcycling Check', URL: ROUTES.UPCYCLING_CHECK, APPROVAL_REQUIRED: true },
    ],
    MASTER_DATA_SUBMENU: [
      { NAME: 'EV Master Data', URL: ROUTES.EV_MASTER_DATA, APPROVAL_REQUIRED: true },
      { NAME: 'MM Master Data', URL: ROUTES.MM_MASTER_DATA, APPROVAL_REQUIRED: true },
      { NAME: 'PT Master Data', URL: ROUTES.PT_MASTER_DATA, APPROVAL_REQUIRED: true },
    ],
    BATTERY_LIBRARY_SUBMENU: [
      { NAME: 'Overview', URL: ROUTES.BATTERY_LIBRARY, APPROVAL_REQUIRED: true },
      { NAME: 'EV Battery Library', URL: ROUTES.EV_BATTERY_LIBRARY, APPROVAL_REQUIRED: true },
      { NAME: 'MM/PT Battery Library', URL: ROUTES.MM_PT_BATTERY_LIBRARY, APPROVAL_REQUIRED: true },
    ],
    SETTINGS: {
      CURRENCY_TOOLTIP: 'Adjust the currency used for displaying values in materials page',
      EXPORT: 'Export Data',
      LOGOUT: 'Sign Out',
    },
  },
  HOME: { TITLE: 'Home', LINK_TEXT: ' View more' },
  OVERVIEW: { TITLE: 'PRODUCTION OVERVIEW' },
  MATERIAL: { TITLE: 'MATERIAL INSIGHTS', SUBTITLE: 'Cell materials in kg' },
  BATTERY: {
    TITLE: 'BATTERY INSIGHTS',
    SUBTITLE: 'Yearly battery packs processed with tendency',
    TENDENCY_SUBTITLE: 'Battery packs processed over the years at monthly level',
  },
  CELL: {
    TITLE: 'CELL INSIGHTS',
    SUBTITLE: 'Condition of tested cells',
    CONDITION_BOX: {
      REUSABLE_CELLS: 'Reusable cells',
      RECYCLABLE_CELLS: 'Cells to be recycled',
    },
  },
  MARKET_INSIGHTS: { TITLE: 'MARKET INSIGHTS' },
  INVENTORY: { TITLE: 'Inventory Tracking' },
  LOTS: {
    TITLE: 'Lots Tracking',
    CLIENT_REPORT: 'Client Report',
    LOT_DETAILS: 'Lot Details',
    LOT_DETAILS_CARD: {
      TOTAL_WEIGHT: 'Total Weight:',
      WEIGHT_UNIT: 'kg',
      UNITS: 'Units',
      DATE: 'Date:',
    },
  },
  BATTERY_LIBRARY_OVERVIEW: {
    TITLE: 'Battery Library Overview',
    EV_COUNT: 'EV Battery Packs',
    MMPT_COUNT: 'Micro-mobility and Power Tool Battery Packs',
  },
  EV_BATTERY_LIBRARY: {
    TITLE: 'EV Battery Library',
    DESCRIPTION:
      'The table shows the battery packs that Circu Li-ion can automatically process on its units. The information reflects the current state and more battery packs will be added to the library. For EV battery packs it is currently not economical to disassemble beyond the module level (units 3 and 4). For detailed information about the battery packs, please filter them in the master data table.',
    BATTERY_CATEGORY: 'EV',
  },
  MM_PT_BATTERY_LIBRARY: {
    TITLE: 'MM/PT Battery Library',
    DESCRIPTION:
      'The table shows the battery packs that Circu Li-ion can automatically process on its units. The information reflects the current state and more battery packs will be added to the library. For detailed information about the battery packs, please filter them in the master data table.',
    BATTERY_CATEGORY: 'MM',
  },
  MASTER_DATA: {
    TITLE: 'Master Data',
    BATTERY_PACK_DETAILS: 'Battery Pack Details',
    NO_MANUFACTURER: 'No data found for the given manufacturer',
    BATTERY_PACK_TYPE: 'Battery Pack Type',
    DETAILED_INFORMATION: 'Detailed Information',
    KPIS_DESCRIPTION: 'KPIs reflect only internal data',
    MISSING_VALUES: 'Missing Values',
    INTERNAL_DATA_POINTS: 'Internal BPs Collected',
    INTERNAL_FILLED_PERCENTAGE: 'Internal Data points Filled',
    VIEW_IN_TABLE: 'View in Table',
    NO_DATA_FOUND: 'We do not have any data for the selected battery pack type at the moment',
    BATTERY_PACK_DROPDOWN_LABEL: 'Select Battery Pack',
    CIRCU_LI_ION_SHAREPOINT_PREFIX: 'https://circuliion2021.sharepoint.com',
    EXPORT_MASTER_DATA: 'Export Master Data',
    EXPORT_TOOLTIP: 'Includes both internal and external data',
  },
  CELL_DETAILS: {
    CELL_NOT_FOUND: 'Cell not found',
    OVERVIEW: 'Overview',
    ELECTRICAL: 'Electrical',
    GEOMETRICAL_AND_MECHANICAL: 'Geometry & Mechanical',
    CHEMISTRY: 'Chemistry',
  },
  STAKEHOLDER_MAP: {
    FACILITY_TYPE: 'Facility Type',
  },
  CHARTS: {
    FACILITY_INSIGHTS: {
      FACILITIES: {
        TOTAL: 'Total Facilities',
        ACTIVE: 'Active Facilities',
      },
    },
    CELL_INSIGHTS: {
      FILTERS: {
        TITLE: 'Customize Insights: Filter by Category, Type, and Client',
      },
      STATE_OF_HEALTH: {
        TITLE: 'State of Health (SoH) of cells',
        ID: 'state-of-health-of-cells',
        YAXIS: 'SoH',
      },
      CONDITION_OF_CELLS: {
        TITLE: 'Condition of cells tested',
        ID: 'condition-of-cells-tested',
      },
      TESTED_CELLS: {
        TITLE: 'Total number of cells tested',
        ID: 'total-number-of-cells-tested',
      },
      CELL_LIBRARY: 'Cell Library',
    },
    BATTERY_INSIGHTS: {
      FILTERS: {
        TITLE: 'Customize Insights: Filter by Category, Type, and Measure',
      },
      YEARLY: {
        TITLE: 'Yearly battery packs processed',
        TITLE_KG: 'Yearly battery packs processed [kg]',
        ID: 'yearly-battery-packs-processed',
        YAXIS: 'Number of battery packs',
      },
      MONTHLY: {
        TITLE: 'Monthly battery packs processed',
        TITLE_KG: 'Monthly battery packs processed [kg]',
        ID: 'monthly-battery-packs-processed',
        YAXIS: 'Number of battery packs',
        PROCESSED_BATTERY_PACKS: 'Processed Battery Packs',
      },
      TENDENCY: {
        TITLE: 'Yearly battery packs processed with tendency',
        TITLE_KG: 'Yearly battery packs processed in kg with tendency',
      },
      PT_MM_LIBRARY: {
        TITLE: 'Power Tool and Micro-mobility battery pack master data',
        DATA: PT_MM_DATA,
      },
      EV_LIBRARY: {
        TITLE: 'Electric Vehicle battery pack master data',
        DATA: EV_DATA,
      },
      HYBRID_LIBRARY: {
        TITLE: 'Hybrid battery pack master data',
        DATA: HYBRID_BATTERY_PACK_DATA,
      },
      MODULE_LIBRARY: {
        TITLE: 'Module master data',
        DATA: MODULE_DATA,
      },
    },
    MATERIAL_INSIGHTS: {
      COMPONENTS: {
        TITLE: 'Battery components available in kg/',
        ID: 'components-available',
        YAXIS: 'Components available in kg/',
        YAXIS_KG: 'Components available in kg',
        YAXIS_USD: 'Components available in ',
        INFO: `Components value in USD is based on price from end of business data from the following dates.`,
      },
      CELL: {
        TITLE: 'Cell materials available in kg/',
        ID: 'materials-available',
        YAXIS_KG: 'Material available in kg',
        YAXIS_USD: 'Material available in ',
        INFO: `Materials value in USD is based on price from end of business data from the following dates.`,
      },
      MATERIALS: {
        TITLE: 'Materials price (per kg) in ',
        ID: 'materials-price',
        WEEK: 'Week',
        MONTH: 'Month',
      },
    },
    MARKET_INSIGHTS: {
      TITLE: 'Market Insights',
      TOP_BRANDS_SALES: {
        TITLE: 'EV car sales per brand',
        ID: 'all-brands-sales',
        YAXIS: 'Sales',
      },
      SELECTED_BRAND_SALES: {
        TITLE: 'EV car sales per model',
        ID: 'selected-brand-sales',
        YAXIS: 'Sales',
      },
    },
    BATTERY_LIBRARY_OVERVIEW: {
      BATTERY_PACKS_STORED: {
        TITLE: 'Battery Packs in library over time',
        YAXIS: 'Battery Library Count',
      },
    },
    INVENTORY_TRACKING: {
      INVENTORY_TRACKING_TABLE: {
        TITLE: 'Inventory Table',
      },
      INVENTORY_TRACKING_CHART: {
        TITLE: 'Inventory Tracking Chart',
      },
    },
    LOTS_TRACKING: {
      BATTERY_PACKS_PER_LOT: {
        TITLE: 'Total battery packs processed per lot [kg]',
        CHART_ID: 'total-batteries-per-lot',
        YAXIS: 'Amount in KG',
        YAXIS_HOVER: 'Total batteries received [kg]',
      },
    },
  },
  APPROVALS: {
    COLUMN_HEADERS: {
      EMAIL: 'User Email',
      COMPANY: 'Company',
      ROLE: 'Role',
      USE_CASE: 'Use Case',
      FREQUENCY: 'Frequency',
    },
    NUM_SELECTED: 'selected',
    TITLE: 'Approvals',
    APPROVAL_SUCCEDDED: 'Successfully approved',
    APPROVAL_FAILED: 'Error approving',
  },
  NEWS: {
    TITLE: `What's new in the battery world?`,
    PUBLISHED_ON: 'Published on: ',
    ARTICLES: [
      {
        TITLE:
          "Communities can't recycle or trash disposable e-cigarettes. So what happens to them?",
        DESCRIPTION:
          'WASHINGTON (AP) — With the growing popularity of disposable e-cigarettes, communities across the U.S. are confronting a new vaping problem: how to safely get rid of millions of small, battery-powered devices that are considered hazardous waste.\n' +
          '\n' +
          'For years, the debate surrounding vaping largely centered on its risks for high school and middle school students enticed by flavors like gummy bear, lemonade and watermelon.\n' +
          '\n' +
          'But the recent shift toward e-cigarettes that can’t be refilled has created a new environmental dilemma. The devices, which contain nicotine, lithium and other metals, cannot be reused or recycled. Under federal environmental law, they also aren’t supposed to go in the trash.\n' +
          '\n',
        URL: 'https://apnews.com/article/vaping-ecigarettes-waste-environment-disposable-pollution-3d19dce9693ce78dd244729f524df02a',
        MEDIA:
          'https://dims.apnews.com/dims4/default/54de3af/2147483647/strip/true/crop/5360x3573+0+0/resize/2880x1920!/format/webp/quality/90/?url=https%3A%2F%2Fassets.apnews.com%2F11%2F34%2F116bec977b2dcb2bf0eafea907dd%2Fb5358e06cef1430ab27582d85a28e14f',
        DATE: '19/10/23',
      },
      {
        TITLE:
          'New EV battery factory requires so much energy a coal power plant will be expanded and its closure delayed by years',
        DESCRIPTION:
          'New electric vehicle battery factory in Kansas will require so much energy that a coal plant slated for closure will now remain open, plus it will be expanded.\n' +
          '\n' +
          'Panasonic is building a $4 billion EV battery factory in De Soto, Kansas. The upcoming lithium-ion battery manufacturing facility is expected to start mass production of EV batteries by the end of March 2025.\n' +
          '\n' +
          'Despite the massive $4 billion price tag for the 2.7 million square-foot Panasonic facility, the Japanese company is "poised to get as much as $6.8 billion from provisions in last year’s federal Inflation Reduction Act," according to a July report from the Kansas City Star.\n',
        URL: 'https://www.theblaze.com/news/electric-vehicle-battery-factory-coal-plant-kansas',
        MEDIA:
          'https://www.theblaze.com/media-library/image.jpg?id=47585532&width=1245&height=700&quality=85&coordinates=0%2C53%2C0%2C54',
        DATE: '01/10/23',
      },
      {
        TITLE: 'Stellantis, Samsung to build second EV battery plant in Kokomo',
        DESCRIPTION:
          'KOKOMO — Stellantis and Samsung EDI have announced a plan to invest $3.2 billion in building a second EV battery plant in Kokomo.\n' +
          '\n' +
          'Governor Eric Holcomb shared the news Wednesday morning.\n' +
          '\n' +
          '“Indiana’s economy is on a roll,” said Gov. Holcomb. “Today’s commitment from Stellantis and Samsung SDI will double the capital investment, the new jobs created, and the impact this joint venture will have on Kokomo and the state of Indiana for decades to come. This decision puts Hoosiers squarely at the center of innovating and developing the future of mobility, catalyzing Indiana’s leadership position in tomorrow’s global economy.”',
        URL: 'https://www.wrtv.com/news/local-news/howard-county/kokomo/stellantis-samsung-to-build-second-ev-battery-plant-in-kokomo',
        MEDIA:
          'https://ewscripps.brightspotcdn.com/dims4/default/daa6e7f/2147483647/strip/true/crop/4707x2648+0+245/resize/1280x720!/quality/90/?url=https%3A%2F%2Fewscripps.brightspotcdn.com%2Fa0%2F69%2Fda45d1c045ecba49f16ed1b55ee1%2Fap22060570717613.jpg',
        DATE: '11/10/23',
      },
      {
        TITLE: 'New Study: How Long Do Electric Car Batteries Last?',
        DESCRIPTION:
          'How long is that EV battery going to last? The one simple answer is that we don’t know for sure because electric cars have not been around long enough for us to tell. The best we can do is observe the apparent degradation in those cars on the road.\n' +
          '\n' +
          'Even that observation can prove a challenge, though, since most EVs have been on the road well under six years, with almost 30% sold in 2022.\n' +
          '\n' +
          'We still have very little sense of how they degrade over their lifetime - which car makers say should be as long as 20 years. So far, it seems that EV batteries have much longer lifespans than anyone imagined, since very few of them have been replaced, even once the 8-year, 100,000 mile warranty period ends. ',
        URL: 'https://www.recurrentauto.com/research/how-long-do-ev-batteries-last',
        MEDIA:
          'https://assets-global.website-files.com/60ce1b7dd21cd5b42639ff42/63079f6ae5897b40216c63e6_How%20Long%20Do%20Tesla%20%26%20EV%20Batteries%20Last.jpg',
        DATE: '13/10/23',
      },
      {
        TITLE: 'Battery recycling adds juice to Avalon’s Thunder Bay lithium plans',
        DESCRIPTION:
          'Recycling the valuable metals from waste batteries has entered into the plans for Avalon Advanced Materials, a potential lithium plant developer in Thunder Bay.\n' +
          '\n' +
          'The Toronto company released details this week on the joint venture partnership deal it signed with Metso of Finland, a leading European provider of clean mining processing technology. \n' +
          '\n' +
          'In an Oct. 19 news release, Avalon mentioned it’s added a battery recycling element and the “refining of battery chemicals for recycle use” to its grander vision of creating an industrial and research park at its chosen site in Thunder Bay’s north end. \n' +
          '\n' +
          'Avalon said it’s entered into an “expanded partnership agreement” with Metso, a development partner with which it signed a memorandum of understanding last July. ',
        URL: 'https://www.tbnewswatch.com/local-news/battery-recycling-adds-juice-to-avalons-thunder-bay-lithium-plans-7715253',
        MEDIA: 'https://www.vmcdn.ca/f/files/nob/avalon-advanced-materials-aerial-2.PNG;w=960',
        DATE: '22/10/23',
      },
      {
        TITLE: 'Is  FastTech the new Fast Fashion?',
        DESCRIPTION:
          'New research shows the UK’s consumption of small ‘FastTech’ electricals is becoming an issue set to outstrip Fast Fashion, in terms of the amount sent to landfill. ' +
          '\n' +
          '‘FastTech’ refers to everyday small electrical items, from headphones to cables, decorative lights to mini fans and even single-use vapes. ' +
          '\n' +
          'These items often have a short lifespan and cost. This means they may be seen as ‘disposable’, even when they’re not designed to be. ' +
          '\n' +
          'This emerging issue is the tip of the iceberg of a bigger challenge of electrical waste in the UK, with the valuable materials contained inside gold, aluminium, and lithium are lost forever when thrown away.' +
          '\n' +
          'The in-depth study conducted by Material Focus as part of the Recycle Your Electricals campaign to mark International E-Waste Day reveals over half a billion FastTech items were purchased in the last year alone.',
        URL: 'https://www.materialfocus.org.uk/press-releases/is-fasttech-the-new-fast-fashion/',
        MEDIA:
          'https://s3.eu-west-1.amazonaws.com/s3-bucket-recycleyourelectricals.org.uk/wp-content/uploads/2022/03/material-focus-logo-colour2-1-e1650990426349.png',
        DATE: '12/10/23',
      },
      {
        TITLE: 'Amazon opens 2 new micromobility hubs in Rome and Trento',
        DESCRIPTION:
          'Amazon opens 2 new micromobility hubs in Rome and Trento - Sustainable Mobility. These are used to make deliveries through electric cargo scooters, cargo bikes, and on foot.' +
          '\n' +
          'The aim is to eliminate vans from the road that increase traffic and pollution in urban centres. The hubs are part of the investment of over 1 billion euros aimed at decarbonizing the transport network of the partners in the Old Continent, through which the fleet will be expanded with 10,000 electric vans and 1,500 battery-powered heavy vehicles.' +
          '\n' +
          'The development of sustainable mobility is also at the heart of companies for which logistics and the transport of goods are fundamental, such as Amazon, which has expressed its contribution to the cause through micromobility hubs now also open in Rome and Trento.' +
          '\n' +
          'These are used to make deliveries through electric cargo scooters, cargo bikes, and on foot.',
        URL: 'https://newsrnd.com/tech/2023-10-25-amazon-opens-2-new-micromobility-hubs-in-rome-and-trento---sustainable-mobility.ByfbCE_LMT.html',
        MEDIA:
          'https://www.ansa.it/webimages/img_700/2023/10/25/a025bf5ba1b0a4ccfb146c8ab2bd3261.jpg',
        DATE: '25/10/23',
      },
    ],
  },
  CLIENTS: {
    TITLE: 'Client Details',
    BATTERY_PACK_SIMULATION: {
      TITLE: 'Battery Pack data collection table',
      DATA: BATTERY_PACK_SIMULATION,
      IMAGES: BATTERY_PACK_IMAGES,
    },
    MODULE_SIMULATION: {
      TITLE: 'Module data collection table',
      DATA: MODULE_SIMULATION,
      IMAGES: MODULE_IMAGES,
    },
    SCHEMA: {
      TITLE: 'Schema for data storage',
    },
  },
};
