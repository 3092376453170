import BatteryOne from 'src/assets/img/bosch_batteries/battery_1.png';
import BatteryTwo from 'src/assets/img/bosch_batteries/battery_2.png';
import BatteryThree from 'src/assets/img/bosch_batteries/battery_3.png';
import Audi_1 from 'src/assets/img/ev_battery_library/Audi_1.png';
import Audi_2 from 'src/assets/img/ev_battery_library/Audi_2.png';
import Audi_3 from 'src/assets/img/ev_battery_library/Audi_3.png';
import BMW_1 from 'src/assets/img/ev_battery_library/BMW_1.png';
import BMW_2 from 'src/assets/img/ev_battery_library/BMW_2.png';
import Citreon_1 from 'src/assets/img/ev_battery_library/Citreon_1.png';
import Ford_1 from 'src/assets/img/ev_battery_library/Ford_1.png';
import Ford_2 from 'src/assets/img/ev_battery_library/Ford_2.png';
//import Hyundai_1 from 'src/assets/img/ev_battery_library/Hyundai_1.png';
import Hyundai_2 from 'src/assets/img/ev_battery_library/Hyundai_2.png';
//import Hyundai_3 from 'src/assets/img/ev_battery_library/Hyundai_3.png';
import Jaguar_1 from 'src/assets/img/ev_battery_library/Jaguar_1.png';
import Kia_1 from 'src/assets/img/ev_battery_library/Kia_1.png';
//import Kia_2 from 'src/assets/img/ev_battery_library/Kia_2.png';
import Kia_3 from 'src/assets/img/ev_battery_library/Kia_3.png';
import Lexus from 'src/assets/img/ev_battery_library/Lexus.png';
import Mercedes_1 from 'src/assets/img/ev_battery_library/Mercedes_1.png';
import MG_1 from 'src/assets/img/ev_battery_library/Mercedes_1.png';
import Mistubushi_1 from 'src/assets/img/ev_battery_library/Mistubushi_1.png';
import Mistubushi_2 from 'src/assets/img/ev_battery_library/Mistubushi_2.png';
import Nissan from 'src/assets/img/ev_battery_library/Nissan.png';
import Opel_1 from 'src/assets/img/ev_battery_library/Opel_1.png';
import Opel_2 from 'src/assets/img/ev_battery_library/Opel_2.png';
import Polestar from 'src/assets/img/ev_battery_library/Polestar.png';
import Tesla_1 from 'src/assets/img/ev_battery_library/Tesla_1.png';
import Tesla_2 from 'src/assets/img/ev_battery_library/Tesla_2.png';
import Toyota_2 from 'src/assets/img/ev_battery_library/Toyota_2.png';
import Volvo_2 from 'src/assets/img/ev_battery_library/Volvo_2.png';
import Volvo_3 from 'src/assets/img/ev_battery_library/Volvo_3.png';
import VW_1 from 'src/assets/img/ev_battery_library/VW_1.png';
import VW_2 from 'src/assets/img/ev_battery_library/VW_2.png';
import VW_3 from 'src/assets/img/ev_battery_library/VW_3.png';
import BMW_Module_1 from 'src/assets/img/ev_battery_library/BMW_Module_1.png';
import BMW_i3_Module from 'src/assets/img/module_library/bmw_i3_battery_module.jpg';
import VW_MODULE_ID3 from 'src/assets/img/module_library/vw-id3-battery-module.jpg';

// import logos
import Audi_logo from 'src/assets/img/logos/electric-vehicle/audi.svg';
import BMW_logo from 'src/assets/img/logos/electric-vehicle/bmw.svg';
import Citroen_logo from 'src/assets/img/logos/electric-vehicle/citroen.svg';
import Ford_logo from 'src/assets/img/logos/electric-vehicle/ford.svg';
import Hyundai_logo from 'src/assets/img/logos/electric-vehicle/hyundai.svg';
import Jaguar_logo from 'src/assets/img/logos/electric-vehicle/jaguar.svg';
import Kia_logo from 'src/assets/img/logos/electric-vehicle/kia.svg';
import Lexus_logo from 'src/assets/img/logos/electric-vehicle/lexus.svg';
import Mercedes_logo from 'src/assets/img/logos/electric-vehicle/mercedes.svg';
import Mg_logo from 'src/assets/img/logos/electric-vehicle/mg.svg';
import Mitsubishi_logo from 'src/assets/img/logos/electric-vehicle/mitsubishi.svg';
import Nissan_logo from 'src/assets/img/logos/electric-vehicle/nissan.svg';
import Opel_logo from 'src/assets/img/logos/electric-vehicle/opel.svg';
import Peugeot_logo from 'src/assets/img/logos/electric-vehicle/peugeot.svg';
import Polestar_logo from 'src/assets/img/logos/electric-vehicle/polestar.svg';
import Porsche_logo from 'src/assets/img/logos/electric-vehicle/porsche.svg';
import Renault_logo from 'src/assets/img/logos/electric-vehicle/renault.svg';
import Skoda_logo from 'src/assets/img/logos/electric-vehicle/skoda.svg';
import Suzuki_logo from 'src/assets/img/logos/electric-vehicle/suzuki.svg';
import Tesla_logo from 'src/assets/img/logos/electric-vehicle/tesla.svg';
import Toyota_logo from 'src/assets/img/logos/electric-vehicle/toyota.svg';
import Volkswagen_logo from 'src/assets/img/logos/electric-vehicle/volkswagen.svg';
import Volvo_logo from 'src/assets/img/logos/electric-vehicle/volvo.svg';
import Xpeng_logo from 'src/assets/img/logos/electric-vehicle/XPEV.svg';
import Panasonic_logo from 'src/assets/img/logos/electric-vehicle/panasonic.svg';
import McLaren_logo from 'src/assets/img/logos/electric-vehicle/mclaren.svg';
import Rimac_logo from 'src/assets/img/logos/electric-vehicle/rimac.svg';
import Swindon_powertrain_logo from 'src/assets/img/logos/electric-vehicle/swindon_powertrain.png';
import Maserati_logo from 'src/assets/img/logos/electric-vehicle/maserati.svg';
import Dodge_logo from 'src/assets/img/logos/electric-vehicle/dodge.svg';
import American_battery_solutions_logo from 'src/assets/img/logos/electric-vehicle/American_Battery_Solutions.png';
import Fisker_logo from 'src/assets/img/logos/electric-vehicle/fisker.svg';
import Yangwang_logo from 'src/assets/img/logos/electric-vehicle/Yangwang.png';
import Man_logo from 'src/assets/img/logos/electric-vehicle/man.svg';
import Lg_logo from 'src/assets/img/logos/electric-vehicle/lg.svg';
import Rolls_royce_logo from 'src/assets/img/logos/electric-vehicle/rolls-royce.svg';
import Lechlanche_logo from 'src/assets/img/logos/electric-vehicle/leclanche.svg';
import Fpt_industrial_logo from 'src/assets/img/logos/electric-vehicle/fpt-industrial.svg';
import Dacia_logo from 'src/assets/img/logos/electric-vehicle/dacia.svg';
import Li_auto_logo from 'src/assets/img/logos/electric-vehicle/Li Auto.svg';
import Nio_logo from 'src/assets/img/logos/electric-vehicle/NIO Auto.svg';
import Cupra_logo from 'src/assets/img/logos/electric-vehicle/Cupra.svg';
import DAF_logo from 'src/assets/img/logos/electric-vehicle/daf.svg';
import Jeep_logo from 'src/assets/img/logos/electric-vehicle/jeep.svg';
import Cadillac_logo from 'src/assets/img/logos/electric-vehicle/cadillac.svg';
import Yadea_logo from 'src/assets/img/logos/electric-vehicle/Yadea.svg';
import Lucid_air_logo from 'src/assets/img/logos/electric-vehicle/lucid.svg';
import SAIC_Logo from 'src/assets/img/logos/electric-vehicle/saic.svg';
import Ferrari_logo from 'src/assets/img/logos/electric-vehicle/ferrari.svg';
import Gmc_logo from 'src/assets/img/logos/electric-vehicle/gmc.svg';
import Lincoln_logo from 'src/assets/img/logos/electric-vehicle/lincoln.svg';
import Honda_logo from 'src/assets/img/logos/electric-vehicle/honda.svg';
import Mastervolt_logo from 'src/assets/img/logos/electric-vehicle/mastervolt.svg';
import Chevrolet_logo from 'src/assets/img/logos/electric-vehicle/chevrolet.svg';
import Tata_logo from 'src/assets/img/logos/electric-vehicle/tata.svg';
import Scania_logo from 'src/assets/img/logos/electric-vehicle/scania.svg';
import Electro_Flight_logo from 'src/assets/img/logos/electric-vehicle/Electroflight.png';
import Rivian_logo from 'src/assets/img/logos/electric-vehicle/rivian.svg';
import Mini_logo from 'src/assets/img/logos/electric-vehicle/mini.svg';
import Super_soco_logo from 'src/assets/img/logos/electric-vehicle/Super Soco.png';
import Fiat_logo from 'src/assets/img/logos/electric-vehicle/fiat-3.svg';
import Sehol_logo from 'src/assets/img/logos/electric-vehicle/Sehol svn.png';
import Hithium_logo from 'src/assets/img/logos/electric-vehicle/Hithium.png';
import Citreon_logo from 'src/assets/img/logos/electric-vehicle/citroen.svg';
import Great_wall_logo from 'src/assets/img/logos/electric-vehicle/great-wall-automotive.svg';
import Cosworth_logo from 'src/assets/img/logos/electric-vehicle/cosworth-1.svg';
import Maxus_logo from 'src/assets/img/logos/electric-vehicle/maxus.svg';
import Smart_logo from 'src/assets/img/logos/electric-vehicle/smart.svg';
import Vauxhall_logo from 'src/assets/img/logos/electric-vehicle/vauxhall.svg';
import Bentley_logo from 'src/assets/img/logos/electric-vehicle/bentley.svg';
import Powertech_logo from 'src/assets/img/logos/electric-vehicle/powertech.png';
import Ducati_logo from 'src/assets/img/logos/electric-vehicle/ducati.svg';
import Gm_logo from 'src/assets/img/logos/electric-vehicle/gm.svg';
import Koenigsegg_logo from 'src/assets/img/logos/electric-vehicle/koenigsegg.svg';
import Xiaomi_logo from 'src/assets/img/logos/electric-vehicle/xiaomi.svg';
import Turntide_logo from 'src/assets/img/logos/electric-vehicle/turntide.png';
import Byd_logo from 'src/assets/img/logos/electric-vehicle/byd.svg';
import Aiways_logo from 'src/assets/img/logos/electric-vehicle/aiways.svg';
import Zeekr_logo from 'src/assets/img/logos/electric-vehicle/Zeekr.png';
import Baic_logo from 'src/assets/img/logos/electric-vehicle/baic.png';
import Ds_automobile_logo from 'src/assets/img/logos/electric-vehicle/ds-automobiles.svg';
import Hypercraft_logo from 'src/assets/img/logos/electric-vehicle/Hypercraft.png';
import Chrysler_logo from 'src/assets/img/logos/electric-vehicle/chrysler.svg';
import Webasto_logo from 'src/assets/img/logos/electric-vehicle/webasto.svg';
import Wae_logo from 'src/assets/img/logos/electric-vehicle/wae.png';
import Alpha_romeo_logo from 'src/assets/img/logos/electric-vehicle/alfa-romeo.svg';
import Lotus_logo from 'src/assets/img/logos/electric-vehicle/lotus.svg';
import Lithios_energy_logo from 'src/assets/img/logos/electric-vehicle/lithos-energy.png';
import Harley_davidson_logo from 'src/assets/img/logos/electric-vehicle/harley-davidson.svg';
import Toshibha_logo from 'src/assets/img/logos/electric-vehicle/toshiba.svg';
import Land_rover_logo from 'src/assets/img/logos/electric-vehicle/land-rover.svg';
import Xalt_energy_logo from 'src/assets/img/logos/electric-vehicle/xalt-energy.png';
import Maybach_logo from 'src/assets/img/logos/electric-vehicle/mercedes-maybach.svg';
import Geely_logo from 'src/assets/img/logos/electric-vehicle/geely.svg';
import Genesis_logo from 'src/assets/img/logos/electric-vehicle/genesis.jpg';
import Subaru_logo from 'src/assets/img/logos/electric-vehicle/subaru.svg';
import Proventia_logo from 'src/assets/img/logos/electric-vehicle/proventia.png';
import Mazda_logo from 'src/assets/img/logos/electric-vehicle/mazda.svg';
import Xing_mobility_logo from 'src/assets/img/logos/electric-vehicle/XingMobility.png';
import Ssang_yong_logo from 'src/assets/img/logos/electric-vehicle/ssangyong.svg';
import Zero_logo from 'src/assets/img/logos/electric-vehicle/Zero.png';
import Vinfast_logo from 'src/assets/img/logos/electric-vehicle/vinfast logo.png';
import BMZ_logo from 'src/assets/img/logos/electric-vehicle/BMZ.png';
import BorgWarner_logo from 'src/assets/img/logos/electric-vehicle/BorgWarner.png';
import CATL_logo from 'src/assets/img/logos/electric-vehicle/CATL.png';
import Hongqi_logo from 'src/assets/img/logos/electric-vehicle/Hong_Qi-Logo.jpeg';
import Atieva_logo from 'src/assets/img/logos/electric-vehicle/Atieva.png';
import Buick_logo from 'src/assets/img/logos/electric-vehicle/Buick.png';
import Ebattery_systems_logo from 'src/assets/img/logos/electric-vehicle/ebattery systems.png';
import Kreisel_logo from 'src/assets/img/logos/electric-vehicle/kreisel.svg';
import Forsee_logo from 'src/assets/img/logos/electric-vehicle/ForseePower.jpg';
import Kokam_logo from 'src/assets/img/logos/electric-vehicle/kokam-logo-vector.svg';
import Romeo_power_logo from 'src/assets/img/logos/electric-vehicle/Romeo Power.png';
import Nimbus_logo from 'src/assets/img/logos/electric-vehicle/nimbusev_logo.jpeg';
import Mg_energy_logo from 'src/assets/img/logos/electric-vehicle/MG energy.png';
import Microvast_logo from 'src/assets/img/logos/electric-vehicle/microvast.png';
import Reap_logo from 'src/assets/img/logos/electric-vehicle/Reap systems.png';
import Luxeed_logo from 'src/assets/img/logos/electric-vehicle/Luxeed.png';
import McMurtry_logo from 'src/assets/img/logos/electric-vehicle/mcmurtry_automotive_limited_logo.jpeg';

// pt-mm logoa
import Ansmann_logo from 'src/assets/img/logos/pt-mm/ANSMANN.jpg';
import Babboe_logo from 'src/assets/img/logos/pt-mm/babboe.png';
import Bosch_logo from 'src/assets/img/logos/pt-mm/bosch.svg';
import Dehawk_logo from 'src/assets/img/logos/pt-mm/DeHawk.png';
import Flyer_logo from 'src/assets/img/logos/pt-mm/flyer.png';
import Goswissdrive_logo from 'src/assets/img/logos/pt-mm/goswissdrive.png';
import Heinzmann_logo from 'src/assets/img/logos/pt-mm/heinzmann.png';
import Kettler_logo from 'src/assets/img/logos/pt-mm/Kettler.png';
import KTM_logo from 'src/assets/img/logos/pt-mm/KTM.png';
import Phylion_logo from 'src/assets/img/logos/pt-mm/phylion.png';
import Qwic_logo from 'src/assets/img/logos/pt-mm/QWIC.jpg';
import Samsung_logo from 'src/assets/img/logos/pt-mm/samsung.svg';
import Trannzx_logo from 'src/assets/img/logos/pt-mm/tranzx.svg';
import yamaha_logo from 'src/assets/img/logos/pt-mm/yamaha.svg';
import Shimano_logo from 'src/assets/img/logos/pt-mm/shimano.png';
import Protanium_logo from 'src/assets/img/logos/pt-mm/protanium.png';
import Fujian_logo from 'src/assets/img/logos/pt-mm/Fujian.jpg';
import ZF_logo from 'src/assets/img/logos/pt-mm/ZF.jpg';
import Jury_logo from 'src/assets/img/logos/pt-mm/Jury.png';
import DeWalt_logo from 'src/assets/img/logos/pt-mm/Dewalt.svg';
import Feihu_logo from 'src/assets/img/logos/pt-mm/Feihu.png';
import Inventus_power_logo from 'src/assets/img/logos/pt-mm/Inventus Power.svg';
import Johnson_Mathey_logo from 'src/assets/img/logos/pt-mm/johnson-matthey.svg';
import Rhy_battery_logo from 'src/assets/img/logos/pt-mm/rhy_battery.png';

import { BatteryPackModelType } from 'src/@types/BatteryDatabase';

export const PT_MM_DATA = [
  {
    NAME: 'Ansmann',
    LOGO: Ansmann_logo,
    TYPE: BatteryPackModelType.MM,
    DATA: [
      {
        name: 'Ansmann e-bike battery 36v',
        mappedCell: 'LG Chem INR18650-MJ1',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/a/n/ansmann-ebike-battery-36v_1.jpg',
      },
      {
        name: 'Ansmann Typ C 36v - Rahmen',
        mappedCell: 'Samsung INR18650-30Q',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/a/n/ansmann-18444-f123941-36v-accu_1.jpg',
      },
      {
        name: 'Ansmann e-bike battery 36v - Rahmen',
        mappedCell: 'Murata US21700VTC6A',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/a/n/ansmann-ebike-battery-frame-36v_2.jpg',
        badgeInfo: '80% upcycling potential',
      },
      {
        name: 'E-bike battery 36V - Rahmen',
        mappedCell: 'A123 A123_18650_M1A',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/p/o/powersmart-ebike-battery-36v_3.jpg',
      },
    ],
  },
  {
    NAME: 'Babboe',
    TYPE: BatteryPackModelType.MM,
    LOGO: Babboe_logo,
    DATA: [
      {
        name: 'Babboe Easi-A36 / ibo-R37 33,8v',
        mappedCell: 'Sony US18650VTC6',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/b/a/babboe-easi-a36-ibo-r37-33_8v.jpg',
        badgeInfo: '90% upcycling potential',
      },
      {
        name: 'Babboe Easi-36100-2AT 37v',
        mappedCell: 'LG Chem INR21700-M50LT',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/b/a/babboe-easi-36100-2at-_37v.jpg',
      },
    ],
  },
  {
    NAME: 'Bosch',
    LOGO: Bosch_logo,
    TYPE: BatteryPackModelType.PT,
    DATA: [
      {
        name: 'PBA 2.5Ah',
        mappedCell: 'Samsung INR18650-25R',
        image: BatteryOne,
        badgeInfo: '70% upcycling potential',
      },
      {
        name: 'PBA 4.0Ah',
        mappedCell: 'Samsung INR18650-30Q',
        image: BatteryTwo,
      },
      {
        name: 'PBA 5.0Ah',
        mappedCell: 'Samsung INR18650-30Q',
        image: BatteryThree,
        badgeInfo: '80% upcycling potential',
      },
      {
        name: 'Bosch PowerPack 300 Active/Performance - frame',
        mappedCell: 'Samsung INR18650-25R',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/b/o/bosch-powerpack-active-line-frame_11.jpg',
      },
      {
        name: 'Bosch PowerPack 300 Active/Performance - luggage rack',
        mappedCell: 'Samsung INR18650-25R',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/b/o/bosch-powerpack-active-line-bagage_1__2.jpg',
      },
      {
        name: 'Bosch PowerTube 500 horizontal 36v (0275007539)',
        mappedCell: 'Samsung INR18650-30Q',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/b/o/bosch-powertube-accu-36v-horizontaal_1.jpg',
      },
    ],
  },
  {
    NAME: 'DeHawk',
    LOGO: Dehawk_logo,
    TYPE: BatteryPackModelType.MM,
    DATA: [
      {
        name: 'DeHawk 36v Rahmen (für NCM Moscow, Milano, Venice)',
        mappedCell: 'LG Chem INR18650-MJ1',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/t/l/tl-36130a-frame-36v-akku.jpg',
      },
      {
        name: 'Flaschenakku 36v (DeHawk, LionTec, Prophete)',
        mappedCell: 'Molicel INR21700-P42A',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/t/y/type-bidon-accu-36v_1.jpg',
        badgeInfo: '80% upcycling potential',
      },
      {
        name: 'Faltrad Akku 36V (DeHawk, ELFEi, Fischer, NCM, Postbike)',
        mappedCell: 'Molicel INR-18650-P28A',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/f/i/fischer-framebuis-accu_3.jpg',
        badgeInfo: '85% upcycling potential',
      },
      {
        name: 'DeHawk 48v Rahmen (für NCM Moscow, Milano, Venice)',
        mappedCell: 'LG Chem INR21700-M50',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/d/e/dehawk-i5-rahmen-akku_1.jpg',
      },
      {
        name: 'Sachs / DeHawk 36v',
        mappedCell: 'DLG NCM18650-260',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/s/a/sachs-electra-3-36v-accu_kopie_1.jpg',
      },
      {
        name: 'Stella / DeHawk Typ 1 36v',
        mappedCell: 'Samsung INR21700-48X',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/s/t/stella-type-1-36v.jpg',
      },
      {
        name: 'Zhenlong Dolphin 24v (u.a. für Umbausatz DeHawk, Prophete, Mifa)',
        mappedCell: 'Sanyo NCR18650G-H09NA',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/z/h/zhenlong-dolphin-36v_1_.jpg',
        badgeInfo: '90% upcycling potential',
      },
    ],
  },
  {
    NAME: 'Flyer',
    LOGO: Flyer_logo,
    TYPE: BatteryPackModelType.MM,
    DATA: [
      {
        name: 'Flyer Premium 26v (Art. NKY210B02 / NKY224B02 / NKY269B2 / NKY281B2 / NKY314B2 / NKY335B2 / NKY374B2)',
        mappedCell: 'Samsung INR21700-50E',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/f/l/flyer-premium-panasonic-26v-accu_1__1_1.jpg',
      },
      {
        name: 'Flyer Deluxe 36v (Art. NKY352B2 / NKY370B2)',
        mappedCell: 'Samsung INR18650-25R',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/f/l/flyer-deluxe-36v-nky352b2-nky370b2.jpg',
      },
      {
        name: 'Flyer 36v Deluxe (Art. NKY365B2)',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/f/l/flyer-premium-panasonic-26v-accu_1__1_2.jpg',
        mappedCell: 'Samsung INR21700-48X',
        badgeInfo: '80% upcycling potential',
      },
      {
        name: 'Flyer 36v Panasonic Next Generation (Art. NKY482B2 / NKY502B2 / NKY504B2 / NKY547B2)',
        mappedCell: 'DLG NCM18650-260',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/f/l/flyer-next-generation-36v-nky502b2-nky504b2-nky547b2_.jpg',
        badgeInfo: '90% upcycling potential',
      },
      {
        name: 'Flyer 36v Panasonic Next Generation (Art. NKY467B2 / NKY503B2 / NKY509B2 / NKY546B2)',
        mappedCell: 'LG Chem INR21700-M50',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/f/l/flyer-next-generation-36v-panasonic-accu_5.jpg',
      },
      {
        name: 'Flyer Next Generation 36v (Art. NKY466B2 / NKY505B2 / NKY548B2)',
        mappedCell: 'Molicel INR-18650-P28A',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/f/l/flyer-next-generation-36v-panasonic-accu_6.jpg',
      },
      {
        name: 'Flyer Deluxe LCD 36v (Art. NKY428B2 / NKY434B2)',
        mappedCell: 'Molicel INR21700-P42A',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/f/l/flyer-deluxe-accu-lcd.jpg',
      },
      {
        name: 'Flyer 26v (Art. NKY177B02 / NKY190B02 / NKY195B02 / NKY232B02 / NKY463B2)',
        mappedCell: 'LG Chem INR18650-MJ1',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/f/l/flyer-panasonic-nky190b02-26v.jpg',
        badgeInfo: '80% upcycling potential',
      },
      {
        name: 'KTM / Flyer 48v (Art. NKY442B2 / NKY506B2 / NKY455B2)',
        mappedCell: 'Samsung INR18650-30Q',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/f/l/flyer-vollblut-nky455b2-48v-accu_1.jpg',
      },
    ],
  },
  {
    NAME: 'GoSwissDrive',
    LOGO: Goswissdrive_logo,
    TYPE: BatteryPackModelType.MM,
    DATA: [
      {
        name: 'BMZ 21179 / 18781 / 14091 / 23269 36v (für Alber, Go SwissDrive, Neodrive, Simplon)',
        mappedCell: 'Murata US21700VTC6A',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/b/m/bmz-21179-18781-14091-36v-accu_1.jpg',
      },
      {
        name: 'Brose / Go SwissDrive 36v (24832-1 / 23252-01)',
        mappedCell: 'A123 A123_18650_M1A',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/b/m/bmz-brose-24832-23252-36v.jpg',
        badgeInfo: '80% upcycling potential',
      },
      {
        name: 'Bulls Green Mover E45 36v (25718 / 16726)',
        mappedCell: 'LG Chem INR18650-MJ1',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/b/u/bulls-green-mover-e45-25718-16726-36v_1.jpg',
      },
    ],
  },
  {
    NAME: 'Heinzmann',
    LOGO: Heinzmann_logo,
    TYPE: BatteryPackModelType.PT,
    DATA: [
      {
        name: 'Heinzmann DirectPower Gepäckträger 36v',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/h/e/heinzmann-directpower-36v.jpg',
        badgeInfo: '80% upcycling potential',
        mappedCell: 'Samsung INR21700-50E',
      },
      {
        name: 'Heinzmann DirectPower 36v (Rahmen)',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/h/e/heinzmann-directpower-frame-36v.jpg',
        mappedCell: 'LG Chem INR18650-MJ1',
      },
      {
        name: 'Heinzmann Classic 36v (870-90-003-29)',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/b/m/bmz-heinzmann-classic-36v_1.jpg',
        mappedCell: 'Samsung INR21700-48X',
      },
      {
        name: 'Heinzmann Battery Box 36v - NiMH',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/h/e/heinzmann-nimh-36v.jpg',
        badgeInfo: '90% upcycling potential',
        mappedCell: 'DLG NCM18650-260',
      },
    ],
  },
  {
    NAME: 'Kettler',
    LOGO: Kettler_logo,
    TYPE: BatteryPackModelType.MM,
    DATA: [
      {
        name: 'Kettler Panasonic 26v (Art. NKY289B2 / NKY224B01 / NKY312B2 / NKY259B02 / NKY336B2 / NKY358B2)',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/k/e/kettler-panasonic-26v-nky289b2-nky224b01-nky312b2-nky259b02-nky336b2-accu-zwart_1.jpg',
        badgeInfo: '80% upcycling potential',
        mappedCell: 'Sanyo NCR18650G-H09NA',
      },
      {
        name: 'Kettler Panasonic 36v (Art. NKY479B2)',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/k/e/kettler-panasonic-nky479b2-36v-accu_3.jpg',
        badgeInfo: '75% upcycling potential',
        mappedCell: 'Samsung INR18650-30Q',
      },
      {
        name: 'Kettler Panasonic 36v (Art. NKY475B2, NKY522B2)',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/k/e/kettler-panasonic-nky479b2-36v-accu.jpg',
        mappedCell: 'LG Chem INR18650-MJ1',
      },
      {
        name: 'Kettler Panasonic Sport 36v (Art. NKY470B2)',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/k/e/kettler-panasonic-36v-nky470b2-accu_1.jpg',
        mappedCell: 'Samsung INR21700-48X',
      },
      {
        name: 'Kettler Panasonic Deluxe 36v (Art. NKY363B2, NKY388B2, NKY408B2)',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/k/e/kettler-deluxe-nky363b2-36v-accu_4.jpg',
        mappedCell: 'Sony US18650VTC6',
      },
      {
        name: 'Kettler Panasonic LCD 36v (Art. NKY431B2 / NKY437B2)',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/k/e/kettler-lcd-nky431b2-36v-accu_2.jpg',
        badgeInfo: '80% upcycling potential',
        mappedCell: 'Samsung INR21700-48X',
      },
    ],
  },
  {
    NAME: 'KTM',
    LOGO: KTM_logo,
    TYPE: BatteryPackModelType.MM,
    DATA: [
      {
        name: 'KTM Panasonic 36v (Art. NKY353B2 / NKY368B2)',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/k/t/ktm-nky353b2-nky368b2-36v-accu_1.jpg',
        mappedCell: 'Sanyo NCR18650G-H09NA',
      },
      {
        name: 'KTM Panasonic LCD 36v (Art. NKY429B2 / NKY430B2 / NKY436B2)',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/k/t/ktm-panasonic-nky430b2-nky436b2-36v.jpg',
        badgeInfo: '80% upcycling potential',
        mappedCell: 'Molicel INR-18650-P28A',
      },
      {
        name: 'KTM Panasonic 36v (Art. NKY483B2 / NKY484B2)',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/k/t/ktm-panasonic-36v-nky483b2-nky484b2-accu_1_2.jpg',
        badgeInfo: '80% upcycling potential',
        mappedCell: 'Samsung INR21700-50E',
      },
      {
        name: 'KTM Panasonic 36v (Art. NKY464B2 / NKY476B2 / NKY477B2)',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/k/t/ktm-panasonic-36v-nky483b2-nky484b2-accu_3.jpg',
        mappedCell: 'A123 A123_18650_M1A',
      },
      {
        name: 'KTM / Flyer 48v (Art. NKY442B2 / NKY506B2 / NKY455B2)',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/f/l/flyer-vollblut-nky455b2-48v-accu_1.jpg',
        mappedCell: 'LithiumWerks APR18650M1B',
      },
      {
        name: 'KTM Panasonic NKY462B2 46,8V - Gepäckträger',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/k/t/ktm-panasonic-nky462b2-46_8v.jpg',
        badgeInfo: '90% upcycling potential',
        mappedCell: 'LG Chem INR21700-M50LT',
      },
    ],
  },
  {
    NAME: 'Phylion',
    LOGO: Phylion_logo,
    TYPE: BatteryPackModelType.MM,
    DATA: [
      {
        name: 'Phylion XH259-10J 25,9v - Rahmen',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/p/h/phylion-silverfish-xh259-10j_1_.jpg',
        mappedCell: 'LG Chem INR18650-MJ1',
      },
      {
        name: 'Phylion XH370-10J 37v - Rahmen',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/p/h/phylion-silverfish-xh259-10j_1__1.jpg',
        badgeInfo: '90% upcycling potential',
        mappedCell: 'LG Chem INR21700-M50',
      },
      {
        name: 'Phylion XH370-10J Wall-E 37v (für u.a. Aldi, Kreidler, Prophete, Saxonette, Trio)',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/p/h/phylion-xh370-10j-wall-e-37v_1_.jpg',
        mappedCell: 'Molicel INR21700-P42A',
      },
      {
        name: 'Phylion XH370-10J 37v (für RAP)',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/p/h/phylion-xh370-10j-rap-37v_1_.jpg',
        mappedCell: 'LG Chem INR21700-M50',
      },
    ],
  },
  {
    NAME: 'Protanium',
    LOGO: Protanium_logo,
    TYPE: BatteryPackModelType.PT,
    DATA: [
      {
        name: 'Protanium BP-L24100ICW (für Batavus Crescendo, Intermezzo, Lento)',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/p/r/protanium-bp-l24100icw-26_6v_1.jpg',
        badgeInfo: '70% upcycling potential',
        mappedCell: 'Samsung INR21700-48X',
      },
      {
        name: 'Protanium BP-L2410SH1 / BP-L2410SHW 26,6v',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/p/r/protanium-bp-l2410sh-26v-zilver_1_2.jpg',
        mappedCell: 'Molicel INR-18650-P28A',
      },
    ],
  },
  {
    NAME: 'QWIC',
    LOGO: Qwic_logo,
    TYPE: BatteryPackModelType.MM,
    DATA: [
      {
        name: 'Qwic 36v für Premium / Performance Series (Länge: 33 cm)',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/q/w/qwic-premium-performance-36v_3__1_1.jpg',
        badgeInfo: '85% upcycling potential',
        mappedCell: 'LithiumWerks APR18650M1B',
      },
      {
        name: 'Qwic 36v für Premium / Performance Series (Länge: 36 cm)',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/q/w/qwic-premium-performance-36v_3__1.jpg',
        mappedCell: 'Samsung INR21700-50E',
      },
      {
        name: 'Qwic 36v für Trend / Compact Series (ab 2015)',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/q/w/qwic-trend-compact-36v_3_.jpg',
        mappedCell: 'LithiumWerks APR18650M1B',
      },
    ],
  },
  {
    NAME: 'Samsung SDI',
    LOGO: Samsung_logo,
    TYPE: BatteryPackModelType.PT,
    DATA: [
      {
        name: 'Samsung SDI-3610E 36v (für Aldi, Kreidler, Lidl, Prophete)',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/s/a/samsung-sdi-3610a-36v-oranje_1__1_1.jpg',
        mappedCell: 'Sanyo NCR18650G-H09NA',
      },
      {
        name: 'Samsung SDI-3613A / SDI-3614A 36v (für Aldi, Kreidler, Lidl, Prophete)',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/s/a/samsung-sdi-3610a-36v-oranje_1__1.jpg',
        badgeInfo: '80% upcycling potential',
        mappedCell: 'A123 A123_18650_M1A',
      },
      {
        name: 'Samsung SDI-3610F / SDI-3611A 36v (für Aldi, Kreidler, Lidl, Prophete)',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/s/a/samsung-sdi-3610f-sdi-3611a-36v-1.jpg',
        mappedCell: 'Samsung INR18650-30Q',
      },
      {
        name: 'Samsung SDI-2510B Side Click 25,4v (für Aldi, Kreidler, Lidl, Prophete)',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/s/a/samsung-sdi-sideclick-accu-24v_2.jpg',
        badgeInfo: '70% upcycling potential',
        mappedCell: 'LG Chem INR21700-M50',
      },
    ],
  },
  {
    NAME: 'Shimano',
    LOGO: Shimano_logo,
    TYPE: BatteryPackModelType.MM,
    DATA: [
      {
        name: 'Shimano Steps BT-E6000 36v - Gepäckträger',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/s/h/shimano-steps-bt-e6000-36v-akku.jpg',
        badgeInfo: '80% upcycling potential',
        mappedCell: 'Murata US21700VTC6A',
      },
      {
        name: 'Shimano Steps BT-E6001 36v - Gepäckträger',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/s/h/shimano-steps-bt-e6000-36v-akku_1.jpg',
        mappedCell: 'LithiumWerks APR18650M1B',
      },
    ],
  },
  {
    NAME: 'TranzX',
    LOGO: Trannzx_logo,
    TYPE: BatteryPackModelType.MM,
    DATA: [
      {
        name: 'TranzX JD-PST BL01 24v Li-ion / Li-Polymer',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/t/r/tranzx-bl01-24v-8ah.jpg',
        mappedCell: 'Molicel INR21700-P42A',
      },
      {
        name: 'TranzX JD-PST BL01 24v NiMH',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/t/r/tranzx-bl01-24v-nimh.jpg',
        mappedCell: 'LG Chem INR18650-MJ1',
      },
      {
        name: 'TranzX JD-PST BL03 24v Li-ion / Li-Polymer',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/t/r/tranzx-jd-pst-bl03-grijs_1.jpg',
        badgeInfo: '90% upcycling potential',
        mappedCell: 'Molicel INR-18650-P28A',
      },
      {
        name: 'TranzX JD-PST BL03 36v Li-ion / Li-Polymer',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/t/r/tranzx-jd-pst-bl03-grijs.jpg',
        mappedCell: 'LG Chem INR21700-M50',
      },
    ],
  },
  {
    NAME: 'Yamaha',
    LOGO: yamaha_logo,
    TYPE: BatteryPackModelType.MM,
    DATA: [
      {
        name: 'Yamaha PASB2 / PASB5 B94 36v - Rahmen',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/y/a/yamaha-pasb2-pasb5-94-00-rahmen-akku.jpg',
        badgeInfo: '75% upcycling potential',
        mappedCell: 'DLG NCM18650-260',
      },
      {
        name: 'Yamaha PASB2 B94 36v - Gepäckträger',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/y/a/yamaha-pasb2-94-00-akku.jpg',
        mappedCell: 'A123 A123_18650_M1A',
      },
      {
        name: 'Sparta Pharos Finesse 24v',
        image:
          'https://www.heskon.de/media/catalog/product/cache/9/small_image/270x/9df78eab33525d08d6e5fb8d27136e95/s/p/sparta-pharos-finesse-24v.jpg',
        badgeInfo: '90% upcycling potential',
        mappedCell: 'Samsung INR21700-48X',
      },
    ],
  },
];

export const EV_DATA = [
  {
    NAME: 'Audi',
    LOGO: Audi_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Audi Q4 e-tron, 2021 45 QUATTRO',
        'Model Year': 2022,
        modelName: 'Q4 Sportback e-tron 45 Quattro',
        'Nominal Capacity': '82 KWh',
        'Useable Capacity': '76,6 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '255',
        Image: Audi_1,
      },
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Audi e-tron, 2018 55 QUATTRO',
        'Model Year': 2021,
        modelName: 'Q4 55 E-tron AWD',
        'Nominal Capacity': '82 kWh',
        'Useable Capacity': '77,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '288',
        Image: Audi_2,
      },
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Audi e-tron, 2018 55 ELECTRIC QUATTRO',
        'Model Year': 2020,
        modelName: 'Q4 55 E-tron AWD',
        'Nominal Capacity': '82 kWh',
        'Useable Capacity': '77,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '288',
        Image: Audi_3,
      },
    ],
  },
  {
    NAME: 'BMW',
    LOGO: BMW_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'BMW iX3, 2021 ELECTRIC',
        'Model Year': 2021,
        modelName: 'iX3',
        'Nominal Capacity': '80,0 kWh',
        'Useable Capacity': '74,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '188',
        Image: BMW_1,
      },
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'BMW iX, 2021 (I20) XDRIVE 40',
        'Model Year': 2022,
        modelName: 'iX xDrive40',
        'Nominal Capacity': '76,6 kWh',
        'Useable Capacity': '71,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '180',
        Image: BMW_2,
      },
    ],
  },
  {
    NAME: 'Citroen',
    LOGO: Citroen_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Citroen e-C4 2020 E-C4',
        'Model Year': 2023,
        modelName: 'e-C4',
        'Nominal Capacity': '50,0 kWh',
        'Useable Capacity': '46,3 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '216',
        Image: Citreon_1,
      },
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Citroen Jumpy, 2016 (Type III) E-JUMPY ELECTRIC',
        'Model Year': 2021,
        modelName: 'e-Jumpy Combi',
        'Nominal Capacity': '50,0 kWh',
        'Useable Capacity': '46,3 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '216',
        Image: Citreon_1,
      },
    ],
  },
  {
    NAME: 'Ford',
    LOGO: Ford_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Ford Mustang Mach-E, 2020 ER RWD',
        'Model Year': 2022,
        modelName: 'Mach E extended range',
        'Nominal Capacity': '98,7 kWh',
        'Useable Capacity': '91,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '376',
        Image: Ford_1,
      },
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Ford Mustang Mach-E, ER RWD',
        'Model Year': 2021,
        modelName: 'Mach E extended range',
        'Nominal Capacity': '98,7 kWh',
        'Useable Capacity': '91,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '376',
        Image: Ford_2,
      },
    ],
  },
  {
    NAME: 'Hyundai',
    LOGO: Hyundai_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Hyundai Ioniq 5, 2021',
        'Model Year': 2022,
        modelName: 'Ioniq5 2021',
        'Nominal Capacity': '58,0 kWh',
        'Useable Capacity': '54,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '288',
        Image: Hyundai_2,
      },
    ],
  },
  {
    NAME: 'Jaguar',
    LOGO: Jaguar_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Jaguar I-PACE, EV400',
        modelName: '2018 I-Pace',
        'Nominal Capacity': '90,0 kWh',
        'Useable Capacity': '84,7 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '108',
        'Model Year': 2022,
        Image: Jaguar_1,
      },
    ],
  },
  {
    NAME: 'Kia',
    LOGO: Kia_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Kia e-Soul, 2019 (Type III) E-SOUL',
        modelName: 'e-Soul',
        'Nominal Capacity': '67,5 kWh',
        'Useable Capacity': '64,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '294',
        'Model Year': 2021,
        Image: Kia_1,
      },
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Kia e-Soul, 2019 (Type III) E-SOUL',
        modelName: 'e-Soul',
        'Nominal Capacity': '67,5 kWh',
        'Useable Capacity': '64,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '294',
        'Model Year': 2021,
        Image: Kia_3,
      },
    ],
  },
  {
    NAME: 'Lexus',
    LOGO: Lexus_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Lexus UX 300E (KMA10)',
        modelName: 'UX300e',
        'Nominal Capacity': '72,8 kWh',
        'Useable Capacity': '64,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '96',
        'Model Year': 2021,
        Image: Lexus,
      },
    ],
  },
  {
    NAME: 'Mercedes',
    LOGO: Mercedes_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Mercedes EQV 300 Long 2020',
        modelName: 'Mercedes EQV 300 Long 90,0 kWh',
        'Nominal Capacity': '100,0 kWh',
        'Useable Capacity': '90,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '288',
        'Model Year': 2021,
        Image: Mercedes_1,
      },
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Mercedes EQC, 2019 EQC 400 4-MATIC',
        modelName: 'EQC 400',
        'Nominal Capacity': '85,0 kWh',
        'Useable Capacity': '80,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '384',
        'Model Year': 2021,
        Image: 'https://finndelstorage.blob.core.windows.net/images/159/l493046a.jpg',
      },
    ],
  },
  {
    NAME: 'MG',
    LOGO: Mg_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'MG Marvel R, 2021',
        modelName: 'Marvel R',
        'Nominal Capacity': '75,0 kWh',
        'Useable Capacity': '70,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': 'TBC',
        'Model Year': 2022,
        Image: MG_1,
      },
    ],
  },
  {
    NAME: 'Mistubishi',
    LOGO: Mitsubishi_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Mitsubishi i-MiEV',
        modelName: 'iMIEV',
        'Nominal Capacity': '16,0 kWh',
        'Useable Capacity': '14,5 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': 'TBC',
        'Model Year': 2014,
        Image: Mistubushi_1,
      },
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Mitsubishi i-MiEV',
        modelName: 'iMIEV',
        'Nominal Capacity': '16,0 kWh',
        'Useable Capacity': '14,5 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': 'TBC',
        'Model Year': 2012,
        Image: Mistubushi_2,
      },
    ],
  },
  {
    NAME: 'Nissan',
    LOGO: Nissan_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Nissan Ariya, 2022',
        modelName: 'Ariya 87kWh',
        'Nominal Capacity': '91,0 kWh',
        'Useable Capacity': '87,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': 'TBC',
        'Model Year': 2023,
        Image: Nissan,
      },
    ],
  },
  {
    NAME: 'Opel',
    LOGO: Opel_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Opel Corsa-e, 2019',
        modelName: 'Opel Corsa Electric 50,0 kWh',
        'Nominal Capacity': '50,0 kWh',
        'Useable Capacity': '46,3 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '216',
        'Model Year': 2021,
        Image: Opel_1,
      },
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Opel Ampera-e, 2017 (Type II)',
        modelName: 'Opel Ampera-e 58,0 kWh',
        'Nominal Capacity': '62,2 kWh',
        'Useable Capacity': '58,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '288',
        'Model Year': 2019,
        Image: Opel_2,
      },
    ],
  },
  {
    NAME: 'Polestar',
    LOGO: Polestar_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Polestar 2, 2020',
        modelName: 'Polestar 2',
        'Nominal Capacity': '82,0 kWh',
        'Useable Capacity': '79,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '324',
        'Model Year': 2023,
        Image: Polestar,
      },
    ],
  },
  {
    NAME: 'Renault',
    LOGO: Renault_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Renault Zoe R135 ZE50, 2019',
        modelName: '2019 Zoe ZE 50',
        'Nominal Capacity': '54,7 kWh',
        'Useable Capacity': '52,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '192',
        'Model Year': 2021,
        Image: 'https://evbaltic.com/wp-content/uploads/2021/11/RENAULT-ZOE-41-kWh.jpg',
      },
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Renault Zoe, ZE40, 2013-2019',
        modelName: 'Zoe ZE 40',
        'Nominal Capacity': '44,1 kWh',
        'Useable Capacity': '41,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '192',
        'Model Year': 2019,
        Image:
          'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeLm0-sSV2biRL5BnC3N5FzysWBUUxU3270CySyVZjYZWz-j5nqLFM0mN8TF3LbLab_UM&usqp=CAU',
      },
    ],
  },
  {
    NAME: 'Skoda',
    LOGO: Skoda_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Škoda Enyaq iV 80X, 2021',
        modelName: 'Enyaq iV',
        'Nominal Capacity': '82,0 kWh',
        'Useable Capacity': '77,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '288',
        'Model Year': 2022,
        Image: 'https://hd2.tudocdn.net/1028643?w=1000&fit=clip',
      },
    ],
  },
  {
    NAME: 'Tesla',
    LOGO: Tesla_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Tesla Model Y Long Range Dual Motor',
        modelName: 'Model Y Long Range Dual Motor',
        'Nominal Capacity': '78,1 kWh',
        'Useable Capacity': '75,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '4416',
        'Model Year': 2023,
        Image: Tesla_1,
      },
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Tesla Model 3 STANDARD RANGE',
        modelName: 'Model 3',
        'Nominal Capacity': '60,0 kWh',
        'Useable Capacity': '57,5 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': 'TBC',
        'Model Year': 2022,
        Image: Tesla_2,
      },
    ],
  },
  {
    NAME: 'Toyota',
    LOGO: Toyota_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Toyota bZ4X AWD',
        modelName: 'Toyota bZ4X AWD 64,0 kWh',
        'Nominal Capacity': '71,4 kWh',
        'Useable Capacity': '64,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '96',
        'Model Year': 2023,
        Image:
          'https://images.carexpert.com.au/resize/3000/-/app/uploads/2021/10/Toyota-bZ4x_01_18.jpg',
      },
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Toyota Proace City Electric L2',
        modelName: 'Toyota Proace City Verso Electric L2 50 kWh',
        'Nominal Capacity': '50,0 kWh',
        'Useable Capacity': '46,3 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '216',
        'Model Year': 2021,
        Image: Toyota_2,
      },
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Toyota RAV4, 2009-2013 (Type III, Fase 2)',
        modelName: 'Toyota RAV4 16.3 kWh',
        'Nominal Capacity': '18,1 kWh',
        'Useable Capacity': '16,3 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '96',
        'Model Year': 2012,
        Image: 'https://i.ebayimg.com/images/g/5n8AAOSwDaNkDFwE/s-l1200.webp',
      },
    ],
  },
  {
    NAME: 'Volvo',
    LOGO: Volvo_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Volvo XC40 RECHARGE AWD',
        modelName: 'XC40 Recharge AWD',
        'Nominal Capacity': '82,0 kWh',
        'Useable Capacity': '79,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '324',
        'Model Year': 2021,
        Image:
          'https://www.autoscout24.fr/cms-content-assets/4qBtYADXgsm4sC0LIbYHzX-00d47f6a278df4e318d740db616f99dd-259180_Volvo_XC40_Recharge_Battery_Package-1100.jpg',
      },
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Volvo XC40 RECHARGE AWD',
        modelName: 'XC40 Recharge AWD',
        'Nominal Capacity': '82,0 kWh',
        'Useable Capacity': '79,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '324',
        'Model Year': 2021,
        Image: Volvo_2,
      },
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'Volvo XC40 RECHARGE AWD',
        modelName: 'XC40 Recharge AWD',
        'Nominal Capacity': '82,0 kWh',
        'Useable Capacity': '79,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '324',
        'Model Year': 2021,
        Image: Volvo_3,
      },
    ],
  },
  {
    NAME: 'VW',
    LOGO: Volkswagen_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'VW ID.4 GTX',
        modelName: 'ID3/4 77kWh',
        'Nominal Capacity': '82,0 kWh',
        'Useable Capacity': '77,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '288',
        'Model Year': 2023,
        Image: VW_1,
      },
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'VW ID.4 PRO',
        modelName: 'ID3/4 77kWh',
        'Nominal Capacity': '82,0 kWh',
        'Useable Capacity': '77,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '288',
        'Model Year': 2022,
        Image: VW_2,
      },
      {
        partInfo: 'Battery Pack, Electric Car',
        'Car Info': 'VW ID.4 PRO',
        modelName: 'ID3/4 77kWh',
        'Nominal Capacity': '82,0 kWh',
        'Useable Capacity': '77,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '288',
        'Model Year': 2022,
        Image: VW_3,
      },
    ],
  },
];

export const HYBRID_BATTERY_PACK_DATA = [
  {
    NAME: 'Audi',
    LOGO: Audi_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Hybrid',
        'Car Info': 'Audi Q7 (Type II) 3,0 TDI E-TRON QUATTRO',
        modelName: 'Q7 e-tron 17,3 kWh',
        'Nominal Capacity': 'TBC',
        'Useable Capacity': '17,3 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '168',
        'Model Year': 2017,
        Image: 'https://finndelstorage.blob.core.windows.net/images/159/l465441a.jpg',
      },
    ],
  },
  {
    NAME: 'BMW',
    LOGO: BMW_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Hybrid',
        'Car Info': 'BMW 3-Serie 330 2.0E PLUG-IN HYBRID XDRIVE TOURING',
        'Model Year': 2021,
        modelName: 'BMW 330e 12,0 kWh',
        'Nominal Capacity': 'TBC',
        'Useable Capacity': '12,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': 'TBC',
        Image: BMW_Module_1,
      },
    ],
  },
  {
    NAME: 'Citroën',
    LOGO: Citroen_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Hybrid',
        'Car Info': 'Citroën C5, Aircross, 1.6 PLUG-IN HYBRID',
        'Model Year': 2022,
        modelName: 'Citroën C5 13,0 kWh',
        'Nominal Capacity': 'TBC',
        'Useable Capacity': '13,0 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': 'TBC',
        Image:
          'https://cdn.proxyparts.com/parts/101293/18794147/large/1a78b9fb-5982-43a0-aebb-587911998d66.jpg',
      },
    ],
  },
  {
    NAME: 'Ford',
    LOGO: Ford_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Hybrid',
        'Car Info': 'Ford Mondeo, 2014-2019 (Type V, Fase 1) 2.0 HYBRID',
        modelName: 'Ford Mondeo 1,4 kWh',
        'Nominal Capacity': 'TBC',
        'Useable Capacity': '1,4 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': 'TBC',
        'Model Year': 2018,
        Image: 'https://www.galamotors.co.uk/wp-content/uploads/2021/01/2017-08-12-18.53.12.jpg',
      },
    ],
  },
  {
    NAME: 'Hyundai',
    LOGO: Hyundai_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Hybrid',
        'Car Info': 'Hyundai Tucson 1.6 T-GDI HYBRID 48V HTRAC',
        modelName: 'Hyundai Tucson IV 1.6 T-GDI',
        'Nominal Capacity': 'TBC',
        'Useable Capacity': '0,44 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': 'TBC',
        'Model Year': 2022,
        Image: 'https://i.ebayimg.com/images/g/MOcAAOSwjwlkIblA/s-l1200.webp',
      },
    ],
  },
  {
    NAME: 'Kia',
    LOGO: Kia_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Hybrid',
        'Car Info': 'Kia Optima, 2017-2020, 2.0 GDI HYBRID',
        modelName: 'Kia Optima Hybrid',
        'Nominal Capacity': '1.76 kWh',
        'Useable Capacity': '1,62 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': 'TBC',
        'Model Year': 2018,
        Image: 'https://i.ebayimg.com/images/g/qk4AAOSwbLli9mvh/s-l1600.jpg',
      },
      {
        partInfo: 'Battery Pack, Hybrid',
        'Car Info': 'Kia Niro, 2016-2021, 1.6 GDI Hybrid',
        modelName: 'Kia Niro Hybrid',
        'Nominal Capacity': 'TBC',
        'Useable Capacity': '1,32 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': 'TBC',
        'Model Year': 2017,
        Image:
          'https://asmeu1.s3.amazonaws.com/pinproHostedImages/d/a/c/h/a/a/i/d8f791d4-b1de-4b7c-a19d-b927fd9bcd49.jpeg',
      },
    ],
  },
  {
    NAME: 'Mercedes',
    LOGO: Mercedes_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Hybrid',
        'Car Info': 'Mercedes-Benz GLE 350 de 4MATIC generation V167',
        modelName: 'Mercedes-Benz GLE 350 de 4MATIC',
        'Nominal Capacity': 'TBC',
        'Useable Capacity': '31,2 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': 'TBC',
        'Model Year': 2022,
        Image:
          'https://cdn.proxyparts.com/parts/101293/18798556/large/ef4706f0-0723-448c-b454-9ad92e7159f3.jpg',
      },
      {
        partInfo: 'Battery Pack, Hybrid',
        'Car Info': 'Mercedes GLC X253 300 E 4-MATIC HYBRID',
        modelName: 'Mercedes GLC X253 300 E 4-MATIC',
        'Nominal Capacity': 'TBC',
        'Useable Capacity': '13,5 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': 'TBC',
        'Model Year': 2021,
        Image: 'https://i.ebayimg.com/images/g/vtMAAOSwGOVkDNGG/s-l1600.jpg',
      },
    ],
  },
  {
    NAME: 'Mitsubishi',
    LOGO: Mitsubishi_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Hybrid',
        'Car Info': 'Mitsubishi Outlander, 2015 (Type III, Fase 2) 2.4 HYBRID',
        modelName: 'Mercedes GLC X253 300 E 4-MATIC',
        'Nominal Capacity': 'TBC',
        'Useable Capacity': 'TBC',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': 'TBC',
        'Model Year': 2020,
        Image: 'https://www.secondlife-evbatteries.com/cdn/shop/products/outlander-pack.jpg',
      },
    ],
  },
  {
    NAME: 'Peugeot',
    LOGO: Peugeot_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Hybrid',
        'Car Info': 'Peugeot 508, 2014-2018 (Type I, Fase 2) 2.0 HDI RXH HYBRID4',
        modelName: 'Peugeot 508 2.0 HDi Hybrid4',
        'Nominal Capacity': 'TBC',
        'Useable Capacity': 'TBC',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': 'TBC',
        'Model Year': 2015,
        Image:
          'https://cdn.proxyparts.com/parts/101293/16950517/medium/54d9709c-48f8-4e6e-a728-a5695eeaee66.jpg',
      },
    ],
  },
  {
    NAME: 'Suzuki',
    LOGO: Suzuki_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Hybrid',
        'Car Info': 'Suzuki Across, 2020 2.5 HYBRID',
        modelName: 'Suzuki Across 2.5 Plug-in Hybrid',
        'Nominal Capacity': 'TBC',
        'Useable Capacity': '18.1 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': 'TBC',
        'Model Year': 2021,
        Image: 'https://i.ebayimg.com/images/g/Iw0AAOSwJd1k3jNu/s-l1600.jpg',
      },
      {
        partInfo: 'Battery Pack, Hybrid',
        'Car Info': 'Suzuki Vitara, 2019 1.2 SHVS ALLGRIP',
        modelName: 'Suzuki Vitara, 2019 1.2 SHVS ALLGRIP',
        'Nominal Capacity': 'TBC',
        'Useable Capacity': 'TBC',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': 'TBC',
        'Model Year': 2019,
        Image: 'https://i.ebayimg.com/images/g/2IUAAOSwX0NlVlo6/s-l1600.jpg',
      },
    ],
  },
  {
    NAME: 'Toyota',
    LOGO: Toyota_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Hybrid',
        'Car Info': 'Toyota Corolla 2019 1.8 HYBRID',
        modelName: 'Toyota Corolla 2019 1.8 HYBRID',
        'Nominal Capacity': 'TBC',
        'Useable Capacity': 'TBC',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': '56',
        'Model Year': 2022,
        Image:
          'https://a.allegroimg.com/original/11b30c/d54fdc0041dfa31e0a00456ca267/BATERIA-AKUMULATOR-TOYOTA-COROLLA-E21-HYBRYDA-Numer-katalogowy-czesci-5789768967',
      },
      {
        partInfo: 'Battery Pack, Hybrid',
        'Car Info': 'Toyota Corolla, 2019 1.8 HYBRID STV',
        modelName: 'Toyota Corolla, 2019 1.8 HYBRID STV',
        'Nominal Capacity': 'TBC',
        'Useable Capacity': 'TBC',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': 'TBC',
        'Model Year': 2021,
        Image: 'https://i.ebayimg.com/images/g/Jc0AAOSwm9BglAcW/s-l1600.jpg',
      },
    ],
  },
  {
    NAME: 'VW',
    LOGO: Volkswagen_logo,
    DATA: [
      {
        partInfo: 'Battery Pack, Hybrid',
        'Car Info': 'VW Golf VII, 2017-2020 (Fase 2), 1.4 GTE HYBRID',
        modelName: 'VW Golf VII 1.4 GTE HYBRID',
        'Nominal Capacity': 'TBC',
        'Useable Capacity': '8,7 kWh',
        'Battery Type': 'Lithium-ion',
        'Number of Cells': 'TBC',
        'Model Year': 2017,
        Image: 'https://openinverter.org/wiki/images/thumb/e/e8/A09A7628.jpg/300px-A09A7628.jpg',
      },
    ],
  },
];

export const MODULE_DATA = [
  {
    NAME: 'Audi',
    LOGO: Audi_logo,
    DATA: [
      {
        partInfo: 'Battery Module',
        'Car Info': 'Audi Q5 PHEV Module',
        Voltage: '13s1p L & M Modules 48v / 12s1p 44v',
        Capacity: '37Ah',
        Image: 'https://jag35.com/cdn/shop/files/Audi-44v-1_960x.jpg?v=1694126318',
        Weight: 'approx. 28 kg',
        Dimensions: '15 x 6 x 4.5 in',
      },
    ],
  },
  {
    NAME: 'BMW',
    LOGO: BMW_logo,
    DATA: [
      {
        partInfo: 'Battery Module',
        'Car Info': '4.15 kWh BMW i3 battery module 45V 94Ah nominal',
        Voltage: '3.8V/Cell, 45.6V/Module',
        Capacity: '94Ah',
        Image: BMW_i3_Module,
        Weight: '28 kg',
        Dimensions: '41 X 31 X 15 cm (L X W X H)',
      },
      {
        partInfo: 'Battery Module',
        'Car Info': '5.3kWh BMW I3 Battery Module 45V 120Ah Nominal',
        Voltage: '3.8V/Cell, 45.6V/Module',
        Capacity: '120Ah',
        Image: 'https://jag35.com/cdn/shop/files/Audi-44v-1_960x.jpg?v=1694126318',
        Weight: '28 kg',
        Dimensions: '41 X 31 X 15 cm (L X W X H)',
      },
    ],
  },
  {
    NAME: 'Nissan',
    LOGO: Nissan_logo,
    DATA: [
      {
        partInfo: 'Battery Module',
        'Car Info': 'Nissan Leaf Gen 2',
        Voltage: '3.65V/Cell, 7.4V/Module',
        Capacity: '60Ah',
        Image: 'https://evshop.eu/4096-medium_default/module-from-nissan-leaf-gen-2-24kwh.jpg',
        Weight: '3.64 kg',
        Dimensions: '30 X 22.2 X 3.4 cm (L X W X H)',
      },
    ],
  },
  {
    NAME: 'Mitsubishi',
    LOGO: Mitsubishi_logo,
    DATA: [
      {
        partInfo: 'Battery Module',
        Capacity: '2.4 Kwh',
        Voltage: '3.75V/Cell, 60V/Module',
        'Car Info': 'Mitsubishi Outlander PHEV Module 2.4 KWh',
        Weight: '26 kg',
        Dimensions: '64.6 x 18.4 x 13 mm (L x W x H)',
        Image: 'https://evshop.eu/4098-medium_default/mitsubishi-outlander-phev-module-24-kwh.jpg',
      },
    ],
  },
  {
    NAME: 'Volkswagen',
    LOGO: Volkswagen_logo,
    DATA: [
      {
        partInfo: 'Battery Module 1',
        Capacity: '234Ah, 6.85kWh',
        Voltage: '3.75V/Cell, 60V/Module',
        'Car Info': 'VW ID (MEB) Battery Module 30V 8S 6.85kWh',
        Weight: '32 kg',
        Dimensions: '59 x 22.5 x 11 mm (L x W x H)',
        Image: VW_MODULE_ID3,
      },
      {
        partInfo: 'Battery Module 2',
        Capacity: '156Ah, 6.85kWh',
        Voltage: '3.7V/Cell, 44.4V/Module',
        'Car Info': 'VW ID (MEB) Battery Module 48V 12S 6.85kWh',
        Weight: '32 kg',
        Dimensions: '59 x 22.5 x 11 mm (L x W x H)',
        Image: VW_MODULE_ID3,
      },
    ],
  },
  {
    NAME: 'Tesla',
    LOGO: Tesla_logo,
    DATA: [
      {
        partInfo: 'Battery Module',
        Capacity: '6.4 Kwhr',
        Voltage: '24.9 to 18.6 VDC',
        'Car Info': 'Tesla Model S',
        Weight: 'approx. 28 kg',
        Dimensions: '685 x 300 (280) x 75 mm (L x W x H)',
        Image: 'https://eveurope.eu/wp-content/uploads/2021/12/Tesla-module-64-kWh.png',
      },
      {
        partInfo: 'Battery Module',
        Capacity: '5.3 Kwhr',
        Voltage: '24.9 to 18.6 VDC',
        'Car Info': 'Tesla Model S',
        Weight: 'approx. 25 kg',
        Dimensions: '685 x 300 (280) x 75 mm (L x W x H)',
        Image: 'https://eveurope.eu/wp-content/uploads/2019/08/tesla-e1585406634731.png',
      },
    ],
  },
  {
    NAME: 'Kia',
    LOGO: Kia_logo,
    DATA: [
      {
        partInfo: 'Battery Module',
        Capacity: '6.4 Kwhr',
        Voltage: '24.9 to 18.6 VDC',
        'Car Info': 'Tesla Model S',
        Weight: 'approx. 28 kg',
        Dimensions: '685 x 300 (280) x 75 mm (L x W x H)',
        Image: 'https://eveurope.eu/wp-content/uploads/2021/12/Tesla-module-64-kWh.png',
      },
      {
        partInfo: 'Battery Module',
        Capacity: '5.3 Kwhr',
        Voltage: '24.9 to 18.6 VDC',
        'Car Info': 'Tesla Model S',
        Weight: 'approx. 25 kg',
        Dimensions: '685 x 300 (280) x 75 mm (L x W x H)',
        Image: 'https://eveurope.eu/wp-content/uploads/2019/08/tesla-e1585406634731.png',
      },
      {
        partInfo: 'Battery Module',
        Capacity: '6.4 Kwhr',
        Voltage: '24.9 to 18.6 VDC',
        'Car Info': 'Tesla Model S',
        Weight: 'approx. 28 kg',
        Dimensions: '685 x 300 (280) x 75 mm (L x W x H)',
        Image: 'https://eveurope.eu/wp-content/uploads/2021/12/Tesla-module-64-kWh.png',
      },
    ],
  },
  {
    NAME: 'Lexus',
    LOGO: Lexus_logo,
    DATA: [
      {
        partInfo: 'Battery Module',
        Capacity: '6.4 Kwhr',
        Voltage: '24.9 to 18.6 VDC',
        'Car Info': 'Tesla Model S',
        Weight: 'approx. 28 kg',
        Dimensions: '685 x 300 (280) x 75 mm (L x W x H)',
        Image: 'https://eveurope.eu/wp-content/uploads/2021/12/Tesla-module-64-kWh.png',
      },
    ],
  },
  {
    NAME: 'Mercedes',
    LOGO: Mercedes_logo,
    DATA: [
      {
        partInfo: 'Battery Module',
        Capacity: '6.4 Kwhr',
        Voltage: '24.9 to 18.6 VDC',
        'Car Info': 'Tesla Model S',
        Weight: 'approx. 28 kg',
        Dimensions: '685 x 300 (280) x 75 mm (L x W x H)',
        Image: 'https://eveurope.eu/wp-content/uploads/2021/12/Tesla-module-64-kWh.png',
      },
      {
        partInfo: 'Battery Module',
        Capacity: '6.4 Kwhr',
        Voltage: '24.9 to 18.6 VDC',
        'Car Info': 'Tesla Model S',
        Weight: 'approx. 28 kg',
        Dimensions: '685 x 300 (280) x 75 mm (L x W x H)',
        Image: 'https://eveurope.eu/wp-content/uploads/2021/12/Tesla-module-64-kWh.png',
      },
    ],
  },
  {
    NAME: 'Opel',
    LOGO: Opel_logo,
    DATA: [
      {
        partInfo: 'Battery Module',
        Capacity: '6.4 Kwhr',
        Voltage: '24.9 to 18.6 VDC',
        'Car Info': 'Tesla Model S',
        Weight: 'approx. 28 kg',
        Dimensions: '685 x 300 (280) x 75 mm (L x W x H)',
        Image: 'https://eveurope.eu/wp-content/uploads/2021/12/Tesla-module-64-kWh.png',
      },
      {
        partInfo: 'Battery Module',
        Capacity: '6.4 Kwhr',
        Voltage: '24.9 to 18.6 VDC',
        'Car Info': 'Tesla Model S',
        Weight: 'approx. 28 kg',
        Dimensions: '685 x 300 (280) x 75 mm (L x W x H)',
        Image: 'https://eveurope.eu/wp-content/uploads/2021/12/Tesla-module-64-kWh.png',
      },
    ],
  },
  {
    NAME: 'Peugeot',
    LOGO: Peugeot_logo,
    DATA: [
      {
        partInfo: 'Battery Module',
        Capacity: '6.4 Kwhr',
        Voltage: '24.9 to 18.6 VDC',
        'Car Info': 'Tesla Model S',
        Weight: 'approx. 28 kg',
        Dimensions: '685 x 300 (280) x 75 mm (L x W x H)',
        Image: 'https://eveurope.eu/wp-content/uploads/2021/12/Tesla-module-64-kWh.png',
      },
      {
        partInfo: 'Battery Module',
        Capacity: '6.4 Kwhr',
        Voltage: '24.9 to 18.6 VDC',
        'Car Info': 'Tesla Model S',
        Weight: 'approx. 28 kg',
        Dimensions: '685 x 300 (280) x 75 mm (L x W x H)',
        Image: 'https://eveurope.eu/wp-content/uploads/2021/12/Tesla-module-64-kWh.png',
      },
      {
        partInfo: 'Battery Module',
        Capacity: '6.4 Kwhr',
        Voltage: '24.9 to 18.6 VDC',
        'Car Info': 'Tesla Model S',
        Weight: 'approx. 28 kg',
        Dimensions: '685 x 300 (280) x 75 mm (L x W x H)',
        Image: 'https://eveurope.eu/wp-content/uploads/2021/12/Tesla-module-64-kWh.png',
      },
      {
        partInfo: 'Battery Module',
        Capacity: '6.4 Kwhr',
        Voltage: '24.9 to 18.6 VDC',
        'Car Info': 'Tesla Model S',
        Weight: 'approx. 28 kg',
        Dimensions: '685 x 300 (280) x 75 mm (L x W x H)',
        Image: 'https://eveurope.eu/wp-content/uploads/2021/12/Tesla-module-64-kWh.png',
      },
    ],
  },
  {
    NAME: 'Porsche',
    LOGO: Porsche_logo,
    DATA: [
      {
        partInfo: 'Battery Module',
        Capacity: '6.4 Kwhr',
        Voltage: '24.9 to 18.6 VDC',
        'Car Info': 'Tesla Model S',
        Weight: 'approx. 28 kg',
        Dimensions: '685 x 300 (280) x 75 mm (L x W x H)',
        Image: 'https://eveurope.eu/wp-content/uploads/2021/12/Tesla-module-64-kWh.png',
      },
      {
        partInfo: 'Battery Module',
        Capacity: '6.4 Kwhr',
        Voltage: '24.9 to 18.6 VDC',
        'Car Info': 'Tesla Model S',
        Weight: 'approx. 28 kg',
        Dimensions: '685 x 300 (280) x 75 mm (L x W x H)',
        Image: 'https://eveurope.eu/wp-content/uploads/2021/12/Tesla-module-64-kWh.png',
      },
    ],
  },
  {
    NAME: 'Renault',
    LOGO: Renault_logo,
    DATA: [
      {
        partInfo: 'Battery Module',
        Capacity: '6.4 Kwhr',
        Voltage: '24.9 to 18.6 VDC',
        'Car Info': 'Tesla Model S',
        Weight: 'approx. 28 kg',
        Dimensions: '685 x 300 (280) x 75 mm (L x W x H)',
        Image: 'https://eveurope.eu/wp-content/uploads/2021/12/Tesla-module-64-kWh.png',
      },
      {
        partInfo: 'Battery Module',
        Capacity: '6.4 Kwhr',
        Voltage: '24.9 to 18.6 VDC',
        'Car Info': 'Tesla Model S',
        Weight: 'approx. 28 kg',
        Dimensions: '685 x 300 (280) x 75 mm (L x W x H)',
        Image: 'https://eveurope.eu/wp-content/uploads/2021/12/Tesla-module-64-kWh.png',
      },
      {
        partInfo: 'Battery Module',
        Capacity: '6.4 Kwhr',
        Voltage: '24.9 to 18.6 VDC',
        'Car Info': 'Tesla Model S',
        Weight: 'approx. 28 kg',
        Dimensions: '685 x 300 (280) x 75 mm (L x W x H)',
        Image: 'https://eveurope.eu/wp-content/uploads/2021/12/Tesla-module-64-kWh.png',
      },
    ],
  },
  {
    NAME: 'Volvo',
    LOGO: Volvo_logo,
    DATA: [
      {
        partInfo: 'Battery Module',
        Capacity: '6.4 Kwhr',
        Voltage: '24.9 to 18.6 VDC',
        'Car Info': 'Tesla Model S',
        Weight: 'approx. 28 kg',
        Dimensions: '685 x 300 (280) x 75 mm (L x W x H)',
        Image: 'https://eveurope.eu/wp-content/uploads/2021/12/Tesla-module-64-kWh.png',
      },
      {
        partInfo: 'Battery Module',
        Capacity: '6.4 Kwhr',
        Voltage: '24.9 to 18.6 VDC',
        'Car Info': 'Tesla Model S',
        Weight: 'approx. 28 kg',
        Dimensions: '685 x 300 (280) x 75 mm (L x W x H)',
        Image: 'https://eveurope.eu/wp-content/uploads/2021/12/Tesla-module-64-kWh.png',
      },
    ],
  },
];

export const EV_BRAND_LOGO_MAPPING = {
  MCMurtry: McMurtry_logo,
  Xpeng: Xpeng_logo,
  Panasonic: Panasonic_logo,
  McLaren: McLaren_logo,
  Ford: Ford_logo,
  Rimac: Rimac_logo,
  'Swindon Powertrain': Swindon_powertrain_logo,
  Maserati: Maserati_logo,
  Dodge: Dodge_logo,
  'American Battery Solutions': American_battery_solutions_logo,
  Skoda: Skoda_logo,
  REAPSystems: Reap_logo,
  Fisker: Fisker_logo,
  'Yangwang (BYD)': Yangwang_logo,
  MAN: Man_logo,
  'LG Energy Solutions': Lg_logo,
  'Rolls Royce': Rolls_royce_logo,
  Leclanche: Lechlanche_logo,
  'FPT Industrial': Fpt_industrial_logo,
  Dacia: Dacia_logo,
  'Li Auto': Li_auto_logo,
  Audi: Audi_logo,
  Nio: Nio_logo,
  MG: Mg_logo,
  Lexus: Lexus_logo,
  Cupra: Cupra_logo,
  DAF: DAF_logo,
  Zero: Zero_logo,
  Jeep: Jeep_logo,
  Cadillac: Cadillac_logo,
  Yadea: Yadea_logo,
  Avatr: '',
  'Lucid Air': Lucid_air_logo,
  SAIC: SAIC_Logo,
  Ferrari: Ferrari_logo,
  BWM: BMW_logo,
  GMC: Gmc_logo,
  Lincoln: Lincoln_logo,
  Honda: Honda_logo,
  Mastervolt: Mastervolt_logo,
  MicroVast: Microvast_logo,
  Chevrolet: Chevrolet_logo,
  Tata: Tata_logo,
  Porsche: Porsche_logo,
  Kreisel: Kreisel_logo,
  Scania: Scania_logo,
  Electroflight: Electro_Flight_logo,
  Mercedes: Mercedes_logo,
  Tesla: Tesla_logo,
  Jaguar: Jaguar_logo,
  Mini: Mini_logo,
  Renault: Renault_logo,
  Kia: Kia_logo,
  Hongqi: Hongqi_logo,
  CATL: CATL_logo,
  'Super Soco': Super_soco_logo,
  Jishi: '',
  Fiat: Fiat_logo,
  Rivian: Rivian_logo,
  Buick: Buick_logo,
  Sehol: Sehol_logo,
  BorgWarner: BorgWarner_logo,
  Luxeed: Luxeed_logo,
  Hithium: Hithium_logo,
  Citroen: Citreon_logo,
  Nimbus: Nimbus_logo,
  'Great Wall Motors': Great_wall_logo,
  Cosworth: Cosworth_logo,
  Toyota: Toyota_logo,
  Maxus: Maxus_logo,
  Smart: Smart_logo,
  'Romeo Power': Romeo_power_logo,
  Vauxhall: Vauxhall_logo,
  MINI: Mini_logo,
  Bentley: Bentley_logo,
  Kokam: Kokam_logo,
  'FORSEE Power': Forsee_logo,
  Vinfast: Vinfast_logo,
  Peugeot: Peugeot_logo,
  'Volvo Trucks': Volvo_logo,
  Powertech: Powertech_logo,
  Ducati: Ducati_logo,
  GM: Gm_logo,
  Koenigsegg: Koenigsegg_logo,
  Atieva: Atieva_logo,
  'MG Energy Systems B.V.': Mg_energy_logo,
  Volvo: Volvo_logo,
  Polestar: Polestar_logo,
  Xiaomi: Xiaomi_logo,
  Suzuki: Suzuki_logo,
  Turntide: Turntide_logo,
  BYD: Byd_logo,
  Mitsubishi: Mitsubishi_logo,
  Aiways: Aiways_logo,
  Zeekr: Zeekr_logo,
  BAIC: Baic_logo,
  'DS Automobiles': Ds_automobile_logo,
  Hypercraft: Hypercraft_logo,
  Chrysler: Chrysler_logo,
  Webasto: Webasto_logo,
  WAE: Wae_logo,
  'e.battery systems': Ebattery_systems_logo,
  BMZ: BMZ_logo,
  'Alfa Romeo': Alpha_romeo_logo,
  Lotus: Lotus_logo,
  'Lithos Energy': Lithios_energy_logo,
  'Harley-Davidson': Harley_davidson_logo,
  Toshiba: Toshibha_logo,
  'Samsung SDI': Samsung_logo,
  'Land Rover': Land_rover_logo,
  'Xalt Energy': Xalt_energy_logo,
  'Mercedes-Maybach': Maybach_logo,
  Opel: Opel_logo,
  Geely: Geely_logo,
  Nissan: Nissan_logo,
  Genesis: Genesis_logo,
  Hyundai: Hyundai_logo,
  Subaru: Subaru_logo,
  Proventia: Proventia_logo,
  Mazda: Mazda_logo,
  BMW: BMW_logo,
  Volkswagen: Volkswagen_logo,
  XingMobility: Xing_mobility_logo,
  SsangYong: Ssang_yong_logo,
};

export const MM_BRAND_LOGO_MAPPING = {
  Ansmann: Ansmann_logo,
  Babboe: Babboe_logo,
  Bosch: Bosch_logo,
  Flyer: Flyer_logo,
  'Fujian EINCIO power technology': Fujian_logo,
  GoSwissDrive: Goswissdrive_logo,
  'Jinyun reypu electronic technology': Jury_logo,
  QWIC: Qwic_logo,
  Shimano: Shimano_logo,
  TranzX: Trannzx_logo,
  'ZF Micromobility': ZF_logo,
};

export const PT_BRAND_LOGO_MAPPING = {
  Bosch: Bosch_logo,
  DeWalt: DeWalt_logo,
  Feihu: Feihu_logo,
  Heinzmann: Heinzmann_logo,
  'Inventus Power': Inventus_power_logo,
  'Johnson Matthey': Johnson_Mathey_logo,
  Protanium: Protanium_logo,
  'RHY Battery': Rhy_battery_logo,
  'Samsung SDI': Samsung_logo,
};
