import * as React from 'react';
import NewsCard from 'src/components/Base/NewsCard';
import { PageContainer } from 'src/components/Structure';
import { TEXT_CONTENTS } from 'src/constants';
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

const News = () => {
  const theme = useTheme();
  const [cardsToShow, setCardsToShow] = React.useState(4);

  return (
    <PageContainer
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant="h2" sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>
        {TEXT_CONTENTS.NEWS.TITLE}
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: theme.spacing(2),
        }}
      >
        {TEXT_CONTENTS.NEWS.ARTICLES.slice(0, cardsToShow).map((news, index) => {
          return (
            <Grid
              key={index}
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={4}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: { xs: theme.spacing(0), sm: theme.spacing(0), md: theme.spacing(1) },
                marginRight: { xs: theme.spacing(0), sm: theme.spacing(0), md: theme.spacing(1) },
                marginBottom: theme.spacing(1),
                marginTop: theme.spacing(1),
              }}
            >
              <NewsCard
                title={news.TITLE}
                date={news.DATE}
                description={news.DESCRIPTION}
                media={news.MEDIA}
                url={news.URL}
              />
            </Grid>
          );
        })}
      </Grid>
      {cardsToShow < TEXT_CONTENTS.NEWS.ARTICLES.length && (
        <Box
          sx={{
            '& > :not(style)': { m: 5 },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Fab
            color="secondary"
            aria-label="load"
            variant="extended"
            onClick={() => setCardsToShow(cardsToShow + 2)}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="body1" sx={{ fontSize: '18px' }}>
              Load More
            </Typography>
            <KeyboardDoubleArrowDownIcon />
          </Fab>
        </Box>
      )}
    </PageContainer>
  );
};

export default News;
