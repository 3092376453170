import React, { useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { visuallyHidden } from '@mui/utils';
import { Stack } from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';

import { PENDING_APPROVALS, APPROVE_USERS, GET_USER_DETAILS } from 'src/graphql/mutations/auth';
import { UserDetails } from 'src/@types/AuthTypes';
import { useMessages } from 'src/components/hooks';
import { TEXT_CONTENTS } from 'src/constants';

interface Data {
  email: string;
  company: string;
  role: string;
  usecase: string;
  frequency: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'email',
    numeric: false,
    disablePadding: true,
    label: TEXT_CONTENTS.APPROVALS.COLUMN_HEADERS.EMAIL,
  },
  {
    id: 'company',
    numeric: false,
    disablePadding: true,
    label: TEXT_CONTENTS.APPROVALS.COLUMN_HEADERS.COMPANY,
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: TEXT_CONTENTS.APPROVALS.COLUMN_HEADERS.ROLE,
  },
  {
    id: 'usecase',
    numeric: false,
    disablePadding: false,
    label: TEXT_CONTENTS.APPROVALS.COLUMN_HEADERS.USE_CASE,
  },
  {
    id: 'frequency',
    numeric: false,
    disablePadding: false,
    label: TEXT_CONTENTS.APPROVALS.COLUMN_HEADERS.FREQUENCY,
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all users',
            }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  handleApproval: () => void;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, handleApproval } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: theme =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} {TEXT_CONTENTS.APPROVALS.NUM_SELECTED}
        </Typography>
      ) : (
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          {TEXT_CONTENTS.APPROVALS.TITLE}
        </Typography>
      )}
      {numSelected > 0 && (
        <Tooltip title="Approve">
          <IconButton color="primary" onClick={() => handleApproval()}>
            <Stack direction="row" spacing={1}>
              <CheckCircleIcon />
              <Typography>Approve</Typography>
            </Stack>
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}
export default function Approvals() {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('email');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [restriced, setRestricted] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { data, loading, error } = useQuery(PENDING_APPROVALS);
  const [approveUsers] = useMutation(APPROVE_USERS);
  const { setSuccessMessage, setErrorMessage } = useMessages();
  const userDetails = useQuery(GET_USER_DETAILS);
  const rows = React.useMemo(() => {
    if (!data) return [];
    return data.getPendingUserDetails.map((user: UserDetails) => ({
      email: user.email,
      company: user.company,
      role: user.role,
      usecase: user.usecase,
      frequency: user.frequency,
    })) as Data[];
  }, [data]);

  useEffect(() => {
    if (userDetails.data.getUserDetails.userType !== 'super_admin') {
      setRestricted(true);
    }
  }, [userDetails.data]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map(n => n.email);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, email: string) => {
    const selectedIndex = selected.indexOf(email);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, email);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleApproval = async () => {
    const selectedUsers = data.getPendingUserDetails.filter((user: UserDetails) =>
      selected.includes(user.email)
    ) as UserDetails[];
    const selectedIds = selectedUsers.map(user => user.id);
    try {
      const approvedUserIds = await approveUsers({
        variables: {
          userIds: selectedIds,
        },
      });
      selectedUsers.forEach(({ id, email }) => {
        if (approvedUserIds.data.approveUsers.includes(id)) {
          setSuccessMessage(`${TEXT_CONTENTS.APPROVALS.APPROVAL_SUCCEDDED} ${email}`);
        } else {
          setErrorMessage(`${TEXT_CONTENTS.APPROVALS.APPROVAL_FAILED} ${email}`);
        }
      });
    } catch (error) {
      console.error('Error approving users', error);
    }
  };

  const isSelected = (email: string) => selected.indexOf(email) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [rows, order, orderBy, page, rowsPerPage]
  );

  if (loading || userDetails.loading) {
    return <Typography variant="body1">{TEXT_CONTENTS.COMMON.LOADING}</Typography>;
  }

  if (error || userDetails.loading) {
    return <Typography variant="body1">{TEXT_CONTENTS.COMMON.ERROR}</Typography>;
  }

  if (restriced) {
    return <Typography variant="body1">{TEXT_CONTENTS.COMMON.NOT_AUTHORISED}</Typography>;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} handleApproval={handleApproval} />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.email);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={event => handleClick(event, row.email)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.email}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      {row.email}
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      {row.company}
                    </TableCell>
                    <TableCell>{row.role}</TableCell>
                    <TableCell>{row.usecase}</TableCell>
                    <TableCell>{row.frequency}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
