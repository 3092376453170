import { cachePersistor } from 'src/graphql/client';
import { signInWithPopup, OAuthProvider, signOut } from 'firebase/auth';

const authResolvers = {
  Query: {},
  Mutation: {
    loginViaMicrosoft: async (
      _: void,
      __: void,
      // eslint-disable-next-line
      { client }: any
    ) => {
      const auth = client.defaultOptions.firebase.getAuth();
      try {
        const microsoftProvider = new OAuthProvider('microsoft.com');
        await signInWithPopup(auth, microsoftProvider);

        // eslint-disable-next-line
      } catch (error: any) {
        throw new Error(error.code || error.message);
      }
    },
    loginViaGoogle: async (
      _: void,
      __: void,
      // eslint-disable-next-line
      { client }: any
    ) => {
      const auth = client.defaultOptions.firebase.getAuth();
      try {
        const googleProvider = new OAuthProvider('google.com');
        await signInWithPopup(auth, googleProvider);

        // eslint-disable-next-line
      } catch (error: any) {
        throw new Error(error.code || error.message);
      }
    },
    logout: async (
      _: void,
      __: void,
      // eslint-disable-next-line
      { client }: any
    ) => {
      const auth = client.defaultOptions.firebase.getAuth();
      // Sign out of Firebase
      await signOut(auth);
      // Clear cache
      await cachePersistor.purge();
      // Clear Apollo store
      await client.clearStore();
    },
  },
};

export default authResolvers;
