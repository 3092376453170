import { gql } from '@apollo/client';

const GET_BATTERY_LIBRARY = gql`
  query Query($filter: BatteryLibraryInput!) {
    getBatteryLibrary(filter: $filter) {
      batteryModelName
      unit1
      unit2
      unit3
      unit4
    }
  }
`;

const GET_BATTERY_LIBRARY_OVERVIEW = gql`
  query GetBatteryLibraryOverview {
    getBatteryLibraryOverview {
      evCount
      mmptCount
      monthlyInventory {
        id
        month
        year
        count
      }
    }
  }
`;

export { GET_BATTERY_LIBRARY_OVERVIEW, GET_BATTERY_LIBRARY };
