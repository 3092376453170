import React from 'react';
import { useDataInsights } from '../../hooks';
import { Box, Grid, Typography } from '@mui/material';
import { NumberBox } from 'src/components/Structure/NumberBox';
import factory from 'src/assets/svgs/factory-icon.svg';
import active from 'src/assets/svgs/active-icon.svg';
import { TEXT_CONTENTS } from 'src/constants';

const FacilityBox: React.FC = () => {
  const { facilityInsights } = useDataInsights();

  return (
    <Grid container spacing={2} mb={2}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <NumberBox>
          <Grid height="100%" container direction="row" justifyContent="space-between">
            <Grid item>
              <Grid container spacing={1} direction="column" justifyItems="space-between">
                <Grid item xs={2} marginTop={1}>
                  <Box component="img" src={factory} alt="Factory Icon" width={20} />
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h6">
                    {TEXT_CONTENTS.CHARTS.FACILITY_INSIGHTS.FACILITIES.TOTAL}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item alignSelf="center">
              <Typography variant="h3" color="grey">
                {facilityInsights?.facilitiesCount.total}
              </Typography>
            </Grid>
          </Grid>
        </NumberBox>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <NumberBox>
          <Grid height="100%" container direction="row" justifyContent="space-between">
            <Grid item>
              <Grid container spacing={1} direction="column" justifyItems="space-between">
                <Grid item xs={2} marginTop={1}>
                  <Box component="img" src={active} alt="Active Icon" width={20} />
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h6">
                    {TEXT_CONTENTS.CHARTS.FACILITY_INSIGHTS.FACILITIES.ACTIVE}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item alignSelf="center">
              <Typography variant="h3" color="grey">
                {facilityInsights?.facilitiesCount.active}
              </Typography>
            </Grid>
          </Grid>
        </NumberBox>
      </Grid>
    </Grid>
  );
};

export default FacilityBox;
