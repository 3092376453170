import React, { useContext } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { FirebaseContext } from 'src/components/Contexts';
import ROUTES from 'src/constants/routes';

const PrivateRoute = ({ children }: { children: React.ReactElement }) => {
  const { pathname, search } = useLocation();
  const { authenticated } = useContext(FirebaseContext);

  if (!authenticated) {
    return (
      <Navigate
        to={ROUTES.LOGIN}
        state={{
          redirect: `${pathname}${search}`,
        }}
      />
    );
  }

  return children;
};

export default PrivateRoute;
