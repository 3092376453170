import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Box,
  Typography,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
  Link,
  Collapse,
  Button,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useNavigate } from 'react-router-dom';

import Logo from 'src/assets/svgs/logo-white-color.svg';
import { TEXT_CONTENTS } from 'src/constants';
import UserMenu from '../UserMenu';
import ROUTES from 'src/constants/routes';
import FeedbackToolbar from './FeedbackToolbar';
import { useFeedbackContext } from 'src/components/Contexts/FeedbackContext';
import { DesktopNavBarMenu, MobileNavBarMenu } from './NavBarMenu';
import { NAV_MENU_ITEMS } from 'src/constants/navigation';

const Index = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down(1050));
  const [anchorElNav, setAnchorElNav] = useState<HTMLElement | null>(null);

  const { enableFeedbackToolbar } = useFeedbackContext();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (event: NonNullable<unknown>, link: string) => {
    setAnchorElNav(null);
    navigate(link);
  };

  useEffect(() => {
    setAnchorElNav(null);
  }, [location.pathname]);

  return (
    <AppBar position="sticky" color="charcoal" sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <Box
          component="div"
          sx={{
            display: isMediumScreen ? 'none' : 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Box component="a" onClick={() => navigate(ROUTES.HOME)}>
            <Box
              component="img"
              src={Logo}
              alt={TEXT_CONTENTS.IMAGES_ALT_TEXT.CIRCULI_ION_LOGO}
              sx={{
                height: 40,
                marginRight: theme.spacing(2),
              }}
            />
          </Box>
          <Typography
            variant="h5"
            sx={{
              mr: 3,
              mb: 0.3,
              '@media (min-width: 801px) and (max-width: 1400px)': {
                display: 'none',
              },
            }}
          >
            <Link
              component={Button}
              onClick={() => navigate(ROUTES.HOME)}
              sx={{
                textDecoration: 'none',
              }}
              color="#FFFFFF"
            >
              {TEXT_CONTENTS.NAVIGATION.TITLE}
            </Link>
          </Typography>
          {NAV_MENU_ITEMS.map((item, index) => (
            <DesktopNavBarMenu key={index} item={item} approved={true} />
          ))}
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: isMediumScreen ? 'flex' : 'none',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
            sx={{ marginLeft: 0 }}
          >
            <MenuIcon sx={{ color: '#FFFFFF', marginLeft: theme.spacing(0) }} />
          </IconButton>
          <Box component="a" href="/" sx={{ textDecoration: 'none' }} color="#FFFFFF">
            <Typography variant="h6" sx={{ marginRight: theme.spacing(4), color: '#FFFFFF' }}>
              {TEXT_CONTENTS.NAVIGATION.TITLE}
            </Typography>
          </Box>

          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElNav)}
            onClose={e => handleCloseNavMenu(e, '')}
            sx={{
              display: isMediumScreen ? 'flex' : 'none',
              marginLeft: { xs: theme.spacing(-2), sm: 0, md: 0 },
              marginRight: { xs: theme.spacing(-2), sm: 0, md: 0 },
            }}
            PaperProps={{
              elevation: 10,
              sx: {
                width: { xs: '100vw', sm: '50vw', md: '50vw' },
              },
            }}
          >
            <MenuItem onClick={e => handleCloseNavMenu(e, ROUTES.HOME)}>Home</MenuItem>
            {NAV_MENU_ITEMS.map((item, index) => (
              <MobileNavBarMenu approved={true} item={item} key={index} />
            ))}
          </Menu>
        </Box>
        <Box flexGrow={1}></Box>
        <UserMenu />
      </Toolbar>
      <Collapse in={enableFeedbackToolbar}>
        <FeedbackToolbar />
      </Collapse>
    </AppBar>
  );
};

export default Index;
