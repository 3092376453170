import { createTheme, getContrastRatio } from '@mui/material/styles';
import './fonts.css';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...((ownerState.variant === 'contained' || ownerState.variant === 'outlined') && {
            borderRadius: '30px',
            paddingLeft: 40,
            paddingRight: 40,
            boxShadow: 'none',
          }),
          fontFamily: 'Open Sans',
          textTransform: 'none',
          ':hover': {
            boxShadow: 'none',
          },
        }),
      },
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#00cfb4',
    },
    secondary: {
      main: '#000000',
    },
    background: {
      default: '#ecedee',
    },
    error: {
      main: '#cf001a',
    },
    charcoal: {
      main: '#1b1d23',
      contrastText: getContrastRatio('#1b1d23', '#fff') > 4.5 ? '#fff' : '#111',
    },
  },
  typography: {
    fontFamily: 'Open Sans',
    'text-9xl': {
      fontFamily: 'Lexend',
      fontSize: '8rem',
      lineHeight: 1,
      fontWeight: 'bold',
    },
    'text-8xl': {
      fontFamily: 'Lexend',
      fontSize: '6rem',
      lineHeight: 1,
      fontWeight: 'bold',
    },
    'text-7xl': {
      fontFamily: 'Lexend',
      fontSize: '4.5rem',
      lineHeight: 1,
      fontWeight: 'bold',
    },
    'text-6xl': {
      fontFamily: 'Lexend',
      fontSize: '3.75rem',
      lineHeight: 1,
      fontWeight: 'bold',
    },
    'text-5xl': {
      fontFamily: 'Lexend',
      fontSize: '3rem',
      lineHeight: 1,
      fontWeight: 'bold',
    },
    'text-4xl': {
      fontFamily: 'Lexend',
      fontSize: '2.25rem',
      lineHeight: '2.5rem',
      fontWeight: 'bold',
    },
    'text-3xl': {
      fontFamily: 'Lexend',
      fontSize: '1.875rem',
      lineHeight: '2.25rem',
      fontWeight: 'bold',
    },
    'text-2xl': {
      fontFamily: 'Lexend',
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontWeight: 'bold',
    },
    'text-xl': {
      fontFamily: 'Lexend',
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      fontWeight: 'bold',
    },
    'text-lg': {
      fontFamily: 'Open Sans',
      fontSize: '1.125rem',
      lineHeight: '1.75rem',
      fontWeight: 'bold',
    },
    'text-base': {
      fontFamily: 'Open Sans',
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    'text-sm': {
      fontFamily: 'Open Sans',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
    'text-xs': {
      fontFamily: 'Open Sans',
      fontSize: '0.75rem',
      lineHeight: '1rem',
    },
    h1: {
      fontFamily: 'Lexend',
      fontWeight: 'bold',
      fontSize: '2.5rem',
    },
    h2: {
      fontFamily: 'Lexend',
      fontWeight: 'bold',
      fontSize: '2.25rem',
    },
    h3: {
      fontFamily: 'Lexend',
      fontWeight: 'bold',
      fontSize: '2.0rem',
    },
    h4: {
      fontFamily: 'Lexend',
      fontWeight: 'bold',
      fontSize: '1.5rem',
    },
    h5: {
      fontFamily: 'Lexend',
      fontWeight: 'bold',
      fontSize: '1.25rem',
    },
    h6: {
      fontFamily: 'Lexend',
      fontWeight: 'bold',
      fontSize: '1.125rem',
    },
  },
  custom: {
    chart: {
      boxPlot: {
        upper: '#959695',
        lower: '#F0EE73',
      },
    },
  },
});

declare module '@mui/material/styles' {
  interface TypographyVariants {
    'text-9xl': React.CSSProperties;
    'text-8xl': React.CSSProperties;
    'text-7xl': React.CSSProperties;
    'text-6xl': React.CSSProperties;
    'text-5xl': React.CSSProperties;
    'text-4xl': React.CSSProperties;
    'text-3xl': React.CSSProperties;
    'text-2xl': React.CSSProperties;
    'text-xl': React.CSSProperties;
    'text-lg': React.CSSProperties;
    'text-base': React.CSSProperties;
    'text-sm': React.CSSProperties;
    'text-xs': React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    'text-9xl'?: React.CSSProperties;
    'text-8xl'?: React.CSSProperties;
    'text-7xl'?: React.CSSProperties;
    'text-6xl'?: React.CSSProperties;
    'text-5xl'?: React.CSSProperties;
    'text-4xl'?: React.CSSProperties;
    'text-3xl'?: React.CSSProperties;
    'text-2xl'?: React.CSSProperties;
    'text-xl'?: React.CSSProperties;
    'text-lg'?: React.CSSProperties;
    'text-base'?: React.CSSProperties;
    'text-sm'?: React.CSSProperties;
    'text-xs'?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    'text-9xl': true;
    'text-8xl': true;
    'text-7xl': true;
    'text-6xl': true;
    'text-5xl': true;
    'text-4xl': true;
    'text-3xl': true;
    'text-2xl': true;
    'text-xl': true;
    'text-lg': true;
    'text-base': true;
    'text-sm': true;
    'text-xs': true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    charcoal: Palette['primary'];
  }

  interface PaletteOptions {
    charcoal?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    charcoal: true;
  }
}

declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    charcoal: true;
  }
}

const exportableTheme = theme;

export default exportableTheme;
