import { initializeApp, FirebaseApp } from 'firebase/app';
import {
  getAuth,
  connectAuthEmulator,
  Auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { RemoteConfig, getRemoteConfig } from 'firebase/remote-config';

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG || '{}');

class Firebase {
  firebase: FirebaseApp;
  auth: Auth;
  config: RemoteConfig;

  constructor(useMock: boolean) {
    const firebase = initializeApp(firebaseConfig);
    const auth = getAuth(firebase);
    const config = getRemoteConfig(firebase);

    if (useMock) {
      // ToDo Clemens: Put constant into configProvider
      connectAuthEmulator(auth, 'http://localhost:9099', {
        disableWarnings: true,
      });
    }

    this.firebase = firebase;
    this.auth = auth;
    this.config = config;
  }

  getAuth = () => this.auth;
  getRemoteConfig = () => this.config;
  getFirebase = () => this.firebase;

  signUp = async (email: string, password: string) => {
    const userCredential = await createUserWithEmailAndPassword(this.auth, email, password);
    const user = userCredential.user;
    return user;
  };

  signIn = async (email: string, password: string) => {
    const userCredential = await signInWithEmailAndPassword(this.auth, email, password);
    const user = userCredential.user;
    return user;
  };

  sendResetPasswordEmail = async (email: string) => {
    const continueUrl = `${process.env.REACT_APP_HOST_URL}/login`;
    await sendPasswordResetEmail(this.auth, email, {
      url: continueUrl,
    });
  };
}

export default Firebase;
