import React from 'react';
import { ChartWrapper, PageContainer, Title } from 'src/components/Structure';
import Report from 'src/components/Clients/Report';
import report from 'src/assets/pdfs/Report.pdf';
import { TEXT_CONTENTS } from 'src/constants';

const Lots: React.FC = () => {
  return (
    <PageContainer>
      <Title variant="h2">{TEXT_CONTENTS.LOTS.TITLE}</Title>
      <ChartWrapper title={TEXT_CONTENTS.LOTS.CLIENT_REPORT}>
        <Report pdfData={report} replaceText="Client 1" />
      </ChartWrapper>
    </PageContainer>
  );
};

export default Lots;
