import * as React from 'react';
import {
  // Badge,
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Modal,
  Typography,
  Stack,
  useTheme,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

import CardMedia from '@mui/material/CardMedia';
import ROUTES from 'src/constants/routes';

interface PT_MM_Props {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  batteryCardData?: PtMmBatteryCardProps[];
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: 3,
  boxShadow: 24,
  p: 4,
  width: { xs: '100vw', sm: '90vw', md: '90vw', lg: '50vw' },
  maxHeight: '80vh',
  overflow: 'auto',
};

interface PtMmBatteryCardProps {
  name: string;
  image: string;
  badgeInfo?: string;
  mappedCell: string;
}

export interface EvBatteryCard {
  partInfo: string;
  'Car Info': string;
  'Model Year': number;
  modelName: string;
  'Nominal Capacity': string;
  'Useable Capacity': string;
  'Battery Type': string;
  'Number of Cells': string;
  Image: string;
}

export interface BatteryModuleCard {
  partInfo: string;
  modelName?: string;
  'Inventory Number': string;
  Capacity: string;
  'Part Number': string;
  Voltage: string;
  'Car Info': string;
  Weight: string;
  Dimensions: string;
  Image: string;
}

interface EvBatteryCardProps {
  batteryPacks: EvBatteryCard[] | BatteryModuleCard[] | Partial<EvBatteryCard>[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BatteryCard: React.FC<PtMmBatteryCardProps> = ({ name, image, badgeInfo, mappedCell }) => {
  const navigate = useNavigate();
  const cardContent = (
    <Card
      elevation={3}
      sx={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', flexGrow: 1 }}
      onClick={() => navigate(ROUTES.CELL_DETAILS, { state: { name: mappedCell } })}
    >
      <CardContent sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <Box
          component="img"
          src={image}
          alt={name}
          sx={{ maxWidth: { xs: '100%', sm: '100%', md: '100%', lg: '12vw' }, height: '100%' }}
        />
        <Typography
          sx={{
            mt: 0.5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          color="text.secondary"
        >
          {name}
        </Typography>
      </CardContent>
    </Card>
  );

  return cardContent;

  // Removing card content for time-being

  // if (!badgeInfo) return cardContent;
  //
  // return (
  //   <Badge
  //     badgeContent={badgeInfo}
  //     color="primary"
  //     anchorOrigin={{ vertical: 'top', horizontal: 'center' as 'left' }}
  //   >
  //     {cardContent}
  //   </Badge>
  // );
};

export const BatteryLibraryModal: React.FC<PT_MM_Props> = ({
  isModalOpen,
  handleCloseModal,
  batteryCardData,
}) => {
  const theme = useTheme();
  return (
    <Modal
      open={isModalOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <IconButton
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            padding: '10px',
          }}
          onClick={handleCloseModal}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
          Select a battery pack to view more details
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
          }}
        >
          {batteryCardData?.map(({ name, image, badgeInfo, mappedCell }, index) => (
            <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
              <BatteryCard
                name={name}
                image={image}
                badgeInfo={badgeInfo}
                mappedCell={mappedCell}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Modal>
  );
};

export const ScrollableCardComponent: React.FC<EvBatteryCardProps> = ({ batteryPacks }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box display="flex" overflow="auto">
      {batteryPacks.map((pack, index: number) => (
        <Card key={index} style={{ minWidth: 350, margin: 10 }}>
          <CardMedia
            component="img"
            height="300"
            width="300"
            image={pack.Image}
            alt="Battery"
            style={{ objectFit: 'contain' }}
          />
          <CardContent>
            {pack.modelName && (
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h6" color={theme.palette.primary.dark}>
                  {pack.modelName}
                </Typography>
                <IconButton
                  sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
                  onClick={() => {
                    const filter = pack.modelName;
                    navigate('/battery-library', { state: { filter } });
                  }}
                >
                  <InfoIcon />
                </IconButton>
              </Stack>
            )}
            <Typography color="textSecondary" mb={3}>
              {pack.partInfo}
            </Typography>
            {
              // @ts-expect-error("Something that is unsafe happens here")
              Object.keys(pack).map((key: keyof EvBatteryCard | keyof BatteryModuleCard) => {
                if (key === 'partInfo') return null;
                if (key === 'Image') return null;
                if (key === 'modelName') return null;

                return (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      marginBottom: theme.spacing(2),
                    }}
                    key={key}
                  >
                    <Typography variant="body2" sx={{ fontWeight: 'light' }}>
                      {key}:
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: 'light' }}>
                      {
                        // @ts-expect-error("Something that is unsafe happens here")
                        pack[key]
                      }
                    </Typography>
                  </Box>
                );
              })
            }
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};
