import React, { useState } from 'react';
import { ToggleButton, ToggleButtonGroup, Box } from '@mui/material';
import { BatteryPackModelType } from 'src/@types/BatteryDatabase';
import CardView from 'src/components/MasterData/CardView';

const MasterDataCardView: React.FC = (): JSX.Element => {
  const [batteryPackType, setBatteryPackType] = useState<BatteryPackModelType>(
    BatteryPackModelType.EV
  );

  const handleBatteryPackTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newBatteryPackType: BatteryPackModelType | null
  ) => {
    if (newBatteryPackType !== null) {
      setBatteryPackType(newBatteryPackType);
    }
  };

  return (
    <Box>
      <ToggleButtonGroup
        value={batteryPackType}
        exclusive
        onChange={handleBatteryPackTypeChange}
        aria-label="bp type toggle"
        sx={{ marginBottom: 2 }}
      >
        <ToggleButton value={BatteryPackModelType.EV} aria-label={BatteryPackModelType.EV}>
          {BatteryPackModelType.EV}
        </ToggleButton>
        <ToggleButton value={BatteryPackModelType.MM} aria-label={BatteryPackModelType.MM}>
          {BatteryPackModelType.MM}
        </ToggleButton>
        <ToggleButton value={BatteryPackModelType.PT} aria-label={BatteryPackModelType.PT}>
          {BatteryPackModelType.PT}
        </ToggleButton>
      </ToggleButtonGroup>
      <CardView batteryPackModelType={batteryPackType} />
    </Box>
  );
};

export default MasterDataCardView;
