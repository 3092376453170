import React, { SetStateAction } from 'react';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Box, Collapse, IconButton, Link, Typography, useTheme } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import { BatteryPackSimulationData } from 'src/@types/DataTypes';

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

interface RowsProps extends BatteryPackSimulationData {
  setOpenImageModal: React.Dispatch<SetStateAction<boolean>>;
}

const Row: React.FC<RowsProps> = ({
  name,
  weight,
  deliveryDate,
  exitTime,
  //   image,
  dischargeData,
  setOpenImageModal,
  preDiagnostics,
}) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  return (
    <React.Fragment>
      <StyledTableRow key={name}>
        <StyledTableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {name}
        </StyledTableCell>
        <StyledTableCell align="right">{weight}</StyledTableCell>
        <StyledTableCell align="right">{deliveryDate}</StyledTableCell>
        <StyledTableCell align="right">{exitTime}</StyledTableCell>
        {preDiagnostics && <StyledTableCell align="right">{preDiagnostics}</StyledTableCell>}
        <StyledTableCell align="right">
          <Link
            onClick={() => setOpenImageModal(true)}
            sx={{ color: theme.palette.primary.dark, cursor: 'pointer' }}
          >
            view
          </Link>
        </StyledTableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Discharge Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Module ID</TableCell>
                    <TableCell align="right">Voltage</TableCell>
                    <TableCell align="right">Capacity</TableCell>
                    <TableCell align="right">Discharge Current</TableCell>
                    <TableCell align="right">Discharge Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dischargeData.map(currentDischargeData => (
                    <TableRow key={currentDischargeData.batteryId}>
                      <TableCell component="th" scope="row">
                        {currentDischargeData.batteryId}
                      </TableCell>
                      <TableCell align="right">{currentDischargeData.voltage}</TableCell>
                      <TableCell align="right">{currentDischargeData.capacity}</TableCell>
                      <TableCell align="right">{currentDischargeData.dischargeCurrent}</TableCell>
                      <TableCell align="right">{currentDischargeData.dischargeTime}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default Row;
