import React, { useContext, useState } from 'react';
import CommonLayout from 'src/components/Auth/CommonLayout';
import { useTheme, Box, Button, Link, Paper, TextField, Typography } from '@mui/material';
import { TEXT_CONTENTS } from 'src/constants';
import { FirebaseContext } from 'src/components/Contexts';
import { FirebaseContextInterface } from 'src/components/Contexts/FirebaseContext';
import { useMessages } from 'src/components/hooks';
import { getFirebaseErrorMessage } from 'src/utils/Firebase';
import { FirebaseError } from 'src/@types/AuthTypes';

const ForgotPassword: React.FC = (): JSX.Element => {
  const theme = useTheme();
  const [email, setEmail] = useState('');
  const context = useContext(FirebaseContext) as FirebaseContextInterface;
  const { setDialog } = useMessages();

  const { firebase } = context;
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await firebase.sendResetPasswordEmail(email);
      setDialog({
        type: 'success',
        title: TEXT_CONTENTS.RESET_PASSWORD.SUCCESS_TITLE,
        message: TEXT_CONTENTS.RESET_PASSWORD.SUCCESS_MESSAGE,
      });
    } catch (error) {
      console.error('Error sending reset password email:', error);
      const firebaseErrorMessage = getFirebaseErrorMessage(error as FirebaseError);
      setDialog({
        type: 'error',
        title: TEXT_CONTENTS.RESET_PASSWORD.ERROR_TITLE,
        message: firebaseErrorMessage,
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value);
  };

  return (
    <CommonLayout>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: { xs: '85vh', sm: '65vh', md: '80vh', lg: '60vh' },
          minWidth: { xs: '90%', sm: '70%', md: '100%', lg: '100%' },
        }}
      >
        <Paper elevation={3} sx={{ padding: 4 }}>
          <Typography
            variant="h4"
            gutterBottom
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {TEXT_CONTENTS.RESET_PASSWORD.TITLE}
          </Typography>

          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              name="email"
              label="Email"
              type="email"
              variant="filled"
              color="primary"
              value={email}
              onChange={handleChange}
              required
            />

            <Box mt={3} mb={2}>
              <Button variant="contained" color="primary" type="submit" fullWidth>
                {TEXT_CONTENTS.RESET_PASSWORD.BUTTON_TEXT}
              </Button>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Link href={'/login'}>
                <Typography
                  variant="body2"
                  sx={{
                    textDecoration: 'underline',
                    color: theme.palette.primary.main,
                  }}
                >
                  {TEXT_CONTENTS.RESET_PASSWORD.LINK_TEXT}
                </Typography>
              </Link>
            </Box>
          </form>
        </Paper>
      </Box>
    </CommonLayout>
  );
};

export default ForgotPassword;
