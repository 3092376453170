import React from 'react';
import ApexCharts from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

import { EV_SALES_COMBINED_LINE_CHART_DATA } from 'src/constants/marketInsights';

interface SalesData {
  Brand: string;
  Quarter: string;
  Year: number;
  Sales: number;
}

const TopEvSalesChart = () => {
  const data: SalesData[] = EV_SALES_COMBINED_LINE_CHART_DATA;

  const groupedData = data.reduce((acc: Record<string, { x: string; y: number }[]>, item) => {
    const key = item.Brand;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push({
      x: `${item.Year} ${item.Quarter}`,
      y: item.Sales,
    });
    return acc;
  }, {});

  const series = Object.keys(groupedData).map(brand => ({
    name: brand,
    data: groupedData[brand],
  }));

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'line',
      height: 450,
      toolbar: {
        show: false,
      },
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    stroke: {
      width: 2,
      curve: 'smooth',
    },
    series: series,
    tooltip: {
      enabled: true,
      shared: false,
    },
    xaxis: {
      type: 'category',
      title: {
        text: 'Year and Quarter',
      },
    },
    yaxis: {
      title: {
        text: 'Sales',
      },
    },
  };

  return <ReactApexChart options={options} series={options.series} type="line" height={450} />;
};

export default TopEvSalesChart;
