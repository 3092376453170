import React from 'react';
import { styled } from '@mui/material';
import theme from 'src/styles/theme';

export interface MapLocationProps {
  count: number;
  name: string;
  isEvProcessible: boolean;
  isPtProcessible: boolean;
  isMmProcessible: boolean;
}
// Styled component for dots on the map
interface DotProps {
  size: number;
  getBackgroundColor: () => string;
}

const InnerDot = styled('div')<DotProps>(({ size, getBackgroundColor }) => ({
  position: 'absolute',
  width: `${size}px`,
  height: `${size}px`,
  borderRadius: '50%',
  backgroundColor: getBackgroundColor(),
  opacity: 1,
  transform: 'translate(-50%, -50%)',
}));

const Dot = styled('div')<DotProps>(({ size, getBackgroundColor }) => ({
  position: 'absolute',
  backgroundColor: getBackgroundColor(),
  borderRadius: '50%',
  width: `${size}px`,
  height: `${size}px`,
  transform: 'translate(-50%, -50%)',
  cursor: 'pointer',
  opacity: 0.5,
  border: `2px solid ${getBackgroundColor()}`,
  transition: 'box-shadow 1s ease', // Added box-shadow transition
  '&.hovered': {
    boxShadow: `0 0 0 ${size / 3}px rgba(82, 255, 148, 1)`, // Increased box-shadow size
  },
}));

const Tooltip = styled('div')<DotProps>(({ theme, size }) => ({
  position: 'absolute',
  backgroundColor: '#000',
  color: '#fff',
  padding: theme.spacing(0.5),
  borderRadius: theme.spacing(0.5),
  whiteSpace: 'nowrap',
  transform: `translate(calc(${size / 8}px), calc(-53%))`,
  marginLeft: theme.spacing(1),
  fontSize: '12px',
  cursor: 'pointer',
  display: 'none',
  '&.hovered': {
    display: 'block',
  },
}));

const MapLocation: React.FC<MapLocationProps> = ({
  count,
  name,
  isEvProcessible,
  isMmProcessible,
  isPtProcessible,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  //TODO: Update the colors based on suggestion from Jana
  const getBackgroundColor = () => {
    if (isEvProcessible && isPtProcessible && isMmProcessible) return theme.palette.primary.main;
    if (isEvProcessible) return '#FFD700';
    if (isPtProcessible) return '#FF6347';
    if (isMmProcessible) return '#20B2AA';
    return theme.palette.secondary.main;
  };

  return (
    <>
      <InnerDot size={8} getBackgroundColor={getBackgroundColor} />
      <Dot
        size={16}
        className={isHovered ? 'hovered' : ''}
        getBackgroundColor={getBackgroundColor}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      <Tooltip
        size={count}
        className={isHovered ? 'hovered' : ''}
        getBackgroundColor={getBackgroundColor}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {name}
      </Tooltip>
    </>
  );
};

export default MapLocation;
