import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Handle, Position } from 'reactflow';
import './styles/input.css';
import { useDesignCheck } from 'src/components/Contexts/DesignCheck';

const Input: React.FC = () => {
  const [username, setUsername] = useState('');
  const { input, setInput } = useDesignCheck();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // alert(inputs);
  };

  const handleBatteryPackChange = (event: ChangeEvent<HTMLSelectElement>) => {
    let batteryDamage = 'Not damaged';
    let cellSealing = 'Sealing-casing';
    let stateOfHealth = '>95';
    if (event.target.value === 'Battery Pack 2') {
      cellSealing = 'No Silicone';
      stateOfHealth = '80-70';
    }
    if (event.target.value === 'Battery Pack 3') {
      batteryDamage = 'Heavily damaged';
      cellSealing = 'Silicone';
      stateOfHealth = '80-70';
    }
    if (event.target.value === 'Battery Pack 4') {
      batteryDamage = 'Low quality battery cells';
      cellSealing = 'Silicone';
      stateOfHealth = '80-70';
    }
    setInput({
      ...input,
      batteryPack: event.target.value,
      batteryDamage,
      cellSealing,
      stateOfHealth,
    });
  };

  const handleChangeCategorie = (event: ChangeEvent<HTMLSelectElement>) => {
    setInput({ ...input, category: event.target.value });
  };

  // const handleChangeBatteryDamage = (event: ChangeEvent<HTMLSelectElement>) => {
  //   setInput({ ...input, batteryDamage: event.target.value });
  // };
  //
  // const handleSealing = (event: ChangeEvent<HTMLSelectElement>) => {
  //   setInput({ ...input, cellSealing: event.target.value });
  // };
  //
  // const handleStateHealth = (event: ChangeEvent<HTMLSelectElement>) => {
  //   setInput({ ...input, stateOfHealth: event.target.value });
  // };

  return (
    <div className="input-container">
      <u className="input-title">Battery Input</u>

      <form onSubmit={handleSubmit}>
        <div>
          <label>
            Name
            <br></br>
            <input
              type="text"
              name="username"
              autoComplete={'off'}
              value={username}
              onChange={handleChange}
            />
          </label>
        </div>
        <div>
          <label>
            Category
            <br></br>
            <select value={input.category} onChange={handleChangeCategorie}>
              <option value="Power-tool">Power-tool</option>
              <option value="Micro-mobility">Micro-mobility</option>
              <option value="E-moped">E-moped</option>
              <option value="EV (automotive)">EV (automotive)</option>
              <option value="Other">Other</option>
            </select>
          </label>
          <br></br>
          <label>
            Battery Pack
            <br></br>
            {input.category === 'Power-tool' || !input.category ? (
              <select value={input.batteryPack} onChange={handleBatteryPackChange}>
                <option value="Battery Pack 1">Hilti B22 5.2Ah</option>
                <option value="Battery Pack 2">Bosch GBA 5.0Ah 2020</option>
                <option value="Battery Pack 4">Parkside PAP 20 B1</option>
                <option value="Battery Pack 3">Bosch GBA 5.0Ah 2022</option>
              </select>
            ) : (
              <select value={input.batteryPack} onChange={handleBatteryPackChange}>
                <option value="Battery Pack 1">Okai ES200</option>
                <option value="Battery Pack 2">NEE1006-M</option>
                <option value="Battery Pack 4">OKAI ES-200</option>
              </select>
            )}
          </label>
          <br></br>
          <label>
            Battery Status
            <br></br>
            <select
              value={input.batteryDamage}
              // onChange={handleChangeBatteryDamage}
              disabled
            >
              <option value="Not damaged">Not damaged</option>
              <option value="Low quality battery cells">Low quality battery cells</option>
              <option value="Heavily damaged">Heavily damaged</option>
            </select>
          </label>
          <br></br>
          <label>
            Cell sealing
            <br></br>
            <select
              value={input.cellSealing}
              // onChange={handleSealing}
              disabled
            >
              <option value="Sealing-casing">Sealing in casing</option>
              <option value="No Silicone">No Silicone</option>
              <option value="Silicone">Silicone</option>
              <option value="Other">Other</option>
            </select>
          </label>
          <br></br>
          <label>
            State of Health
            <br></br>
            <select
              value={input.stateOfHealth}
              // onChange={handleStateHealth}
              disabled
            >
              <option value=">95"> above 95%</option>
              <option value="95-80">between 95%-80%</option>
              <option value="80-70">between 80%-70%</option>
              <option value="<70">below 70%</option>
            </select>
          </label>
        </div>
        <div>
          <br></br>
          <input type="submit" />
        </div>
      </form>
      <Handle type="source" position={Position.Right} style={{ top: 20 }} />
    </div>
  );
};

export default Input;
