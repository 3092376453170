import { gql } from '@apollo/client';

const GET_STAKEHOLDER_MAPPING = gql`
  query Query {
    stakeholderMapping {
      company
      country
      category
      city
      startYear
      link
      latitude
      longitude
    }
  }
`;

export default GET_STAKEHOLDER_MAPPING;
