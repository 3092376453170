import { gql } from '@apollo/client';

const GET_INVENTORY = gql`
  query Query {
    getInventory {
      batteryModel
      area
      location
      granularity
      totalUnits
    }
  }
`;

const GET_WEEKLY_INVENTORY = gql`
  query Query {
    getInventoryShare {
      EV {
        capacity
        name
      }
      PT {
        name
        capacity
      }
      MM {
        name
        capacity
      }
    }
    getInventoryByWeeks {
      week
      battery
      cell
    }
  }
`;

export { GET_INVENTORY, GET_WEEKLY_INVENTORY };
