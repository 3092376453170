import React from 'react';
import { Link, Stack, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const StyledLink = ({
  id,
  name,
  menu,
  onClickHandler,
}: {
  id: string;
  name: string;
  menu?: { NAME: string; URL: string; APPROVAL_REQUIRED: boolean }[];
  onClickHandler: (event: React.MouseEvent<HTMLElement>) => void;
}) => {
  const location = useLocation();
  const theme = useTheme();

  const getLinkColor = (menu?: { NAME: string; URL: string; APPROVAL_REQUIRED: boolean }[]) => {
    if (!menu) {
      return '#FFFFFF';
    }

    const isActiveLink = menu
      .map(currentItem => `/${currentItem.NAME.toLowerCase()}`)
      .includes(location.pathname);

    if (isActiveLink) {
      return theme.palette.primary.main;
    }

    return '#FFFFFF';
  };

  return (
    <Link
      id={id}
      sx={{
        display: { xs: 'none', md: 'flex' },
        color: getLinkColor(menu),
        textDecoration: 'none',
        marginLeft: theme.spacing(4),
        fontWeight: 'bold',
        '&:hover': {
          textDecoration: 'none',
          cursor: 'pointer',
          color: theme.palette.primary.light,
        },
      }}
      onClick={onClickHandler}
    >
      <Stack direction={'row'}>
        <Typography variant="text-base">{name}</Typography>
        {menu && <KeyboardArrowDownIcon />}
      </Stack>
    </Link>
  );
};

export default StyledLink;
