import { ApexOptions } from 'apexcharts';
import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { useDataInsights } from '../../hooks';
import { TEXT_CONTENTS } from 'src/constants';
import { useTheme } from '@mui/material';
import { ChartContainer } from 'src/components/Structure';
import { ChartProps } from 'src/@types/DataTypes';
import { ToggleButtonValues } from 'src/components/Charts/Filters/BatteryFilters';

interface Props extends ChartProps {
  selectedBatteryType: string;
  selectedBatteryCategory: string;
  selectedToggleValue: ToggleButtonValues;
}

interface YearlyAggregation {
  name: string;
  totalBatteryPacks?: number;
  totalWeightKg?: number;
}

const YearlyProcessed: React.FC<Props> = ({
  chartId = 'yearly-battery-packs-processed',
  selectedBatteryCategory,
  selectedBatteryType,
  selectedToggleValue,
}) => {
  const theme = useTheme();
  const height = theme.spacing(50);
  const { batteryInsights } = useDataInsights();

  let filteredData = batteryInsights?.batteryDataInsights;
  if (selectedBatteryType !== 'Total') {
    filteredData = batteryInsights?.batteryDataInsights.filter(
      ({ batteryType, batteryCategory }) =>
        batteryType === selectedBatteryType && batteryCategory === selectedBatteryCategory
    );
  } else if (selectedBatteryCategory !== 'Total' && selectedBatteryType === 'Total') {
    filteredData = batteryInsights?.batteryDataInsights.filter(
      ({ batteryCategory }) => batteryCategory === selectedBatteryCategory
    );
  }

  const yearlyAggregation: YearlyAggregation[] = useMemo(() => {
    return (
      filteredData?.reduce((acc, curr) => {
        const yearObject = acc.find(item => item.name === curr.year.toString());
        if (!yearObject) {
          acc.push({
            name: curr.year.toString(),
            totalBatteryPacks: curr.numberBatteryPacks,
            totalWeightKg:
              selectedToggleValue === ToggleButtonValues.WEIGHT_IN_KG ? curr.weightKg : 0,
          });
        } else {
          if (selectedToggleValue === ToggleButtonValues.COUNT && yearObject.totalBatteryPacks) {
            yearObject.totalBatteryPacks += curr.numberBatteryPacks;
          } else if (
            selectedToggleValue === ToggleButtonValues.WEIGHT_IN_KG &&
            yearObject.totalWeightKg
          ) {
            yearObject.totalWeightKg += curr.weightKg;
          }
        }
        return acc;
      }, [] as YearlyAggregation[]) || []
    );
  }, [filteredData, selectedToggleValue]);

  const latestYear = yearlyAggregation.sort((a, b) => parseInt(a.name) - parseInt(b.name))[
    yearlyAggregation.length - 1
  ].name;
  const years = yearlyAggregation.map(({ name }) => {
    const processedName = name === latestYear ? name + ' - ytd' : name;
    return processedName;
  });
  const yearlyData = yearlyAggregation.map(data =>
    selectedToggleValue === ToggleButtonValues.COUNT
      ? data.totalBatteryPacks ?? null
      : Math.round(data.totalWeightKg || 0) ?? null
  );

  const options: ApexOptions = {
    chart: {
      id: chartId,
      fontFamily: theme.typography.fontFamily,
      background: theme.palette.background.paper,
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '12px',
        colors: ['#000000'],
      },
    },
    colors: [theme.palette.primary.light],
    yaxis: { title: { text: TEXT_CONTENTS.CHARTS.BATTERY_INSIGHTS.YEARLY.YAXIS } },
    xaxis: {
      categories: [...years],
      labels: {
        style: {
          fontSize: '15px',
        },
      },
    },
  };

  const series = useMemo(
    () => [
      {
        name: TEXT_CONTENTS.CHARTS.BATTERY_INSIGHTS.YEARLY.YAXIS,
        data: yearlyData,
      },
    ],
    [yearlyData]
  );

  return (
    <ChartContainer height={height} dataNotAvailable={yearlyData.length === 0}>
      <Chart
        options={options}
        series={series}
        type="bar"
        height={height}
        colors={[theme.palette.primary.light, theme.palette.primary.dark]}
        style={{ color: theme.palette.secondary.main, fontFamily: theme.typography.fontFamily }}
      />
    </ChartContainer>
  );
};

export default YearlyProcessed;
