import { BatteryPackSimulationData } from 'src/@types/DataTypes';

function createData(
  name: string,
  weight: number,
  deliveryDate: string,
  exitTime: string,
  image: string,
  dischargeData: Record<string, string>[],
  preDiagnostics: string
): BatteryPackSimulationData {
  return {
    name,
    weight: `${weight}`,
    deliveryDate,
    exitTime,
    preDiagnostics,
    image,
    dischargeData,
  };
}

export const MODULE_SIMULATION: BatteryPackSimulationData[] = [
  createData(
    '5uhhnsdf89942',
    15,
    '12:30, 18 Nov 2023',
    '16:15, 18 Nov 2023',
    'https://i.ebayimg.com/images/g/LRYAAOSwdWNke4Tb/s-l1600.jpg',
    [
      {
        batteryId: '7hnmbosdf89942',
        voltage: '3.7V',
        capacity: '2000mAh',
        dischargeCurrent: '0.2C',
        dischargeTime: '10h',
      },
      {
        batteryId: '4jklfosdf987po',
        voltage: '3.6V',
        capacity: '1500mAh',
        dischargeCurrent: '0.5C',
        dischargeTime: '8h',
      },
      {
        batteryId: '9mnvbsdiu2h4jk',
        voltage: '4.0V',
        capacity: '1800mAh',
        dischargeCurrent: '0.3C',
        dischargeTime: '12h',
      },
    ],
    'Reusable'
  ),
  createData(
    '89hnfsdkhfsdb',
    27,
    '10:18, 14 Nov 2023',
    '12:10, 15 Nov 2023',
    'https://i.ebayimg.com/images/g/LRYAAOSwdWNke4Tb/s-l1600.jpg',
    [
      {
        batteryId: '7hnmbosdf89942',
        voltage: '3.7V',
        capacity: '2000mAh',
        dischargeCurrent: '0.2C',
        dischargeTime: '10h',
      },
      {
        batteryId: '4jklfosdf987po',
        voltage: '3.6V',
        capacity: '1500mAh',
        dischargeCurrent: '0.5C',
        dischargeTime: '8h',
      },
      {
        batteryId: '9mnvbsdiu2h4jk',
        voltage: '4.0V',
        capacity: '1800mAh',
        dischargeCurrent: '0.3C',
        dischargeTime: '12h',
      },
    ],
    'Recyclable'
  ),
  createData(
    '1nkgsdihknlos',
    22,
    '8:00, 16 Nov 2023',
    '15:15, 16 Nov 2023',
    'https://i.ebayimg.com/images/g/LRYAAOSwdWNke4Tb/s-l1600.jpg',
    [
      {
        batteryId: '7hnmbosdf89942',
        voltage: '3.7V',
        capacity: '2000mAh',
        dischargeCurrent: '0.2C',
        dischargeTime: '10h',
      },
      {
        batteryId: '4jklfosdf987po',
        voltage: '3.6V',
        capacity: '1500mAh',
        dischargeCurrent: '0.5C',
        dischargeTime: '8h',
      },
      {
        batteryId: '9mnvbsdiu2h4jk',
        voltage: '4.0V',
        capacity: '1800mAh',
        dischargeCurrent: '0.3C',
        dischargeTime: '12h',
      },
    ],
    'Reusable'
  ),
  createData(
    '8nskdbfkdbbks',
    35,
    '11:26, 15 Nov 2023',
    '9:40, 17 Nov 2023',
    'https://i.ebayimg.com/images/g/LRYAAOSwdWNke4Tb/s-l1600.jpg',
    [
      {
        batteryId: '7hnmbosdf89942',
        voltage: '3.7V',
        capacity: '2000mAh',
        dischargeCurrent: '0.2C',
        dischargeTime: '10h',
      },
      {
        batteryId: '4jklfosdf987po',
        voltage: '3.6V',
        capacity: '1500mAh',
        dischargeCurrent: '0.5C',
        dischargeTime: '8h',
      },
      {
        batteryId: '9mnvbsdiu2h4jk',
        voltage: '4.0V',
        capacity: '1800mAh',
        dischargeCurrent: '0.3C',
        dischargeTime: '12h',
      },
    ],
    'Reusable'
  ),
  createData(
    '0bkbnkdshfosf',
    56,
    '17:38, 14 Nov 2023',
    '11:07, 15 Nov 2023',
    'https://i.ebayimg.com/images/g/LRYAAOSwdWNke4Tb/s-l1600.jpg',
    [
      {
        batteryId: '7hnmbosdf89942',
        voltage: '3.7V',
        capacity: '2000mAh',
        dischargeCurrent: '0.2C',
        dischargeTime: '10h',
      },
      {
        batteryId: '4jklfosdf987po',
        voltage: '3.6V',
        capacity: '1500mAh',
        dischargeCurrent: '0.5C',
        dischargeTime: '8h',
      },
      {
        batteryId: '9mnvbsdiu2h4jk',
        voltage: '4.0V',
        capacity: '1800mAh',
        dischargeCurrent: '0.3C',
        dischargeTime: '12h',
      },
    ],
    'Reusable'
  ),
  createData(
    'q2gq28z646nc',
    17,
    '18:11, 14 Nov 2023',
    '04:11, 16 Nov 2023',
    'https://i.ebayimg.com/images/g/LRYAAOSwdWNke4Tb/s-l1600.jpg',
    [
      {
        batteryId: '7hnmbosdf89942',
        voltage: '3.7V',
        capacity: '2000mAh',
        dischargeCurrent: '0.2C',
        dischargeTime: '10h',
      },
      {
        batteryId: '4jklfosdf987po',
        voltage: '3.6V',
        capacity: '1500mAh',
        dischargeCurrent: '0.5C',
        dischargeTime: '8h',
      },
      {
        batteryId: '9mnvbsdiu2h4jk',
        voltage: '4.0V',
        capacity: '1800mAh',
        dischargeCurrent: '0.3C',
        dischargeTime: '12h',
      },
    ],
    'Recyclable'
  ),
  createData(
    'fuutfsen4dep',
    33,
    '15:16, 14 Nov 2023',
    '23:16, 15 Nov 2023',
    'https://i.ebayimg.com/images/g/LRYAAOSwdWNke4Tb/s-l1600.jpg',
    [
      {
        batteryId: '7hnmbosdf89942',
        voltage: '3.7V',
        capacity: '2000mAh',
        dischargeCurrent: '0.2C',
        dischargeTime: '10h',
      },
      {
        batteryId: '4jklfosdf987po',
        voltage: '3.6V',
        capacity: '1500mAh',
        dischargeCurrent: '0.5C',
        dischargeTime: '8h',
      },
      {
        batteryId: '9mnvbsdiu2h4jk',
        voltage: '4.0V',
        capacity: '1800mAh',
        dischargeCurrent: '0.3C',
        dischargeTime: '12h',
      },
    ],
    'Recyclable'
  ),
  createData(
    'jq6la6raduh2',
    16,
    '06:34, 16 Nov 2023',
    '05:34, 18 Nov 2023',
    'https://i.ebayimg.com/images/g/LRYAAOSwdWNke4Tb/s-l1600.jpg',
    [
      {
        batteryId: '7hnmbosdf89942',
        voltage: '3.7V',
        capacity: '2000mAh',
        dischargeCurrent: '0.2C',
        dischargeTime: '10h',
      },
      {
        batteryId: '4jklfosdf987po',
        voltage: '3.6V',
        capacity: '1500mAh',
        dischargeCurrent: '0.5C',
        dischargeTime: '8h',
      },
      {
        batteryId: '9mnvbsdiu2h4jk',
        voltage: '4.0V',
        capacity: '1800mAh',
        dischargeCurrent: '0.3C',
        dischargeTime: '12h',
      },
    ],
    'Reusable'
  ),
  createData(
    '3dwza2gugilh',
    24,
    '08:10, 16 Nov 2023',
    '11:10, 17 Nov 2023',
    'https://i.ebayimg.com/images/g/LRYAAOSwdWNke4Tb/s-l1600.jpg',
    [
      {
        batteryId: '7hnmbosdf89942',
        voltage: '3.7V',
        capacity: '2000mAh',
        dischargeCurrent: '0.2C',
        dischargeTime: '10h',
      },
      {
        batteryId: '4jklfosdf987po',
        voltage: '3.6V',
        capacity: '1500mAh',
        dischargeCurrent: '0.5C',
        dischargeTime: '8h',
      },
      {
        batteryId: '9mnvbsdiu2h4jk',
        voltage: '4.0V',
        capacity: '1800mAh',
        dischargeCurrent: '0.3C',
        dischargeTime: '12h',
      },
    ],
    'Reusable'
  ),
  createData(
    'vea79giloj9g',
    240,
    '07:01, 14 Nov 2023',
    '02:01, 15 Nov 2023',
    'https://i.ebayimg.com/images/g/LRYAAOSwdWNke4Tb/s-l1600.jpg',
    [
      {
        batteryId: '7hnmbosdf89942',
        voltage: '3.7V',
        capacity: '2000mAh',
        dischargeCurrent: '0.2C',
        dischargeTime: '10h',
      },
      {
        batteryId: '4jklfosdf987po',
        voltage: '3.6V',
        capacity: '1500mAh',
        dischargeCurrent: '0.5C',
        dischargeTime: '8h',
      },
      {
        batteryId: '9mnvbsdiu2h4jk',
        voltage: '4.0V',
        capacity: '1800mAh',
        dischargeCurrent: '0.3C',
        dischargeTime: '12h',
      },
    ],
    'Reusable'
  ),
];

export const BATTERY_PACK_SIMULATION: BatteryPackSimulationData[] = [
  createData(
    '5uhhnsdf89942',
    454,
    '12:30, 18 Nov 2023',
    '16:15, 18 Nov 2023',
    'https://i.ebayimg.com/images/g/LRYAAOSwdWNke4Tb/s-l1600.jpg',
    [
      {
        batteryId: '7hnmbosdf89942',
        voltage: '3.7V',
        capacity: '2000mAh',
        dischargeCurrent: '0.2C',
        dischargeTime: '10h',
      },
      {
        batteryId: '4jklfosdf987po',
        voltage: '3.6V',
        capacity: '1500mAh',
        dischargeCurrent: '0.5C',
        dischargeTime: '8h',
      },
      {
        batteryId: '9mnvbsdiu2h4jk',
        voltage: '4.0V',
        capacity: '1800mAh',
        dischargeCurrent: '0.3C',
        dischargeTime: '12h',
      },
    ],
    'Reusable'
  ),
  createData(
    '89hnfsdkhfsdb',
    544,
    '10:18, 14 Nov 2023',
    '12:10, 15 Nov 2023',
    'https://i.ebayimg.com/images/g/LRYAAOSwdWNke4Tb/s-l1600.jpg',
    [
      {
        batteryId: '7hnmbosdf89942',
        voltage: '3.7V',
        capacity: '2000mAh',
        dischargeCurrent: '0.2C',
        dischargeTime: '10h',
      },
      {
        batteryId: '4jklfosdf987po',
        voltage: '3.6V',
        capacity: '1500mAh',
        dischargeCurrent: '0.5C',
        dischargeTime: '8h',
      },
      {
        batteryId: '9mnvbsdiu2h4jk',
        voltage: '4.0V',
        capacity: '1800mAh',
        dischargeCurrent: '0.3C',
        dischargeTime: '12h',
      },
    ],
    'Reusable'
  ),
  createData(
    '1nkgsdihknlos',
    443,
    '8:00, 16 Nov 2023',
    '15:15, 16 Nov 2023',
    'https://i.ebayimg.com/images/g/LRYAAOSwdWNke4Tb/s-l1600.jpg',
    [
      {
        batteryId: '7hnmbosdf89942',
        voltage: '3.7V',
        capacity: '2000mAh',
        dischargeCurrent: '0.2C',
        dischargeTime: '10h',
      },
      {
        batteryId: '4jklfosdf987po',
        voltage: '3.6V',
        capacity: '1500mAh',
        dischargeCurrent: '0.5C',
        dischargeTime: '8h',
      },
      {
        batteryId: '9mnvbsdiu2h4jk',
        voltage: '4.0V',
        capacity: '1800mAh',
        dischargeCurrent: '0.3C',
        dischargeTime: '12h',
      },
    ],
    'Recyclable'
  ),
  createData(
    '8nskdbfkdbbks',
    414.16,
    '11:26, 15 Nov 2023',
    '9:40, 17 Nov 2023',
    'https://i.ebayimg.com/images/g/LRYAAOSwdWNke4Tb/s-l1600.jpg',
    [
      {
        batteryId: '7hnmbosdf89942',
        voltage: '3.7V',
        capacity: '2000mAh',
        dischargeCurrent: '0.2C',
        dischargeTime: '10h',
      },
      {
        batteryId: '4jklfosdf987po',
        voltage: '3.6V',
        capacity: '1500mAh',
        dischargeCurrent: '0.5C',
        dischargeTime: '8h',
      },
      {
        batteryId: '9mnvbsdiu2h4jk',
        voltage: '4.0V',
        capacity: '1800mAh',
        dischargeCurrent: '0.3C',
        dischargeTime: '12h',
      },
    ],
    'Reusable'
  ),
  createData(
    '0bkbnkdshfosf',
    546,
    '17:38, 14 Nov 2023',
    '11:07, 15 Nov 2023',
    'https://i.ebayimg.com/images/g/LRYAAOSwdWNke4Tb/s-l1600.jpg',
    [
      {
        batteryId: '7hnmbosdf89942',
        voltage: '3.7V',
        capacity: '2000mAh',
        dischargeCurrent: '0.2C',
        dischargeTime: '10h',
      },
      {
        batteryId: '4jklfosdf987po',
        voltage: '3.6V',
        capacity: '1500mAh',
        dischargeCurrent: '0.5C',
        dischargeTime: '8h',
      },
      {
        batteryId: '9mnvbsdiu2h4jk',
        voltage: '4.0V',
        capacity: '1800mAh',
        dischargeCurrent: '0.3C',
        dischargeTime: '12h',
      },
    ],
    'Recyclable'
  ),
  createData(
    'q2gq28z646nc',
    386,
    '18:11, 14 Nov 2023',
    '04:11, 16 Nov 2023',
    'https://i.ebayimg.com/images/g/LRYAAOSwdWNke4Tb/s-l1600.jpg',
    [
      {
        batteryId: '7hnmbosdf89942',
        voltage: '3.7V',
        capacity: '2000mAh',
        dischargeCurrent: '0.2C',
        dischargeTime: '10h',
      },
      {
        batteryId: '4jklfosdf987po',
        voltage: '3.6V',
        capacity: '1500mAh',
        dischargeCurrent: '0.5C',
        dischargeTime: '8h',
      },
      {
        batteryId: '9mnvbsdiu2h4jk',
        voltage: '4.0V',
        capacity: '1800mAh',
        dischargeCurrent: '0.3C',
        dischargeTime: '12h',
      },
    ],
    'Reusable'
  ),
  createData(
    'fuutfsen4dep',
    527,
    '15:16, 14 Nov 2023',
    '23:16, 15 Nov 2023',
    'https://i.ebayimg.com/images/g/LRYAAOSwdWNke4Tb/s-l1600.jpg',
    [
      {
        batteryId: '7hnmbosdf89942',
        voltage: '3.7V',
        capacity: '2000mAh',
        dischargeCurrent: '0.2C',
        dischargeTime: '10h',
      },
      {
        batteryId: '4jklfosdf987po',
        voltage: '3.6V',
        capacity: '1500mAh',
        dischargeCurrent: '0.5C',
        dischargeTime: '8h',
      },
      {
        batteryId: '9mnvbsdiu2h4jk',
        voltage: '4.0V',
        capacity: '1800mAh',
        dischargeCurrent: '0.3C',
        dischargeTime: '12h',
      },
    ],
    'Reusable'
  ),
  createData(
    'jq6la6raduh2',
    629,
    '06:34, 16 Nov 2023',
    '05:34, 18 Nov 2023',
    'https://i.ebayimg.com/images/g/LRYAAOSwdWNke4Tb/s-l1600.jpg',
    [
      {
        batteryId: '7hnmbosdf89942',
        voltage: '3.7V',
        capacity: '2000mAh',
        dischargeCurrent: '0.2C',
        dischargeTime: '10h',
      },
      {
        batteryId: '4jklfosdf987po',
        voltage: '3.6V',
        capacity: '1500mAh',
        dischargeCurrent: '0.5C',
        dischargeTime: '8h',
      },
      {
        batteryId: '9mnvbsdiu2h4jk',
        voltage: '4.0V',
        capacity: '1800mAh',
        dischargeCurrent: '0.3C',
        dischargeTime: '12h',
      },
    ],
    'Reusable'
  ),
  createData(
    '3dwza2gugilh',
    534,
    '08:10, 16 Nov 2023',
    '11:10, 17 Nov 2023',
    'https://i.ebayimg.com/images/g/LRYAAOSwdWNke4Tb/s-l1600.jpg',
    [
      {
        batteryId: '7hnmbosdf89942',
        voltage: '3.7V',
        capacity: '2000mAh',
        dischargeCurrent: '0.2C',
        dischargeTime: '10h',
      },
      {
        batteryId: '4jklfosdf987po',
        voltage: '3.6V',
        capacity: '1500mAh',
        dischargeCurrent: '0.5C',
        dischargeTime: '8h',
      },
      {
        batteryId: '9mnvbsdiu2h4jk',
        voltage: '4.0V',
        capacity: '1800mAh',
        dischargeCurrent: '0.3C',
        dischargeTime: '12h',
      },
    ],
    'Reusable'
  ),
  createData(
    'vea79giloj9g',
    427,
    '07:01, 14 Nov 2023',
    '02:01, 15 Nov 2023',
    'https://i.ebayimg.com/images/g/LRYAAOSwdWNke4Tb/s-l1600.jpg',
    [
      {
        batteryId: '7hnmbosdf89942',
        voltage: '3.7V',
        capacity: '2000mAh',
        dischargeCurrent: '0.2C',
        dischargeTime: '10h',
      },
      {
        batteryId: '4jklfosdf987po',
        voltage: '3.6V',
        capacity: '1500mAh',
        dischargeCurrent: '0.5C',
        dischargeTime: '8h',
      },
      {
        batteryId: '9mnvbsdiu2h4jk',
        voltage: '4.0V',
        capacity: '1800mAh',
        dischargeCurrent: '0.3C',
        dischargeTime: '12h',
      },
    ],
    'Recyclable'
  ),
];

export const BATTERY_PACK_IMAGES = [
  'https://www.wardsauto.com/sites/wardsauto.com/files/2017-Chevrolet-BoltEV-020.jpg',
  'https://d2q5yj3kkcfw7v.cloudfront.net/listing/0005/44/65135e888ceecdbf5f2d76c3664f2848b18a3a78.jpeg',
  'https://www.secondlife-evbatteries.com/cdn/shop/products/volkswagen_100672080_h.jpg?v=1649847821',
  'https://www.secondlife-evbatteries.com/cdn/shop/products/IMG_20210514_112818v2.jpg?v=1635342767&width=1946',
  'https://cdn.panorica.com/sites/default/files/image-gallery/Electric-vehicle-battery-2023-004.webp',
  'https://www.secondlife-evbatteries.com/cdn/shop/products/volkswagen_100672080_h_d3bfbdde-430a-48cc-93f1-0df63c468b3b.jpg?v=1649847723',
];

export const MODULE_IMAGES = [
  'https://www.evwest.com/catalog/images/thumbs/def/large/products/tesla-model-s-lithium-ion-18650-ev-module-22-8-volt-5-3-kwh.jpg',
  'https://cdn.shopify.com/s/files/1/0246/7145/3236/products/tesla-5.3kwh-battery-module-1-V2_1200x1200.jpg',
  'https://i.ebayimg.com/images/g/ZrUAAOSwqGBj~QKS/s-l1600.jpg',
  'https://overkillsolar.com/wp-content/uploads/2022/12/MG_0774-1.jpg',
  'https://eveurope.eu/wp-content/uploads/IMG_6768.jpg',
  'https://www.bpathenergy.nl/wp-content/uploads/2020/03/3-Tesla-444-cel-800-600.jpg',
];
