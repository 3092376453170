import React, { useState, useEffect } from 'react';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { toTitleCase } from 'src/utils/Text';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Paper,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { DateRange, DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Chart from 'react-apexcharts';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ChartWrapper, ChartContainer } from 'src/components/Structure';

import { lotData, options } from 'src/constants/lots';
import { TEXT_CONTENTS } from 'src/constants';

interface ReportProps {
  pdfData: string;
  replaceText: string;
}

interface BarChartSeries {
  name: string;
  data: number[];
}

interface BarChartOptions {
  chart: {
    id: string;
    fontFamily: React.CSSProperties['fontFamily'];
    background: string;
    toolbar: {
      show: boolean;
      tools: {
        download: boolean;
        selection: boolean;
        zoom: boolean;
        zoomin: boolean;
        zoomout: boolean;
        pan: boolean;
        reset: boolean;
      };
    };
  };
  dataLabels: { enabled: boolean; style: { fontSize: string; colors: string[] } };
  colors: string[];
  yaxis: { title: { text: string } };
  xaxis: { categories: string[]; labels: { style: { fontSize: string } } };
}

interface BarChartData {
  series: BarChartSeries[];
  options: BarChartOptions;
}

const Report: React.FC<ReportProps> = ({ pdfData, replaceText }) => {
  const theme = useTheme();
  const height = theme.spacing(55);
  const [modifiedPdfUrl, setModifiedPdfUrl] = useState<string>('');
  const [selectedOptions, setSelectedOptions] = useState<string[]>(['Lot 2', 'Lot 6', 'Lot 7']);
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const [barChartData, setBarChartData] = useState<BarChartData>({
    series: [],
    options: {
      chart: {
        id: 'total-batteries-per-lot',
        fontFamily: theme.typography.fontFamily,
        background: theme.palette.background.paper,
        toolbar: {
          show: false,
          tools: {
            download: false, // detailedView,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
          colors: ['#000000'],
        },
      },
      colors: [theme.palette.primary.light],
      yaxis: {
        title: {
          text: TEXT_CONTENTS.CHARTS.LOTS_TRACKING.BATTERY_PACKS_PER_LOT.YAXIS,
        },
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            fontSize: '15px',
          },
        },
      },
    },
  });

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const getBatteryDataForSelectedLots = (selectedLots: string[]) => {
      return selectedLots
        .map(lotName => lotData.find(lot => lot.name === lotName))
        .filter(lot => lot !== null); // This filters out any lots not found in the data
    };

    const selectedLotData = getBatteryDataForSelectedLots(selectedOptions).filter(
      lot => lot !== null
    ) as { name: string; weight: number; batteryPacks: { name: string; number: number }[] }[];
    const batteryWeights = selectedLotData.map(lot => lot.weight);
    const lotNames = selectedLotData.map(lot => lot.name);

    // Update bar chart data state here
    setBarChartData(prevData => ({
      ...prevData,
      series: [
        {
          name: TEXT_CONTENTS.CHARTS.LOTS_TRACKING.BATTERY_PACKS_PER_LOT.YAXIS_HOVER,
          data: batteryWeights,
        },
      ],
      options: {
        ...prevData.options,
        xaxis: { categories: lotNames, labels: { style: { fontSize: '15px' } } },
      },
    }));
    // eslint-disable-next-line
  }, [selectedOptions]);

  function formatRandomDate(startDate: Date, endDate: Date) {
    const timeDiff = endDate.getTime() - startDate.getTime();
    const randomTime = startDate.getTime() + Math.random() * timeDiff;
    return new Date(randomTime).toLocaleDateString(); // You can customize the date format as needed
  }

  const [dateRange, setDateRange] = useState([
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const handleCalendarToggle = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const handleDateRangeSelect = (item: {
    selection: { startDate: Date; endDate: Date; key: string };
  }) => {
    const numberOfLots = Math.floor(Math.random() * 4) + 1;
    const tempLotsSet = new Set<string>();
    while (tempLotsSet.size < numberOfLots) {
      const randomLot = options[Math.floor(Math.random() * options.length)];
      tempLotsSet.add(randomLot);
    }
    const tempLots = Array.from(tempLotsSet);
    setSelectedOptions(tempLots);
    setDateRange([item.selection]);
  };

  const handleSelectChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedOptions(event.target.value as string[]);
  };

  useEffect(() => {
    const replaceTextInPdf = async (pdfBuffer: ArrayBuffer) => {
      try {
        const pdfDoc = await PDFDocument.load(pdfBuffer);
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

        const pages = pdfDoc.getPages();
        const page = pages[0];

        const x = 154;
        const y = 621;
        const width = 60;
        const height = 8;

        page.drawRectangle({
          x,
          y,
          width,
          height,
          color: rgb(1, 1, 1),
        });

        page.drawText(toTitleCase(replaceText), {
          x,
          y,
          size: 10,
          font: helveticaFont,
          color: rgb(0, 0, 0),
        });

        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        setModifiedPdfUrl(url);
      } catch (error) {
        console.error('Error processing PDF:', error);
      }
    };

    const fetchAndReplaceTextInPdf = async () => {
      try {
        const response = await fetch(pdfData);
        const arrayBuffer = await response.arrayBuffer();
        await replaceTextInPdf(arrayBuffer);
      } catch (error) {
        console.error('Error loading or processing PDF:', error);
      }
    };

    fetchAndReplaceTextInPdf();
  }, [pdfData, replaceText]);

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={4}
        sx={{ width: '100%', overflowX: 'scroll' }}
        order={{ xs: 1, sm: 1, md: 1, lg: 1 }}
      >
        <Button
          onClick={handleCalendarToggle}
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
          endIcon={<ArrowDropDownIcon />}
        >
          <Typography>Filter by date</Typography>
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={4} order={{ xs: 3, sm: 3, md: 3, lg: 2 }}>
        <FormControl variant="filled" fullWidth>
          <InputLabel id="multi-select-label" color="primary">
            Lots
          </InputLabel>
          <Select
            fullWidth
            labelId="multi-select-label"
            variant="filled"
            color="primary"
            multiple
            value={selectedOptions}
            onChange={handleSelectChange}
            renderValue={selected => selected.join(', ')}
          >
            {options.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={4} order={{ xs: 4, sm: 4, md: 4, lg: 3 }}>
        {modifiedPdfUrl && (
          <Button
            href={modifiedPdfUrl}
            download="modified-pdf.pdf"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // flexGrow: 1,
              height: '100%',
            }}
            endIcon={<FileDownloadIcon />}
          >
            <Typography>Download Report</Typography>
          </Button>
        )}
      </Grid>
      <Grid container spacing={2} order={5} mt={4} mb={2}>
        <Grid item xs={8}>
          <Box>
            <ChartWrapper
              title={TEXT_CONTENTS.CHARTS.LOTS_TRACKING.BATTERY_PACKS_PER_LOT.TITLE}
              chartId={TEXT_CONTENTS.CHARTS.LOTS_TRACKING.BATTERY_PACKS_PER_LOT.CHART_ID}
            >
              <ChartContainer height={height} dataNotAvailable={selectedOptions.length === 0}>
                <Chart
                  options={barChartData.options}
                  series={barChartData.series}
                  type="bar"
                  height={height}
                  colors={[theme.palette.primary.light, theme.palette.primary.dark]}
                  style={{
                    color: theme.palette.secondary.main,
                    fontFamily: theme.typography.fontFamily,
                  }}
                />
              </ChartContainer>
            </ChartWrapper>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            sx={{
              height: 550,
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '0.4em',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'secondary',
                outline: '1px solid slategrey',
              },
            }}
          >
            <Typography variant="h6">{TEXT_CONTENTS.LOTS.LOT_DETAILS}</Typography>
            {lotData
              .filter(lot => selectedOptions.includes(lot.name))
              .map(lot => (
                <Paper
                  key={lot.name}
                  sx={{
                    marginTop: '0.5rem',
                    padding: '1rem',
                    backgroundColor: 'primary', // Or any theme color that matches your design
                    minHeight: `calc(${height} / ${lotData.length})`,
                  }}
                >
                  <Typography variant="h6">{lot.name}</Typography>
                  <Typography variant="body1">
                    {TEXT_CONTENTS.LOTS.LOT_DETAILS_CARD.TOTAL_WEIGHT} {lot.weight}{' '}
                    {TEXT_CONTENTS.LOTS.LOT_DETAILS_CARD.WEIGHT_UNIT}
                  </Typography>
                  {lot.batteryPacks.map(pack => (
                    <Typography key={pack.name} variant="body2">
                      {pack.name} - {pack.number} {TEXT_CONTENTS.LOTS.LOT_DETAILS_CARD.UNITS}
                    </Typography>
                  ))}
                  <Typography variant="body2">
                    {TEXT_CONTENTS.LOTS.LOT_DETAILS_CARD.DATE}{' '}
                    {formatRandomDate(dateRange[0].startDate, dateRange[0].endDate)}
                  </Typography>
                </Paper>
              ))}
          </Box>
        </Grid>
      </Grid>
      {isCalendarOpen && (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          order={{ xs: 2, sm: 2, md: 2, lg: 4 }}
          sx={{
            width: '100%',
            overflowX: 'scroll',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {isMobile ? (
            <DateRange
              editableDateInputs={true}
              onChange={handleDateRangeSelect}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
            />
          ) : (
            <DateRangePicker
              editableDateInputs={true}
              onChange={handleDateRangeSelect}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
              months={2}
              direction="horizontal"
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default Report;
