import { gql } from '@apollo/client';

const typeDefs = gql`
  type SeriesLabel {
    series: [String]
    labels: [String]
  }

  type Types {
    group: String
    label: String
    minimum: Int
    q1: Int
    median: Int
    q3: Int
    maximum: Int
    outliers: [Int]
  }

  type CellInsights {
    cellCondition: SeriesLabel
    types: [Types]
  }

  type LabelValue {
    label: String
    value: Int
  }

  type MaterialInsights {
    materials: [LabelValue]
    components: [LabelValue]
  }

  type SeriesData {
    name: String
    data: [Int]
  }
  type Monthly {
    categories: [String]
    series: [SeriesData]
  }

  type BatteryInsights {
    yearly: [LabelValue]
    monthly: Monthly
  }

  type Facilities {
    active: Int
    total: Int
  }

  type Suppliers {
    latitude: Float
    count: Int
  }

  type Production {
    facilities: Facilities
    suppliers: [Suppliers]
  }

  type DataInsights {
    cellInsights: CellInsights
    materialInsights: MaterialInsights
    batteryInsights: BatteryInsights
    production: Production
  }
`;

export default typeDefs;
