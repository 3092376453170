import React, { ChangeEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  alpha,
  Box,
  Divider,
  MenuItem,
  Switch,
  Tooltip,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Logout as LogOutIcon,
  Person as UserIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import Menu, { MenuProps } from '@mui/material/Menu';
import { LOGOUT } from 'src/graphql/mutations';
import { useMutation } from '@apollo/client';
import { useFirebase, useMessages, useUserSettings } from '../hooks';
import { TEXT_CONTENTS } from 'src/constants';
import { Currency } from 'src/@types/ChartSettings/MaterialsValue';
import { UPDATE_USER_SETTINGS } from 'src/graphql/mutations/settings';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    slotProps={{
      paper: {
        elevation: 10,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: { xs: 14, sm: 80, md: 80, lg: 80 },
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      },
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const CurrencyLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(1),
}));

const CurrencySwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      transform: 'translateX(22px)',
      color: '#fff',
      '& .MuiSwitch-thumb:before': {
        content: '"€"',
      },
      '& .MuiSwitch-thumb:after': {
        content: '""',
      },
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
    '&:not(.Mui-checked) .MuiSwitch-thumb:after': {
      content: '"$"',
    },
    '&:not(.Mui-checked) .MuiSwitch-thumb:before': {
      content: '""',
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&:before, &:after': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: '14px',
      color: '#fff',
    },
  },
  '& .MuiSwitch-track': {
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));

const UserMenu = () => {
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery('(max-width:1150px)');
  const navigate = useNavigate();
  const { email } = useFirebase();
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { loading, currency, setCurrency } = useUserSettings();
  const [selectedCurrency, setSelectedCurrency] = React.useState<Currency>(currency);
  const [isSettingsOpen, setIsSettingsOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | SVGSVGElement>(null);

  const [logout] = useMutation(LOGOUT, {
    onError: () => {
      setErrorMessage('We are experiencing issues logging you out.');
    },
    onCompleted: () => {
      setSuccessMessage('You have been logged out.');
      navigate('/');
    },
    fetchPolicy: 'no-cache',
  });

  const [updateUserSettings] = useMutation(UPDATE_USER_SETTINGS, {
    onError: () => {
      setErrorMessage('We are experiencing issues updating your settings.');
    },
    onCompleted: () => {
      setSuccessMessage('Your settings have been updated.');
    },
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsSettingsOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsSettingsOpen(false);
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose();
  };

  const handleOpenUserMenu = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    setAnchorElUser(event.currentTarget as SVGSVGElement);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCurrencyChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const newCurrency = event.target.checked ? Currency.EUR : Currency.USD;
    try {
      await updateUserSettings({
        variables: {
          settings: {
            currency: newCurrency,
          },
        },
      });
      setCurrency(newCurrency);
      setSelectedCurrency(newCurrency);
      setSuccessMessage('Your settings have been updated.');
    } catch (error) {
      setErrorMessage('We are experiencing issues updating your settings.');
    }
  };

  useEffect(() => {
    setSelectedCurrency(currency as Currency);
  }, [loading, currency]);

  if (!isSmallerScreen) {
    return (
      //   <Box
      //     sx={{
      //       display: 'flex',
      //       justifyContent: 'flex-end',
      //       alignItems: 'center',
      //       color: '#FFFFFF',
      //     }}
      //   >
      <>
        <Button
          sx={{
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            cursor: 'pointer',
            color: '#fff',
            fontWeight: 'bold',
          }}
          startIcon={<SettingsIcon />}
          onClick={handleClick}
        >
          <Typography variant="text-lg">Settings</Typography>
        </Button>
        <StyledMenu
          anchorEl={anchorEl}
          open={isSettingsOpen}
          onClose={handleClose}
          disableAutoFocusItem
        >
          <MenuItem
            sx={{
              padding: theme.spacing(1, 2),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <UserIcon />
            <Typography variant="body1" sx={{ margin: theme.spacing(0, 1) }}>
              {email}
            </Typography>
          </MenuItem>
          <Tooltip title={TEXT_CONTENTS.NAVIGATION.SETTINGS.CURRENCY_TOOLTIP} placement="left">
            <MenuItem
              sx={{
                padding: theme.spacing(1, 2),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <CurrencyLabel>{Currency.USD}</CurrencyLabel>
              <CurrencySwitch
                checked={selectedCurrency === Currency.EUR}
                onChange={handleCurrencyChange}
                inputProps={{ 'aria-label': 'currency switch' }}
              />
              <CurrencyLabel>{Currency.EUR}</CurrencyLabel>
            </MenuItem>
          </Tooltip>
          <Divider sx={{ my: 0.5 }} />
          <MenuItem onClick={handleLogout} disableRipple>
            <LogOutIcon />
            {TEXT_CONTENTS.NAVIGATION.SETTINGS.LOGOUT}
          </MenuItem>
        </StyledMenu>
      </>
      //   </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        color: '#FFFFFF',
      }}
    >
      <SettingsIcon onClick={handleOpenUserMenu} sx={{ color: '#FFFFFF' }} />
      <StyledMenu
        anchorEl={anchorElUser}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        disableAutoFocusItem
      >
        <MenuItem
          sx={{
            padding: theme.spacing(1, 2),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <UserIcon />
          <Typography variant="body1" sx={{ margin: theme.spacing(0, 1) }}>
            {email}
          </Typography>
        </MenuItem>
        <Tooltip title={TEXT_CONTENTS.NAVIGATION.SETTINGS.CURRENCY_TOOLTIP} placement="left">
          <MenuItem
            sx={{
              padding: theme.spacing(1, 2),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <CurrencyLabel>{Currency.USD}</CurrencyLabel>
            <CurrencySwitch
              checked={selectedCurrency === Currency.EUR}
              onChange={handleCurrencyChange}
              inputProps={{ 'aria-label': 'currency switch' }}
            />
            <CurrencyLabel>{Currency.EUR}</CurrencyLabel>
          </MenuItem>
        </Tooltip>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handleLogout} disableRipple>
          <LogOutIcon />
          {TEXT_CONTENTS.NAVIGATION.SETTINGS.LOGOUT}
        </MenuItem>
      </StyledMenu>
    </Box>
  );
};

export default UserMenu;
