import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Collapse, Divider, MenuItem, Typography } from '@mui/material';

import RestrictedBadge from 'src/components/Structure/RestrictedBadge';
import StyledLink from 'src/components/Structure/Navigation/Link';
import StyledMenu, { NavBarMenuItem } from 'src/components/Structure/Navigation/Menu';
import { NavItem } from 'src/@types/Navigation';

interface NavBarMenuProps {
  item: NavItem;
  approved: boolean;
}

const DesktopNavBarMenu: React.FC<NavBarMenuProps> = ({ item, approved }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { NAME, SUB_MENU } = item;
  const navigate = useNavigate();

  const handleClickLink = (event: React.MouseEvent<HTMLElement>, url?: string) => {
    setAnchorEl(event.currentTarget);
    if (url) {
      navigate(url);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseInnerMenu = (link: string) => {
    setAnchorEl(null);
    navigate(link);
  };

  return (
    <>
      <RestrictedBadge restricted={!approved}>
        <StyledLink
          id={`${NAME}-button`}
          name={NAME}
          menu={SUB_MENU}
          onClickHandler={e => handleClickLink(e, item.URL)}
        />
      </RestrictedBadge>
      {SUB_MENU && (
        <StyledMenu
          id={`${NAME}-menu`}
          MenuListProps={{
            'aria-labelledby': `${NAME}-button`,
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {SUB_MENU.map((link, index) => (
            <NavBarMenuItem
              key={index}
              onClickHandler={() => handleCloseInnerMenu(link.URL)}
              url={link.URL}
              name={link.NAME}
              menuType={NAME}
            />
          ))}
        </StyledMenu>
      )}
    </>
  );
};

const MobileNavBarMenu: React.FC<NavBarMenuProps> = ({ item }) => {
  const [showMenu, setShowMenu] = React.useState(false);
  const { NAME, SUB_MENU, URL } = item;
  const navigate = useNavigate();

  const handleClick = (url?: string) => {
    setShowMenu(!showMenu);
    if (url) {
      navigate(url);
    }
  };

  const handleCloseInnerMenu = (e: React.MouseEvent<HTMLLIElement>, link: string) => {
    setShowMenu(false);
    navigate(link);
  };

  return (
    <>
      <Divider />
      <MenuItem onClick={() => handleClick(URL)}>
        <Typography textAlign="center">{NAME}</Typography>
      </MenuItem>
      <Collapse in={showMenu} timeout="auto" unmountOnExit>
        {SUB_MENU &&
          SUB_MENU.map((link, index) => (
            <MenuItem
              key={index}
              onClick={e => handleCloseInnerMenu(e, link.URL)}
              sx={{ backgroundColor: '#f2f2f2' }}
            >
              <Typography textAlign="center">{link.NAME}</Typography>
            </MenuItem>
          ))}
      </Collapse>
    </>
  );
};

export { DesktopNavBarMenu, MobileNavBarMenu };
