import React, { useEffect, useState } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useQuery } from '@apollo/client';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

import { ChartWrapper, PageContainer, Title } from 'src/components/Structure';
import { GET_BATTERY_LIBRARY_OVERVIEW } from 'src/graphql/queries';
import { TEXT_CONTENTS } from 'src/constants';
import { InventoryOverview } from 'src/@types/BatteryLibrary';
import KpiCard from 'src/components/Structure/KpiCard';

const BatteryLibraryOverview: React.FC = () => {
  const { loading, error, data } = useQuery<{ getBatteryLibraryOverview: InventoryOverview }>(
    GET_BATTERY_LIBRARY_OVERVIEW
  );
  const [evCount, setEvCount] = useState<number>(0);
  const [mmptCount, setMmptCount] = useState<number>(0);
  const [chartOptions, setChartOptions] = useState<ApexOptions>({
    chart: {
      height: 350,
      type: 'line',
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      type: 'category',
      categories: [],
    },
    dataLabels: {
      enabled: false,
    },
  });

  const [chartSeries, setChartSeries] = useState<{ name: string; data: number[] }[]>([
    { name: TEXT_CONTENTS.CHARTS.BATTERY_LIBRARY_OVERVIEW.BATTERY_PACKS_STORED.YAXIS, data: [] },
  ]);
  const theme = useTheme();

  useEffect(() => {
    if (data?.getBatteryLibraryOverview?.evCount) {
      setEvCount(data.getBatteryLibraryOverview.evCount);
    }
    if (data?.getBatteryLibraryOverview?.mmptCount) {
      setMmptCount(data.getBatteryLibraryOverview.mmptCount);
    }
    if (data?.getBatteryLibraryOverview?.monthlyInventory) {
      const inventoryData = data.getBatteryLibraryOverview.monthlyInventory;

      const categories = inventoryData.map(
        item => `${item.month.toString().slice(0, 3)} ${item.year}`
      );
      const seriesData = inventoryData.map(item => item.count);

      setChartOptions(prevOptions => ({
        ...prevOptions,
        xaxis: { ...prevOptions.xaxis, categories },
      }));

      setChartSeries([
        {
          name: TEXT_CONTENTS.CHARTS.BATTERY_LIBRARY_OVERVIEW.BATTERY_PACKS_STORED.YAXIS,
          data: seriesData,
        },
      ]);
    }
  }, [data, data?.getBatteryLibraryOverview]);

  if (loading) return <Typography variant="body1">{TEXT_CONTENTS.COMMON.LOADING}</Typography>;

  if (error) return <Typography variant="body1">{TEXT_CONTENTS.COMMON.ERROR}</Typography>;

  return (
    <PageContainer>
      <Title variant="h2">{TEXT_CONTENTS.BATTERY_LIBRARY_OVERVIEW.TITLE}</Title>
      <Grid container spacing={2} height={theme.spacing(30)}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <KpiCard
            text={TEXT_CONTENTS.BATTERY_LIBRARY_OVERVIEW.EV_COUNT}
            value={evCount.toString()}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <KpiCard
            text={TEXT_CONTENTS.BATTERY_LIBRARY_OVERVIEW.MMPT_COUNT}
            value={mmptCount.toString()}
          />
        </Grid>
        <Grid item xs={12}>
          <ChartWrapper
            title={TEXT_CONTENTS.CHARTS.BATTERY_LIBRARY_OVERVIEW.BATTERY_PACKS_STORED.TITLE}
          >
            <ReactApexChart options={chartOptions} series={chartSeries} type="line" height={350} />
          </ChartWrapper>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default BatteryLibraryOverview;
