export const getPreviousBusinessDay = () => {
  const today = new Date();
  let offset = 1;

  // If today is Monday, subtract 3 days to get the previous Friday.
  if (today.getDay() === 1) {
    offset = 3;
  }

  // If today is Sunday, subtract 2 days to get the previous Friday.
  else if (today.getDay() === 0) {
    offset = 2;
  }

  const previousBusinessDay = new Date(today);
  previousBusinessDay.setDate(today.getDate() - offset);

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return `${
    months[previousBusinessDay.getMonth()]
  } ${previousBusinessDay.getDate()}, ${previousBusinessDay.getFullYear()}`;
};

export const getFormattedDay = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();
  return `${day} ${month}, ${year}`;
};

export const dateStrToTimestamp = (dateStr: string): number => {
  const [day, month, year] = dateStr.split('/').map(num => parseInt(num, 10));
  const dateObj = new Date(year + 2000, month - 1, day); // Adding 2000 to get the full year
  return Math.floor(dateObj.getTime() / 1000);
};

export const initializeMonthlyData = () => ({
  January: 0,
  February: 0,
  March: 0,
  April: 0,
  May: 0,
  June: 0,
  July: 0,
  August: 0,
  September: 0,
  October: 0,
  November: 0,
  December: 0,
});
