import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  useTheme,
} from '@mui/material';

interface ClientFilterProps {
  clients: string[];
  selectedClient: string;
  setSelectedClient: React.Dispatch<React.SetStateAction<string>>;
}

const ClientFilter: React.FC<ClientFilterProps> = ({
  clients,
  selectedClient,
  setSelectedClient,
}): JSX.Element => {
  const theme = useTheme();

  const handleDropdownChange = (event: SelectChangeEvent) => {
    setSelectedClient(event.target.value);
  };

  return (
    <FormControl
      variant="filled"
      sx={{
        width: '100%',
      }}
    >
      <InputLabel id="battery-type-label">Client</InputLabel>
      <Select
        labelId="battery-type-label"
        label="Commodity"
        value={selectedClient}
        onChange={handleDropdownChange}
        variant="filled"
        sx={{
          flex: 1,
          marginRight: theme.spacing(2),
        }}
      >
        <MenuItem value="All Clients">All Clients</MenuItem>
        {clients?.map((client, index) => {
          return (
            <MenuItem key={index} value={client}>
              {client}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export { ClientFilter };
