import React, { useState, useRef } from 'react';
import { ChartWrapper, PageContainer, Title } from 'src/components/Structure';
import { Grid, useTheme, Modal } from '@mui/material';
import { TEXT_CONTENTS } from 'src/constants';
import { ScrollableCardComponent } from 'src/components/Structure/BatteryLibraryModal';
import { EvBatteryCard } from 'src/components/Structure/BatteryLibraryModal';
import BatteryPackSimulation from 'src/components/Clients/BatteryPackSimulation';
import ModuleSimulation from 'src/components/Clients/ModuleSimulation';
import Box from '@mui/material/Box';
import Report from 'src/components/Clients/Report';
import report from 'src/assets/pdfs/Report.pdf';
import { useLocation } from 'react-router-dom';
import IpadInterface from 'src/components/Clients/IpadInterface';
import { ProcessFlowSimulation } from 'src/@types/DataTypes';
import ERDDiagram from 'src/components/Clients/Schema';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import MasterDataOemCard from 'src/components/MasterData/MasterDataOemCard';

const Clients: React.FC = (): JSX.Element => {
  const theme = useTheme();
  const location = useLocation();
  const client = location.pathname.split('/')[2];
  const [evModalIndex, setEvModalIndex] = useState<number>(-1);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [isBpSimulation, setIsBpSimulation] = useState(true);
  const [bpSimulation, setBpSimulation] = useState<ProcessFlowSimulation>({
    qrScan: 0,
    weight: 0,
    dischargeData: 0,
    done: 0,
  });
  const [moduleSimulation, setModuleSimulation] = useState<ProcessFlowSimulation>({
    qrScan: 0,
    weight: 0,
    dischargeData: 0,
    done: 0,
  });
  const [batteryCategoryTab, setBatteryCategoryTab] = useState<number>(0);
  const tabStyle = {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 1,
    cursor: 'pointer',
    fontSize: '1.3rem',
    minWidth: 'auto',
    marginRight: '10px',
  };
  const tabsContainerStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '20px',
    marginLeft: theme.spacing(-4),
  };

  const interval = useRef<NodeJS.Timeout | null>(null);

  const startInterval = (
    intervalRef: React.MutableRefObject<NodeJS.Timeout | null>,
    updateStateFunc: () => void,
    delay: number
  ): NodeJS.Timeout => {
    updateStateFunc();
    intervalRef.current = setInterval(updateStateFunc, delay);
    return intervalRef.current as NodeJS.Timeout;
  };

  const handleStart = () => {
    const setSimulation = isBpSimulation ? setBpSimulation : setModuleSimulation;
    const intervalID = startInterval(
      interval,
      () => {
        setSimulation(prevState => ({ ...prevState, qrScan: prevState.qrScan + 1 }));
        setTimeout(() => {
          setSimulation(prevState => ({ ...prevState, weight: prevState.weight + 1 }));
        }, 3000);
        setTimeout(() => {
          setSimulation(prevState => ({
            ...prevState,
            dischargeData: prevState.dischargeData + 1,
          }));
        }, 6000);
      },
      9000
    );

    setTimeout(() => {
      clearInterval(intervalID);
      setSimulation(prevState => ({
        ...prevState,
        qrScan: 0,
        weight: 0,
        dischargeData: 0,
      }));
    }, 36000 * 8);
  };

  const handleStop = () => {
    if (interval.current) {
      clearInterval(interval.current);
      interval.current = null;
      const setSimulation = isBpSimulation ? setBpSimulation : setModuleSimulation;
      setSimulation(prevState => ({
        ...prevState,
        qrScan: 0,
        weight: 0,
        dischargeData: 0,
      }));
    }
  };

  const getModalContent = (batteryCategoryTab: number, modalIndex: number) => {
    if (batteryCategoryTab === 0) {
      return (
        modalIndex > -1
          ? TEXT_CONTENTS.CHARTS.BATTERY_INSIGHTS.EV_LIBRARY.DATA[evModalIndex]
          : TEXT_CONTENTS.CHARTS.BATTERY_INSIGHTS.EV_LIBRARY.DATA[0]
      ).DATA as EvBatteryCard[];
    }
    if (batteryCategoryTab === 1) {
      return (
        modalIndex > -1
          ? TEXT_CONTENTS.CHARTS.BATTERY_INSIGHTS.HYBRID_LIBRARY.DATA[evModalIndex]
          : TEXT_CONTENTS.CHARTS.BATTERY_INSIGHTS.HYBRID_LIBRARY.DATA[0]
      ).DATA as EvBatteryCard[];
    }
    return TEXT_CONTENTS.CHARTS.BATTERY_INSIGHTS.MODULE_LIBRARY.DATA[0].DATA;
  };

  return (
    <PageContainer>
      <Title variant="h1">{TEXT_CONTENTS.CLIENTS.TITLE}</Title>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ChartWrapper title={'Client Report'}>
            <Report pdfData={report} replaceText={client} />
          </ChartWrapper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} order={{ xs: 5, sm: 5, md: 5, lg: 5 }}>
          <ChartWrapper title={TEXT_CONTENTS.CHARTS.BATTERY_INSIGHTS.EV_LIBRARY.TITLE}>
            <Tabs
              value={batteryCategoryTab}
              variant="scrollable"
              scrollButtons={true}
              sx={tabsContainerStyle}
            >
              <Tab label={'EV'} sx={tabStyle} onClick={() => setBatteryCategoryTab(0)} />
              <Tab label={'Hybrid'} sx={tabStyle} onClick={() => setBatteryCategoryTab(1)} />
              <Tab label={'Module'} sx={tabStyle} onClick={() => setBatteryCategoryTab(2)} />
            </Tabs>
            {(batteryCategoryTab === 0 || batteryCategoryTab === 2) && (
              <Grid container sx={{ marginTop: theme.spacing(2) }}>
                {TEXT_CONTENTS.CHARTS.BATTERY_INSIGHTS.EV_LIBRARY.DATA.map(
                  ({ NAME, DATA, LOGO }, index) => (
                    <Grid item xs={6} sm={6} md={2} lg={2} key={index}>
                      <MasterDataOemCard
                        name={NAME}
                        count={DATA.length}
                        logo={LOGO}
                        handleClick={() => {
                          setEvModalIndex(index);
                        }}
                      />
                    </Grid>
                  )
                )}
              </Grid>
            )}
            {batteryCategoryTab === 1 && (
              <Grid container sx={{ marginTop: theme.spacing(2) }}>
                {TEXT_CONTENTS.CHARTS.BATTERY_INSIGHTS.HYBRID_LIBRARY.DATA.map(
                  ({ NAME, DATA, LOGO }, index) => (
                    <Grid item xs={6} sm={6} md={2} lg={2} key={index}>
                      <MasterDataOemCard
                        name={NAME}
                        logo={LOGO}
                        count={DATA.length}
                        handleClick={() => {
                          setEvModalIndex(index);
                        }}
                      />
                    </Grid>
                  )
                )}
              </Grid>
            )}
          </ChartWrapper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} order={{ xs: 6, sm: 6, md: 6, lg: 6 }}>
          <ChartWrapper title={'Ipad Interface - BatDat for operations'}>
            <IpadInterface
              batteryPackSimulationData={bpSimulation}
              moduleSimulationData={moduleSimulation}
              onClickStart={handleStart}
              onClickStop={handleStop}
              isBpSimulation={isBpSimulation}
              setIsBpSimulation={setIsBpSimulation}
            />
          </ChartWrapper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} order={{ xs: 7, sm: 7, md: 7, lg: 7 }}>
          <ChartWrapper title={TEXT_CONTENTS.CLIENTS.BATTERY_PACK_SIMULATION.TITLE}>
            <BatteryPackSimulation
              setOpenImageModal={setOpenImageModal}
              batteryPackSimulationData={bpSimulation}
            />
          </ChartWrapper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} order={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
          <ChartWrapper title={TEXT_CONTENTS.CLIENTS.MODULE_SIMULATION.TITLE}>
            <ModuleSimulation
              setOpenImageModal={setOpenImageModal}
              moduleSimulationData={moduleSimulation}
            />
          </ChartWrapper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} order={{ xs: 9, sm: 9, md: 9, lg: 9 }}>
          <ChartWrapper title={TEXT_CONTENTS.CLIENTS.SCHEMA.TITLE}>
            <ERDDiagram />
          </ChartWrapper>
        </Grid>
      </Grid>
      <Modal
        open={evModalIndex > -1}
        onClose={() => {
          setEvModalIndex(-1);
        }}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <ScrollableCardComponent batteryPacks={getModalContent(batteryCategoryTab, evModalIndex)} />
      </Modal>
      <Modal
        open={openImageModal}
        onClose={() => {
          setOpenImageModal(false);
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <Box
          component="img"
          src={TEXT_CONTENTS.CLIENTS.BATTERY_PACK_SIMULATION.DATA[0].image}
          sx={{
            width: { xs: '90vw', sm: '90vw', md: '85vw', lg: '70vw' },
            height: { xs: '35vh', sm: '50vh', md: '60vh', lg: '70vh' },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
      </Modal>
    </PageContainer>
  );
};

export default Clients;
