export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, (txt: string) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const camelCaseToTitleCase = (camelCaseStr: string) => {
  const words = camelCaseStr.match(/[A-Z][a-z]+|[0-9]+|[a-z]+/g);

  if (!words) {
    return toTitleCase(camelCaseStr);
  }
  return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

export const isOffice365Url = (url: string) => {
  const pattern = /\/:[^/]+\/s\/.*[?].*/;

  return pattern.test(url);
};
