import React, { useState } from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import ReactMapGL, { Marker } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import MapLocation from 'src/components/Charts/ProductionInsights/MapLocation';
import { useDataInsights } from 'src/components/hooks';

const Map: React.FC = () => {
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN || '';
  const { facilityInsights } = useDataInsights();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mapViewport, setMapViewport] = useState({
    height: theme.spacing(76.5),
    width: '100%',
    longitude: isMobile ? 6 : 6,
    latitude: 53,
    zoom: 3,
  });

  const settings = {
    dragPan: true,
    dragRotate: false,
    scrollZoom: !isMobile,
    touchZoom: true,
    touchRotate: false,
    keyboard: false,
    doubleClickZoom: true,
  };

  return (
    <Box style={{ backgroundColor: 'white' }}>
      <ReactMapGL
        {...mapViewport}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        mapStyle="mapbox://styles/mapbox/light-v11"
        onViewportChange={setMapViewport}
        onResize={() => {
          setMapViewport({ ...mapViewport, width: '100%' });
        }}
        {...settings}
      >
        {facilityInsights?.facilitiesLocation.map((facility, index) => (
          <Marker key={index} latitude={facility.latitude} longitude={facility.longitude}>
            <div style={{ cursor: 'pointer', display: 'flex', position: 'relative' }}>
              <MapLocation
                count={facility.capacityPerMonth}
                name={facility.facilityName}
                isEvProcessible={facility.isEvProcessible}
                isMmProcessible={facility.isMmProcessible}
                isPtProcessible={facility.isPtProcessible}
              />
            </div>
          </Marker>
        ))}
      </ReactMapGL>
    </Box>
  );
};

export default Map;
