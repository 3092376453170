import React, { useCallback, useEffect, useState } from 'react';
import { Theme, ToggleButton, ToggleButtonGroup, useMediaQuery, useTheme } from '@mui/material';
import { ChartContainer } from 'src/components/Structure';
import Chart from 'react-apexcharts';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useDataInsights } from 'src/components/hooks';
import { ChartProps, CurrencyRate, MetalPrice } from 'src/@types/DataTypes';
import { getFormattedDay } from 'src/utils/Date';
import { roundToTwoDecimals } from 'src/utils/Number';
import { Currency } from 'src/@types/ChartSettings/MaterialsValue';
import { TEXT_CONTENTS } from 'src/constants';

interface Props extends ChartProps {
  currency: Currency;
}

const MaterialsValue: React.FC<Props> = ({ currency, chartId = 'materials-price' }) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedMetal, setSelectedMetal] = useState('Aluminum');
  const [duration, setDuration] = useState(
    TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.MATERIALS.MONTH.toLowerCase()
  );
  const height = theme.spacing(56.5);
  const { materialInsights, currencyRates } = useDataInsights();

  const getTimestamps = (filteredData: MetalPrice[]) =>
    filteredData?.map(metal => getFormattedDay(metal.timestamp));

  const metalNames = [...new Set(materialInsights?.metalsPrice.map(metal => metal.name))];

  const computeDerivedData = useCallback(
    (currentMetal: string, currentDuration: string, currentCurrency: Currency) => {
      const currentDate = new Date();
      const oneDayInSeconds = 24 * 60 * 60;
      const currentTimestampInSeconds = Math.floor(currentDate.getTime() / 1000);
      let cutoffTimestamp = currentTimestampInSeconds;

      if (currentDuration === TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.MATERIALS.WEEK.toLowerCase()) {
        cutoffTimestamp = currentTimestampInSeconds - 7 * oneDayInSeconds;
      } else if (
        currentDuration === TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.MATERIALS.MONTH.toLowerCase()
      ) {
        cutoffTimestamp = currentTimestampInSeconds - 30 * oneDayInSeconds;
      }

      const filterAndSortMetalData = (metal: string): MetalPrice[] => {
        return materialInsights?.metalsPrice
          .filter(metalData => metalData.name === metal)
          .sort((a: MetalPrice, b: MetalPrice) => a.timestamp - b.timestamp) as MetalPrice[];
      };

      const getPrices = (filteredData: MetalPrice[]): number[] => {
        return filteredData?.map(metal => {
          if (currentCurrency === Currency.EUR) {
            const currencyRate = currencyRates?.find(
              rate => rate.currency === currentCurrency
            ) as CurrencyRate;
            return roundToTwoDecimals(metal.price * currencyRate?.rate);
          }
          return roundToTwoDecimals(metal.price);
        });
      };

      const filteredData = filterAndSortMetalData(currentMetal).filter(
        metal => metal.timestamp >= cutoffTimestamp
      );

      return {
        newTimestamps: getTimestamps(filteredData),
        newSeries: [
          {
            name: currentMetal,
            data: getPrices(filteredData),
          },
        ],
      };
    },
    [currencyRates, materialInsights]
  );

  const [series, setSeries] = useState(
    () => computeDerivedData(selectedMetal, duration, currency).newSeries
  );
  const [timestamps, setTimestamps] = useState(
    () => computeDerivedData(selectedMetal, duration, currency).newTimestamps
  );

  useEffect(() => {
    const { newTimestamps, newSeries } = computeDerivedData(selectedMetal, duration, currency);
    setTimestamps(newTimestamps);
    setSeries(newSeries);
  }, [selectedMetal, duration, currency, computeDerivedData]);

  const tabStyle = {
    border: (theme: Theme) => `1px solid ${theme.palette.divider}`,
    borderRadius: 1,
    cursor: 'pointer',
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number, newMetal: string) => {
    setValue(newValue);
    setSelectedMetal(newMetal);
  };

  const handleDurationChange = (
    event: React.MouseEvent<HTMLElement>,
    newDuration: string | null
  ) => {
    if (newDuration) setDuration(newDuration);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Tabs
          value={value}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          aria-label="scrollable tabs"
        >
          {metalNames.map((metal, index) => (
            <Tab
              key={metal}
              label={metal}
              sx={tabStyle}
              onClick={e => handleChange(e, index, metal)}
            />
          ))}
        </Tabs>
        <ToggleButtonGroup
          value={duration}
          exclusive
          orientation={isMobileOrTablet ? 'vertical' : 'horizontal'}
          onChange={handleDurationChange}
        >
          <ToggleButton value={TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.MATERIALS.WEEK.toLowerCase()}>
            {TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.MATERIALS.WEEK}
          </ToggleButton>
          <ToggleButton
            value={TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.MATERIALS.MONTH.toLowerCase()}
          >
            {TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.MATERIALS.MONTH}
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <ChartContainer height={theme.spacing(55)} dataNotAvailable={series.length === 0}>
        <Chart
          options={{
            chart: {
              id: chartId,
              width: '100%',
              type: 'line',
              toolbar: { show: false },
              zoom: {
                enabled: false,
              },
              fontFamily: theme.typography.fontFamily,
            },
            dataLabels: {
              enabled: false,
            },
            colors: [theme.palette.primary.main],
            stroke: {
              curve: 'straight',
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5,
              },
            },
            xaxis: {
              categories: timestamps,
              labels: {
                style: {
                  fontSize: '15px',
                },
              },
              tooltip: {
                enabled: false,
              },
            },
            yaxis: {
              axisBorder: { show: false },
              axisTicks: { show: false },
              opposite: false,
            },
            tooltip: {
              enabled: true,
            },
          }}
          series={series}
          type="line"
          height={height}
          colors={[theme.palette.primary.light, theme.palette.primary.dark]}
          style={{
            fontFamily: theme.typography.fontFamily,
            marginTop: theme.spacing(0),
          }}
        />
      </ChartContainer>
    </Box>
  );
};

export default MaterialsValue;
