import React, { FormEvent, useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { useMessages } from 'src/components/hooks';
import { FirebaseContext } from 'src/components/Contexts';
import { LoginForm } from 'src/components/Auth';
import { DefaultObject } from 'src/@types/GenericTypes';
import CommonLayout from 'src/components/Auth/CommonLayout';
import { FirebaseError, SignInFormData } from 'src/@types/AuthTypes';
import { FirebaseContextInterface } from 'src/components/Contexts/FirebaseContext';
import { getFirebaseErrorMessage } from 'src/utils/Firebase';
import { GET_USER_DETAILS } from 'src/graphql/mutations/auth';
import { TEXT_CONTENTS } from 'src/constants';

const Login: React.FC = (): JSX.Element => {
  const context = useContext(FirebaseContext) as FirebaseContextInterface;
  const { firebase, onSuccessfulAuthentication } = context;
  const [formData, setFormData] = useState<SignInFormData>({
    email: '',
    password: '',
  });
  const [userEmail, setUserEmail] = useState('');
  const navigate = useNavigate();
  const { state } = useLocation();
  const [getUserDetails, { data }] = useLazyQuery(GET_USER_DETAILS);
  const { setDialog } = useMessages();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    try {
      const user = await firebase.signIn(formData.email, formData.password);
      if (user && user.uid && user.email) {
        setUserEmail(user.email);
        getUserDetails();
      }
    } catch (error) {
      console.error('Error signing in:', error);
      const firebaseErrorMessage = getFirebaseErrorMessage(error as FirebaseError);
      setDialog({
        type: 'error',
        title: TEXT_CONTENTS.LOGIN.ERROR,
        message: firebaseErrorMessage,
      });
    }
  };

  useEffect(() => {
    if (data && data.getUserDetails) {
      const userDetails = data.getUserDetails;

      if (userDetails.approved) {
        onSuccessfulAuthentication(userEmail);
        const redirectPath = ((state as DefaultObject)?.redirect as string) || '/';
        navigate(redirectPath);
      } else {
        navigate('/battery-map');
      }
      //   firebase.getAuth().signOut();
      //   setDialog({
      //     type: 'error',
      //     title: "Your account isn't approved yet",
      //     message:
      //       'Your account was created but was not approved by our admin. We will notify you once your account is approved. Be sure to check your spam or junk folders for updates.',
      //   });
      // }
    }
  });

  return (
    <CommonLayout>
      <LoginForm
        signInFormData={formData}
        setSignInFormData={setFormData}
        handleSubmit={handleSubmit}
      />
    </CommonLayout>
  );
};
export default Login;
