import { gql } from '@apollo/client';

export const GET_EARLY_USERS_FEEDBACK = gql`
  query Query {
    getEarlyUsersFeedback {
      interestedIn
      missingFeatures
      suggestedPrice
      usageFrequency
      adaptionToFeatures
      rating
    }
  }
`;
