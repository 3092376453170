import { SalesRecord, BrandSalesData } from 'src/@types/DataTypes';

const EV_SALES_COMBINED_LINE_CHART_DATA = [
  {
    Brand: 'Tesla',
    Quarter: 'Q1',
    Year: 2017,
    Sales: 5447,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q2',
    Year: 2017,
    Sales: 9985,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q3',
    Year: 2017,
    Sales: 16175,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q4',
    Year: 2017,
    Sales: 24046,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q1',
    Year: 2018,
    Sales: 29314,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q2',
    Year: 2018,
    Sales: 35654,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q3',
    Year: 2018,
    Sales: 42649,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q4',
    Year: 2018,
    Sales: 50109,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q1',
    Year: 2019,
    Sales: 70924,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q2',
    Year: 2019,
    Sales: 91119,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q3',
    Year: 2019,
    Sales: 118744,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q4',
    Year: 2019,
    Sales: 151546,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q1',
    Year: 2020,
    Sales: 172546,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q2',
    Year: 2020,
    Sales: 185034,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q3',
    Year: 2020,
    Sales: 209740,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q4',
    Year: 2020,
    Sales: 241551,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q1',
    Year: 2021,
    Sales: 269491,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q2',
    Year: 2021,
    Sales: 302821,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q3',
    Year: 2021,
    Sales: 345540,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q4',
    Year: 2021,
    Sales: 396909,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q1',
    Year: 2022,
    Sales: 452979,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q2',
    Year: 2022,
    Sales: 477853,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q3',
    Year: 2022,
    Sales: 529194,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q4',
    Year: 2022,
    Sales: 619045,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q1',
    Year: 2023,
    Sales: 709402,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q2',
    Year: 2023,
    Sales: 795536,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q3',
    Year: 2023,
    Sales: 875551,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q4',
    Year: 2023,
    Sales: 958364,
  },
  {
    Brand: 'Tesla',
    Quarter: 'Q1',
    Year: 2024,
    Sales: 959757,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q1',
    Year: 2017,
    Sales: 1538,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q2',
    Year: 2017,
    Sales: 4842,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q3',
    Year: 2017,
    Sales: 9348,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q4',
    Year: 2017,
    Sales: 14716,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q1',
    Year: 2018,
    Sales: 19869,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q2',
    Year: 2018,
    Sales: 24391,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q3',
    Year: 2018,
    Sales: 28402,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q4',
    Year: 2018,
    Sales: 36479,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q1',
    Year: 2019,
    Sales: 43108,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q2',
    Year: 2019,
    Sales: 49914,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q3',
    Year: 2019,
    Sales: 57213,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q4',
    Year: 2019,
    Sales: 66143,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q1',
    Year: 2020,
    Sales: 79393,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q2',
    Year: 2020,
    Sales: 89282,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q3',
    Year: 2020,
    Sales: 111778,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q4',
    Year: 2020,
    Sales: 174796,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q1',
    Year: 2021,
    Sales: 200660,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q2',
    Year: 2021,
    Sales: 244923,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q3',
    Year: 2021,
    Sales: 285148,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q4',
    Year: 2021,
    Sales: 328189,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q1',
    Year: 2022,
    Sales: 350509,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q2',
    Year: 2022,
    Sales: 377498,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q3',
    Year: 2022,
    Sales: 413953,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q4',
    Year: 2022,
    Sales: 484395,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q1',
    Year: 2023,
    Sales: 525921,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q2',
    Year: 2023,
    Sales: 581082,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q3',
    Year: 2023,
    Sales: 632304,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q4',
    Year: 2023,
    Sales: 671552,
  },
  {
    Brand: 'Volkswagen',
    Quarter: 'Q1',
    Year: 2024,
    Sales: 672229,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q1',
    Year: 2017,
    Sales: 8634,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q2',
    Year: 2017,
    Sales: 15958,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q3',
    Year: 2017,
    Sales: 21737,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q4',
    Year: 2017,
    Sales: 28444,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q1',
    Year: 2018,
    Sales: 36559,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q2',
    Year: 2018,
    Sales: 44241,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q3',
    Year: 2018,
    Sales: 50746,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q4',
    Year: 2018,
    Sales: 63852,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q1',
    Year: 2019,
    Sales: 74662,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q2',
    Year: 2019,
    Sales: 86269,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q3',
    Year: 2019,
    Sales: 95863,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q4',
    Year: 2019,
    Sales: 106588,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q1',
    Year: 2020,
    Sales: 125677,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q2',
    Year: 2020,
    Sales: 140105,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q3',
    Year: 2020,
    Sales: 164578,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q4',
    Year: 2020,
    Sales: 201509,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q1',
    Year: 2021,
    Sales: 216043,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q2',
    Year: 2021,
    Sales: 238269,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q3',
    Year: 2021,
    Sales: 255019,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q4',
    Year: 2021,
    Sales: 288628,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q1',
    Year: 2022,
    Sales: 306024,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q2',
    Year: 2022,
    Sales: 329408,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q3',
    Year: 2022,
    Sales: 351768,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q4',
    Year: 2022,
    Sales: 384804,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q1',
    Year: 2023,
    Sales: 403826,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q2',
    Year: 2023,
    Sales: 421328,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q3',
    Year: 2023,
    Sales: 438062,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q4',
    Year: 2023,
    Sales: 456956,
  },
  {
    Brand: 'Renault',
    Quarter: 'Q1',
    Year: 2024,
    Sales: 457298,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q1',
    Year: 2017,
    Sales: 3943,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q2',
    Year: 2017,
    Sales: 7538,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q3',
    Year: 2017,
    Sales: 10948,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q4',
    Year: 2017,
    Sales: 14953,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q1',
    Year: 2018,
    Sales: 18868,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q2',
    Year: 2018,
    Sales: 22874,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q3',
    Year: 2018,
    Sales: 27314,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q4',
    Year: 2018,
    Sales: 32903,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q1',
    Year: 2019,
    Sales: 40214,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q2',
    Year: 2019,
    Sales: 47059,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q3',
    Year: 2019,
    Sales: 54461,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q4',
    Year: 2019,
    Sales: 61974,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q1',
    Year: 2020,
    Sales: 66723,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q2',
    Year: 2020,
    Sales: 69647,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q3',
    Year: 2020,
    Sales: 75768,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q4',
    Year: 2020,
    Sales: 83853,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q1',
    Year: 2021,
    Sales: 89665,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q2',
    Year: 2021,
    Sales: 98534,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q3',
    Year: 2021,
    Sales: 108517,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q4',
    Year: 2021,
    Sales: 125249,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q1',
    Year: 2022,
    Sales: 142376,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q2',
    Year: 2022,
    Sales: 159610,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q3',
    Year: 2022,
    Sales: 178689,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q4',
    Year: 2022,
    Sales: 210478,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q1',
    Year: 2023,
    Sales: 230156,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q2',
    Year: 2023,
    Sales: 257179,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q3',
    Year: 2023,
    Sales: 288801,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q4',
    Year: 2023,
    Sales: 330515,
  },
  {
    Brand: 'BMW',
    Quarter: 'Q1',
    Year: 2024,
    Sales: 331925,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q1',
    Year: 2017,
    Sales: 979,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q2',
    Year: 2017,
    Sales: 2339,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q3',
    Year: 2017,
    Sales: 3737,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q4',
    Year: 2017,
    Sales: 5295,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q1',
    Year: 2018,
    Sales: 7525,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q2',
    Year: 2018,
    Sales: 9066,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q3',
    Year: 2018,
    Sales: 11890,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q4',
    Year: 2018,
    Sales: 16684,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q1',
    Year: 2019,
    Sales: 24643,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q2',
    Year: 2019,
    Sales: 30994,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q3',
    Year: 2019,
    Sales: 37847,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q4',
    Year: 2019,
    Sales: 45506,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q1',
    Year: 2020,
    Sales: 53244,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q2',
    Year: 2020,
    Sales: 59857,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q3',
    Year: 2020,
    Sales: 77087,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q4',
    Year: 2020,
    Sales: 99437,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q1',
    Year: 2021,
    Sales: 111501,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q2',
    Year: 2021,
    Sales: 125395,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q3',
    Year: 2021,
    Sales: 144406,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q4',
    Year: 2021,
    Sales: 168219,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q1',
    Year: 2022,
    Sales: 189153,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q2',
    Year: 2022,
    Sales: 207622,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q3',
    Year: 2022,
    Sales: 226563,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q4',
    Year: 2022,
    Sales: 249928,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q1',
    Year: 2023,
    Sales: 266656,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q2',
    Year: 2023,
    Sales: 284099,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q3',
    Year: 2023,
    Sales: 304588,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q4',
    Year: 2023,
    Sales: 319145,
  },
  {
    Brand: 'Hyundai',
    Quarter: 'Q1',
    Year: 2024,
    Sales: 319746,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q1',
    Year: 2017,
    Sales: 7,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q2',
    Year: 2017,
    Sales: 45,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q3',
    Year: 2017,
    Sales: 78,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q4',
    Year: 2017,
    Sales: 118,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q1',
    Year: 2018,
    Sales: 170,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q2',
    Year: 2018,
    Sales: 278,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q3',
    Year: 2018,
    Sales: 346,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q4',
    Year: 2018,
    Sales: 1278,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q1',
    Year: 2019,
    Sales: 3706,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q2',
    Year: 2019,
    Sales: 7199,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q3',
    Year: 2019,
    Sales: 11289,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q4',
    Year: 2019,
    Sales: 18018,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q1',
    Year: 2020,
    Sales: 25766,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q2',
    Year: 2020,
    Sales: 30608,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q3',
    Year: 2020,
    Sales: 39535,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q4',
    Year: 2020,
    Sales: 50996,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q1',
    Year: 2021,
    Sales: 58331,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q2',
    Year: 2021,
    Sales: 69107,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q3',
    Year: 2021,
    Sales: 83282,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q4',
    Year: 2021,
    Sales: 103175,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q1',
    Year: 2022,
    Sales: 119989,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q2',
    Year: 2022,
    Sales: 136348,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q3',
    Year: 2022,
    Sales: 151935,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q4',
    Year: 2022,
    Sales: 179155,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q1',
    Year: 2023,
    Sales: 202064,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q2',
    Year: 2023,
    Sales: 224854,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q3',
    Year: 2023,
    Sales: 249143,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q4',
    Year: 2023,
    Sales: 274684,
  },
  {
    Brand: 'Audi',
    Quarter: 'Q1',
    Year: 2024,
    Sales: 275584,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q1',
    Year: 2017,
    Sales: 665,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q2',
    Year: 2017,
    Sales: 981,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q3',
    Year: 2017,
    Sales: 1432,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q4',
    Year: 2017,
    Sales: 1912,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q1',
    Year: 2018,
    Sales: 2570,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q2',
    Year: 2018,
    Sales: 3193,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q3',
    Year: 2018,
    Sales: 3683,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q4',
    Year: 2018,
    Sales: 4413,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q1',
    Year: 2019,
    Sales: 4947,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q2',
    Year: 2019,
    Sales: 5267,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q3',
    Year: 2019,
    Sales: 5504,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q4',
    Year: 2019,
    Sales: 5759,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q1',
    Year: 2020,
    Sales: 14142,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q2',
    Year: 2020,
    Sales: 19584,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q3',
    Year: 2020,
    Sales: 29679,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q4',
    Year: 2020,
    Sales: 45141,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q1',
    Year: 2021,
    Sales: 59733,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q2',
    Year: 2021,
    Sales: 75119,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q3',
    Year: 2021,
    Sales: 89060,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q4',
    Year: 2021,
    Sales: 109352,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q1',
    Year: 2022,
    Sales: 126802,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q2',
    Year: 2022,
    Sales: 147286,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q3',
    Year: 2022,
    Sales: 165854,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q4',
    Year: 2022,
    Sales: 188252,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q1',
    Year: 2023,
    Sales: 209128,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q2',
    Year: 2023,
    Sales: 229188,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q3',
    Year: 2023,
    Sales: 248750,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q4',
    Year: 2023,
    Sales: 257972,
  },
  {
    Brand: 'Peugeot',
    Quarter: 'Q1',
    Year: 2024,
    Sales: 258252,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q1',
    Year: 2017,
    Sales: 1148,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q2',
    Year: 2017,
    Sales: 2062,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q3',
    Year: 2017,
    Sales: 3638,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q4',
    Year: 2017,
    Sales: 5212,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q1',
    Year: 2018,
    Sales: 6849,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q2',
    Year: 2018,
    Sales: 8596,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q3',
    Year: 2018,
    Sales: 10310,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q4',
    Year: 2018,
    Sales: 11989,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q1',
    Year: 2019,
    Sales: 15940,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q2',
    Year: 2019,
    Sales: 19489,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q3',
    Year: 2019,
    Sales: 22203,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q4',
    Year: 2019,
    Sales: 24999,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q1',
    Year: 2020,
    Sales: 31324,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q2',
    Year: 2020,
    Sales: 36525,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q3',
    Year: 2020,
    Sales: 49941,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q4',
    Year: 2020,
    Sales: 62006,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q1',
    Year: 2021,
    Sales: 73054,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q2',
    Year: 2021,
    Sales: 85490,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q3',
    Year: 2021,
    Sales: 100336,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q4',
    Year: 2021,
    Sales: 121591,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q1',
    Year: 2022,
    Sales: 143150,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q2',
    Year: 2022,
    Sales: 159922,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q3',
    Year: 2022,
    Sales: 173441,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q4',
    Year: 2022,
    Sales: 187634,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q1',
    Year: 2023,
    Sales: 201289,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q2',
    Year: 2023,
    Sales: 217978,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q3',
    Year: 2023,
    Sales: 240500,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q4',
    Year: 2023,
    Sales: 256335,
  },
  {
    Brand: 'KIA',
    Quarter: 'Q1',
    Year: 2024,
    Sales: 257026,
  },
];

const BRAND_LEVEL_SALES: BrandSalesData = {
  Tesla: [
    { year: 2017, sales: 24046 },
    { year: 2018, sales: 26063 },
    { year: 2019, sales: 101437 },
    { year: 2020, sales: 90005 },
    { year: 2021, sales: 161396 },
    { year: 2022, sales: 222136 },
    { year: 2023, sales: 339320 },
  ],
  Volkswagen: [
    { year: 2017, sales: 14716 },
    { year: 2018, sales: 21763 },
    { year: 2019, sales: 29664 },
    { year: 2020, sales: 108653 },
    { year: 2021, sales: 160770 },
    { year: 2022, sales: 156206 },
    { year: 2023, sales: 187160 },
  ],
  Renault: [
    { year: 2017, sales: 28444 },
    { year: 2018, sales: 35408 },
    { year: 2019, sales: 42736 },
    { year: 2020, sales: 94921 },
    { year: 2021, sales: 96762 },
    { year: 2022, sales: 96176 },
    { year: 2023, sales: 72152 },
  ],
  BMW: [
    { year: 2017, sales: 14953 },
    { year: 2018, sales: 17950 },
    { year: 2019, sales: 29071 },
    { year: 2020, sales: 21879 },
    { year: 2021, sales: 42328 },
    { year: 2022, sales: 85229 },
    { year: 2023, sales: 120037 },
  ],
  Hyundai: [
    { year: 2017, sales: 5295 },
    { year: 2018, sales: 11389 },
    { year: 2019, sales: 28822 },
    { year: 2020, sales: 53931 },
    { year: 2021, sales: 70500 },
    { year: 2022, sales: 81709 },
    { year: 2023, sales: 69217 },
  ],
  Audi: [
    { year: 2017, sales: 118 },
    { year: 2018, sales: 1160 },
    { year: 2019, sales: 16740 },
    { year: 2020, sales: 32978 },
    { year: 2021, sales: 53203 },
    { year: 2022, sales: 75980 },
    { year: 2023, sales: 95529 },
  ],
  Peugeot: [
    { year: 2017, sales: 1912 },
    { year: 2018, sales: 2501 },
    { year: 2019, sales: 1346 },
    { year: 2020, sales: 39382 },
    { year: 2021, sales: 69353 },
    { year: 2022, sales: 78900 },
    { year: 2023, sales: 69720 },
  ],
  Kia: [
    { year: 2017, sales: 5212 },
    { year: 2018, sales: 36048 },
    { year: 2019, sales: 62631 },
    { year: 2020, sales: 177796 },
    { year: 2021, sales: 380481 },
    { year: 2022, sales: 488147 },
    { year: 2023, sales: 543102 },
  ],
};

const BRAND_SALES_PER_YEAR: Record<string, Record<string, SalesRecord[]>> = {
  Tesla: {
    'Tesla Model S': [
      {
        year: 2019,
        month: 'Jan',
        sales: 225,
      },
      {
        year: 2019,
        month: 'Feb',
        sales: 526,
      },
      {
        year: 2019,
        month: 'Mar',
        sales: 1000,
      },
      {
        year: 2019,
        month: 'Apr',
        sales: 1242,
      },
      {
        year: 2019,
        month: 'May',
        sales: 1589,
      },
      {
        year: 2019,
        month: 'Jun',
        sales: 2684,
      },
      {
        year: 2019,
        month: 'Jul',
        sales: 3068,
      },
      {
        year: 2019,
        month: 'Aug',
        sales: 3487,
      },
      {
        year: 2019,
        month: 'Sep',
        sales: 4104,
      },
      {
        year: 2019,
        month: 'Oct',
        sales: 4230,
      },
      {
        year: 2019,
        month: 'Nov',
        sales: 4543,
      },
      {
        year: 2019,
        month: 'Dec',
        sales: 5404,
      },
      {
        year: 2020,
        month: 'Jan',
        sales: 243,
      },
      {
        year: 2020,
        month: 'Feb',
        sales: 361,
      },
      {
        year: 2020,
        month: 'Mar',
        sales: 933,
      },
      {
        year: 2020,
        month: 'Apr',
        sales: 1117,
      },
      {
        year: 2020,
        month: 'May',
        sales: 1251,
      },
      {
        year: 2020,
        month: 'Jun',
        sales: 1566,
      },
      {
        year: 2020,
        month: 'Jul',
        sales: 1615,
      },
      {
        year: 2020,
        month: 'Aug',
        sales: 1696,
      },
      {
        year: 2020,
        month: 'Sep',
        sales: 2550,
      },
      {
        year: 2020,
        month: 'Oct',
        sales: 2660,
      },
      {
        year: 2020,
        month: 'Nov',
        sales: 2871,
      },
      {
        year: 2020,
        month: 'Dec',
        sales: 3694,
      },
      {
        year: 2021,
        month: 'Jan',
        sales: 46,
      },
      {
        year: 2021,
        month: 'Feb',
        sales: 55,
      },
      {
        year: 2021,
        month: 'Mar',
        sales: 65,
      },
      {
        year: 2021,
        month: 'Apr',
        sales: 69,
      },
      {
        year: 2021,
        month: 'May',
        sales: 70,
      },
      {
        year: 2021,
        month: 'Jun',
        sales: 70,
      },
      {
        year: 2021,
        month: 'Jul',
        sales: 70,
      },
      {
        year: 2021,
        month: 'Aug',
        sales: 72,
      },
      {
        year: 2021,
        month: 'Sep',
        sales: 74,
      },
      {
        year: 2021,
        month: 'Oct',
        sales: 78,
      },
      {
        year: 2021,
        month: 'Nov',
        sales: 79,
      },
      {
        year: 2021,
        month: 'Dec',
        sales: 79,
      },
      {
        year: 2022,
        month: 'Jan',
        sales: 2,
      },
      {
        year: 2022,
        month: 'Feb',
        sales: 3,
      },
      {
        year: 2022,
        month: 'Mar',
        sales: 4,
      },
      {
        year: 2022,
        month: 'Apr',
        sales: 5,
      },
      {
        year: 2022,
        month: 'May',
        sales: 9,
      },
      {
        year: 2022,
        month: 'Jun',
        sales: 14,
      },
      {
        year: 2022,
        month: 'Jul',
        sales: 15,
      },
      {
        year: 2022,
        month: 'Aug',
        sales: 17,
      },
      {
        year: 2022,
        month: 'Sep',
        sales: 20,
      },
      {
        year: 2022,
        month: 'Oct',
        sales: 24,
      },
      {
        year: 2022,
        month: 'Nov',
        sales: 168,
      },
      {
        year: 2022,
        month: 'Dec',
        sales: 1672,
      },
      {
        year: 2023,
        month: 'Jan',
        sales: 339,
      },
      {
        year: 2023,
        month: 'Feb',
        sales: 474,
      },
      {
        year: 2023,
        month: 'Mar',
        sales: 1192,
      },
      {
        year: 2023,
        month: 'Apr',
        sales: 1477,
      },
      {
        year: 2023,
        month: 'May',
        sales: 2255,
      },
      {
        year: 2023,
        month: 'Jun',
        sales: 2912,
      },
      {
        year: 2023,
        month: 'Jul',
        sales: 3056,
      },
      {
        year: 2023,
        month: 'Aug',
        sales: 3188,
      },
      {
        year: 2023,
        month: 'Sep',
        sales: 3721,
      },
      {
        year: 2023,
        month: 'Oct',
        sales: 3857,
      },
      {
        year: 2023,
        month: 'Nov',
        sales: 4141,
      },
      {
        year: 2023,
        month: 'Dec',
        sales: 4426,
      },
    ],
    'Tesla Model 3': [
      {
        year: 2019,
        month: 'Jan',
        sales: 58,
      },
      {
        year: 2019,
        month: 'Feb',
        sales: 3197,
      },
      {
        year: 2019,
        month: 'Mar',
        sales: 16710,
      },
      {
        year: 2019,
        month: 'Apr',
        sales: 19739,
      },
      {
        year: 2019,
        month: 'May',
        sales: 21964,
      },
      {
        year: 2019,
        month: 'Jun',
        sales: 31385,
      },
      {
        year: 2019,
        month: 'Jul',
        sales: 33826,
      },
      {
        year: 2019,
        month: 'Aug',
        sales: 36567,
      },
      {
        year: 2019,
        month: 'Sep',
        sales: 48983,
      },
      {
        year: 2019,
        month: 'Oct',
        sales: 50001,
      },
      {
        year: 2019,
        month: 'Nov',
        sales: 55320,
      },
      {
        year: 2019,
        month: 'Dec',
        sales: 72852,
      },
      {
        year: 2020,
        month: 'Jan',
        sales: 943,
      },
      {
        year: 2020,
        month: 'Feb',
        sales: 3272,
      },
      {
        year: 2020,
        month: 'Mar',
        sales: 12212,
      },
      {
        year: 2020,
        month: 'Apr',
        sales: 13509,
      },
      {
        year: 2020,
        month: 'May',
        sales: 14558,
      },
      {
        year: 2020,
        month: 'Jun',
        sales: 18278,
      },
      {
        year: 2020,
        month: 'Jul',
        sales: 18707,
      },
      {
        year: 2020,
        month: 'Aug',
        sales: 23914,
      },
      {
        year: 2020,
        month: 'Sep',
        sales: 32899,
      },
      {
        year: 2020,
        month: 'Oct',
        sales: 33436,
      },
      {
        year: 2020,
        month: 'Nov',
        sales: 36695,
      },
      {
        year: 2020,
        month: 'Dec',
        sales: 53304,
      },
      {
        year: 2021,
        month: 'Jan',
        sales: 725,
      },
      {
        year: 2021,
        month: 'Feb',
        sales: 4860,
      },
      {
        year: 2021,
        month: 'Mar',
        sales: 18607,
      },
      {
        year: 2021,
        month: 'Apr',
        sales: 19459,
      },
      {
        year: 2021,
        month: 'May',
        sales: 25546,
      },
      {
        year: 2021,
        month: 'Jun',
        sales: 43058,
      },
      {
        year: 2021,
        month: 'Jul',
        sales: 43622,
      },
      {
        year: 2021,
        month: 'Aug',
        sales: 50370,
      },
      {
        year: 2021,
        month: 'Sep',
        sales: 65896,
      },
      {
        year: 2021,
        month: 'Oct',
        sales: 68434,
      },
      {
        year: 2021,
        month: 'Nov',
        sales: 75491,
      },
      {
        year: 2021,
        month: 'Dec',
        sales: 91588,
      },
      {
        year: 2022,
        month: 'Jan',
        sales: 407,
      },
      {
        year: 2022,
        month: 'Feb',
        sales: 7784,
      },
      {
        year: 2022,
        month: 'Mar',
        sales: 22805,
      },
      {
        year: 2022,
        month: 'Apr',
        sales: 23268,
      },
      {
        year: 2022,
        month: 'May',
        sales: 23569,
      },
      {
        year: 2022,
        month: 'Jun',
        sales: 27944,
      },
      {
        year: 2022,
        month: 'Jul',
        sales: 28137,
      },
      {
        year: 2022,
        month: 'Aug',
        sales: 30422,
      },
      {
        year: 2022,
        month: 'Sep',
        sales: 40108,
      },
      {
        year: 2022,
        month: 'Oct',
        sales: 42992,
      },
      {
        year: 2022,
        month: 'Nov',
        sales: 52926,
      },
      {
        year: 2022,
        month: 'Dec',
        sales: 66648,
      },
      {
        year: 2023,
        month: 'Jan',
        sales: 1052,
      },
      {
        year: 2023,
        month: 'Feb',
        sales: 4206,
      },
      {
        year: 2023,
        month: 'Mar',
        sales: 13987,
      },
      {
        year: 2023,
        month: 'Apr',
        sales: 16439,
      },
      {
        year: 2023,
        month: 'May',
        sales: 21612,
      },
      {
        year: 2023,
        month: 'Jun',
        sales: 34412,
      },
      {
        year: 2023,
        month: 'Jul',
        sales: 39466,
      },
      {
        year: 2023,
        month: 'Aug',
        sales: 49513,
      },
      {
        year: 2023,
        month: 'Sep',
        sales: 52818,
      },
      {
        year: 2023,
        month: 'Oct',
        sales: 58406,
      },
      {
        year: 2023,
        month: 'Nov',
        sales: 72892,
      },
      {
        year: 2023,
        month: 'Dec',
        sales: 84157,
      },
    ],
    'Tesla Model X': [
      {
        year: 2019,
        month: 'Jan',
        sales: 244,
      },
      {
        year: 2019,
        month: 'Feb',
        sales: 478,
      },
      {
        year: 2019,
        month: 'Mar',
        sales: 1044,
      },
      {
        year: 2019,
        month: 'Apr',
        sales: 1211,
      },
      {
        year: 2019,
        month: 'May',
        sales: 1686,
      },
      {
        year: 2019,
        month: 'Jun',
        sales: 2411,
      },
      {
        year: 2019,
        month: 'Jul',
        sales: 2690,
      },
      {
        year: 2019,
        month: 'Aug',
        sales: 3080,
      },
      {
        year: 2019,
        month: 'Sep',
        sales: 3827,
      },
      {
        year: 2019,
        month: 'Oct',
        sales: 3999,
      },
      {
        year: 2019,
        month: 'Nov',
        sales: 4311,
      },
      {
        year: 2019,
        month: 'Dec',
        sales: 5064,
      },
      {
        year: 2020,
        month: 'Jan',
        sales: 155,
      },
      {
        year: 2020,
        month: 'Feb',
        sales: 289,
      },
      {
        year: 2020,
        month: 'Mar',
        sales: 783,
      },
      {
        year: 2020,
        month: 'Apr',
        sales: 883,
      },
      {
        year: 2020,
        month: 'May',
        sales: 1018,
      },
      {
        year: 2020,
        month: 'Jun',
        sales: 1360,
      },
      {
        year: 2020,
        month: 'Jul',
        sales: 1442,
      },
      {
        year: 2020,
        month: 'Aug',
        sales: 1541,
      },
      {
        year: 2020,
        month: 'Sep',
        sales: 2385,
      },
      {
        year: 2020,
        month: 'Oct',
        sales: 2526,
      },
      {
        year: 2020,
        month: 'Nov',
        sales: 2736,
      },
      {
        year: 2020,
        month: 'Dec',
        sales: 3575,
      },
      {
        year: 2021,
        month: 'Jan',
        sales: 67,
      },
      {
        year: 2021,
        month: 'Feb',
        sales: 101,
      },
      {
        year: 2021,
        month: 'Mar',
        sales: 116,
      },
      {
        year: 2021,
        month: 'Apr',
        sales: 121,
      },
      {
        year: 2021,
        month: 'May',
        sales: 122,
      },
      {
        year: 2021,
        month: 'Jun',
        sales: 129,
      },
      {
        year: 2021,
        month: 'Jul',
        sales: 130,
      },
      {
        year: 2021,
        month: 'Aug',
        sales: 130,
      },
      {
        year: 2021,
        month: 'Sep',
        sales: 130,
      },
      {
        year: 2021,
        month: 'Oct',
        sales: 131,
      },
      {
        year: 2021,
        month: 'Nov',
        sales: 131,
      },
      {
        year: 2021,
        month: 'Dec',
        sales: 133,
      },
      {
        year: 2022,
        month: 'Jan',
        sales: 1,
      },
      {
        year: 2022,
        month: 'Feb',
        sales: 4,
      },
      {
        year: 2022,
        month: 'Mar',
        sales: 6,
      },
      {
        year: 2022,
        month: 'Apr',
        sales: 9,
      },
      {
        year: 2022,
        month: 'May',
        sales: 12,
      },
      {
        year: 2022,
        month: 'Jun',
        sales: 15,
      },
      {
        year: 2022,
        month: 'Jul',
        sales: 16,
      },
      {
        year: 2022,
        month: 'Aug',
        sales: 16,
      },
      {
        year: 2022,
        month: 'Sep',
        sales: 15,
      },
      {
        year: 2022,
        month: 'Oct',
        sales: 17,
      },
      {
        year: 2022,
        month: 'Nov',
        sales: 76,
      },
      {
        year: 2022,
        month: 'Dec',
        sales: 1216,
      },
      {
        year: 2023,
        month: 'Jan',
        sales: 201,
      },
      {
        year: 2023,
        month: 'Feb',
        sales: 251,
      },
      {
        year: 2023,
        month: 'Mar',
        sales: 638,
      },
      {
        year: 2023,
        month: 'Apr',
        sales: 985,
      },
      {
        year: 2023,
        month: 'May',
        sales: 1594,
      },
      {
        year: 2023,
        month: 'Jun',
        sales: 2202,
      },
      {
        year: 2023,
        month: 'Jul',
        sales: 2327,
      },
      {
        year: 2023,
        month: 'Aug',
        sales: 2473,
      },
      {
        year: 2023,
        month: 'Sep',
        sales: 2801,
      },
      {
        year: 2023,
        month: 'Oct',
        sales: 2901,
      },
      {
        year: 2023,
        month: 'Nov',
        sales: 3084,
      },
      {
        year: 2023,
        month: 'Dec',
        sales: 3322,
      },
    ],
    'Tesla Model Y': [
      {
        year: 2019,
        month: 'Jan',
        sales: 0,
      },
      {
        year: 2019,
        month: 'Feb',
        sales: 0,
      },
      {
        year: 2019,
        month: 'Mar',
        sales: 0,
      },
      {
        year: 2019,
        month: 'Apr',
        sales: 0,
      },
      {
        year: 2019,
        month: 'May',
        sales: 0,
      },
      {
        year: 2019,
        month: 'Jun',
        sales: 0,
      },
      {
        year: 2019,
        month: 'Jul',
        sales: 0,
      },
      {
        year: 2019,
        month: 'Aug',
        sales: 0,
      },
      {
        year: 2019,
        month: 'Sep',
        sales: 0,
      },
      {
        year: 2019,
        month: 'Oct',
        sales: 0,
      },
      {
        year: 2019,
        month: 'Nov',
        sales: 0,
      },
      {
        year: 2019,
        month: 'Dec',
        sales: 0,
      },
      {
        year: 2020,
        month: 'Jan',
        sales: 0,
      },
      {
        year: 2020,
        month: 'Feb',
        sales: 0,
      },
      {
        year: 2020,
        month: 'Mar',
        sales: 0,
      },
      {
        year: 2020,
        month: 'Apr',
        sales: 1,
      },
      {
        year: 2020,
        month: 'May',
        sales: 1,
      },
      {
        year: 2020,
        month: 'Jun',
        sales: 2,
      },
      {
        year: 2020,
        month: 'Jul',
        sales: 3,
      },
      {
        year: 2020,
        month: 'Aug',
        sales: 10,
      },
      {
        year: 2020,
        month: 'Sep',
        sales: 29,
      },
      {
        year: 2020,
        month: 'Oct',
        sales: 49,
      },
      {
        year: 2020,
        month: 'Nov',
        sales: 43,
      },
      {
        year: 2020,
        month: 'Dec',
        sales: 57,
      },
      {
        year: 2021,
        month: 'Jan',
        sales: 108,
      },
      {
        year: 2021,
        month: 'Feb',
        sales: 121,
      },
      {
        year: 2021,
        month: 'Mar',
        sales: 216,
      },
      {
        year: 2021,
        month: 'Apr',
        sales: 473,
      },
      {
        year: 2021,
        month: 'May',
        sales: 1076,
      },
      {
        year: 2021,
        month: 'Jun',
        sales: 1758,
      },
      {
        year: 2021,
        month: 'Jul',
        sales: 2325,
      },
      {
        year: 2021,
        month: 'Aug',
        sales: 3010,
      },
      {
        year: 2021,
        month: 'Sep',
        sales: 10968,
      },
      {
        year: 2021,
        month: 'Oct',
        sales: 11152,
      },
      {
        year: 2021,
        month: 'Nov',
        sales: 15496,
      },
      {
        year: 2021,
        month: 'Dec',
        sales: 22518,
      },
      {
        year: 2022,
        month: 'Jan',
        sales: 208,
      },
      {
        year: 2022,
        month: 'Feb',
        sales: 4825,
      },
      {
        year: 2022,
        month: 'Mar',
        sales: 15694,
      },
      {
        year: 2022,
        month: 'Apr',
        sales: 16248,
      },
      {
        year: 2022,
        month: 'May',
        sales: 17227,
      },
      {
        year: 2022,
        month: 'Jun',
        sales: 28248,
      },
      {
        year: 2022,
        month: 'Jul',
        sales: 29804,
      },
      {
        year: 2022,
        month: 'Aug',
        sales: 35926,
      },
      {
        year: 2022,
        month: 'Sep',
        sales: 54077,
      },
      {
        year: 2022,
        month: 'Oct',
        sales: 56455,
      },
      {
        year: 2022,
        month: 'Nov',
        sales: 69978,
      },
      {
        year: 2022,
        month: 'Dec',
        sales: 89910,
      },
      {
        year: 2023,
        month: 'Jan',
        sales: 6125,
      },
      {
        year: 2023,
        month: 'Feb',
        sales: 21158,
      },
      {
        year: 2023,
        month: 'Mar',
        sales: 56373,
      },
      {
        year: 2023,
        month: 'Apr',
        sales: 65348,
      },
      {
        year: 2023,
        month: 'May',
        sales: 84499,
      },
      {
        year: 2023,
        month: 'Jun',
        sales: 114572,
      },
      {
        year: 2023,
        month: 'Jul',
        sales: 124120,
      },
      {
        year: 2023,
        month: 'Aug',
        sales: 143472,
      },
      {
        year: 2023,
        month: 'Sep',
        sales: 170774,
      },
      {
        year: 2023,
        month: 'Oct',
        sales: 179665,
      },
      {
        year: 2023,
        month: 'Nov',
        sales: 194817,
      },
      {
        year: 2023,
        month: 'Dec',
        sales: 216617,
      },
    ],
  },
};

const EV_MODELS_PER_BRAND: Record<string, string[]> = {
  Volkswagen: ['ID 3', 'ID 4', 'ID Buzz', 'ID 7'],
  Kia: ['EV6', 'EV7'],
  BMW: ['i3', 'i4', 'i5', 'i6'],
  Hyundai: ['Ioniq 5', 'Ioniq 6', 'Ioniq 7'],
  Audi: ['e-tron', 'e-tron GT', 'Q4 e-tron'],
  Peugeot: ['e-208', 'e-2008'],
  Renault: ['Zoe', 'Megane eVision'],
};

export {
  EV_SALES_COMBINED_LINE_CHART_DATA,
  BRAND_LEVEL_SALES,
  BRAND_SALES_PER_YEAR,
  EV_MODELS_PER_BRAND,
};
