import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box } from '@mui/material';

import { BRAND_SALES_PER_YEAR, EV_MODELS_PER_BRAND } from 'src/constants/marketInsights';

// Define the structure of the data for a single sales record.
interface SalesRecord {
  year: number;
  month: string;
  sales: number;
}

// Define the structure for the processed data for a quarter.
interface QuarterlySales {
  quarter: string;
  sales: number;
}

const ModelSalesChart: React.FC<{ brand: string }> = ({ brand }) => {
  const data = BRAND_SALES_PER_YEAR[brand];

  // Helper function to map month to quarter.
  const monthToQuarter = (month: string) => {
    const map: Record<string, string> = {
      Jan: 'Q1',
      Feb: 'Q1',
      Mar: 'Q1',
      Apr: 'Q2',
      May: 'Q2',
      Jun: 'Q2',
      Jul: 'Q3',
      Aug: 'Q3',
      Sep: 'Q3',
      Oct: 'Q4',
      Nov: 'Q4',
      Dec: 'Q4',
    };
    return map[month];
  };

  const generateRandomQuarterlySales = (years: number[], quarters: string[]): QuarterlySales[] => {
    const randomQuarterlySalesData: QuarterlySales[] = [];
    years.forEach((year, index) => {
      quarters.forEach((quarter, quarterIndex) => {
        randomQuarterlySalesData.push({
          quarter: `${year} ${quarter}`,
          sales: Math.floor(Math.random() * (8 - index) ** (index + 1) * 2 ** (quarterIndex + 1)),
        });
      });
    });
    return randomQuarterlySalesData;
  };

  const processQuarterlySales = (salesData: SalesRecord[]): QuarterlySales[] => {
    const quarterlySales: Record<string, number> = {};

    salesData.forEach(({ year, month, sales }) => {
      const quarter = `${year} ${monthToQuarter(month)}`;
      if (!quarterlySales[quarter]) {
        quarterlySales[quarter] = 0;
      }
      quarterlySales[quarter] += sales;
    });

    return Object.entries(quarterlySales).map(([quarter, sales]) => ({
      quarter,
      sales,
    }));
  };

  const allModelsQuarterlySales: Record<string, QuarterlySales[]> = {};
  if (data) {
    Object.keys(data).forEach(model => {
      allModelsQuarterlySales[model] = processQuarterlySales(data[model]);
    });
  } else {
    const models = EV_MODELS_PER_BRAND[brand];
    models.forEach(model => {
      allModelsQuarterlySales[model] = generateRandomQuarterlySales(
        [2019, 2020, 2021, 2022, 2023],
        ['Q1', 'Q2', 'Q3', 'Q4']
      );
    });
  }

  const series = Object.keys(allModelsQuarterlySales).map(model => {
    return {
      name: model,
      data: allModelsQuarterlySales[model].map(qs => qs.sales),
    };
  });

  const categories = Array.from(
    new Set(
      Object.values(allModelsQuarterlySales)
        .flat()
        .map(qs => qs.quarter)
    )
  ).sort();

  const options: ApexCharts.ApexOptions = {
    chart: {
      height: 400,
      type: 'line',
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    series: series,
    tooltip: {
      enabled: true,
      shared: false,
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      title: {
        text: `${brand} Sales`,
      },
    },
  };

  return (
    <Box id="brand-sales-per-model">
      <ReactApexChart options={options} series={series} type="line" height={400} />
    </Box>
  );
};

export default ModelSalesChart;
