import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_EARLY_USERS_FEEDBACK } from 'src/graphql/queries/feedback';

interface FeedbackContextType {
  clickCount: number;
  setClickCount: React.Dispatch<React.SetStateAction<number>>;
  setClicksNecessary: React.Dispatch<React.SetStateAction<number>>;
  enableFeedbackToolbar: boolean;
  setEnableFeedbackToolbar: React.Dispatch<React.SetStateAction<boolean>>;
  showFeedbackModal: boolean;
  setShowFeedbackModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFeedbackProvided: React.Dispatch<React.SetStateAction<boolean>>;
}

const FeedbackContext = createContext<FeedbackContextType>({
  clickCount: 0,
  setClickCount: () => {},
  setClicksNecessary: () => {},
  enableFeedbackToolbar: false,
  setEnableFeedbackToolbar: () => {},
  showFeedbackModal: false,
  setShowFeedbackModal: () => {},
  setIsFeedbackProvided: () => {},
});

export const useFeedbackContext = () => {
  const context = useContext(FeedbackContext);
  if (!context) {
    throw new Error('useFeedbackContext must be used within a FeedbackProvider');
  }
  return context;
};

interface FeedbackProviderProps {
  children: ReactNode;
}

export const ClickProvider: React.FC<FeedbackProviderProps> = ({ children }) => {
  const [clickCount, setClickCount] = useState(0);
  const [clicksNecessary, setClicksNecessary] = useState(20);
  const [isFeedbackProvided, setIsFeedbackProvided] = useState(false);
  const [enableFeedbackToolbar, setEnableFeedbackToolbar] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const { data } = useQuery(GET_EARLY_USERS_FEEDBACK);

  useEffect(() => {
    if (
      (!data || !data?.getEarlyUsersFeedback) &&
      !isFeedbackProvided &&
      clickCount >= clicksNecessary
    ) {
      setEnableFeedbackToolbar(true);
      setIsFeedbackProvided(false);
    } else if (data?.getEarlyUsersFeedback) {
      setIsFeedbackProvided(true);
      setEnableFeedbackToolbar(false);
    }
  }, [data, clickCount, clicksNecessary, isFeedbackProvided]);

  useEffect(() => {
    const incrementCount = () => {
      setClickCount(prevCount => prevCount + 1);
    };
    document.addEventListener('click', incrementCount);
    return () => {
      document.removeEventListener('click', incrementCount);
    };
  }, []);

  return (
    <FeedbackContext.Provider
      value={{
        clickCount,
        setClickCount,
        setClicksNecessary,
        enableFeedbackToolbar,
        setEnableFeedbackToolbar,
        showFeedbackModal,
        setShowFeedbackModal,
        setIsFeedbackProvided,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
};
