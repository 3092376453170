import React from 'react';
import { Box } from '@mui/material';
import ERDiagram from 'src/assets/img/ER Diagram.png';

const ERDDiagram: React.FC = () => {
  return (
    <Box
      component="img"
      src={ERDiagram}
      alt="ERD Diagram"
      sx={{ maxWidth: '100%', maxHeight: '100%' }}
    />
  );
};

export default ERDDiagram;
