import React from 'react';
import { Box, Grid, Typography, Card, CardContent, useTheme } from '@mui/material';
import MemoryIcon from '@mui/icons-material/Memory';
import ScienceIcon from '@mui/icons-material/Science';
import CELL_DETAILS from 'src/constants/CellDetails';
import { useLocation } from 'react-router-dom';

import { TEXT_CONTENTS } from 'src/constants';

const CellDetails: React.FC = (): JSX.Element => {
  const theme = useTheme();
  const location = useLocation();

  if (!location.state || !location.state.name) {
    return <Typography variant="body1">{TEXT_CONTENTS.CELL_DETAILS.CELL_NOT_FOUND}</Typography>;
  }

  const cellName = location.state.name;
  const cellDetails = CELL_DETAILS.filter(({ name }) => name === cellName)[0];

  return (
    <Grid container>
      <Grid item xs={12} mt={theme.spacing(2)}>
        <Typography variant="h3">{cellName}</Typography>
        <br />
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <MemoryIcon sx={{ color: theme.palette.secondary.light }} />
          <Typography variant="body2" sx={{ marginRight: theme.spacing(15) }}>
            {cellDetails.company}
          </Typography>
          <ScienceIcon sx={{ color: theme.palette.secondary.light }} />
          <Typography variant="body2">{cellDetails.composition}</Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={9}
        order={{ xs: 2, sm: 2, md: 2, lg: 1 }}
        mt={{
          xs: theme.spacing(1),
          sm: theme.spacing(1),
          md: theme.spacing(1),
          lg: theme.spacing(4),
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            mt={{
              xs: theme.spacing(1),
              sm: theme.spacing(1),
              md: theme.spacing(1),
              lg: theme.spacing(4),
            }}
          >
            <Card
              elevation={1}
              sx={{ borderRadius: 3, display: 'flex', flexGrow: 1, height: '100%' }}
            >
              <CardContent sx={{ width: '100%' }}>
                <Typography
                  variant="body1"
                  sx={{ fontSize: '24px', fontWeight: 'light', marginBottom: theme.spacing(2) }}
                >
                  {TEXT_CONTENTS.CELL_DETAILS.OVERVIEW}
                </Typography>
                {cellDetails.data.overview.map(({ name, value }, index) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: theme.spacing(2),
                      width: '100%',
                    }}
                    key={index}
                  >
                    <Typography variant="body2" sx={{ fontWeight: 'light' }}>
                      {name}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: 'light' }}>
                      {value}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} mt={theme.spacing(4)}>
            <Card
              elevation={1}
              sx={{
                borderRadius: 3,
                ml: { lg: theme.spacing(2) },
                display: 'flex',
                flexGrow: 1,
                height: '100%',
                width: '100%',
              }}
            >
              <CardContent sx={{ width: '100%' }}>
                <Typography
                  variant="body1"
                  sx={{ fontSize: '24px', fontWeight: 'light', marginBottom: theme.spacing(2) }}
                >
                  {TEXT_CONTENTS.CELL_DETAILS.ELECTRICAL}
                </Typography>
                {cellDetails.data.electrical.map(({ name, value }, index) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: theme.spacing(2),
                      width: '100%',
                    }}
                    key={index}
                  >
                    <Typography variant="body2" sx={{ fontWeight: 'light' }}>
                      {name}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: 'light' }}>
                      {value}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} mt={theme.spacing(4)}>
            <Card
              elevation={1}
              sx={{
                borderRadius: 3,
                display: 'flex',
                flexGrow: 1,
                height: '100%',
                width: '100%',
              }}
            >
              <CardContent sx={{ width: '100%' }}>
                <Typography
                  variant="body1"
                  sx={{ fontSize: '24px', fontWeight: 'light', marginBottom: theme.spacing(2) }}
                >
                  {TEXT_CONTENTS.CELL_DETAILS.GEOMETRICAL_AND_MECHANICAL}
                </Typography>
                {cellDetails.data.geometry.map(({ name, value }, index) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: theme.spacing(2),
                      width: '100%',
                    }}
                    key={index}
                  >
                    <Typography variant="body2" sx={{ fontWeight: 'light' }}>
                      {name}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: 'light' }}>
                      {value}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} mt={theme.spacing(4)}>
            <Card
              elevation={1}
              sx={{
                borderRadius: 3,
                ml: { lg: theme.spacing(2) },
                display: 'flex',
                height: '100%',
                width: '100%',
              }}
            >
              <CardContent sx={{ width: '100%' }}>
                <Typography
                  variant="body1"
                  sx={{ fontSize: '24px', fontWeight: 'light', marginBottom: theme.spacing(2) }}
                >
                  {TEXT_CONTENTS.CELL_DETAILS.CHEMISTRY}
                </Typography>
                {cellDetails.data.chemistry.map(({ name, value }, index) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: theme.spacing(2),
                      width: '100%',
                    }}
                    key={index}
                  >
                    <Typography variant="body2" sx={{ fontWeight: 'light' }}>
                      {name}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: 'light' }}>
                      {value}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        lg={3}
        order={{ xs: 1, sm: 1, md: 1, lg: 2 }}
        mt={{
          xs: theme.spacing(1),
          sm: theme.spacing(1),
          md: theme.spacing(1),
          lg: theme.spacing(4),
        }}
      >
        <Card
          elevation={1}
          sx={{
            borderRadius: 3,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: { xs: 0, sm: 0, md: 0, lg: 1 },
            height: { xs: '95%', sm: '100%', md: '100%', lg: '95%' },
            ml: { lg: theme.spacing(4) },
            mt: {
              xs: theme.spacing(1),
              sm: theme.spacing(1),
              md: theme.spacing(1),
              lg: theme.spacing(4),
            },
          }}
        >
          <CardContent
            sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Box
              component="img"
              sx={{
                width: cellDetails.name === 'Sanyo NCR18650G-H09NA' ? '100%' : '40%',
                height: { xs: '100%', sm: '100%', md: '100%', lg: '65%' },
              }}
              alt={'cell'}
              src={cellDetails.image}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CellDetails;
