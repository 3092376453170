import { TEXT_CONTENTS } from 'src/constants/textContents';
import { NavItem } from 'src/@types/Navigation';

const NAV_MENU_ITEMS: NavItem[] = [
  { NAME: 'Operations', SUB_MENU: TEXT_CONTENTS.NAVIGATION.OPERATIONS_SUBMENU },
  { NAME: 'Analytics', SUB_MENU: TEXT_CONTENTS.NAVIGATION.ANALYTICS_SUBMENU },
  { NAME: 'Intelligence', SUB_MENU: TEXT_CONTENTS.NAVIGATION.INTELLIGENCE_SUBMENU },
  { NAME: 'Master Data', SUB_MENU: TEXT_CONTENTS.NAVIGATION.MASTER_DATA_SUBMENU },
  { NAME: 'Battery Library', SUB_MENU: TEXT_CONTENTS.NAVIGATION.BATTERY_LIBRARY_SUBMENU },
  ...TEXT_CONTENTS.NAVIGATION.LINKS,
];

export { NAV_MENU_ITEMS };
