import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Input,
  InputAdornment,
  FormControlLabel,
  Typography,
  Checkbox,
  useTheme,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { GridColDef, GridFilterModel } from '@mui/x-data-grid';
import { useLocation } from 'react-router-dom';

import MasterDataDrawerContent from 'src/components/MasterData/MasterDataDrawerContent';
import { BatteryDatabase, BatteryPackModelType } from 'src/@types/BatteryDatabase';
import { camelCaseToTitleCase } from 'src/utils/Text';
import { useQuery } from '@apollo/client';
import { GET_BATTERY_DATABASE } from 'src/graphql/queries';
import StyledDataGrid, { renderTableCell } from 'src/components/Structure/SyledDataGrid';
import {
  EXTERNAL_DATA_SOURCE,
  INTERNAL_DATA_SOURCE,
  MASTER_DATA_TABLE_HEADER_MAPPING,
  PRIMARY_COLUMNS,
} from 'src/constants/masterData';
import { TEXT_CONTENTS } from 'src/constants';
import { useDrawer } from 'src/components/Contexts/DrawerContext';

const TableView: React.FC<{ batteryPackType: BatteryPackModelType }> = ({ batteryPackType }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const qsFilter = query.get('filter');
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<BatteryDatabase[]>([]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [filter, setFilter] = useState<string>('');
  const [includeExternal, setIncludeExternal] = useState<boolean>(false);
  const { toggleDrawer, setDrawerHeader, setDrawerContent } = useDrawer();

  const openDrawer = useCallback(
    (open: boolean, row?: BatteryDatabase) => () => {
      if (setDrawerHeader) {
        setDrawerHeader(row?.batteryPackModelName ?? 'Battery Database');
      }
      if (setDrawerContent) {
        setDrawerContent(
          <MasterDataDrawerContent
            batteryPackType={batteryPackType}
            selectedRow={row ?? null}
            getHeaderName={getHeaderName}
          />
        );
      }
      toggleDrawer(open);
    },
    [batteryPackType, setDrawerContent, setDrawerHeader, toggleDrawer]
  );

  const batteryPackModelTypeFilter = {
    filter: {
      batteryPackModelType: batteryPackType,
    },
  };
  const { loading, error, data } = useQuery<{ getBatteryDatabase: BatteryDatabase[] }>(
    GET_BATTERY_DATABASE,
    { variables: batteryPackModelTypeFilter }
  );

  const theme = useTheme();

  const getHeaderName = (key: string) => {
    return (
      MASTER_DATA_TABLE_HEADER_MAPPING[key as keyof typeof MASTER_DATA_TABLE_HEADER_MAPPING] ||
      camelCaseToTitleCase(key)
    );
  };

  const filterColumnsWithData = useCallback(
    (data: BatteryDatabase[], allColumns: GridColDef[]): GridColDef[] => {
      return allColumns.filter(column =>
        data.some(row => {
          const value = row[column.field as keyof BatteryDatabase];
          return value !== null && value !== '';
        })
      );
    },
    []
  );

  const defineColumns = useCallback(
    (dataSample: BatteryDatabase): GridColDef[] => {
      if (!dataSample) return [];

      const tableKeys = Object.keys(dataSample).filter(key => PRIMARY_COLUMNS.includes(key));
      const filteredTableKeys = tableKeys.map(key => ({
        field: key,
        headerName: getHeaderName(key),
        flex: 1,
        minWidth: 300,
        renderCell: renderTableCell,
      }));

      filteredTableKeys.push({
        field: 'actions',
        headerName: 'Actions',
        minWidth: 150,
        flex: 1,
        renderCell: params => (
          <Button onClick={openDrawer(true, params.row)}>View More Details</Button>
        ),
      });
      return filteredTableKeys;
    },
    [openDrawer]
  );

  const filterDataBasedOnFreeText = useCallback(
    (value: string) => {
      if (!data || !data.getBatteryDatabase) return;

      value = value.toLowerCase();

      const filteredRows = data.getBatteryDatabase
        .filter(row =>
          Object.values(row).some(fieldValue => {
            if (fieldValue !== null && fieldValue !== undefined) {
              return fieldValue.toString().toLowerCase().includes(value);
            }
            return false;
          })
        )
        .map((item, index) => ({ ...item, id: index + 1 }));

      setRows(filteredRows);
    },
    [data]
  );

  useEffect(() => {
    if (data && data.getBatteryDatabase) {
      const initialRows = data.getBatteryDatabase
        .filter(item => {
          const dataSource = item.dataSource?.toUpperCase() ?? '';
          return (
            (includeExternal && dataSource === EXTERNAL_DATA_SOURCE) ||
            dataSource === INTERNAL_DATA_SOURCE
          );
        })
        .map((item, index) => {
          return {
            ...item,
            id: index + 1,
          };
        });
      setColumns(defineColumns(data.getBatteryDatabase[0]));
      setRows(initialRows);
    }
  }, [data, defineColumns, filterColumnsWithData, includeExternal]);

  useEffect(() => {
    if (qsFilter) {
      setFilter(qsFilter);
      filterDataBasedOnFreeText(qsFilter);
    }
  }, [qsFilter, filterDataBasedOnFreeText]);

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFilter(value);
    filterDataBasedOnFreeText(value);
  };

  const handleClearFilter = () => {
    setFilterModel({ items: [] });
    setFilter('');
    filterDataBasedOnFreeText('');
  };

  const handleToggleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIncludeExternal(event.target.checked);
  };

  if (loading) {
    return <Typography variant="body1">{TEXT_CONTENTS.COMMON.LOADING}</Typography>;
  }

  if (error) {
    return <Typography variant="body1">{TEXT_CONTENTS.COMMON.ERROR}</Typography>;
  }

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ mt: theme.spacing(1), mb: theme.spacing(1) }}
      >
        <FormControlLabel
          control={<Checkbox checked={includeExternal} onChange={handleToggleChange} />}
          label={'Include External Data Sources'}
        />
        <Input
          type="text"
          value={filter}
          onChange={handleFilterChange}
          placeholder="Filter..."
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          endAdornment={
            filter && (
              <InputAdornment position={'end'}>
                <IconButton onClick={handleClearFilter}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            )
          }
          sx={{
            width: { xs: '100%', sm: '100%', md: '100%', lg: '50%' },
            maxWidth: '500px',
            padding: '15px 20px',
            borderRadius: '4px',
            marginLeft: theme.spacing(2),
            border: '1px solid #ccc',
            '&:hover': {
              borderColor: 'black',
            },
            '&.Mui-focused': {
              borderColor: '#3f51b5',
              boxShadow: '0 0 0 2px rgba(63, 81, 181, 0.2)',
            },
          }}
        />
      </Box>
      <StyledDataGrid
        rows={rows}
        columns={columns}
        filterModel={filterModel}
        onFilterModelChange={model => {
          setFilterModel(model);
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default TableView;
