import React, { createContext, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';

import { TEXT_CONTENTS } from 'src/constants';

interface HeadContextInterface {
  setHelmetTitle: (title: string) => void;
  setHelmetDescription: (description: string) => void;
}

const HeadContext = createContext<HeadContextInterface>({
  setHelmetTitle: () => null,
  setHelmetDescription: () => null,
});
const HeadProvider = ({ children }: { children: React.ReactNode }) => {
  const [title, setTitle] = useState<string>('');
  const [metaDescription, setMetaDescription] = useState<string>('');

  const setHelmetTitle = useCallback(
    (title: string) => {
      setTitle(title);
    },
    [setTitle]
  );

  const setHelmetDescription = useCallback(
    (description: string) => {
      setMetaDescription(description);
    },
    [setMetaDescription]
  );

  return (
    <HeadContext.Provider
      value={{
        setHelmetTitle,
        setHelmetDescription,
      }}
    >
      <Helmet>
        <title>
          {title ? `${title} - ` : ''}
          {TEXT_CONTENTS.COMMON.CIRCU_LI_ION_TEXT}
        </title>
        <meta name="description" content={metaDescription ?? ''} />
      </Helmet>
      {children}
    </HeadContext.Provider>
  );
};

export { HeadProvider };

export default HeadContext;
