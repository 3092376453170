import React, { useState, useEffect, createContext, ReactNode } from 'react';
import { Button, DialogActions, Snackbar, useTheme } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface Props {
  children: ReactNode;
}

interface MessageContextProps {
  setErrorMessage: (errorMessage: string) => void;
  setSuccessMessage: (successMessage: string) => void;
  setDialog: (dialog: { title: string; message: string; type: 'error' | 'success' }) => void;
}

const MessageContext = createContext<MessageContextProps>({
  setErrorMessage: () => {
    console.error('unimplemented context messageContext');
  },
  setSuccessMessage: () => {
    console.error('unimplemented context messageContext');
  },
  setDialog: () => {
    console.error('unimplemented context messageContext');
  },
});

const MessageProvider: React.FC<Props> = ({ children }) => {
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  // const [openDialog, setOpenDialog] = useState(false);
  const [dialog, setDialog] = useState<{
    title: string;
    message: string;
    type: 'error' | 'success';
  } | null>(null);

  const resetMessage = () => {
    setErrorMessage(null);
    setSuccessMessage(null);
    setDialog(null);
  };

  const message: string | null = errorMessage || successMessage;

  useEffect(() => setOpen(!!message), [message]);

  const onClose = () => {
    setOpen(false);
    setTimeout(() => resetMessage(), 500);
  };

  const handleCloseDialog = () => {
    setDialog(null);
  };

  useEffect(() => {
    if (dialog?.title && dialog?.message) {
      setTimeout(() => {
        resetMessage();
      }, 6 * 1000);
    }
  }, [dialog?.title, dialog?.message]);

  return (
    <MessageContext.Provider
      value={{
        setErrorMessage,
        setSuccessMessage,
        setDialog,
      }}
    >
      <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
        <Alert
          onClose={onClose}
          severity={successMessage ? 'success' : 'error'}
          data-cy={`alert-${successMessage ? 'success' : 'error'}`}
        >
          {message}
        </Alert>
      </Snackbar>
      <Dialog
        open={Boolean(dialog)}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          color={dialog?.type === 'error' ? theme.palette.error.dark : theme.palette.primary.dark}
        >
          {dialog?.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialog?.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: theme.palette.primary.dark }} onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {children}
    </MessageContext.Provider>
  );
};

export { MessageProvider };

export default MessageContext;
