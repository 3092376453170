import React, { createContext, ReactNode, useState } from 'react';
import { StakeholderMapping } from 'src/@types/DataTypes';

interface StakeholderMappingContext {
  stakeholderMapping: StakeholderMapping[];
  setStakeholderMapping: (stakeholderMapping: StakeholderMapping[]) => void;
}

const defaultStakeholderMapping: StakeholderMappingContext = {
  stakeholderMapping: [
    {
      country: '',
      company: '',
      category: '',
    },
  ],
  setStakeholderMapping: () => console.error('Unimplemented function'),
};

const StakeholderContext = createContext<StakeholderMappingContext>(defaultStakeholderMapping);

interface UserSettingsProviderProps {
  children: ReactNode;
}

const StakeholderMappingProvider = ({ children }: UserSettingsProviderProps) => {
  const [stakeholderMapping, setStakeholderMapping] = useState<StakeholderMapping[]>(
    defaultStakeholderMapping.stakeholderMapping
  );

  return (
    <StakeholderContext.Provider value={{ stakeholderMapping, setStakeholderMapping }}>
      {children}
    </StakeholderContext.Provider>
  );
};

export { StakeholderMappingProvider };

export default StakeholderContext;
