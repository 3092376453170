import React, { createContext, useContext, useState, ReactNode } from 'react';

interface InputsForDesignCheck {
  batteryPack?: string;
  category?: string;
  batteryDamage?: string;
  cellSealing?: string;
  stateOfHealth?: string;
}

interface DesignCheckContextProps {
  selectedBatteryPack: string;
  setSelectedBatteryPack: React.Dispatch<React.SetStateAction<string>>;
  input: InputsForDesignCheck;
  setInput: React.Dispatch<React.SetStateAction<InputsForDesignCheck>>;
}

const DesignCheckContext = createContext<DesignCheckContextProps | undefined>(undefined);

export const useDesignCheck = () => {
  const context = useContext(DesignCheckContext);
  if (!context) {
    throw new Error('useDesignCheck must be used within a DesignCheckProvider');
  }
  return context;
};

export const DesignCheckProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedBatteryPack, setSelectedBatteryPack] = useState('');
  const [input, setInput] = useState<InputsForDesignCheck>({
    batteryPack: 'Battery Pack 1',
    category: 'Power-tool',
    batteryDamage: 'Not damaged',
    cellSealing: 'Sealing in casing',
    stateOfHealth: 'above 95%',
  });

  return (
    <DesignCheckContext.Provider
      value={{
        selectedBatteryPack,
        setSelectedBatteryPack,
        input,
        setInput,
      }}
    >
      {children}
    </DesignCheckContext.Provider>
  );
};
