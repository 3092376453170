import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { ToggleButton, ToggleButtonGroup, Grid, Typography, Stack } from '@mui/material';
import { useQuery } from '@apollo/client';
import TableViewIcon from '@mui/icons-material/TableView';
import ViewModuleIcon from '@mui/icons-material/ViewModule';

import { BatteryDatabase, BatteryPackModelType, View } from 'src/@types/BatteryDatabase';
import { GET_BATTERY_DATABASE } from 'src/graphql/queries';
import TableView from 'src/components/MasterData/TableView';
import { PageContainer, Title } from 'src/components/Structure';
import { TEXT_CONTENTS } from 'src/constants';
import KpiCard from 'src/components/Structure/KpiCard';
import ExportMasterDataButton from 'src/components/MasterData/ExportMasterData';
import CardView from 'src/components/MasterData/CardView';

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: theme.palette.primary.dark,
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
}));

const BatteryLibraryTableWrapper: React.FC<{ batteryPackType: BatteryPackModelType }> = ({
  batteryPackType,
}) => {
  const batteryPackModelTypeFilter = {
    filter: {
      batteryPackModelType: batteryPackType,
    },
  };
  const { loading, error, data } = useQuery<{
    getBatteryDatabase: BatteryDatabase[];
    getInternalDataCount: number;
    getInternalDataFilledPercentage: number;
  }>(GET_BATTERY_DATABASE, { variables: batteryPackModelTypeFilter });
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const filter = query.get('filter');
  const [evData, setEvData] = useState<BatteryDatabase[] | null>(null);
  const [collectedBatteryPacksCount, setCollectedBatteryPacksCount] = useState<number>(0);
  const [availableFilledFieldsPercentage, setAvailableFilledFieldsPercentage] = useState<number>(0);
  const [view, setView] = useState(View.Table);

  const handleViewChange = (event: React.MouseEvent<HTMLElement>, nextView: View) => {
    if (nextView === View.Cards) {
      query.delete('filter');
      navigate(`?${query.toString()}`);
    }
    if (nextView) {
      setView(nextView);
    }
  };

  useEffect(() => {
    if (data?.getBatteryDatabase) {
      setEvData(data?.getBatteryDatabase as BatteryDatabase[]);
    }
    if (data?.getInternalDataCount !== undefined) {
      setCollectedBatteryPacksCount(data?.getInternalDataCount);
    }
    if (data?.getInternalDataFilledPercentage !== undefined) {
      setAvailableFilledFieldsPercentage(data?.getInternalDataFilledPercentage);
    }
  }, [data?.getInternalDataFilledPercentage, data?.getInternalDataCount, data?.getBatteryDatabase]);

  useEffect(() => {
    if (filter) {
      setView(View.Table);
    }
  }, [filter]);

  if (loading) return <Typography variant="body1">{TEXT_CONTENTS.COMMON.LOADING}</Typography>;

  if (error) return <Typography variant="body1">{TEXT_CONTENTS.COMMON.ERROR}</Typography>;

  if (evData === null) {
    return <Typography variant="body1">{TEXT_CONTENTS.COMMON.LOADING}</Typography>;
  }

  return (
    <PageContainer>
      <Title variant="h1">{`${batteryPackType} ${TEXT_CONTENTS.MASTER_DATA.TITLE}`}</Title>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <KpiCard
            text={TEXT_CONTENTS.MASTER_DATA.INTERNAL_DATA_POINTS}
            value={collectedBatteryPacksCount.toString()}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <KpiCard
            text={TEXT_CONTENTS.MASTER_DATA.INTERNAL_FILLED_PERCENTAGE}
            value={`${availableFilledFieldsPercentage} %`}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" color="black">
            {TEXT_CONTENTS.MASTER_DATA.KPIS_DESCRIPTION}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction="row"
            spacing={2}
            mb={2}
            sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
          >
            <ExportMasterDataButton
              batteryMasterData={data?.getBatteryDatabase as BatteryDatabase[]}
              batteryPackType={batteryPackType}
            />
            <ToggleButtonGroup
              value={view}
              exclusive
              onChange={handleViewChange}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              <StyledToggleButton value={View.Table} aria-label="table view">
                <TableViewIcon />
              </StyledToggleButton>
              <StyledToggleButton value={View.Cards} aria-label="card view">
                <ViewModuleIcon />
              </StyledToggleButton>
            </ToggleButtonGroup>
          </Stack>
          {(view === View.Table || filter) && <TableView batteryPackType={batteryPackType} />}
          {view === View.Cards && !filter && <CardView batteryPackModelType={batteryPackType} />}
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default BatteryLibraryTableWrapper;
