import React, { useMemo } from 'react';
import { Paper, Grid, Button, Typography, Box, useTheme, Switch } from '@mui/material';
import QRCode from 'qrcode.react';
import { ProcessFlowSimulation } from 'src/@types/DataTypes';
import { TEXT_CONTENTS } from 'src/constants';
import DischaringPlot from 'src/components/Clients/DischargingPlot';

interface Props {
  onClickStart: () => void;
  onClickStop: () => void;
  batteryPackSimulationData: ProcessFlowSimulation;
  moduleSimulationData: ProcessFlowSimulation;
  isBpSimulation: boolean;
  setIsBpSimulation: React.Dispatch<React.SetStateAction<boolean>>;
}

const IpadInterface: React.FC<Props> = ({
  batteryPackSimulationData,
  moduleSimulationData,
  isBpSimulation,
  setIsBpSimulation,
  onClickStart,
  onClickStop,
}) => {
  const theme = useTheme();

  const showImage =
    batteryPackSimulationData.qrScan > batteryPackSimulationData.done ||
    moduleSimulationData.qrScan > moduleSimulationData.done;
  const showWeightControl =
    (batteryPackSimulationData.qrScan === batteryPackSimulationData.weight &&
      batteryPackSimulationData.qrScan > 0) ||
    (moduleSimulationData.qrScan === moduleSimulationData.weight &&
      moduleSimulationData.qrScan > 0);
  const showDischargeGraph =
    (batteryPackSimulationData.dischargeData === batteryPackSimulationData.weight &&
      batteryPackSimulationData.dischargeData > batteryPackSimulationData.done) ||
    (moduleSimulationData.dischargeData === moduleSimulationData.weight &&
      moduleSimulationData.dischargeData > moduleSimulationData.done);

  const onToggleChange = () => {
    setIsBpSimulation(!isBpSimulation);
    onClickStop();
  };

  const images = useMemo(
    () =>
      isBpSimulation
        ? TEXT_CONTENTS.CLIENTS.BATTERY_PACK_SIMULATION.IMAGES
        : TEXT_CONTENTS.CLIENTS.MODULE_SIMULATION.IMAGES,
    [isBpSimulation]
  );
  let index = 0;
  if (batteryPackSimulationData.qrScan > 0) {
    index = batteryPackSimulationData.qrScan % images.length;
  } else if (moduleSimulationData.qrScan > 0) {
    index = moduleSimulationData.qrScan % images.length;
  }
  const randomImage = useMemo(() => {
    return images[index];
  }, [index, images]);

  return (
    <Box sx={{ margin: 'auto', mb: 2, maxWidth: '850px' }}>
      <Paper
        elevation={3}
        sx={{
          borderRadius: '25px',
          borderColor: 'secondary.light',
          borderWidth: '50px',
          borderStyle: 'solid',
          width: '100%',
          position: 'relative',
          zIndex: 2,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '-40px',
            left: 'calc(50% - 15px)',
            width: '20px',
            height: '20px',
            backgroundColor: 'black',
            borderRadius: '50%',
            border: '2px solid gray',
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '-32px',
            left: 'calc(50% - 25px)',
            width: '2px',
            height: '2px',
            backgroundColor: 'black',
            borderRadius: '50%',
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 'calc(50% - 25px)',
            right: '-48px',
            width: '47px',
            height: '47px',
            backgroundColor: 'secondary.dark',
            borderRadius: '50%',
            border: '5px solid gray',
            zIndex: 1,
          }}
        />
        <Box sx={{ bgcolor: 'secondary.main', color: '#FFFFFF', p: 2 }}>
          <Typography variant="h6" component="h1">
            BatDat for Operations
          </Typography>
        </Box>
        <Box sx={{ p: 2, backgroundColor: '#3595fc' }}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} container justifyContent="space-between">
                  <Box sx={{ flexGrow: 1 }}>
                    <Button
                      variant="contained"
                      onClick={onClickStart}
                      sx={{
                        margin: theme.spacing(1),
                        color: '#000000',
                        backgroundColor: '#ffffff',
                        borderRadius: '0px',
                        width: '20%',
                      }}
                    >
                      Start
                    </Button>
                    <Button
                      variant="contained"
                      onClick={onClickStop}
                      sx={{
                        margin: theme.spacing(1),
                        color: '#000000',
                        backgroundColor: '#ffffff',
                        borderRadius: '0px',
                        width: '20%',
                      }}
                    >
                      Stop
                    </Button>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <label htmlFor="toggle">BP</label>
                    <Switch
                      id="toggle"
                      inputProps={{ 'aria-label': 'controlled' }}
                      onChange={() => onToggleChange()}
                    />
                    <label htmlFor="toggle">Module</label>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <Grid container>
                <Grid item xs={4}>
                  {showImage ? (
                    <Box
                      sx={{
                        m: 2,
                        position: 'relative',
                        height: '200px',
                        width: '85%',
                        bgcolor: '#cccccc',
                        justifyContent: 'center',
                      }}
                      component="img"
                      src={randomImage}
                    />
                  ) : (
                    <Box sx={{ m: 2, position: 'relative', height: '200px', bgcolor: '#ffffff' }}>
                      <Typography
                        variant="body2"
                        component="div"
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        BP Image
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Box sx={{ m: 2, position: 'relative', height: '200px', bgcolor: '#ffffff' }}>
                    {showWeightControl ? (
                      <Box>
                        <Typography
                          variant="h4"
                          component="div"
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                          }}
                        >
                          {isBpSimulation
                            ? TEXT_CONTENTS.CLIENTS.BATTERY_PACK_SIMULATION.DATA[
                                batteryPackSimulationData.weight - 1
                              ].weight
                            : TEXT_CONTENTS.CLIENTS.MODULE_SIMULATION.DATA[
                                moduleSimulationData.weight - 1
                              ].weight}{' '}
                          Kg
                        </Typography>
                      </Box>
                    ) : (
                      <Typography
                        variant="body2"
                        component="div"
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        Weight control
                      </Typography>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  {showDischargeGraph ? (
                    <Box
                      sx={{
                        m: 2,
                        position: 'relative',
                        height: '200px',
                        width: '85%',
                        bgcolor: '#cccccc',
                        justifyContent: 'center',
                      }}
                    >
                      <DischaringPlot />
                    </Box>
                  ) : (
                    <Box sx={{ m: 2, position: 'relative', height: '200px', bgcolor: '#ffffff' }}>
                      <Typography
                        variant="body2"
                        component="div"
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        Discharging Data Insights
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    flexWrap: 'wrap',
                  }}
                >
                  <Box sx={{ width: '100%', height: '100%', maxWidth: '10%', maxHeight: '10%' }}>
                    <QRCode size={56} value="http://circuli-ion.com" />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    <Typography
                      variant="body1"
                      component="p"
                      sx={{ fontSize: '16px', marginLeft: theme.spacing(-10) }}
                    >
                      QR Scan
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexWrap: 'wrap',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    <Box
                      component="img"
                      sx={{ width: '64px', height: '64px', backgroundColor: '#ffffff' }}
                      src={'https://cdn-icons-png.flaticon.com/512/4601/4601783.png'}
                    />
                    <Typography variant="body1" component="p">
                      Weight Control
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexWrap: 'wrap',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    <Box
                      component="img"
                      sx={{ width: '64px', height: '64px' }}
                      src={
                        'https://img.freepik.com/premium-vector/battery-icon-set-isolated-white-background-vector-illustration_736051-264.jpg?w=1380'
                      }
                    />
                    <Typography variant="body1" component="p">
                      Discharge
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default IpadInterface;
