import { gql } from '@apollo/client';

export const LOGIN_VIA_MICROSOFT = gql`
  mutation LoginViaMicrosoft {
    loginViaMicrosoft @client
  }
`;

export const LOGIN_VIA_GOOGLE = gql`
  mutation LoginViaGoogle {
    loginViaGoogle @client
  }
`;

export const LOGOUT = gql`
  mutation Logout {
    logout @client
  }
`;

export const SIGN_UP = gql`
  mutation UpdateUserDetails($userDetails: UserDetailsInput!) {
    updateUserDetails(userDetails: $userDetails) {
      company
      email
      role
      frequency
      usecase
      dataInterest
      dataInterestOther
      userType
      approved
    }
  }
`;

// TODO: Move GET_USER_DETAILS and PENDING_APPROVALS to /queries/auth.tsx
export const GET_USER_DETAILS = gql`
  query Query {
    getUserDetails {
      company
      email
      role
      frequency
      usecase
      dataInterest
      dataInterestOther
      userType
      approved
    }
  }
`;

export const PENDING_APPROVALS = gql`
  query Query {
    getPendingUserDetails {
      id
      company
      email
      role
      frequency
      usecase
      dataInterest
      dataInterestOther
      userType
      approved
    }
  }
`;

export const APPROVE_USERS = gql`
  mutation ApproveUsers($userIds: [String!]!) {
    approveUsers(userIds: $userIds)
  }
`;
