import { ApexOptions } from 'apexcharts';
import React, { useCallback, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useDataInsights } from '../../hooks';
import { TEXT_CONTENTS } from 'src/constants';
import { useTheme } from '@mui/material';
import { ChartContainer } from 'src/components/Structure';
import Box from '@mui/material/Box';
import { DEFAULT_MATERIALS_VALUE, MATERIALS_MAPPING } from 'src/constants/materialsValue';
import { Currency } from 'src/@types/ChartSettings/MaterialsValue';
import { roundToTwoDecimals } from 'src/utils/Number';
import { CellOrBatteryComponent, ChartProps, CurrencyRate } from 'src/@types/DataTypes';
import ChartTabs from 'src/components/Charts/MaterialInsights/ChartTabs';

interface props extends ChartProps {
  detailedView?: boolean;
  latestTimestamps?: Record<string, number>;
  displayCurrency?: Currency;
  selectedBatteryCategory: string;
}

const MaterialsAvailable: React.FC<props> = ({
  latestTimestamps,
  detailedView = false,
  displayCurrency = Currency.EUR,
  chartId = 'materials-available',
  selectedBatteryCategory,
}) => {
  const theme = useTheme();
  const height = theme.spacing(56.5);
  const { materialInsights, currencyRates } = useDataInsights();

  const accumulateMaterials = useCallback(
    (materials: CellOrBatteryComponent[]) => {
      let filteredData = materials;
      if (selectedBatteryCategory !== 'Total') {
        filteredData = materials.filter(
          ({ batteryCategory }) => batteryCategory === selectedBatteryCategory
        );
      }
      return filteredData.reduce<{ [label: string]: number }>((acc, { label, value }) => {
        acc[label] = (acc[label] || 0) + value;
        return acc;
      }, {});
    },
    [selectedBatteryCategory]
  );

  const materialAccumulator = accumulateMaterials(materialInsights?.cellMaterials || []);

  const categories = materialAccumulator && Object.keys(materialAccumulator);
  const data = materialAccumulator && Object.values(materialAccumulator);

  const [options, setOptions] = useState<ApexOptions>({
    chart: {
      id: chartId,
      fontFamily: theme.typography.fontFamily,
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: [theme.palette.text.primary],
        fontSize: theme.typography.body2.fontSize + 'px',
        fontWeight: 'lighter',
      },
      textAnchor: 'start',
      offsetY: 8,
      offsetX: 4,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: { position: 'bottom' },
        barHeight: '90%',
        borderRadius: 4,
      },
    },
    colors: [theme.palette.primary.light, theme.palette.error.main, theme.palette.error.main],
    yaxis: {
      labels: {
        show: true,
        rotate: 0,
        offsetX: 4,
        align: 'right',
        minWidth: 75,
        maxWidth: 160,
        style: {
          fontSize: '14px',
        },
      },
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    xaxis: {
      categories: categories,
      labels: { show: false },
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    grid: { show: false },
    tooltip: {
      enabled: false,
    },
  });

  const [series, setSeries] = useState<ApexAxisChartSeries>([
    {
      name: TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.CELL.YAXIS_KG,
      data: data as number[],
    },
  ]);
  const [value, setValue] = React.useState(0);

  const getSeriesWhenCustomInputDisabled = useCallback(() => {
    const accumulatedMaterials = accumulateMaterials(materialInsights?.cellMaterials || []);

    const data = Object.entries(accumulatedMaterials).map(([label, value]) => {
      const metalPrice = materialInsights?.metalsPrice.find(metal => {
        const materialName = MATERIALS_MAPPING[label] || label;
        return metal.name === materialName && metal.timestamp === latestTimestamps?.[metal.name];
      });
      let price = metalPrice?.price || DEFAULT_MATERIALS_VALUE[label];

      if (displayCurrency === Currency.EUR) {
        const currencyRate = currencyRates?.find(
          currency => currency.currency === displayCurrency
        ) as CurrencyRate;
        price = price * currencyRate?.rate;
      }
      return { label, value: roundToTwoDecimals(value * price) };
    });
    return data;
  }, [
    materialInsights?.cellMaterials,
    materialInsights?.metalsPrice,
    displayCurrency,
    currencyRates,
    latestTimestamps,
    accumulateMaterials,
  ]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      const materialAccumulator = accumulateMaterials(materialInsights?.cellMaterials || []);
      setSeries(() => [
        {
          name: TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.CELL.YAXIS_KG,
          data: Object.values(materialAccumulator) as number[],
        },
      ]);
      setOptions(prevOptions => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: Object.keys(materialAccumulator) as string[],
        },
      }));
    } else {
      const dataOnTabChange = getSeriesWhenCustomInputDisabled();
      const categories = dataOnTabChange.map(({ label }) => label);
      const data = dataOnTabChange.map(({ value }) => value);
      setSeries(() => [
        {
          name: TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.CELL.YAXIS_USD + displayCurrency,
          data: data as number[],
        },
      ]);
      setOptions(prevOptions => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: categories as string[],
        },
      }));
    }
  };

  useEffect(() => {
    if (value === 1) {
      const dataOnTabChange = getSeriesWhenCustomInputDisabled();
      const categories = dataOnTabChange.map(({ label }) => label);
      const data = dataOnTabChange.map(({ value }) => value);
      setSeries(prevSeries => [
        {
          ...prevSeries[0],
          data: data as number[],
        },
      ]);
      setOptions(prevOptions => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: categories as string[],
        },
      }));
    } else {
      const materialAccumulator = accumulateMaterials(materialInsights?.cellMaterials || []);
      setSeries([
        {
          name: TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.CELL.YAXIS_KG,
          data: Object.values(materialAccumulator) as number[],
        },
      ]);
      setOptions(prevOptions => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: Object.keys(materialAccumulator) as string[],
        },
      }));
    }
  }, [
    getSeriesWhenCustomInputDisabled,
    materialInsights?.cellMaterials,
    value,
    selectedBatteryCategory,
    accumulateMaterials,
  ]);

  return (
    <Box>
      {detailedView && (
        <ChartTabs value={value} onTabChange={handleTabChange} currency={displayCurrency} />
      )}
      <ChartContainer height={theme.spacing(50)} dataNotAvailable={series.length === 0}>
        <Chart
          options={options}
          series={series}
          type="bar"
          height={height}
          colors={[theme.palette.primary.light, theme.palette.primary.dark]}
          style={{
            color: theme.palette.secondary.main,
            fontFamily: theme.typography.fontFamily,
            marginLeft: theme.spacing(-3),
            marginTop: theme.spacing(-4.2),
          }}
        />
      </ChartContainer>
    </Box>
  );
};

export default MaterialsAvailable;
