import { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_STAKEHOLDER_MAPPING } from 'src/graphql/queries';
import StakeholderContext from 'src/components/Contexts/StakeholderContext';

const useStakeholderMapping = () => {
  const { loading, data } = useQuery(GET_STAKEHOLDER_MAPPING);
  const { stakeholderMapping, setStakeholderMapping } = useContext(StakeholderContext);
  useEffect(() => {
    if (!loading && data?.stakeholderMapping) {
      setStakeholderMapping(data?.stakeholderMapping);
    }
  }, [loading, data, setStakeholderMapping]);

  return { loading, stakeholderMapping };
};

export default useStakeholderMapping;
