import { gql } from '@apollo/client';
import {
  CELL_INSIGHTS_FRAGMENT,
  MATERIAL_INSIGHTS_FRAGMENT,
  BATTERY_INSIGHTS_FRAGMENT,
  CURRENCY_CONVERSION_RATE,
  FACILITY_INSIGHTS_FRAGMENT,
} from 'src/graphql/fragments';

export const GET_DATA_INSIGHTS = gql`
  query DataInsights {
    cellInsights {
      ...CellInsights
    }
    materialInsights {
      ...MaterialInsights
    }
    batteryInsights {
      ...BatteryInsights
    }
    facilityInsights {
      ...FacilityInsights
    }
    currencyRates {
      ...CurrencyDetails
      __typename
    }
  }
  ${CELL_INSIGHTS_FRAGMENT}
  ${MATERIAL_INSIGHTS_FRAGMENT}
  ${BATTERY_INSIGHTS_FRAGMENT}
  ${FACILITY_INSIGHTS_FRAGMENT}
  ${CURRENCY_CONVERSION_RATE}
`;
