import React, { useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { useDataInsights } from '../../hooks';
import { TEXT_CONTENTS } from 'src/constants';
import { useTheme } from '@mui/material';
import { ChartContainer } from 'src/components/Structure';
import { ChartProps } from 'src/@types/DataTypes';
import { ToggleButtonValues } from 'src/components/Charts/Filters/BatteryFilters';

interface Props extends ChartProps {
  selectedBatteryType?: string;
  selectedBatteryCategory?: string;
  detailedView?: boolean;
  selectedToggleValue?: ToggleButtonValues;
}

const MonthlyProcessed: React.FC<Props> = ({
  selectedBatteryType,
  selectedBatteryCategory,
  selectedToggleValue = ToggleButtonValues.WEIGHT_IN_KG,
  //   detailedView = false,
  chartId = 'monthly-battery-packs-processed',
}) => {
  const theme = useTheme();
  const height = theme.spacing(50);
  const { batteryInsights } = useDataInsights();
  const curveType = 'smooth' as const;

  const options = {
    chart: {
      id: chartId,
      fontFamily: theme.typography.fontFamily,
      background: theme.palette.background.paper,
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [],
        },
      },
    },
    tooltip: {
      enabled: true,
      shared: false,
    },
    dataLabels: { enabled: false },
    stroke: { curve: curveType },
    colors: [theme.palette.primary.light],
    yaxis: {
      title: {
        text:
          selectedToggleValue === ToggleButtonValues.WEIGHT_IN_KG
            ? TEXT_CONTENTS.CHARTS.BATTERY_INSIGHTS.MONTHLY.YAXIS + ' [kg]'
            : TEXT_CONTENTS.CHARTS.BATTERY_INSIGHTS.MONTHLY.YAXIS,
      },
    },
    xaxis: {
      tickAmount: 12,
      tickPlacement: 'on',
      labels: {
        style: {
          fontSize: '15px',
        },
      },
    },
  };

  const monthlyBatteryTypeData = useMemo(() => {
    let filteredData = batteryInsights?.batteryDataInsights ?? [];

    if (selectedBatteryType !== 'Total') {
      filteredData =
        batteryInsights?.batteryDataInsights.filter(
          ({ batteryType, batteryCategory }) =>
            batteryType === selectedBatteryType && batteryCategory === selectedBatteryCategory
        ) ?? [];
    } else if (selectedBatteryCategory !== 'Total' && selectedBatteryType === 'Total') {
      filteredData =
        batteryInsights?.batteryDataInsights.filter(
          ({ batteryCategory }) => batteryCategory === selectedBatteryCategory
        ) ?? [];
    }

    const aggregation = filteredData?.reduce<{ [key: string]: number }>((acc, curr) => {
      const monthYear = `${curr.month.slice(0, 3)} ${curr.year}`;
      const valueToAdd =
        selectedToggleValue === ToggleButtonValues.COUNT
          ? curr.numberBatteryPacks
          : Math.round(curr.weightKg);

      acc[monthYear] = (acc[monthYear] || 0) + valueToAdd;

      return acc;
    }, {});

    const seriesData = Object.entries(aggregation).map(([key, value]) => {
      return {
        x: key,
        y: value,
      };
    });

    return [
      {
        name: selectedToggleValue === ToggleButtonValues.COUNT ? 'Count' : 'Weight [kg]',
        data: seriesData,
      },
    ];
  }, [batteryInsights, selectedBatteryType, selectedBatteryCategory, selectedToggleValue]);

  const [series, setSeries] = useState<ApexAxisChartSeries>(monthlyBatteryTypeData || []);

  useEffect(() => {
    setSeries(monthlyBatteryTypeData || []);
  }, [monthlyBatteryTypeData]);

  return (
    <ChartContainer height={height} dataNotAvailable={series.length === 0}>
      <Chart
        options={options}
        series={series}
        type="bar"
        height={height}
        colors={[theme.palette.primary.light, theme.palette.primary.dark]}
        style={{
          color: theme.palette.secondary.main,
          fontFamily: theme.typography.fontFamily,
        }}
      />
    </ChartContainer>
  );
};

export default MonthlyProcessed;
