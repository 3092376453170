import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChartWrapper, PageContainer, Title } from 'src/components/Structure';
import { Card, CardContent, Grid, Typography, useTheme } from '@mui/material';
import { TEXT_CONTENTS } from 'src/constants';
import { useDataInsights } from 'src/components/hooks';
import { BoxPlot, CellCounts, Condition, ConditionBox } from 'src/components/Charts';
import CELL_DETAILS from 'src/constants/CellDetails';
import { BatteryCategoryFilter, BatteryTypeFilter } from 'src/components/Charts/Filters/Common';
import { ClientFilter } from 'src/components/Charts/Filters/CellFilters';
import { CellCondition } from 'src/@types/DataTypes';

const Cell: React.FC = (): JSX.Element => {
  const { loading, cellInsights } = useDataInsights();
  const [selectedBatteryCategory, setSelectedBatteryCategory] = useState<string>('Total');
  const [selectedBatteryType, setSelectedBatteryType] = useState<string>('Total');
  const [selectedClient, setSelectedClient] = useState<string>('All Clients');
  const navigate = useNavigate();
  const theme = useTheme();

  const batteryCategories = [
    ...new Set(cellInsights?.testedCells?.map(cell => cell.batteryCategory) || []),
  ];
  const batteryTypes = [
    ...new Set(
      cellInsights?.testedCells
        ?.filter(({ batteryCategory }) => selectedBatteryCategory === batteryCategory)
        .map(({ batteryType }) => batteryType)
    ),
  ];
  const clients = [
    ...new Set(
      cellInsights?.testedCells
        ?.filter(({ batteryCategory }) => selectedBatteryCategory === batteryCategory)
        .filter(({ batteryType }) => selectedBatteryType === batteryType)
        ?.map(cell => cell.client) || ['All Clients']
    ),
  ];

  const filteredCellCondition = cellInsights?.cellCondition
    .filter(condition => {
      if (selectedClient === 'All Clients') return true;
      return condition?.client === selectedClient;
    })
    .filter(condition => {
      if (selectedBatteryCategory === 'Total') return true;
      return condition?.batteryCategory === selectedBatteryCategory;
    })
    .filter(condition => {
      if (selectedBatteryType === 'Total') return true;
      return condition?.batteryType === selectedBatteryType;
    });
  const accumulatedData = filteredCellCondition?.reduce(
    (acc, curr) => {
      if (!curr) return acc;

      acc.reusablePct += +curr.reusablePct * curr.totalCells;
      acc.recycleablePct += +curr.recycleablePct * curr.totalCells;
      acc.totalCells += +curr.totalCells;

      return acc;
    },
    {
      reusablePct: 0,
      recycleablePct: 0,
      totalCells: 0,
    } as Omit<CellCondition, 'client' | 'batteryCategory' | 'batteryType'>
  );

  useEffect(() => {
    if (selectedBatteryCategory === 'Total' || selectedBatteryType === 'Total') {
      setSelectedClient('All Clients');
    }
  }, [selectedBatteryCategory, selectedBatteryType]);

  if (loading) return <Typography variant="body1">{TEXT_CONTENTS.COMMON.LOADING}</Typography>;

  return (
    <PageContainer>
      <Title variant="h1">{TEXT_CONTENTS.CELL.TITLE}</Title>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ChartWrapper title={TEXT_CONTENTS.CHARTS.CELL_INSIGHTS.FILTERS.TITLE}>
            <Grid container>
              <Grid item xs={12} sm={4} md={4} sx={{ marginBottom: theme.spacing(2) }}>
                <BatteryCategoryFilter
                  batteryCategories={batteryCategories}
                  selectedBatteryCategory={selectedBatteryCategory}
                  setSelectedBatteryCategory={setSelectedBatteryCategory}
                  setSelectedBatteryType={setSelectedBatteryType}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                sx={{
                  marginBottom: theme.spacing(2),
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <BatteryTypeFilter
                  batteryTypes={batteryTypes}
                  selectedBatteryType={selectedBatteryType}
                  setSelectedBatteryType={setSelectedBatteryType}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                sx={{
                  marginBottom: theme.spacing(2),
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ClientFilter
                  clients={clients}
                  selectedClient={selectedClient}
                  setSelectedClient={setSelectedClient}
                />
              </Grid>
            </Grid>
          </ChartWrapper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ChartWrapper title={TEXT_CONTENTS.CHARTS.CELL_INSIGHTS.CONDITION_OF_CELLS.TITLE}>
            <Condition aggregateConditionData={accumulatedData} />
            <ConditionBox
              batteryCategory={selectedBatteryCategory}
              batteryType={selectedBatteryType}
              client={selectedClient}
              aggregatedConditionData={accumulatedData}
            />
          </ChartWrapper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ChartWrapper
            title={TEXT_CONTENTS.CHARTS.CELL_INSIGHTS.TESTED_CELLS.TITLE}
            chartId={TEXT_CONTENTS.CHARTS.CELL_INSIGHTS.TESTED_CELLS.ID}
          >
            <CellCounts
              chartId={TEXT_CONTENTS.CHARTS.CELL_INSIGHTS.TESTED_CELLS.ID}
              batteryCategory={selectedBatteryCategory}
              batteryType={selectedBatteryType}
              client={selectedClient}
            />
          </ChartWrapper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ChartWrapper
            title={TEXT_CONTENTS.CHARTS.CELL_INSIGHTS.STATE_OF_HEALTH.TITLE}
            chartId={TEXT_CONTENTS.CHARTS.CELL_INSIGHTS.STATE_OF_HEALTH.ID}
          >
            <BoxPlot
              chartId={TEXT_CONTENTS.CHARTS.CELL_INSIGHTS.STATE_OF_HEALTH.ID}
              client={selectedClient}
              batteryCategory={selectedBatteryCategory}
              batteryType={selectedBatteryType}
            />
          </ChartWrapper>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <ChartWrapper title={TEXT_CONTENTS.CHARTS.CELL_INSIGHTS.CELL_LIBRARY}>
          <Grid container sx={{ display: 'flex', justifyContent: 'space-around' }}>
            {CELL_DETAILS.map(({ name, company, image, model }, index) => (
              <Grid
                item
                xs={11}
                sm={5}
                md={2}
                lg={2}
                key={index}
                sx={{
                  mb: theme.spacing(4),
                  ml: theme.spacing(0.5),
                  mr: theme.spacing(0.5),
                  mt: theme.spacing(4),
                }}
              >
                <Card
                  elevation={5}
                  sx={{
                    borderRadius: 3,
                    display: 'flex',
                    flexGrow: 1,
                    height: '100%',
                    width: '100%',
                    cursor: 'pointer',
                  }}
                  onClick={() => navigate(`/cell-details`, { state: { name } })}
                >
                  <CardContent
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      style={{
                        width: index === 3 ? '100%' : '40%',
                        height: '65%',
                        marginBottom: theme.spacing(2),
                      }}
                      alt={'cell'}
                      src={image}
                    />
                    <Typography variant="body1" sx={{ fontSize: '24px', fontWeight: 'bold' }}>
                      {model}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: '24px', fontWeight: 'light' }}>
                      {company}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </ChartWrapper>
      </Grid>
    </PageContainer>
  );
};

export default Cell;
