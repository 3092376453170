import React from 'react';
import { Badge } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';

const RestrictedBadge: React.FC<{ children: React.ReactNode; restricted: boolean }> = ({
  children,
  restricted,
}) => {
  if (!restricted) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <Badge color="error" badgeContent={<BlockIcon style={{ fontSize: 'large' }} />}>
      {children}
    </Badge>
  );
};

export default RestrictedBadge;
