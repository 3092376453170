import React, { ReactNode, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

type Series = {
  name: string;
  //eslint-disable-next-line
  data: any[];
};

interface Props {
  children: ReactNode;
  title: string;
  info?: ReactNode;
  settings?: JSX.Element;
  chartId?: string;
}

const ChartWrapper: React.FC<Props> = ({
  title,
  info,
  children,
  settings,
  chartId,
}): JSX.Element => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [showDownloadMenu, setShowDownloadMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const getChartData = async (chartId: string): Promise<Series[] | null> => {
    try {
      const options = await ApexCharts.exec(chartId, 'getOptions');
      if (options && options.series) {
        // Assuming you want to extract all the series data:
        return options.series as Series[];
      }
      return null;
    } catch (error) {
      console.error('Error extracting data from chart:', error);
      return null;
    }
  };

  const convertToCSV = (seriesData: Series[]) => {
    let csv = 'Label,Value\n'; // Header
    if (seriesData && seriesData.length > 0 && seriesData[0].data) {
      seriesData[0].data.forEach(value => {
        csv += `${value.x},${value.y}\n`;
      });
    }
    return csv;
  };

  const handleDownload = async (chartId: string, fileType: string) => {
    try {
      if (fileType === 'csv') {
        const chartData = await getChartData(chartId);
        if (chartData) {
          const csvData = convertToCSV(chartData);
          const blob = new Blob([csvData], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = url;
          link.download = `${chartId}.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          window.URL.revokeObjectURL(url);
          handleClose();
        } else {
          console.error('Failed to retrieve chart data.');
        }
      } else {
        const dataURI = await ApexCharts.exec(chartId, 'dataURI');
        if (dataURI && dataURI.imgURI) {
          const link = document.createElement('a');
          link.href = dataURI.imgURI;
          link.download = `${chartId}.${fileType}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          handleClose();
        } else {
          console.error('Failed to retrieve dataURI from chart.');
        }
      }
    } catch (error) {
      console.error('Error downloading chart:', error);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setShowDownloadMenu(!showDownloadMenu);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowDownloadMenu(false);
  };

  return (
    <Box component="div" sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(5) }}>
      <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ marginBottom: theme.spacing(1) }} variant="h6">
          {title}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            ml: 'auto',
          }}
        >
          {chartId && (
            <>
              <IconButton
                aria-label="download chart data"
                sx={{ marginLeft: theme.spacing(1) }}
                onClick={handleClick}
              >
                <DownloadIcon />
              </IconButton>
              <Menu
                id="download-menu"
                aria-labelledby="download chart data"
                anchorEl={anchorEl}
                open={showDownloadMenu}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={() => handleDownload(chartId, 'png')}>Download png</MenuItem>
                <MenuItem onClick={() => handleDownload(chartId, 'jpeg')}>Download jpg</MenuItem>
                {/*<MenuItem onClick={() => handleDownload(chartId, 'csv')}>Download csv</MenuItem>*/}
              </Menu>
            </>
          )}
          {settings}
          {info && (
            <Tooltip
              title={info}
              open={open}
              placement="bottom-end"
              onMouseEnter={() => {
                if (open) {
                  setOpen(false);
                  return;
                }
                setOpen(true);
              }}
              onMouseLeave={() => setOpen(false)}
              onTouchStart={() => {
                if (open) {
                  setOpen(false);
                  return;
                }
                setOpen(true);
              }}
              onTouchEnd={() => setOpen(false)}
            >
              <IconButton aria-label="info about the chart" sx={{ marginLeft: theme.spacing(1) }}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      {children}
    </Box>
  );
};

export default ChartWrapper;
