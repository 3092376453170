import React, { createContext } from 'react';
import config from './config.json';
import Config from './Config';
import { appQueryParams } from 'src/constants/appQueryParams';
import { NestedObject } from 'src/@types/GenericTypes';

interface ConfigContextInterface {
  config: Config;
}

const defaultConfig = config.development;
const environment = (process.env.NODE_ENV ?? 'development') as keyof typeof config;
const environmentConfig: NestedObject = config[environment];
const finalConfig: NestedObject = {
  ...defaultConfig,
  ...environmentConfig,
  appQueryParams,
};

const ConfigContext = createContext<ConfigContextInterface | null>(null);
const ConfigConsumer = ConfigContext.Consumer;
const configInstance = new Config(finalConfig);

const ConfigProvider = ({ children }: { children: React.ReactNode }) => (
  <ConfigContext.Provider value={{ config: configInstance }}>{children}</ConfigContext.Provider>
);

export { ConfigProvider, ConfigConsumer, configInstance };

export default ConfigContext;
