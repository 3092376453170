import React from 'react';
import { Paper, Typography, useTheme } from '@mui/material';

interface KpiCardProps {
  text: string;
  value: string;
}

const KpiCard: React.FC<KpiCardProps> = ({ text, value }) => {
  const theme = useTheme();
  return (
    <Paper
      sx={{
        height: '100%',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="text-8xl" fontWeight="lighter" color="primary.dark">
        {value}
      </Typography>
      <Typography variant="h5" color="black">
        {text}
      </Typography>
    </Paper>
  );
};

export default KpiCard;
