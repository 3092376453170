import { FirebaseError } from 'src/@types/AuthTypes';

export function getFirebaseErrorMessage(error: FirebaseError): string {
  let errorMessage: string;

  switch (error.code) {
    case 'auth/user-not-found':
      errorMessage =
        "We couldn't find an account associated with that email. Double-check your entry or consider signing up!";
      break;

    case 'auth/wrong-password':
      errorMessage = 'Oops! The password you entered seems to be incorrect. Please try again.';
      break;

    case 'auth/email-already-in-use':
      errorMessage =
        'It looks like this email is already in use. Maybe you’ve already signed up? Try logging in instead.';
      break;

    case 'auth/invalid-email':
      errorMessage =
        'Hmm, that doesn’t look like a valid email address. Could you check it and try again?';
      break;

    case 'auth/operation-not-allowed':
      errorMessage =
        'Sorry, this sign-in method is currently disabled. Please contact our support or try a different method.';
      break;

    case 'auth/account-exists-with-different-credential':
      errorMessage =
        'It seems you’ve already signed up using a different method with this email. Try signing in with that method or use a different email.';
      break;

    case 'auth/network-request-failed':
      errorMessage =
        'It looks like there’s an issue with your network. Please check your connection and try again.';
      break;

    default:
      errorMessage =
        'Oops! Something went wrong on our end. We’re sorry for the inconvenience. Please try again later or contact our support.';
  }

  return errorMessage;
}
