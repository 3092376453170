import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Grid,
  useTheme,
  IconButton,
  InputLabel,
  FormControl,
  MenuItem,
  Chip,
  OutlinedInput,
  useMediaQuery,
  Theme,
} from '@mui/material';
import ReactMapGL, {
  Marker,
  Popup,
  ViewportProps,
  NavigationControl,
  FlyToInterpolator,
} from 'react-map-gl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import useStakeholderMapping from 'src/components/hooks/useStakeholderMapping';
import RecyclingIcon from '@mui/icons-material/Recycling';
import BatteryCharging20RoundedIcon from '@mui/icons-material/BatteryCharging20Rounded';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import HomeIcon from '@mui/icons-material/Home';
import { StakeholderMapping } from 'src/@types/DataTypes';
import { TEXT_CONTENTS } from 'src/constants';

interface ViewportState extends ViewportProps {
  latitude: number;
  longitude: number;
  zoom: number;
  bearing: number;
  pitch: number;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const Stakeholders: React.FC = () => {
  const { loading, stakeholderMapping } = useStakeholderMapping();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const countries = [
    ...new Set(stakeholderMapping.map((stakeholder: StakeholderMapping) => stakeholder.country)),
  ];
  const categories = [
    ...new Set(stakeholderMapping.map((stakeholder: StakeholderMapping) => stakeholder.category)),
  ];

  const defaultView = {
    latitude: 52,
    longitude: 14,
    zoom: 4,
    bearing: 0,
    pitch: 0,
  };

  const [viewport, setViewport] = useState<ViewportState>(defaultView);
  const [selectedCountry, setSelectedCountry] = React.useState<string[]>(countries);
  const [selectedCategories, setSelectedCategories] = React.useState<string[]>(categories);
  const [selectedPlace, setSelectedPlace] = useState<StakeholderMapping | null>(null);

  const filteredStakeholderMapping = stakeholderMapping.filter(
    (stakeholder: StakeholderMapping) =>
      stakeholder.latitude !== null &&
      stakeholder.longitude !== null &&
      selectedCountry.includes(stakeholder.country) &&
      selectedCategories.includes(stakeholder.category)
  );

  const settings = {
    dragPan: true,
    dragRotate: true,
    scrollZoom: true,
    touchZoom: true,
    touchRotate: true,
    keyboard: false,
    doubleClickZoom: true,
  };

  const handleCountryChange = (event: SelectChangeEvent<typeof selectedCountry>) => {
    const {
      target: { value },
    } = event;
    setSelectedCountry(typeof value === 'string' ? value.split(',') : value);
  };

  const handleCategoryChange = (event: SelectChangeEvent<typeof selectedCategories>) => {
    const {
      target: { value },
    } = event;
    setSelectedCategories(typeof value === 'string' ? value.split(',') : value);
  };

  const handleCategoryDelete = (categoryToDelete: string) => {
    setSelectedCategories(categories =>
      categories.filter(category => category !== categoryToDelete)
    );
  };

  const handleCountryDelete = (countryToDelete: string) => {
    setSelectedCountry(countries => countries.filter(country => country !== countryToDelete));
  };

  useEffect(() => {
    setSelectedCountry([
      ...new Set(stakeholderMapping.map((stakeholder: StakeholderMapping) => stakeholder.country)),
    ]);
    setSelectedCategories([
      ...new Set(stakeholderMapping.map((stakeholder: StakeholderMapping) => stakeholder.category)),
    ]);
  }, [loading, stakeholderMapping]);

  if (loading) {
    return <Typography variant="body1">{TEXT_CONTENTS.COMMON.LOADING}</Typography>;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <Grid container sx={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={8}
          order={{ xs: 2, sm: 2, md: 2, lg: 1 }}
          sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <ReactMapGL
            {...viewport}
            width="100%"
            height={isMobile ? '55vh' : '85vh'}
            onViewportChange={(nextViewport: ViewportProps) =>
              setViewport(nextViewport as ViewportState)
            }
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            mapStyle="mapbox://styles/mapbox/light-v11"
            {...settings}
          >
            {filteredStakeholderMapping.map((poi, index) => (
              <Marker
                key={index}
                latitude={poi?.latitude as number}
                longitude={poi?.longitude as number}
              >
                <Box
                  onClick={() => setSelectedPlace(poi)}
                  sx={{
                    transform: 'translate(-50%, -50%)',
                    position: 'absolute',
                  }}
                >
                  {poi.category === 'Recycler' && (
                    <RecyclingIcon sx={{ color: theme.palette.primary.dark }} />
                  )}
                  {poi.category === 'GigaFactory' && <BatteryCharging20RoundedIcon />}
                  {poi.category === 'Upcycler' && <HealthAndSafetyIcon sx={{ color: '#144508' }} />}
                </Box>
              </Marker>
            ))}

            {selectedPlace && (
              <Popup
                latitude={selectedPlace?.latitude as number}
                longitude={selectedPlace?.longitude as number}
                onClose={() => setSelectedPlace(null)}
              >
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {selectedPlace.company}
                    </Typography>
                    <Typography color="textSecondary">
                      {selectedPlace.city + ', ' + selectedPlace.country}
                    </Typography>
                    <br />
                    <Typography color="textSecondary">
                      {selectedPlace.startYear && `Started on - ${selectedPlace.startYear}`}
                    </Typography>
                    <Typography color="textSecondary">
                      Facility Type - {selectedPlace.category}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => window.open(selectedPlace.link, '_blank')}>
                      Learn More
                    </Button>
                  </CardActions>
                </Card>
              </Popup>
            )}
            <IconButton
              onClick={() => {
                setViewport({
                  ...defaultView,
                  transitionDuration: 500,
                  transitionInterpolator: new FlyToInterpolator(),
                });
              }}
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                padding: '10px',
              }}
            >
              <HomeIcon />
            </IconButton>
            <NavigationControl />
          </ReactMapGL>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={4}
          order={{ xs: 1, sm: 1, md: 1, lg: 2 }}
          sx={{
            height: '85vh',
            overflowY: 'scroll',
            marginTop: { xs: theme.spacing(4), sm: theme.spacing(0) },
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={11} md={11} lg={11}>
              <FormControl
                fullWidth
                margin="normal"
                sx={{ marginLeft: { xs: theme.spacing(0), sm: theme.spacing(2) } }}
              >
                <InputLabel id="country-multiple-chip-label">Country</InputLabel>
                <Select
                  labelId="country-multiple-chip-label"
                  id="country-multiple-chip"
                  multiple
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  input={<OutlinedInput id="select-multiple-chip" label="Country" />}
                  renderValue={selected => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map(value => (
                        <Chip
                          onMouseDown={event => {
                            event.stopPropagation();
                          }}
                          key={value}
                          label={value}
                          onDelete={() => handleCountryDelete(value)}
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {countries.map(country => (
                    <MenuItem
                      key={country}
                      value={country}
                      style={getStyles(country, selectedCountry, theme)}
                    >
                      {country}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={11} md={11} lg={11}>
              <FormControl
                fullWidth
                margin="normal"
                sx={{ marginLeft: { xs: theme.spacing(0), sm: theme.spacing(2) } }}
              >
                <InputLabel id="category-multiple-chip-label">Category</InputLabel>
                <Select
                  labelId="category-multiple-chip-label"
                  id="category-multiple-chip"
                  multiple
                  value={selectedCategories}
                  onChange={handleCategoryChange}
                  input={<OutlinedInput id="select-multiple-chip" label="Category" />}
                  renderValue={selected => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map(value => {
                        if (value === 'Recycler') {
                          return (
                            <Chip
                              key={value}
                              label={value}
                              onMouseDown={event => event.stopPropagation()}
                              onDelete={() => handleCategoryDelete(value)}
                              icon={<RecyclingIcon sx={{ color: theme.palette.primary.dark }} />}
                            />
                          );
                        }
                        if (value === 'Upcycler') {
                          return (
                            <Chip
                              key={value}
                              label={value}
                              icon={<HealthAndSafetyIcon />}
                              onMouseDown={event => event.stopPropagation()}
                              onDelete={() => handleCategoryDelete(value)}
                            />
                          );
                        }
                        return (
                          <Chip
                            key={value}
                            label={value}
                            icon={<BatteryCharging20RoundedIcon />}
                            onMouseDown={event => event.stopPropagation()}
                            onDelete={() => handleCategoryDelete(value)}
                          />
                        );
                      })}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {categories.map(category => (
                    <MenuItem
                      key={category}
                      value={category}
                      style={getStyles(category, selectedCategories, theme)}
                    >
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {filteredStakeholderMapping.map((stakeholder, index) => (
            <Card
              key={index}
              sx={{
                marginBottom: theme.spacing(2),
                marginLeft: { xs: theme.spacing(0), sm: theme.spacing(2) },
              }}
            >
              <CardContent>
                <Typography variant="h5" component="h2">
                  {stakeholder.company}
                </Typography>
                <Typography color="textSecondary">
                  {stakeholder.city + ', ' + stakeholder.country}
                </Typography>
                <Typography color="textSecondary">
                  {stakeholder.startYear && `Started on - ${stakeholder.startYear}`}
                </Typography>
                <Typography color="textSecondary">
                  {TEXT_CONTENTS.STAKEHOLDER_MAP.FACILITY_TYPE} - {stakeholder.category}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" onClick={() => window.open(stakeholder.link, '_blank')}>
                  Learn More
                </Button>
              </CardActions>
            </Card>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Stakeholders;
