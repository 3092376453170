import React, { memo, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  Battery,
  Cell,
  Home,
  Material,
  CellDetails,
  DesignCheck,
  Clients,
  Inventory,
} from 'src/containers';
import Approvals from 'src/containers/Approvals';
import { FirebaseContext } from 'src/components/Contexts';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button, DialogActions, useTheme } from '@mui/material';

import { Layout } from 'src/components/Structure';
import MarketInsights from 'src/containers/MarketInsights';
import BatteryLibraryTableWrapper from 'src/containers/MasterData';
import { TEXT_CONTENTS } from 'src/constants';
import ROUTES from 'src/constants/routes';
import { BatteryPackModelType } from 'src/@types/BatteryDatabase';
import BatteryLibraryOverview from 'src/containers/BatteryLibraryOverview';
import BatteryLibrary from 'src/containers/BatteryLibrary';
import Lots from 'src/containers/Lots';

interface Props {
  authenticatedRoutes?: string[];
}

const RouteConfig = [
  {
    path: ROUTES.HOME,
    component: <Home />,
  },
  {
    path: ROUTES.BATTERY_ANALYTICS,
    component: <Battery />,
  },
  {
    path: ROUTES.MATERIAL_ANALYTICS,
    component: <Material />,
  },
  {
    path: ROUTES.CELL_ANALYTICS,
    component: <Cell />,
  },
  {
    path: ROUTES.MARKET_INSIGHTS,
    component: <MarketInsights />,
  },
  {
    path: ROUTES.CELL_DETAILS,
    component: <CellDetails />,
  },
  {
    path: ROUTES.APPROVALS,
    component: <Approvals />,
  },
  {
    path: ROUTES.UPCYCLING_CHECK,
    component: <DesignCheck />,
  },
  {
    path: ROUTES.EV_MASTER_DATA,
    component: <BatteryLibraryTableWrapper batteryPackType={BatteryPackModelType.EV} />,
  },
  {
    path: ROUTES.MM_MASTER_DATA,
    component: <BatteryLibraryTableWrapper batteryPackType={BatteryPackModelType.MM} />,
  },
  {
    path: ROUTES.PT_MASTER_DATA,
    component: <BatteryLibraryTableWrapper batteryPackType={BatteryPackModelType.PT} />,
  },
  {
    path: ROUTES.CLIENTS,
    component: <Clients />,
  },
  {
    path: ROUTES.BATTERY_LIBRARY,
    component: <BatteryLibraryOverview />,
  },
  {
    path: ROUTES.EV_BATTERY_LIBRARY,
    component: (
      <BatteryLibrary
        title={TEXT_CONTENTS.EV_BATTERY_LIBRARY.TITLE}
        batteryCategory={TEXT_CONTENTS.EV_BATTERY_LIBRARY.BATTERY_CATEGORY}
        description={TEXT_CONTENTS.EV_BATTERY_LIBRARY.DESCRIPTION}
      />
    ),
  },
  {
    path: ROUTES.MM_PT_BATTERY_LIBRARY,
    component: (
      <BatteryLibrary
        title={TEXT_CONTENTS.MM_PT_BATTERY_LIBRARY.TITLE}
        batteryCategory={TEXT_CONTENTS.MM_PT_BATTERY_LIBRARY.BATTERY_CATEGORY}
        description={TEXT_CONTENTS.MM_PT_BATTERY_LIBRARY.DESCRIPTION}
      />
    ),
  },
  {
    path: ROUTES.INVENTORY,
    component: <Inventory />,
  },
  {
    path: ROUTES.LOTS,
    component: <Lots />,
  },
];

const RestrictedPageDialog: React.FC<{
  open: boolean;
}> = ({ open }) => {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" color={theme.palette.error.dark}>
        Restricted Access
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {TEXT_CONTENTS.COMMON.ACCOUNT_APPROVAL_NEEDED_MESSAGE}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{ color: theme.palette.primary.dark }} href={'/battery-map'}>
          Open Battery Map
        </Button>
        <Button sx={{ color: theme.palette.primary.dark }} href={'/news'}>
          Explore Battery News
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RestrictedPageWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { approved } = useContext(FirebaseContext);
  const [open] = React.useState(!approved);

  if (approved) {
    return <>{children}</>;
  }

  return <RestrictedPageDialog open={open} />;
};

const AuthenticatedRoutes: React.FC<Props> = () => {
  return (
    <Routes>
      {RouteConfig.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            element={
              <Layout>
                <RestrictedPageWrapper>{route.component}</RestrictedPageWrapper>
              </Layout>
            }
          />
        );
      })}
    </Routes>
  );
};
export default memo(AuthenticatedRoutes);
