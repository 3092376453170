export const BATTERY_CATEGORIES = ['Total', 'EV', 'PT', 'MM'];

export const BATTERY_WEIGHTS = {
  Total: 455.12,
  EV: 454,
  PT: 0.62,
  MM: 0.5,
};

export const CELL_WEIGHTS = {
  Total: 1.25,
  EV: 0.38,
  PT: 0.46,
  MM: 0.41,
};

export const INVENTORY_TABLE_HEADERS_MAPPING = {
  batteryModel: 'Battery Model',
  area: 'Area',
  location: 'Location',
  granularity: 'Granularity',
  totalUnits: 'Total Units',
};
