import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';

import StyledDataGrid, { renderTableCell } from 'src/components/Structure/SyledDataGrid';
import { GridColDef, GridFilterModel } from '@mui/x-data-grid';
import { GET_INVENTORY } from 'src/graphql/queries';
import { Inventory } from 'src/@types/Inventory';
import { INVENTORY_TABLE_HEADERS_MAPPING } from 'src/constants/inventory';
import { TEXT_CONTENTS } from 'src/constants';

interface GetInventory {
  getInventory: Inventory[];
}

const InventoryTable: React.FC = () => {
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<Inventory[]>([]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const { loading, error, data } = useQuery<GetInventory>(GET_INVENTORY);

  const defineColumns = useCallback((dataSample: Inventory): GridColDef[] => {
    const tableKeys = Object.keys(dataSample).filter(key => key !== '__typename' && key !== 'id');
    const tableKeysWithId = [
      { field: 'id', headerName: 'ID', minWidth: 175, flex: 0.5, renderTableCell },
    ].concat(
      tableKeys.map(key => ({
        field: key,
        headerName:
          INVENTORY_TABLE_HEADERS_MAPPING[key as keyof typeof INVENTORY_TABLE_HEADERS_MAPPING],
        flex: 1,
        minWidth: 175,
        renderTableCell,
      }))
    );
    return tableKeysWithId;
  }, []);

  useEffect(() => {
    if (data?.getInventory) {
      const initialRows = data.getInventory.map((item, index) => ({
        ...item,
        id: index + 1,
      }));
      setColumns(defineColumns(data.getInventory[0]));
      setRows(initialRows);
    }
  }, [data, defineColumns]);

  if (loading) return <Typography variant="body1">{TEXT_CONTENTS.COMMON.LOADING}</Typography>;

  if (error) return <Typography variant="body1">{TEXT_CONTENTS.COMMON.ERROR}</Typography>;

  return (
    <Box>
      <StyledDataGrid
        rows={rows}
        columns={columns}
        filterModel={filterModel}
        onFilterModelChange={model => {
          setFilterModel(model);
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 10, 25]}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default InventoryTable;
