import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Modal, Box, Typography, TextField, Slider, Rating, Button } from '@mui/material';
import { useMutation } from '@apollo/client';
import { useFeedbackContext } from 'src/components/Contexts/FeedbackContext';
import { EARLY_USERS_FEEDBACK } from 'src/graphql/mutations/feedback';
import { TEXT_CONTENTS } from 'src/constants';

const FeedbackModal: React.FC = () => {
  const [formData, setFormData] = useState({
    interestedIn: '',
    missingFeatures: '',
    suggestedPrice: 0,
    usageFrequency: '',
    adaptionToFeatures: '',
    rating: 0,
  });
  const [errors, setErrors] = useState({
    ratingError: false,
    sliderError: false,
  });
  const { showFeedbackModal, setShowFeedbackModal, setIsFeedbackProvided } = useFeedbackContext();

  const [storeFeedback] = useMutation(EARLY_USERS_FEEDBACK);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSliderChange = (event: Event, value: number | number[]) => {
    setFormData({
      ...formData,
      suggestedPrice: Array.isArray(value) ? value[0] : value,
    });
  };

  const handleRatingChange = (event: React.ChangeEvent<unknown>, newValue: number | null) => {
    setFormData({
      ...formData,
      rating: newValue || 0,
    });
  };

  const validateFields = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (formData.rating === 0) {
      newErrors.ratingError = true;
      isValid = false;
    } else {
      newErrors.ratingError = false;
    }

    if (formData.suggestedPrice === 0) {
      newErrors.sliderError = true;
      isValid = false;
    } else {
      newErrors.sliderError = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isFormValid = validateFields();

    if (isFormValid) {
      try {
        await storeFeedback({ variables: { earlyUsersFeedback: formData } });
        setShowFeedbackModal(false);
        setIsFeedbackProvided(true);
      } catch (error) {
        console.error('Error submitting feedback:', error);
      }
    }
  };

  const handleClose = () => {
    setShowFeedbackModal(false);
  };

  const modalStyle = {
    position: 'absolute',
    borderRadius: '10px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', sm: '90%', md: '70%', lg: '50%' },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal open={showFeedbackModal}>
      <Box sx={modalStyle} component="form" onSubmit={handleSubmit}>
        <Typography variant="h6" component="h4" gutterBottom>
          {TEXT_CONTENTS.FEEDBACK.MODAL.TITLE}
        </Typography>
        <TextField
          required
          color="secondary"
          fullWidth
          name="interestedIn"
          label={TEXT_CONTENTS.FEEDBACK.MODAL.INTERESTED_IN}
          margin="normal"
          value={formData.interestedIn}
          onChange={handleChange}
        />
        <TextField
          color="secondary"
          required
          fullWidth
          name="missingFeatures"
          label={TEXT_CONTENTS.FEEDBACK.MODAL.MISSING_FEATURES}
          margin="normal"
          value={formData.missingFeatures}
          onChange={handleChange}
        />
        <Typography gutterBottom>{TEXT_CONTENTS.FEEDBACK.MODAL.SUGGESTED_PRICE}</Typography>
        <Slider
          value={formData.suggestedPrice}
          onChange={handleSliderChange}
          step={50}
          min={0}
          max={20000}
          valueLabelDisplay="auto"
        />
        {errors.sliderError && <Typography color="error">This field is required.</Typography>}
        <TextField
          color="secondary"
          required
          fullWidth
          name="usageFrequency"
          label={TEXT_CONTENTS.FEEDBACK.MODAL.USAGE_FREQUENCY}
          value={formData.usageFrequency}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          color="secondary"
          required
          fullWidth
          name="adaptionToFeatures"
          label={TEXT_CONTENTS.FEEDBACK.MODAL.ADAPTION_TO_FEATURES}
          onChange={handleChange}
          margin="normal"
        />
        <Typography gutterBottom>{TEXT_CONTENTS.FEEDBACK.MODAL.ASK_RATING}</Typography>
        <Rating name="rating" value={formData.rating} onChange={handleRatingChange} />
        {errors.ratingError && (
          <Typography color="error">{TEXT_CONTENTS.FEEDBACK.MODAL.RATING_ERROR}</Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
            Submit Feedback
          </Button>
          <Button onClick={handleClose} variant="outlined" color="primary" sx={{ mt: 2 }}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FeedbackModal;
