import { ApexOptions } from 'apexcharts';
import React, { useCallback, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useDataInsights } from '../../hooks';
import { TEXT_CONTENTS } from 'src/constants';
import { useTheme } from '@mui/material';
import { ChartContainer } from 'src/components/Structure';
import Box from '@mui/material/Box';
import { DEFAULT_MATERIALS_VALUE, MATERIALS_MAPPING } from 'src/constants/materialsValue';
import { roundToTwoDecimals } from 'src/utils/Number';
import { Currency } from 'src/@types/ChartSettings/MaterialsValue';
import { CellOrBatteryComponent, ChartProps, CurrencyRate } from 'src/@types/DataTypes';
import ChartTabs from 'src/components/Charts/MaterialInsights/ChartTabs';

interface props extends ChartProps {
  displayCurrency?: Currency;
  selectedBatteryCategory: string;
}

const ComponentsAvailable: React.FC<props> = ({
  displayCurrency = Currency.EUR,
  chartId = 'components-available',
  selectedBatteryCategory,
}) => {
  const theme = useTheme();
  const height = theme.spacing(50);
  const { materialInsights, currencyRates } = useDataInsights();

  const accumulateMaterials = useCallback(
    (materials: CellOrBatteryComponent[]) => {
      let filteredData = materials;
      if (selectedBatteryCategory !== 'Total') {
        filteredData = materials.filter(
          ({ batteryCategory }) => batteryCategory === selectedBatteryCategory
        );
      }
      return filteredData.reduce<{ [label: string]: number }>((acc, { label, value }) => {
        acc[label] = (acc[label] || 0) + value;
        return acc;
      }, {});
    },
    [selectedBatteryCategory]
  );

  const materialAccumulator = accumulateMaterials(materialInsights?.batteryComponents || []);

  const categories = materialAccumulator && Object.keys(materialAccumulator);
  const data = materialAccumulator && Object.values(materialAccumulator);

  const [options, setOptions] = useState<ApexOptions>({
    chart: {
      id: chartId,
      fontFamily: theme.typography.fontFamily,
      background: theme.palette.background.paper,
      toolbar: { show: false },
    },
    legend: { show: false },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '12px',
        fontWeight: 'lighter',
        colors: ['#000000'],
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
      },
    },
    colors: [theme.palette.primary.main],
    yaxis: {
      title: { text: TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.COMPONENTS.YAXIS + displayCurrency },
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          fontSize: '15px',
        },
      },
    },
    tooltip: {
      enabled: false,
    },
  });

  const [series, setSeries] = useState<ApexAxisChartSeries>([
    {
      name: TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.COMPONENTS.YAXIS_KG,
      data: data,
    },
  ]);
  const [value, setValue] = React.useState(0);

  const getSeriesWhenCustomInputToggled = useCallback(() => {
    const accumulatedMaterials = accumulateMaterials(materialInsights?.batteryComponents || []);
    const data = Object.entries(accumulatedMaterials).map(([label, value]) => {
      const metalPrice = materialInsights?.metalsPrice.find(metal => {
        const materialName = MATERIALS_MAPPING[label] || label;
        return metal.name === materialName;
      });
      let price = metalPrice?.price ? metalPrice?.price : DEFAULT_MATERIALS_VALUE[label];
      if (displayCurrency === Currency.EUR) {
        const currencyRate = currencyRates?.find(
          currency => currency.currency === displayCurrency
        ) as CurrencyRate;
        price = price * currencyRate?.rate;
      }

      return { label, value: roundToTwoDecimals(value * price) };
    });
    return data;
  }, [
    materialInsights?.batteryComponents,
    materialInsights?.metalsPrice,
    displayCurrency,
    accumulateMaterials,
    currencyRates,
  ]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      const componentAccumulator = accumulateMaterials(materialInsights?.batteryComponents || []);
      setSeries(prevSeries => [
        {
          ...prevSeries[0],
          data: Object.values(componentAccumulator) as number[],
        },
      ]);
      setOptions(prevOptions => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: Object.keys(componentAccumulator) as string[],
        },
      }));
    } else {
      const dataOnTabChange = getSeriesWhenCustomInputToggled();
      const categories = dataOnTabChange?.map(({ label }) => label);
      const data = dataOnTabChange?.map(({ value }) => value);
      setSeries(() => [
        {
          name: TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.CELL.YAXIS_USD + displayCurrency,
          data: data as number[],
        },
      ]);
      setOptions(prevOptions => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: categories as string[],
        },
      }));
    }
  };

  useEffect(() => {
    if (value === 1) {
      const dataOnTabChange = getSeriesWhenCustomInputToggled();
      const categories = dataOnTabChange?.map(({ label }) => label);
      const data = dataOnTabChange?.map(({ value }) => value);
      setSeries(prevSeries => [
        {
          ...prevSeries[0],
          data: data as number[],
        },
      ]);
      setOptions(prevOptions => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: categories as string[],
        },
      }));
    } else {
      const componentAccumulator = accumulateMaterials(materialInsights?.batteryComponents || []);
      setSeries(() => [
        {
          name: TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.COMPONENTS.YAXIS_KG,
          data: Object.values(componentAccumulator) as number[],
        },
      ]);
      setOptions(prevOptions => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: Object.keys(componentAccumulator) as string[],
        },
      }));
    }
  }, [
    getSeriesWhenCustomInputToggled,
    materialInsights?.batteryComponents,
    selectedBatteryCategory,
    accumulateMaterials,
    displayCurrency,
    value,
  ]);

  return (
    <Box>
      <ChartTabs value={value} currency={displayCurrency} onTabChange={handleTabChange} />
      <ChartContainer height={height} dataNotAvailable={series.length === 0}>
        <Chart
          options={options}
          series={series}
          type="bar"
          height={height}
          colors={[theme.palette.primary.light, theme.palette.primary.dark]}
          style={{
            color: theme.palette.secondary.main,
            fontFamily: theme.typography.fontFamily,
          }}
        />
      </ChartContainer>
    </Box>
  );
};

export default ComponentsAvailable;
