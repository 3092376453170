import React, { MouseEvent } from 'react';
import { Card, CardActionArea, CardContent, Typography, useTheme } from '@mui/material';

const MasterDataOemCard: React.FC<{
  name: string;
  count: number;
  logo: string;
  handleClick: (event: MouseEvent<HTMLButtonElement>) => void;
}> = ({ name, count, logo, handleClick }): JSX.Element => {
  const theme = useTheme();
  return (
    <Card
      elevation={5}
      sx={{
        margin: theme.spacing(0.5),
        '&:hover': {
          backgroundColor: theme.palette.primary.light,
          transform: 'scale(1.02)',
          transition: theme.transitions.create(['background-color', 'transform'], {
            duration: theme.transitions.duration.complex,
          }),
        },
        height: '100%',
      }}
    >
      <CardActionArea
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
        onClick={handleClick}
      >
        <CardContent>
          <img src={logo} alt={name} width="90" height="50" />
          <Typography gutterBottom variant="body1" component="p" sx={{ textAlign: 'center' }}>
            {name} ({count})
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default MasterDataOemCard;
