import React, { createContext, ReactNode, useState } from 'react';
import { Currency } from 'src/@types/ChartSettings/MaterialsValue';

interface UserSettings {
  currency: Currency;
  darkMode: boolean;
  setCurrency: (currency: Currency) => void;
  setDarkMode: (darkMode: boolean) => void;
}

const defaultSettings: UserSettings = {
  currency: Currency.EUR,
  darkMode: false,
  setCurrency: () => {
    console.error('unimplemented context userSettings');
  },
  setDarkMode: () => {
    console.error('unimplemented context userSettings');
  },
};

const UserSettingsContext = createContext<UserSettings>(defaultSettings);

interface UserSettingsProviderProps {
  children: ReactNode;
}

const UserSettingsProvider = ({ children }: UserSettingsProviderProps) => {
  const [currency, setCurrency] = useState<Currency>(defaultSettings.currency);
  const [darkMode, setDarkMode] = useState<boolean>(defaultSettings.darkMode);

  return (
    <UserSettingsContext.Provider value={{ currency, darkMode, setCurrency, setDarkMode }}>
      {children}
    </UserSettingsContext.Provider>
  );
};

export { UserSettingsProvider };

export default UserSettingsContext;
