import React, { createContext, useEffect, useState } from 'react';
import Firebase from './Firebase';
import { fetchAndActivate, getAll } from 'firebase/remote-config';
import { DefaultObject } from 'src/@types/GenericTypes';
import { GET_USER_DETAILS } from 'src/graphql/mutations/auth';
import { useLazyQuery } from '@apollo/client';

export interface FirebaseContextInterface {
  authenticated: boolean;
  approved: boolean;
  userType: string | null;
  flags: DefaultObject;
  email: string | null;
  firebase: Firebase;
  onSuccessfulAuthentication: (email: string) => void;
}

const FirebaseContext = createContext<FirebaseContextInterface | DefaultObject>({});
const FirebaseConsumer = FirebaseContext.Consumer;

const defaultConfig = {
  // e.g. underMaintenance: null,
};

const determineFlagType = (value: string) => {
  if (value === 'true') return true;
  if (value === 'false') return false;

  return value.toString();
};

type FirebaseProviderProps = {
  children: React.ReactNode;
  firebase: Firebase;
};

const FirebaseProvider = ({ children, firebase }: FirebaseProviderProps) => {
  const remoteConfig = firebase.getRemoteConfig();
  const [flags, setFlags] = useState(defaultConfig);
  const [authenticated, setAuthenticated] = useState(false);
  const [approved, setApproved] = useState(false);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState<string | null>(null);
  const [userType, setUserType] = useState<string | null>(null);
  const [getUserDetails, { data }] = useLazyQuery(GET_USER_DETAILS);

  const handleSuccessfulAuthentication = (email: string) => {
    setAuthenticated(true);
    setEmail(email);
  };

  useEffect(() => {
    if (data && data.getUserDetails) {
      const userDetails = data.getUserDetails;

      if (userDetails.approved) {
        setApproved(true);
      }
      if (userDetails.userType) {
        setUserType(userDetails.userType);
      }
    }
  }, [data]);

  useEffect(() => {
    remoteConfig.defaultConfig = flags;
    remoteConfig.settings.minimumFetchIntervalMillis = 0;

    const fetchFlags = async () => {
      await fetchAndActivate(remoteConfig);
      const remoteFlags = await getAll(remoteConfig);
      const newFlags: DefaultObject = {};

      Object.entries(remoteFlags).forEach(([key, config]) => {
        newFlags[key] = determineFlagType(config.asString());
      });

      setFlags(newFlags);
    };

    fetchFlags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    firebase.getAuth().onAuthStateChanged(user => {
      setAuthenticated(!!user);
      if (user?.uid) {
        getUserDetails();
      }
      user?.email && setEmail(user?.email);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <></>;

  return (
    <FirebaseContext.Provider
      value={{
        authenticated,
        approved,
        userType,
        flags,
        email,
        firebase,
        onSuccessfulAuthentication: handleSuccessfulAuthentication,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export { FirebaseProvider, FirebaseConsumer };

export default FirebaseContext;
