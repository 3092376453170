import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { Menu, MenuProps, MenuItem, Typography, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

interface NavBarMenuItemProps {
  name: string;
  url: string;
  menuType: string;
  onClickHandler: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    url: string,
    menuType: string
  ) => void;
}

const NavBarMenuItem: React.FC<NavBarMenuItemProps> = ({ name, url, onClickHandler, menuType }) => {
  const theme = useTheme();
  const location = useLocation();

  const isActive = location.pathname === url;
  const color = isActive ? theme.palette.primary.dark : theme.palette.secondary.dark;

  return (
    <MenuItem
      onClick={e => onClickHandler(e, url, menuType)}
      sx={{
        color: color,
        '&:hover': {
          backgroundColor: theme.palette.action.selected,
        },
      }}
    >
      <Typography textAlign="left">{name}</Typography>
    </MenuItem>
  );
};

export { NavBarMenuItem };
export default StyledMenu;
