import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';

interface ChartTabsProps {
  value: number;
  currency?: string;
  onTabChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const ChartTabs: React.FC<ChartTabsProps> = ({ value, currency, onTabChange }) => {
  const theme = useTheme();
  const tabStyle = {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 1,
    cursor: 'pointer',
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        width: '100%',
        marginBottom: theme.spacing(2),
      }}
    >
      <Tabs
        value={value}
        variant="scrollable"
        scrollButtons={true}
        sx={{ display: 'flex', float: 'right' }}
      >
        <Tab label={'Value in Kg'} sx={tabStyle} onClick={e => onTabChange(e, 0)} />
        <Tab label={`Value in ${currency}`} sx={tabStyle} onClick={e => onTabChange(e, 1)} />
      </Tabs>
    </Box>
  );
};

export default ChartTabs;
