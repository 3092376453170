import { gql } from '@apollo/client';

const GET_SETTINGS = gql`
  query DataInsights {
    getUserSettings {
      currency
      darkMode
    }
  }
`;

export default GET_SETTINGS;
