import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Layout } from 'src/components/Structure';
import { News, Stakeholders } from 'src/containers';
import { StakeholderMappingProvider } from 'src/components/Contexts/StakeholderContext';
import ROUTES from 'src/constants/routes';

const PreviewRoutes = () => {
  return (
    <Routes>
      <Route
        path={ROUTES.NEWS}
        element={
          <Layout>
            <News />
          </Layout>
        }
      />
      <Route
        path={ROUTES.BATTERY_MAP}
        element={
          <Layout>
            <StakeholderMappingProvider>
              <Stakeholders />
            </StakeholderMappingProvider>
          </Layout>
        }
      />
    </Routes>
  );
};

export default PreviewRoutes;
