import { ApexOptions } from 'apexcharts';
import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { useTheme } from '@mui/material';
import { useDataInsights } from 'src/components/hooks';
import { ChartContainer } from 'src/components/Structure';
import { ChartProps, LabelValue } from 'src/@types/DataTypes';

interface Props extends ChartProps {
  client?: string;
  batteryCategory?: string;
  batteryType?: string;
}

const CellCounts: React.FC<Props> = ({
  chartId = '',
  client = 'All Clients',
  batteryType = 'Total',
  batteryCategory = 'Total',
}) => {
  const theme = useTheme();
  const { cellInsights } = useDataInsights();
  const testedCells =
    cellInsights?.testedCells
      .filter(cell => {
        if (client === 'All Clients') return true;
        return cell.client === client;
      })
      .filter(cell => {
        if (batteryCategory === 'Total') return true;
        return cell.batteryCategory === batteryCategory;
      })
      .filter(cell => {
        if (batteryType === 'Total') return true;
        return cell.batteryType === batteryType;
      }) || [];
  const height = theme.spacing(61);
  const series: { name: string; data: number[] }[] = [];

  const aggregateData = new Map<string, Map<string, number>>();

  interface OldTestedCells {
    manufacturer: string;
    types: LabelValue[];
  }

  testedCells.forEach(cell => {
    if (!aggregateData.has(cell.manufacturer)) {
      aggregateData.set(cell.manufacturer, new Map<string, number>());
    }
    const manufacturerLabels = aggregateData.get(cell.manufacturer);
    if (!manufacturerLabels) return;

    cell.types.forEach(type => {
      const currentValue = manufacturerLabels.get(type.label) || 0;
      manufacturerLabels.set(type.label, currentValue + type.value);
    });
  });

  const formattedData: OldTestedCells[] = Array.from(aggregateData).map(
    ([manufacturer, labelsMap]) => ({
      manufacturer,
      types: Array.from(labelsMap, ([label, value]): LabelValue => ({ label, value })),
      __typename: 'TestedCells',
    })
  );

  formattedData.forEach(({ types }, index) => {
    types.forEach(({ label, value }) => {
      const data = new Array(formattedData.length).fill(0);
      data[index] = value;
      series.push({
        name: label,
        data,
      });
    });
  });

  const [options] = useState<ApexOptions>({
    chart: {
      id: chartId,
      type: 'bar',
      height: 350,
      background: theme.palette.background.paper,
      stacked: true,
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '13px',
              fontWeight: 900,
            },
          },
        },
      },
    },
    xaxis: {
      categories: formattedData.map(({ manufacturer }) => manufacturer),
    },
    colors: [
      '#80c7fd',
      '#008FFB',
      '#80f1cb',
      '#00E396',
      '#52FF94',
      '#F39A00',
      '#800080',
      '#FF7F50',
      '#FF69B4',
    ],
    legend: {
      position: 'right',
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
  });

  return (
    <ChartContainer height={height} dataNotAvailable={series.length === 0}>
      <Chart options={options} series={series} type="bar" height={height} />
    </ChartContainer>
  );
};

export default CellCounts;
