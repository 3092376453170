import React, { ReactNode } from 'react';
import { Box, Button, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Logo from 'src/assets/svgs/logo-black-color.svg';
import { TEXT_CONTENTS } from 'src/constants';
import { useTheme } from '@mui/system';

interface Props {
  children: ReactNode;
}

const CommonLayout: React.FC<Props> = ({ children }): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        height: { xs: 'auto', sm: 'auto', md: '40vh', lg: '100vh' },
      }}
    >
      <Grid
        item
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: {
            xs: 'calc(var(--vh, 1vh) * 35)',
            sm: 'calc(var(--vh, 1vh) * 30)',
            md: '100vh',
            lg: '100vh',
          },
          marginLeft: { xs: theme.spacing(1), sm: theme.spacing(1), md: '0', lg: '0' },
          marginRight: { xs: theme.spacing(1), sm: theme.spacing(1), md: '0', lg: '0' },
        }}
        xs={12}
        sm={12}
        md={6}
        lg={6}
      >
        <Box
          component="img"
          src={Logo}
          alt={TEXT_CONTENTS.IMAGES_ALT_TEXT.CIRCULI_ION_LOGO}
          sx={{
            paddingBottom: { xs: theme.spacing(2), sm: theme.spacing(2), md: theme.spacing(5) },
            width: 146,
          }}
        />
        <Box>
          <Typography
            variant={isMobile ? 'h4' : 'h1'}
            sx={{
              paddingBottom: { xs: theme.spacing(0), sm: theme.spacing(1) },
            }}
          >
            {TEXT_CONTENTS.LOGIN.TITLE}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              paddingBottom: theme.spacing(6),
            }}
          >
            {TEXT_CONTENTS.LOGIN.CAPTION}
          </Typography>
        </Box>
        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}>
          <Typography variant="body2">
            <Link
              href={TEXT_CONTENTS.LOGIN.LINK_TO_MAIN_PAGE_URL}
              underline="hover"
              sx={{ color: 'text.secondary' }}
            >
              {TEXT_CONTENTS.LOGIN.LINK_TO_MAIN_PAGE_TEXT}
            </Link>
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        sx={{
          backgroundColor: theme.palette.primary.light,
          borderTopLeftRadius: { xs: '5px', sm: '5px', md: '0px', lg: '0px' },
          borderTopRightRadius: { xs: '5px', sm: '5px', md: '0px', lg: '0px' },
          minHeight: { xs: '85vh', sm: '50vh', md: '60vh', lg: '60vh' },
          display: 'flex',
          flexDirection: 'column',
          flexGrow: '1',
          justifyContent: { xs: 'space-between', sm: 'space-between', md: 'center', lg: 'center' },
          alignItems: 'center',
        }}
      >
        {children}
      </Grid>
      <Button
        variant="contained"
        color="primary"
        href={TEXT_CONTENTS.LOGIN.LINK_TO_MAIN_PAGE_URL}
        fullWidth
        sx={{
          display: { xs: 'flex', sm: 'flex', md: 'none' },
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '0px',
          height: {
            xs: 'calc(var(--vh, 1vh) * 5)',
            sm: 'calc(var(--vh, 1vh) * 5)',
            md: '40vh',
            lg: '100vh',
          }, //, md: '100vh' },
        }}
      >
        <ArrowBackIcon sx={{ marginRight: theme.spacing(1) }} />
        <Typography variant="body2" color="#FFFFFF">
          {TEXT_CONTENTS.LOGIN.LINK_TO_MAIN_PAGE_TEXT}
        </Typography>
      </Button>
    </Grid>
  );
};
export default CommonLayout;
