import { gql } from '@apollo/client';

export const GET_INITIAL_STATE = gql`
  query GetInitialState {
    seed @client
  }
`;

export const GET_RESET_STATE = gql`
  query GetResetState {
    seed @client
  }
`;
