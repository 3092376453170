const HOME = '/';
const BATTERY_ANALYTICS = '/battery-analytics';
const MATERIAL_ANALYTICS = '/material-analytics';
const CELL_ANALYTICS = '/cell-analytics';
const BATTERY_MAP = '/battery-map';
const CELL_DETAILS = '/cell-details';
const MARKET_INSIGHTS = '/market-insights';
const UPCYCLING_CHECK = '/upcycling-check';
const EV_MASTER_DATA = '/ev-master-data';
const MM_MASTER_DATA = '/mm-master-data';
const PT_MASTER_DATA = '/pt-master-data';
const CLIENTS = '/clients/:id';
const NEWS = '/news';
const LOGIN = '/login';
const REGISTER = '/register';
const APPROVALS = '/approvals';
const INVENTORY = '/track-inventory';
const LOTS = '/track-lots';
const BATTERY_LIBRARY = '/battery-library-overview';
const EV_BATTERY_LIBRARY = '/ev-battery-library';
const MM_PT_BATTERY_LIBRARY = '/mm-pt-battery-library';
const BATTERY_PACK_DETAILS = '/battery-pack-details/:manufacturer';

const ROUTES = {
  HOME,
  BATTERY_ANALYTICS,
  MATERIAL_ANALYTICS,
  CELL_ANALYTICS,
  BATTERY_MAP,
  CELL_DETAILS,
  MARKET_INSIGHTS,
  UPCYCLING_CHECK,
  EV_MASTER_DATA,
  MM_MASTER_DATA,
  PT_MASTER_DATA,
  BATTERY_LIBRARY,
  EV_BATTERY_LIBRARY,
  MM_PT_BATTERY_LIBRARY,
  CLIENTS,
  NEWS,
  LOGIN,
  REGISTER,
  APPROVALS,
  INVENTORY,
  LOTS,
  BATTERY_PACK_DETAILS,
};

export default ROUTES;
