import React, { useMemo } from 'react';
import { useDataInsights } from '../../hooks';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import { NumberBox } from 'src/components/Structure/NumberBox';
import tendencyUp from 'src/assets/svgs/tendency-up-icon.svg';
import tendencyDown from 'src/assets/svgs/tendency-down-icon.svg';
import { ToggleButtonValues } from 'src/components/Charts/Filters/BatteryFilters';

interface TendencyMap {
  label: string;
  percentageChange: number;
  value: number;
}

const Tendency: React.FC<{
  row: boolean;
  selectedBatteryType: string;
  selectedBatteryCategory: string;
  selectedToggleValue?: ToggleButtonValues;
}> = ({
  row,
  selectedBatteryType,
  selectedBatteryCategory,
  selectedToggleValue = ToggleButtonValues.WEIGHT_IN_KG,
}) => {
  const theme = useTheme();
  const { batteryInsights } = useDataInsights();

  const yearlyAggregatedData = useMemo(() => {
    let filteredData = batteryInsights?.batteryDataInsights;
    if (selectedBatteryType !== 'Total') {
      filteredData = batteryInsights?.batteryDataInsights.filter(
        ({ batteryType, batteryCategory }) =>
          batteryType === selectedBatteryType && batteryCategory === selectedBatteryCategory
      );
    } else if (selectedBatteryCategory !== 'Total' && selectedBatteryType === 'Total') {
      filteredData = batteryInsights?.batteryDataInsights.filter(
        ({ batteryCategory }) => batteryCategory === selectedBatteryCategory
      );
    }
    return (filteredData || []).reduce(
      (acc, curr) => {
        const year = curr.year;
        const valueToAdd =
          selectedToggleValue === ToggleButtonValues.COUNT
            ? curr.numberBatteryPacks
            : Math.round(curr.weightKg);

        if (!acc[year]) {
          acc[year] = valueToAdd ?? 0;
        } else {
          acc[year] += valueToAdd ?? 0;
        }
        return acc;
      },
      {} as { [key: string]: number }
    );
  }, [batteryInsights, selectedBatteryType, selectedBatteryCategory, selectedToggleValue]);

  const updatedData: TendencyMap[] = Object.keys(yearlyAggregatedData)
    .sort()
    .map((year, index, arr) => {
      const value = yearlyAggregatedData[year];
      let percentageChange = 0;
      if (index > 0) {
        const prevYearValue = yearlyAggregatedData[arr[index - 1]];
        if (prevYearValue !== 0) {
          percentageChange = ((value - prevYearValue) / prevYearValue) * 100;
        }
      }
      return {
        label: index === Object.keys(yearlyAggregatedData).length - 1 ? `${year} - ytd` : year,
        value,
        percentageChange,
      };
    });

  return (
    <Grid container spacing={2} direction={row ? 'row' : 'column'}>
      {updatedData.map(({ label, value, percentageChange }, index) => (
        <Grid item xs={6} key={index}>
          <NumberBox>
            <Grid height="100%" container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Grid container spacing={0.5} direction="column" justifyItems="space-between">
                  <Grid item xs={2}>
                    <Typography variant="h6">{label}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="grey"
                      //   sx={{ fontSize: row ? '12px' : '16px' }}
                    >
                      {selectedToggleValue === ToggleButtonValues.COUNT ? value : `${value} kg`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {percentageChange >= 0 && (
                <Grid
                  item
                  xs={5}
                  sx={{
                    borderRadius: 0.5,
                    backgroundColor:
                      percentageChange >= 0
                        ? theme.palette.primary.light
                        : theme.palette.warning.light,
                    padding: 0.5,
                    display: 'flex',
                    height: '75%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="button" fontWeight="fontWeightBold" textAlign="center">
                    {percentageChange >= 0 ? '+' : ''}
                    {percentageChange.toFixed(1)}%&nbsp;
                    <Box
                      component="img"
                      src={percentageChange >= 0 ? tendencyUp : tendencyDown}
                      alt="Tendency direction Icon"
                      sx={{
                        width: 20,
                        float: 'right',
                      }}
                    />
                  </Typography>
                </Grid>
              )}
            </Grid>
          </NumberBox>
        </Grid>
      ))}
    </Grid>
  );
};

export default Tendency;
