import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import Navigation from './Navigation';
import { Container } from '@mui/system';
import { Helmet } from 'react-helmet';
import Main from 'src/components/Structure/Main';
import MoreInfoDrawer from 'src/components/Structure/MoreInfoDrawer';
import { useDrawer } from 'src/components/Contexts/DrawerContext';

interface Props {
  children: ReactNode;
}

const Layout: React.FC<Props> = ({ children }): JSX.Element => {
  const { drawerOpen } = useDrawer();

  return (
    <Box>
      <Helmet>
        <meta name="theme-color" content="#1b1d23" />
      </Helmet>
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          minHeight: '100%',
        }}
      >
        <Navigation />
        <Main open={drawerOpen}>
          <Container sx={{ mt: 2 }}>{children}</Container>
        </Main>
        <MoreInfoDrawer />
      </Box>
    </Box>
  );
};

export default Layout;
