import React from 'react';
import { AuthLayout } from 'src/components/Auth';
import { Grid, Typography, Link, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TEXT_CONTENTS } from 'src/constants';

const NotFound: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <AuthLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            sx={{
              paddingBottom: theme.spacing(1),
            }}
          >
            {TEXT_CONTENTS.NOT_FOUND.TITLE}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              paddingBottom: theme.spacing(6),
            }}
          >
            {TEXT_CONTENTS.NOT_FOUND.TEXT}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <Link
              onClick={() => navigate('/')}
              underline="hover"
              sx={{ color: 'text.primary', cursor: 'pointer', fontWeight: 600 }}
            >
              {TEXT_CONTENTS.NOT_FOUND.LINK_TEXT}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </AuthLayout>
  );
};

export default NotFound;
