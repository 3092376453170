import * as React from 'react';
import CircularDesignCheck from 'src/components/DesignCheck/Index';
import { DesignCheckProvider } from 'src/components/Contexts/DesignCheck';

const DesignCheck: React.FC = () => {
  return (
    <DesignCheckProvider>
      <CircularDesignCheck />
    </DesignCheckProvider>
  );
};

export default DesignCheck;
