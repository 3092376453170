export const PRIMARY_COLUMNS = [
  'batteryPackModelName',
  'oemName',
  'batteryPackLengthMm',
  'batteryPackWidthMm',
  'batteryPackHeightMm',
  'batteryPackWeightKg',
  'dismantlingTimeAutomatedMin',
  'dismantlingTimeManualMin',
];

export const MASTER_DATA_TABLE_HEADER_MAPPING = {
  batteryPackModelName: 'Battery Pack Model Name',
  dataSource: 'Data Source',
  oemName: 'OEM Name',
  oemCountry: 'OEM Country',
  batteryPackLengthMm: 'Battery Pack Length [mm]',
  batteryPackWidthMm: 'Battery Pack Width [mm]',
  batteryPackHeightMm: 'Battery Pack Height [mm]',
  batteryPackWeightKg: 'Battery Pack Weight [kg]',
  disassemblyInstructions: 'Disassembly Instructions',
  lidSizeMm: 'Lid Size [mm]',
  lidLengthMm: 'Lid Length [mm]',
  lidWidthMm: 'Lid Width [mm]',
  lidHeightMm: 'Lid Height [mm]',
  lidMaterial: 'Lid Material',
  sealingType: 'Sealing Type',
  casingWeightKg: 'Casing Weight [kg]',
  batteryPackDesign: 'Battery Pack Design',
  energyAh: 'Energy [Ah]',
  socWindows: 'SOC Windows',
  nominalVoltageV: 'Nominal Voltage [V]',
  maxVoltageV: 'Max Voltage [V]',
  minVoltageV: 'Min Voltage [V]',
  nominalCapacityAh: 'Nominal Capacity [Ah]',
  nModules: 'N Modules',
  moduleWeightKg: 'Module Weight [kg]',
  moduleLengthMm: 'Module Length [mm]',
  moduleWidthMm: 'Module Width [mm]',
  moduleHeightMm: 'Module Height [mm]',
  busBarConnectionMaterial: 'Bus Bar Connection Material',
  busBarConnectionType: 'Bus Bar Connection Type',
  cellsPerPack: 'Cells Per Pack',
  cellToPackRatio: 'Cell To Pack Ratio',
  dismantlingTimeAutomatedMin: 'Dismantling Time Automated [min]',
  dismantlingTimeManualMin: 'Dismantling Time Manual [min]',
};

export const COLUMNS_TO_HIDE = ['id', '__typename', 'batteryPackModelName'];

export const COLUMNS_TO_SHOW = {
  EV: [
    'dataSource',
    'oemName',
    'oemCountry',
    'batteryPackLengthMm',
    'batteryPackWidthMm',
    'batteryPackHeightMm',
    'batteryPackWeightKg',
    'disassemblyInstructions',
    'lidLengthMm',
    'lidWidthMm',
    'lidHeightMm',
    'lidMaterial',
    'sealingType',
    'casingWeightKg',
    'batteryPackDesign',
    'energyAh',
    'socWindows',
    'maxVoltageV',
    'minVoltageV',
    'nominalCapacityAh',
    'nModules',
    'moduleWeightKg',
    'moduleLengthMm',
    'moduleWidthMm',
    'moduleHeightMm',
    'busBarConnectionMaterial',
    'busBarConnectionType',
    'cellsPerPack',
    'cellToPackRatio',
    'dismantlingTimeAutomatedMin',
    'dismantlingTimeManualMin',
  ],
  PT: [
    'dataSource',
    'oemName',
    'oemCountry',
    'batteryPackLengthMm',
    'batteryPackWidthMm',
    'batteryPackHeightMm',
    'batteryPackWeightKg',
    'disassemblyInstructions',
    'lidLengthMm',
    'lidWidthMm',
    'lidHeightMm',
    'lidMaterial',
    'sealingType',
    'casingWeightKg',
    'batteryPackDesign',
    'energyAh',
    'socWindows',
    'maxVoltageV',
    'minVoltageV',
    'nominalCapacityAh',
    'busBarConnectionMaterial',
    'busBarConnectionType',
    'cellsPerPack',
    'cellToPackRatio',
    'dismantlingTimeAutomatedMin',
    'dismantlingTimeManualMin',
  ],
  MM: [
    'dataSource',
    'oemName',
    'oemCountry',
    'batteryPackLengthMm',
    'batteryPackWidthMm',
    'batteryPackHeightMm',
    'batteryPackWeightKg',
    'disassemblyInstructions',
    'lidLengthMm',
    'lidWidthMm',
    'lidHeightMm',
    'lidMaterial',
    'sealingType',
    'casingWeightKg',
    'batteryPackDesign',
    'energyAh',
    'socWindows',
    'maxVoltageV',
    'minVoltageV',
    'nominalCapacityAh',
    'busBarConnectionMaterial',
    'busBarConnectionType',
    'cellsPerPack',
    'cellToPackRatio',
    'dismantlingTimeAutomatedMin',
    'dismantlingTimeManualMin',
  ],
};

export const INTERNAL_DATA_SOURCE = 'INTERNAL';
export const EXTERNAL_DATA_SOURCE = 'EXTERNAL';
