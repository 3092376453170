import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

enum ToggleButtonValues {
  WEIGHT_IN_KG = 'Weight (kg)',
  COUNT = 'Count',
}

interface ToggleButtonFilterProps {
  selectedValue: ToggleButtonValues;
  setSelectedValue: React.Dispatch<React.SetStateAction<ToggleButtonValues>>;
}

const ToggleButtonFilter: React.FC<ToggleButtonFilterProps> = ({
  selectedValue,
  setSelectedValue,
}): JSX.Element => {
  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: ToggleButtonValues | null
  ) => {
    if (newAlignment !== null) {
      setSelectedValue(newAlignment);
    }
  };

  return (
    <ToggleButtonGroup
      orientation="horizontal"
      fullWidth
      value={selectedValue}
      exclusive
      onChange={handleAlignment}
    >
      <ToggleButton value={ToggleButtonValues.WEIGHT_IN_KG}>
        {ToggleButtonValues.WEIGHT_IN_KG}
      </ToggleButton>
      <ToggleButton value={ToggleButtonValues.COUNT}>{ToggleButtonValues.COUNT}</ToggleButton>
    </ToggleButtonGroup>
  );
};

export { ToggleButtonFilter, ToggleButtonValues };
