import React from 'react';
import { useDesignCheck } from 'src/components/Contexts/DesignCheck';
import './styles/output.css';

const Output: React.FC = () => {
  const { input } = useDesignCheck();
  return (
    <div className="output-container">
      <u className="output-title">Output</u>

      {!input?.batteryPack && (
        <div>
          {' '}
          <p>
            <span style={{ fontWeight: 'bold' }}>Disassembly-Class: A</span>
            <br />
            The chosen battery pack design can be disassembled by us and does not show any design
            improvement possibilities
          </p>
          <p>
            <span style={{ fontWeight: 'bold', alignItems: 'center' }}>Congratulations</span>
            <br />
            Due to the high SoH value, the battery cells can be reused in new battery packs.
          </p>
        </div>
      )}

      {input?.batteryPack === 'Battery Pack 1' && (
        <div>
          {' '}
          <p>
            <span style={{ fontWeight: 'bold' }}>Disassembly-Class: A</span>
            <br />
            The chosen battery pack design can be disassembled by us and does not show any design
            improvement possibilities
          </p>
          <p>
            <span style={{ fontWeight: 'bold', alignItems: 'center' }}>Congratulations</span>
            <br />
            Due to the high SoH value, the battery cells can be reused in new battery packs.
          </p>
        </div>
      )}

      {input?.batteryPack === 'Battery Pack 2' && (
        <div>
          {' '}
          <p>
            <span style={{ fontWeight: 'bold' }}>Disassembly-Class: D</span>
            <br />
            Due to the use of silicone, the selected battery pack design has considerable problems
            with disassembly. An additional charge of{' '}
            <span style={{ fontWeight: 'bold' }}>€0.2/cell</span> applies.
          </p>
          <p>
            Due to the reduced SoH value, the battery cells can only be reused in home battery
            systems.
          </p>
        </div>
      )}

      {input?.batteryPack === 'Battery Pack 3' && (
        <div>
          {' '}
          <p>
            <span style={{ fontWeight: 'bold' }}>Disassembly-Class: undefined</span>
            <br />
            Due to the heavy damage to the battery pack, the safety requirements for dismantling and
            subsequent upcycling of the cells cannot be guaranteed. We therefore prepare the battery
            packs for safe transportation to a conventional recycling facility.
          </p>
          <p>
            This mainly relates to the utilization phase of the battery packs and can therefore only
            be influenced indirectly in the design phase. Please ensure that all specified safety
            requirements are met.
          </p>
        </div>
      )}

      {input?.batteryPack === 'Battery Pack 4' && (
        <div>
          {' '}
          <p>
            <span style={{ fontWeight: 'bold' }}>Disassembly-class: undefined</span>
            <br />
            Due to the low-quality battery cells inside the battery pack, upcycling is not
            economically viable as the cells cannot be sold afterwards (no market for used
            low-quality cells).
          </p>
          <p>
            {/*<span style={{ fontWeight: 'bold', alignItems: 'center' }}>Congratulations</span>*/}
            <br />
            We therefore discharge and dismantle the battery packs (separate recycling process for
            housing) and feed the battery cells into a sustainable battery recycling process (higher
            recovery of elements, with lower energy). When designing the battery packs, please use
            high-quality battery cells to enable the battery cells to be reused in their second
            life.
          </p>
        </div>
      )}
    </div>
  );
};

export default Output;
