import React from 'react';

import { Toolbar, Typography, Button, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useFeedbackContext } from 'src/components/Contexts/FeedbackContext';
import { TEXT_CONTENTS } from 'src/constants';

export default function FeedbackToolbar() {
  const { setClickCount, setEnableFeedbackToolbar, setShowFeedbackModal, setClicksNecessary } =
    useFeedbackContext();

  const handleOpenFeedbackModal = () => {
    setShowFeedbackModal(true);
    handleDismissFeedback();
  };

  const handleDismissFeedback = () => {
    setEnableFeedbackToolbar(false);
    setClicksNecessary(200);
    setClickCount(0);
  };

  const theme = useTheme();
  return (
    <Toolbar
      sx={{ color: theme.palette.charcoal.main, backgroundColor: theme.palette.primary.main }}
    >
      <Box
        sx={{
          display: 'flex',
          my: 1,
          gap: 1,
          width: '100%',
          alignItems: 'center',
          flexDirection: { xs: 'column', lg: 'row' },
          justifyContent: { xs: 'center', lg: 'space-between' },
        }}
      >
        <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>
          {TEXT_CONTENTS.FEEDBACK.TOOLBAR.TITLE}
        </Typography>
        <Box>
          <Button
            onClick={handleOpenFeedbackModal}
            variant="contained"
            color="charcoal"
            sx={{ fontWeight: 'bold', mr: 1 }}
          >
            Participate
          </Button>
          <Button
            onClick={handleDismissFeedback}
            variant="outlined"
            color="charcoal"
            sx={{ fontWeight: 'bold' }}
          >
            Not right now
          </Button>
        </Box>
      </Box>
    </Toolbar>
  );
}
