import React, { useState } from 'react';
import { ChartWrapper, PageContainer, Title } from 'src/components/Structure';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  useTheme,
} from '@mui/material';
import { TEXT_CONTENTS } from 'src/constants';
import { useDataInsights, useUserSettings } from 'src/components/hooks';
import { ComponentsAvailable, MaterialsAvailable, MaterialsValue } from 'src/components/Charts';
import { CellOrBatteryComponent, MetalPrice } from 'src/@types/DataTypes';
import { Currency } from 'src/@types/ChartSettings/MaterialsValue';

interface BatteryCatergoryFilterProps {
  labeledValueWithCategory: CellOrBatteryComponent[];
  selectedBatteryCategory: string;
  setSelectedBatteryCategory: React.Dispatch<React.SetStateAction<string>>;
}

const BatteryCategoryFilter: React.FC<BatteryCatergoryFilterProps> = ({
  labeledValueWithCategory,
  selectedBatteryCategory,
  setSelectedBatteryCategory,
}): JSX.Element => {
  const theme = useTheme();

  const handleDropdownChange = (event: SelectChangeEvent) => {
    setSelectedBatteryCategory(event.target.value);
  };

  const batteryCategories = [
    ...new Set(labeledValueWithCategory?.map(({ batteryCategory }) => batteryCategory)),
  ];

  return (
    <FormControl
      variant="filled"
      sx={{
        width: '100%',
      }}
    >
      <InputLabel id="battery-type-label">Battery Category</InputLabel>
      <Select
        labelId="battery-type-label"
        label="Commodity"
        value={selectedBatteryCategory}
        onChange={handleDropdownChange}
        variant="filled"
        sx={{
          flex: 1,
          marginRight: theme.spacing(2),
        }}
      >
        <MenuItem value="Total">Total</MenuItem>
        {batteryCategories?.map((batteryCategory, index) => {
          return (
            <MenuItem key={index} value={batteryCategory}>
              {batteryCategory}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const Material: React.FC = (): JSX.Element => {
  const theme = useTheme();
  const { loading: insightsLoading, materialInsights } = useDataInsights();
  const { loading: settingsLoading, currency: displayCurrency } = useUserSettings();
  const [selectedBatteryCategory, setSelectedBatteryCategory] =
    useState<string>('Micro-Mobility - MM');

  if (insightsLoading || settingsLoading) {
    return (
      <PageContainer>
        <Skeleton variant="text" width={200} height={40} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ChartWrapper
              title={`${TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.CELL.TITLE}${displayCurrency}`}
            >
              <Skeleton variant="rectangular" width="100%" height={300} />
            </ChartWrapper>
          </Grid>
          <Grid item xs={6}>
            <ChartWrapper
              title={`${TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.COMPONENTS.TITLE}${displayCurrency}`}
            >
              <Skeleton variant="rectangular" width="100%" height={300} />
            </ChartWrapper>
          </Grid>
          <Grid item xs={6}>
            <ChartWrapper
              title={`${TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.MATERIALS.TITLE}${displayCurrency}`}
            >
              <Skeleton variant="rectangular" width="100%" height={300} />
            </ChartWrapper>
          </Grid>
        </Grid>
      </PageContainer>
    );
  }

  const latestTimestamps: Record<string, number> = {};

  materialInsights?.metalsPrice.forEach((metal: MetalPrice) => {
    if (latestTimestamps[metal.name] === undefined) {
      latestTimestamps[metal.name] = metal.timestamp;
    } else if (metal.timestamp > latestTimestamps[metal.name]) {
      latestTimestamps[metal.name] = metal.timestamp;
    }
  });

  return (
    <PageContainer>
      <Title variant="h1">{TEXT_CONTENTS.MATERIAL.TITLE}</Title>
      <Grid
        container
        spacing={2}
        sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: theme.spacing(2) }}
      >
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <BatteryCategoryFilter
            labeledValueWithCategory={
              materialInsights?.batteryComponents as CellOrBatteryComponent[]
            }
            selectedBatteryCategory={selectedBatteryCategory}
            setSelectedBatteryCategory={setSelectedBatteryCategory}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ChartWrapper
            title={`${TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.CELL.TITLE}${displayCurrency}`}
            chartId={TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.CELL.ID}
          >
            <MaterialsAvailable
              detailedView={true}
              latestTimestamps={latestTimestamps}
              displayCurrency={displayCurrency as Currency}
              chartId={TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.CELL.ID}
              selectedBatteryCategory={selectedBatteryCategory}
            />
          </ChartWrapper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ChartWrapper
            title={`${TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.COMPONENTS.TITLE}${displayCurrency}`}
            chartId={TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.COMPONENTS.ID}
          >
            <ComponentsAvailable
              displayCurrency={displayCurrency as Currency}
              chartId={TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.COMPONENTS.ID}
              selectedBatteryCategory={selectedBatteryCategory}
            />
          </ChartWrapper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ChartWrapper
            title={`${TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.MATERIALS.TITLE}${displayCurrency}`}
            chartId={TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.MATERIALS.ID}
          >
            <MaterialsValue
              currency={displayCurrency}
              chartId={TEXT_CONTENTS.CHARTS.MATERIAL_INSIGHTS.MATERIALS.ID}
            />
          </ChartWrapper>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Material;
