import React, { useState } from 'react';
import { ChartWrapper, PageContainer, Title } from 'src/components/Structure';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { TEXT_CONTENTS } from 'src/constants';
import { useDataInsights } from 'src/components/hooks';
import { BatteryPacksProcessed, Tendency } from 'src/components/Charts';
import {
  ToggleButtonFilter,
  ToggleButtonValues,
} from 'src/components/Charts/Filters/BatteryFilters';
import MasterDataCardView from 'src/components/Charts/BatteryInsights/MasterDataCardView';
import { BatteryCategoryFilter, BatteryTypeFilter } from 'src/components/Charts/Filters/Common';

const Battery: React.FC = (): JSX.Element => {
  const theme = useTheme();
  const [selectedBatteryCategory, setSelectedBatteryCategory] = useState<string>('Total');
  const [selectedBatteryType, setSelectedBatteryType] = useState<string>('Total');
  const [selectedToggleValue, setSelectedToggleValue] = useState<ToggleButtonValues>(
    ToggleButtonValues.WEIGHT_IN_KG
  );
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { loading, batteryInsights } = useDataInsights();

  const batteryCategories = [
    ...new Set(batteryInsights?.batteryDataInsights?.map(({ batteryCategory }) => batteryCategory)),
  ];

  const batteryTypes = [
    ...new Set(
      batteryInsights?.batteryDataInsights
        ?.filter(({ batteryCategory }) => selectedBatteryCategory === batteryCategory)
        .map(({ batteryType }) => batteryType)
    ),
  ];

  if (loading) return <p>{TEXT_CONTENTS.COMMON.LOADING}</p>;

  return (
    <PageContainer>
      <Title variant="h1">{TEXT_CONTENTS.BATTERY.TITLE}</Title>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ChartWrapper title={TEXT_CONTENTS.CHARTS.BATTERY_INSIGHTS.FILTERS.TITLE}>
            <Grid container>
              <Grid item xs={12} sm={4} md={4} sx={{ marginBottom: theme.spacing(2) }}>
                <BatteryCategoryFilter
                  batteryCategories={batteryCategories}
                  selectedBatteryCategory={selectedBatteryCategory}
                  setSelectedBatteryCategory={setSelectedBatteryCategory}
                  setSelectedBatteryType={setSelectedBatteryType}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                sx={{
                  marginBottom: theme.spacing(2),
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <BatteryTypeFilter
                  batteryTypes={batteryTypes}
                  selectedBatteryType={selectedBatteryType}
                  setSelectedBatteryType={setSelectedBatteryType}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} sx={{ flexGrow: 1 }}>
                <ToggleButtonFilter
                  selectedValue={selectedToggleValue}
                  setSelectedValue={setSelectedToggleValue}
                />
              </Grid>
            </Grid>
          </ChartWrapper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ChartWrapper
            title={
              selectedToggleValue === ToggleButtonValues.COUNT
                ? TEXT_CONTENTS.CHARTS.BATTERY_INSIGHTS.TENDENCY.TITLE
                : TEXT_CONTENTS.CHARTS.BATTERY_INSIGHTS.TENDENCY.TITLE_KG
            }
          >
            <Tendency
              row={!isMobile}
              selectedBatteryType={selectedBatteryType}
              selectedBatteryCategory={selectedBatteryCategory}
              selectedToggleValue={selectedToggleValue}
            />
          </ChartWrapper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <BatteryPacksProcessed
            selectedBatteryType={selectedBatteryType}
            selectedBatteryCategory={selectedBatteryCategory}
            selectedToggleValue={selectedToggleValue}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} order={{ xs: 6, sm: 6, md: 6, lg: 6 }}>
          <ChartWrapper title={TEXT_CONTENTS.CHARTS.BATTERY_INSIGHTS.EV_LIBRARY.TITLE}>
            <MasterDataCardView />
          </ChartWrapper>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Battery;
