export interface BatteryDatabase {
  batteryPackModelName?: string;
  batteryPackModelType?: string;
  dataSource?: string;
  oemName?: string;
  oemCountry?: string;
  batteryPackLengthMm?: number;
  batteryPackWidthMm?: number;
  batteryPackHeightMm?: number;
  batteryPackWeightKg?: number;
  disassemblyInstructions?: string;
  lidSizeMm?: number;
  lidMaterial?: string;
  sealingType?: string;
  casingWeightKg?: number;
  batteryPackDesign?: string;
  energyAh?: number;
  socWindows?: string;
  nominalVoltageV?: number;
  maxVoltageV?: number;
  minVoltageV?: number;
  nominalCapacityAh?: number;
  nModules?: number;
  moduleWeightKg?: number;
  moduleLengthMm?: number;
  moduleWidthMm?: number;
  moduleHeightMm?: number;
  busBarConnectionMaterial?: string;
  busBarConnectionType?: string;
  cellsPerPack?: number;
  cellToPackRatio?: number;
  dismantlingTimeAutomatedMin?: number;
  dismantlingTimeManualMin?: number;
}

export enum BatteryPackModelType {
  EV = 'EV',
  PT = 'PT',
  MM = 'MM',
}

export enum View {
  Table = 'table',
  Cards = 'cards',
}

export interface OemMetaData {
  oemName: string;
  batteryPacksCount: number;
}
