import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  useTheme,
} from '@mui/material';

interface BatteryCatergoryFilterProps {
  batteryCategories: string[];
  selectedBatteryCategory: string;
  setSelectedBatteryCategory: React.Dispatch<React.SetStateAction<string>>;
  setSelectedBatteryType: React.Dispatch<React.SetStateAction<string>>;
}

interface BatteryTypeFilterProps {
  batteryTypes: string[];
  selectedBatteryType: string;
  setSelectedBatteryType: React.Dispatch<React.SetStateAction<string>>;
}

const BatteryCategoryFilter: React.FC<BatteryCatergoryFilterProps> = ({
  batteryCategories,
  selectedBatteryCategory,
  setSelectedBatteryCategory,
  setSelectedBatteryType,
}): JSX.Element => {
  const theme = useTheme();

  const handleDropdownChange = (event: SelectChangeEvent) => {
    setSelectedBatteryCategory(event.target.value);
    setSelectedBatteryType('Total');
  };

  return (
    <FormControl
      variant="filled"
      sx={{
        width: '100%',
      }}
    >
      <InputLabel id="battery-type-label">Battery Category</InputLabel>
      <Select
        labelId="battery-type-label"
        label="Commodity"
        value={selectedBatteryCategory}
        onChange={handleDropdownChange}
        variant="filled"
        sx={{
          flex: 1,
          marginRight: theme.spacing(2),
        }}
      >
        <MenuItem value="Total">Total</MenuItem>
        {batteryCategories?.map((batteryCategory, index) => {
          return (
            <MenuItem key={index} value={batteryCategory}>
              {batteryCategory}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const BatteryTypeFilter: React.FC<BatteryTypeFilterProps> = ({
  batteryTypes,
  selectedBatteryType,
  setSelectedBatteryType,
}): JSX.Element => {
  const theme = useTheme();

  const handleDropdownChange = (event: SelectChangeEvent) => {
    setSelectedBatteryType(event.target.value);
  };

  return (
    <FormControl
      variant="filled"
      sx={{
        width: '100%',
      }}
    >
      <InputLabel id="battery-type-label">Battery Type</InputLabel>
      <Select
        labelId="battery-type-label"
        label="Commodity"
        value={selectedBatteryType}
        onChange={handleDropdownChange}
        variant="filled"
        sx={{
          flex: 1,
          marginRight: theme.spacing(2),
        }}
      >
        <MenuItem value="Total">Total</MenuItem>
        {batteryTypes?.map((batteryType, index) => {
          return (
            <MenuItem key={index} value={batteryType}>
              {batteryType}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export { BatteryCategoryFilter, BatteryTypeFilter };
