import React from 'react';
import { TEXT_CONTENTS } from 'src/constants';
import { Grid } from '@mui/material';
import { ChartWrapper, PageContainer, Title } from 'src/components/Structure';
import InventoryTable from 'src/components/Inventory/InventoryTable';
import InventoryTrackingChart from 'src/components/Inventory/InventoryTrackingChart';

const Inventory: React.FC = () => {
  return (
    <PageContainer>
      <Title variant="h2">{TEXT_CONTENTS.INVENTORY.TITLE}</Title>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ChartWrapper
            title={TEXT_CONTENTS.CHARTS.INVENTORY_TRACKING.INVENTORY_TRACKING_TABLE.TITLE}
          >
            <InventoryTable />
          </ChartWrapper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ChartWrapper
            title={TEXT_CONTENTS.CHARTS.INVENTORY_TRACKING.INVENTORY_TRACKING_CHART.TITLE}
          >
            <InventoryTrackingChart />
          </ChartWrapper>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Inventory;
