export const options = [
  'Lot 1',
  'Lot 2',
  'Lot 3',
  'Lot 4',
  'Lot 5',
  'Lot 6',
  'Lot 7',
  'Lot 8',
  'Lot 9',
  'Lot 10',
];

const getRandomWeight = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const lotData = [
  {
    name: 'Lot 1',
    weight: getRandomWeight(2000, 5000),
    batteryPacks: [
      { name: 'Tesla Model S 75KWh', number: 5 },
      { name: 'KIA EV Soul 30KWh', number: 2 },
    ],
  },
  {
    name: 'Lot 2',
    weight: getRandomWeight(2000, 5000),
    batteryPacks: [
      { name: 'Tesla Model S 75KWh', number: 5 },
      { name: 'KIA EV Soul 30KWh', number: 2 },
    ],
  },
  {
    name: 'Lot 3',
    weight: getRandomWeight(2000, 5000),
    batteryPacks: [
      { name: 'Tesla Model S 75KWh', number: 5 },
      { name: 'KIA EV Soul 30KWh', number: 2 },
    ],
  },
  {
    name: 'Lot 4',
    weight: getRandomWeight(2000, 5000),
    batteryPacks: [
      { name: 'Tesla Model S 75KWh', number: 5 },
      { name: 'KIA EV Soul 30KWh', number: 2 },
    ],
  },
  {
    name: 'Lot 5',
    weight: getRandomWeight(2000, 5000),
    batteryPacks: [
      { name: 'Tesla Model S 75KWh', number: 5 },
      { name: 'KIA EV Soul 30KWh', number: 2 },
    ],
  },
  {
    name: 'Lot 6',
    weight: getRandomWeight(2000, 5000),
    batteryPacks: [
      { name: 'Tesla Model S 75KWh', number: 5 },
      { name: 'KIA EV Soul 30KWh', number: 2 },
    ],
  },
  {
    name: 'Lot 7',
    weight: getRandomWeight(2000, 5000),
    batteryPacks: [
      { name: 'Tesla Model S 75KWh', number: 5 },
      { name: 'KIA EV Soul 30KWh', number: 2 },
    ],
  },
  {
    name: 'Lot 8',
    weight: getRandomWeight(2000, 5000),
    batteryPacks: [
      { name: 'Tesla Model S 75KWh', number: 5 },
      { name: 'KIA EV Soul 30KWh', number: 2 },
    ],
  },
  {
    name: 'Lot 9',
    weight: getRandomWeight(2000, 5000),
    batteryPacks: [
      { name: 'Tesla Model S 75KWh', number: 5 },
      { name: 'KIA EV Soul 30KWh', number: 2 },
    ],
  },
  {
    name: 'Lot 10',
    weight: getRandomWeight(2000, 5000),
    batteryPacks: [
      { name: 'Tesla Model S 75KWh', number: 5 },
      { name: 'KIA EV Soul 30KWh', number: 2 },
    ],
  },
];
