import { styled } from '@mui/material/styles';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import { BatteryDatabase } from 'src/@types/BatteryDatabase';
import isUrl from 'validator/lib/isURL';
import { Link, Typography } from '@mui/material';
import { roundToTwoDecimals } from 'src/utils/Number';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
  WebkitFontSmoothing: 'auto',
  background: 'white',
  letterSpacing: 'normal',
  //   '& .MuiDataGrid-columnsContainer': {
  //     backgroundColor: theme.palette.primary.light,
  //   },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.palette.primary.light}`, // === 'light' ? '#f0f0f0' : '#303030'}`,
  },
  '& .MuiDataGrid-cell': {
    color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
  },
  '& .MuiPaginationItem-root': {
    borderRadius: 0,
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
    '.MuiDataGrid-columnHeaderTitle': {
      color: 'white',
    },
    '.MuiDataGrid-sortIcon': {
      color: 'white',
    },
    '.MuiDataGrid-menuIcon': {
      color: 'white',
    },
  },
}));

export const renderTableCell = (params: GridRenderCellParams<BatteryDatabase>) => {
  if (typeof params.value === 'string') {
    if (isUrl(params.value)) {
      return (
        <Link href={params.value} color="secondary" target="_blank" rel="noopener noreferrer">
          View
        </Link>
      );
    }

    try {
      const parsedvalue = parseFloat(params.value);
      if (!isNaN(parsedvalue)) {
        return <Typography variant="body1">{parsedvalue.toFixed(2)}</Typography>;
      }
    } catch (e) {
      console.error(e);
      return <Typography variant="body1">{params.value}</Typography>;
    }
  }

  if (typeof params.value === 'number') {
    return <Typography variant="body1">{roundToTwoDecimals(params.value)}</Typography>;
  }
  return <Typography variant="body1">{params.value}</Typography>;
};

export default StyledDataGrid;
