import { gql } from '@apollo/client';

export const UPDATE_USER_SETTINGS = gql`
  mutation Mutation($settings: UserSettingsInput!) {
    updateUserSettings(settings: $settings) {
      currency
      darkMode
    }
  }
`;
