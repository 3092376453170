import React from 'react';
import ApexCharts from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

const BatteryVoltageChart: React.FC = () => {
  const cyclesData: Record<string, number[]> = {
    cycle0: [3.8, 3.79, 3.78, 3.75, 3.73, 3.71, 3.69, 3.67, 3.65, 3.63],
    cycle25: [3.79, 3.78, 3.76, 3.74, 3.72, 3.7, 3.68, 3.66, 3.64, 3.62],
    cycle50: [3.78, 3.77, 3.75, 3.73, 3.71, 3.69, 3.67, 3.65, 3.63, 3.61],
  };

  const timeLabels = ['0s', '10s', '20s', '30s', '40s', '50s', '60s', '70s', '80s', '90s'];

  const series = Object.keys(cyclesData).map((cycle, index) => ({
    name: `Cycle ${index * 25}`,
    data: cyclesData[cycle],
  }));

  const options: ApexOptions = {
    chart: {
      type: 'line',
      height: 200,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      background: 'white',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 1,
    },
    xaxis: {
      categories: timeLabels,
    },
    tooltip: {
      x: {
        format: 's',
      },
    },
    legend: {
      show: false,
    },
  };

  return <ApexCharts options={options} series={series} type="line" height={200} />;
};

export default BatteryVoltageChart;
