import React from 'react';
import {
  Box,
  ClickAwayListener,
  Drawer,
  IconButton,
  AppBar,
  Typography,
  Toolbar,
  useTheme,
  Stack,
} from '@mui/material';

import { useDrawer } from 'src/components/Contexts/DrawerContext';
import CloseIcon from '@mui/icons-material/Close';

const MoreInfoDrawer: React.FC = () => {
  const theme = useTheme();
  const { drawerOpen, toggleDrawer, drawerHeader, drawerContent } = useDrawer();

  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={() => toggleDrawer(false)}>
      <Drawer
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 500,
          },
        }}
        variant="persistent"
        anchor="right"
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
      >
        <Toolbar />
        <Box
          sx={{
            width: 475,
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 'none',
            '-ms-overflow-style': 'none',
          }}
          role="presentation"
        >
          <AppBar
            position="sticky"
            elevation={0}
            sx={{
              paddingLeft: theme.spacing(2),
              backgroundColor: 'background.paper',
              borderBottom: 3,
              borderColor: 'divider',
            }}
          >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              {drawerHeader && (
                <Typography variant="h3" color="primary" sx={{ pt: 2, pb: 2 }}>
                  {drawerHeader}
                </Typography>
              )}
              <IconButton onClick={() => toggleDrawer(false)}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </AppBar>
          {drawerContent}
        </Box>
      </Drawer>
    </ClickAwayListener>
  );
};

export default MoreInfoDrawer;
