export const MATERIALS_MAPPING: Record<string, string> = {
  Aluminium: 'Aluminum',
  Cobalt: 'Cobalt',
  'Copper Wiring': 'Copper',
  Lithium: 'Lithium',
  Manganese: 'Manganese',
  Nickel: 'Nickel',
};

export const DEFAULT_MATERIALS_VALUE: Record<string, number> = {
  Aluminium: 2.24,
  Cobalt: 33.42,
  Graphite: 0.69,
  Other: 0.29,
  Solvants: 1.42,
  'Copper Wiring': 8.07,
  Lithium: 22.84,
  Manganese: 30.75,
  Nickel: 18.31,
  PCB: 0.83,
  Plastic: 0.69,
  Plug: 0.95,
  Aluminum: 2.16,
  Steel: 3.26,
  Copper: 8.19,
  Thermoplastics: 3.72,
  'Ceramics/Glass': 7.91,
  Elastomeric: 1.98,
  PCBs: 3.47,
  Zinc: 9.56,
  Lead: 12.15,
};
