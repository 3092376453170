import React from 'react';
import { Box, Typography, Skeleton } from '@mui/material';
import { TEXT_CONTENTS } from 'src/constants';

const ChartContainer = ({
  height,
  children,
  dataNotAvailable,
}: {
  height: number | string;
  children: JSX.Element | JSX.Element[];
  dataNotAvailable?: boolean;
}): JSX.Element => {
  return (
    <Box height={height} overflow="hidden" borderRadius={0.5} my={1}>
      {dataNotAvailable ? (
        <Box position="relative" width="100%" height={height}>
          <Skeleton variant="rectangular" width="100%" height="100%" animation="wave" />
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography textAlign="center" variant="subtitle1">
              {TEXT_CONTENTS.COMMON.DATA_NOT_AVAILABLE}
            </Typography>
          </Box>
        </Box>
      ) : (
        ''
      )}
      {children}
    </Box>
  );
};

export default ChartContainer;
