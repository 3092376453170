import { ApexOptions } from 'apexcharts';
import React, { useState, useMemo } from 'react';
import Chart from 'react-apexcharts';
import { useDataInsights } from '../../hooks';
import { TEXT_CONTENTS } from 'src/constants';
import { useTheme } from '@mui/material';
import { ChartContainer } from 'src/components/Structure';
import { ChartProps } from 'src/@types/DataTypes';

interface Props extends ChartProps {
  client?: string;
  batteryCategory?: string;
  batteryType?: string;
}

const BoxPlot: React.FC<Props> = ({
  chartId = 'state-of-health-of-cells',
  client = 'All Clients',
  batteryType = 'Total',
  batteryCategory = 'Total',
}) => {
  const theme = useTheme();
  const height = theme.spacing(50);
  const { cellInsights } = useDataInsights();
  const filteredTypes = useMemo(
    () =>
      cellInsights?.types
        .filter(type => client === 'All Clients' || type.client === client)
        .filter(type => batteryCategory === 'Total' || type.batteryCategory === batteryCategory)
        .filter(type => batteryType === 'Total' || type.batteryType === batteryType) || [],
    [cellInsights, client, batteryCategory, batteryType]
  );

  const outliers: { x: string; y: number }[] = [];
  filteredTypes?.forEach(type => {
    outliers.push({ x: type.label, y: Math.min(...type.outliers) });
    outliers.push({ x: type.label, y: Math.max(...type.outliers) });
  });

  const boxPlotData: { x: string; y: number[] }[] = filteredTypes?.map(type => ({
    x: type.label,
    y: [type.minimum, type.q1, type.median, type.q3, type.maximum],
  }));

  const [options] = useState<ApexOptions>({
    chart: {
      id: chartId,
      fontFamily: theme.typography.fontFamily,
      background: theme.palette.background.paper,
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    legend: { show: false },
    plotOptions: {
      boxPlot: {
        colors: {
          upper: theme.custom.chart.boxPlot.upper,
          lower: theme.custom.chart.boxPlot.lower,
        },
      },
    },
    yaxis: {
      title: { text: TEXT_CONTENTS.CHARTS.CELL_INSIGHTS.STATE_OF_HEALTH.YAXIS },
      labels: {
        formatter: function (val) {
          return val.toFixed(1);
        },
      },
    },
    grid: { row: { colors: [theme.palette.background.default, theme.palette.background.paper] } },
  });

  const [series] = useState<ApexAxisChartSeries>([
    {
      type: 'boxPlot',
      data: boxPlotData,
    },
    {
      type: 'scatter',
      data: outliers,
    },
  ]);

  return (
    <ChartContainer height={height} dataNotAvailable={series.length === 0}>
      <Chart
        options={options}
        series={series}
        type="boxPlot"
        height={height}
        style={{ color: theme.palette.secondary.main, fontFamily: theme.typography.fontFamily }}
      />
    </ChartContainer>
  );
};

export default BoxPlot;
