import React, { useCallback, useEffect, useState } from 'react';
import ReactFlow, {
  // MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  Edge,
  NodeTypes,
  BackgroundVariant,
  Connection,
  OnConnect,
} from 'reactflow';

import 'reactflow/dist/style.css';
import Input from './Input';
import Output from './Output';
// import { SignInFormData } from 'src/@types/AuthTypes';
import { useDesignCheck } from 'src/components/Contexts/DesignCheck';

// Define the types for your initial nodes and edges if they have a specific structure
interface CustomNodeData {
  label: string;
  style?: React.CSSProperties;
}

// const defaultViewport = { x: 0, y: 0, zoom: 1.5 };
// const edgeOptions = {type: 'smoothstep'};

const nodeTypes: NodeTypes = {
  inputNode: Input,
  outputNode: Output,
};

export default function CircularDesignCheck() {
  const { input } = useDesignCheck();

  const [nodes, setNodes, onNodesChange] = useNodesState<CustomNodeData>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [flowKey] = useState(0);

  useEffect(() => {
    // Update nodes and edges
    const greenBackground = 'linear-gradient(rgba(76, 252, 57, 0.8), rgba(29, 210, 11, 0.8))';
    const redBackground = 'linear-gradient(rgba(230, 140, 9, 0.3), rgba(255, 0, 67, 0.5))';
    const blueBackground = 'linear-gradient(rgba(72, 164, 245, 0.8), rgba(8, 127, 232, 0.8))';
    setNodes([
      { id: 'input', position: { x: 50, y: 0 }, type: 'inputNode', data: { label: 'Input' } },
      {
        id: 'bdl',
        position: { x: 525, y: 0 },
        data: { label: `${input.category} BDL` },
        style: { fontSize: '24px' },
      },

      {
        id: 'sorting',
        position: { x: 350, y: 75 },
        data: { label: 'Unit 0: Sorting Step' },
        style: {
          backgroundImage: input.batteryPack === 'Battery Pack 4' ? redBackground : greenBackground,
        },
      },
      {
        id: 'pre-diagnostic',
        position: { x: 350, y: 150 },
        data: { label: 'Unit 1: Pre-diagnostic' },
        style: {
          backgroundImage:
            input.batteryPack === 'Battery Pack 3'
              ? redBackground
              : input.batteryPack === 'Battery Pack 4'
                ? undefined
                : greenBackground,
        },
      },
      {
        id: 'opening-system',
        position: { x: 350, y: 225 },
        data: { label: 'Unit 2: Opening System' },
        style: {
          backgroundImage:
            input.batteryPack === 'Battery Pack 1'
              ? greenBackground
              : input.batteryPack === 'Battery Pack 2'
                ? redBackground
                : undefined,
        },
      },
      {
        id: 'cell-extraction',
        position: { x: 350, y: 300 },
        data: { label: 'Unit 3: Cell Extraction' },
        style: {
          backgroundImage: input.batteryPack === 'Battery Pack 1' ? greenBackground : undefined,
        },
      },
      {
        id: 'cell-diagnostic',
        position: { x: 350, y: 375 },
        data: { label: 'Unit 4: Cell Diagnostic' },
        style: {
          backgroundImage: input.batteryPack === 'Battery Pack 1' ? greenBackground : undefined,
        },
      },
      {
        id: 'primary-battery-cat',
        position: { x: 50, y: 500 },
        data: { label: 'Quality 1' },
        style: {
          backgroundImage: input.batteryPack === 'Battery Pack 1' ? greenBackground : undefined,
        },
      },
      {
        id: 'second-battery-cat',
        position: { x: 250, y: 500 },
        data: { label: 'Quality 2' },
      },
      {
        id: 'ter-battery-cat',
        position: { x: 450, y: 500 },
        data: { label: 'Quality 3' },
        style: {
          backgroundImage: input.batteryPack === 'Battery Pack 2' ? greenBackground : undefined,
        },
      },
      {
        id: 'sustainable-recycling',
        position: { x: 650, y: 500 },
        data: { label: 'Sustainable Recycling (Hydrometallurgy)' },
        style: {
          backgroundImage: input.batteryPack === 'Battery Pack 4' ? blueBackground : undefined,
        },
      },
      { id: 'discharge-nfit', position: { x: 650, y: 150 }, data: { label: 'Discharging' } },
      { id: 'dismantel-nfit', position: { x: 650, y: 225 }, data: { label: 'Dismantelling' } },
      { id: 'shredding-susRec', position: { x: 650, y: 600 }, data: { label: 'Shredding' } },
      { id: 'seperation', position: { x: 650, y: 650 }, data: { label: 'Sepearation' } },
      { id: 'leaching', position: { x: 650, y: 700 }, data: { label: 'Leaching' } },
      { id: 'percification', position: { x: 650, y: 750 }, data: { label: 'Percification' } },
      { id: 'metal-recovery', position: { x: 650, y: 800 }, data: { label: 'Metal-Recovery' } },
      { id: 'non-ferrous', position: { x: 815, y: 850 }, data: { label: 'Non-Ferrous' } },
      { id: 'ferrous', position: { x: 980, y: 850 }, data: { label: 'Ferrous' } },
      {
        id: 'material-recycling',
        position: { x: 650, y: 850 },
        data: { label: 'Recovery: Lithium, Aluminium, Manganese' },
        type: 'output',
      },
      {
        id: 'cell_reuse_1',
        position: { x: 50, y: 850 },
        data: { label: 'Reuse Battery-Cells' },
        type: 'output',
        style: {
          backgroundImage: input.batteryPack === 'Battery Pack 1' ? blueBackground : undefined,
        },
      },
      {
        id: 'cell_reuse_2',
        position: { x: 350, y: 850 },
        data: { label: 'Upcycling Battery-Cells' },
        type: 'output',
        style: {
          backgroundImage: input.batteryPack === 'Battery Pack 2' ? blueBackground : undefined,
        },
      },
      { id: 'safety-packing', position: { x: 1150, y: 375 }, data: { label: 'Safety-Packing' } },
      {
        id: 'normal-recycling',
        position: { x: 1150, y: 500 },
        data: { label: 'Traditional Recycling (Pyrometallurgy)' },
        style: {
          backgroundImage: input.batteryPack === 'Battery Pack 3' ? blueBackground : undefined,
        },
      },
      { id: 'shredding-nRec', position: { x: 1150, y: 600 }, data: { label: 'Shredding' } },
      { id: 'roasting-nRec', position: { x: 1150, y: 665 }, data: { label: 'Roasting' } },
      {
        id: 'smelting-nRec',
        position: { x: 1150, y: 730 },
        data: { label: 'Smelting - hazardous' },
      },
      { id: 'refining-nRec', position: { x: 1150, y: 795 }, data: { label: 'Refining' } },
      {
        id: 'metal-recovery-nRec',
        position: { x: 1150, y: 850 },
        data: { label: 'Recovery: Cobalt, Nickel' },
      },
      {
        id: 'output',
        position: { x: 1000, y: 0 },
        type: 'outputNode',
        data: { label: 'Output' },
      },
    ]);
    setEdges([
      { id: 'input-pre', source: 'input', target: 'sorting', animated: true },
      {
        id: 'sort',
        source: 'sorting',
        target: 'pre-diagnostic',
        animated: input.batteryPack === 'Battery Pack 4' ? false : true,
      },
      {
        id: 'pre-op',
        source: 'pre-diagnostic',
        target: 'opening-system',
        animated:
          input.batteryPack === 'Battery Pack 3' || input.batteryPack === 'Battery Pack 4'
            ? false
            : true,
      },
      {
        id: 'op-cell',
        source: 'opening-system',
        target: 'cell-extraction',
        animated:
          input.batteryPack === 'Battery Pack 3' || input.batteryPack === 'Battery Pack 4'
            ? false
            : true,
      },
      {
        id: 'cell-cell',
        source: 'cell-extraction',
        target: 'cell-diagnostic',
        animated:
          input.batteryPack === 'Battery Pack 3' || input.batteryPack === 'Battery Pack 4'
            ? false
            : true,
      },
      {
        id: 'cell-primary',
        source: 'cell-diagnostic',
        target: 'primary-battery-cat',
        label: 'SoH > 95%',
        animated: input.batteryPack === 'Battery Pack 1' ? true : false,
      },
      {
        id: 'cell-secondary',
        source: 'cell-diagnostic',
        target: 'second-battery-cat',
        label: '95% > SoH > 80%',
      },
      {
        id: 'cell-ter',
        source: 'cell-diagnostic',
        target: 'ter-battery-cat',
        label: '80% > SoH > 70%',
        animated: input.batteryPack === 'Battery Pack 2' ? true : false,
      },
      {
        id: 'cell-third',
        source: 'cell-diagnostic',
        target: 'sustainable-recycling',
        label: '70% > SoH ',
      },
      {
        id: 'packing',
        source: 'pre-diagnostic',
        target: 'safety-packing',
        label: 'heavily damaged',
        animated: input.batteryPack === 'Battery Pack 3' ? true : false,
      },
      {
        id: 'norm-recycling',
        source: 'safety-packing',
        target: 'normal-recycling',
        animated: input.batteryPack === 'Battery Pack 3' ? true : false,
      },

      { id: 'discharge-safety', source: 'discharge-safety', target: 'dismantel-safety' },
      { id: 'cell-third-saf', source: 'dismantel-safety', target: 'third-battery-cat' },
      { id: 'shred-batRec', source: 'third-battery-cat', target: 'shredding-batRec' },
      { id: 'sorting-blMa', source: 'shredding-batRec', target: 'Sorting-batRec' },
      { id: 'hydrMe', source: 'Sorting-batRec', target: 'HydroMet' },
      { id: 'material_batRec', source: 'HydroMet', target: 'material_recycling' },
      {
        id: 'reuse1',
        source: 'primary-battery-cat',
        target: 'cell_reuse_1',
        animated: input.batteryPack === 'Battery Pack 1' ? true : false,
      },
      { id: 'reuse2', source: 'second-battery-cat', target: 'cell_reuse_2', label: 'higher price' },
      {
        id: 'reuse3',
        source: 'ter-battery-cat',
        target: 'cell_reuse_2',
        label: 'lower price',
        animated: input.batteryPack === 'Battery Pack 2' ? true : false,
      },

      {
        id: 'sust-recycling',
        source: 'sorting',
        sourceHandle: 'a',
        target: 'discharge-nfit',
        label: 'not fit for upcycling',
        animated: input.batteryPack === 'Battery Pack 4' ? true : false,
      },
      {
        id: 'discharge-nfit',
        source: 'discharge-nfit',
        target: 'dismantel-nfit',
        animated: input.batteryPack === 'Battery Pack 4' ? true : false,
      },
      {
        id: 'cell-third-saf',
        source: 'dismantel-nfit',
        target: 'sustainable-recycling',
        animated: input.batteryPack === 'Battery Pack 4' ? true : false,
      },
      {
        id: 'shred-susRec',
        source: 'sustainable-recycling',
        target: 'shredding-susRec',
        animated: input.batteryPack === 'Battery Pack 4' ? true : false,
      },
      {
        id: 'separation-susRec',
        source: 'shredding-susRec',
        target: 'seperation',
        label: 'black mass',
        animated: input.batteryPack === 'Battery Pack 4' ? true : false,
      },
      {
        id: 'leaching-susRec',
        source: 'seperation',
        target: 'leaching',
        animated: input.batteryPack === 'Battery Pack 4' ? true : false,
      },
      {
        id: 'percification-susRec',
        source: 'leaching',
        target: 'percification',
        animated: input.batteryPack === 'Battery Pack 4' ? true : false,
      },
      { id: 'metal-susRec', source: 'percification', target: 'material-recovery' },
      { id: 'material-susRec', source: 'material-recovery', target: 'material-recycling' },
      {
        id: 'non-ferrous-susRec',
        source: 'shredding-susRec',
        target: 'non-ferrous',
        label: 'non-ferrous',
      },
      { id: 'ferrous-susRec', source: 'shredding-susRec', target: 'ferrous', label: 'ferrous' },

      {
        id: 'shred-nRec',
        source: 'normal-recycling',
        target: 'shredding-nRec',
        animated: input.batteryPack === 'Battery Pack 3' ? true : false,
      },
      {
        id: 'roasting-nRec',
        source: 'shredding-nRec',
        target: 'roasting-nRec',
        animated: input.batteryPack === 'Battery Pack 3' ? true : false,
      },
      {
        id: 'smelting-nRec',
        source: 'roasting-nRec',
        target: 'smelting-nRec',
        animated: input.batteryPack === 'Battery Pack 3' ? true : false,
      },
      {
        id: 'refining-nRec',
        source: 'smelting-nRec',
        target: 'refining-nRec',
        animated: input.batteryPack === 'Battery Pack 3' ? true : false,
      },
      {
        id: 'material-nRec',
        source: 'refining-nRec',
        target: 'metal-recovery-nRec',
        animated: input.batteryPack === 'Battery Pack 3' ? true : false,
      },
      { id: 'slag-nRec', source: 'smelting-nRec', target: 'slag-nRec' },
    ]);
    //eslint-disable-next-line
  }, [input]);

  const onConnect = useCallback<OnConnect>(
    (connection: Edge | Connection) => {
      setEdges(eds => addEdge(connection, eds));
    },
    [setEdges]
  );

  return (
    <div style={{ width: '100%', height: '90vh', overflowY: 'scroll' }}>
      <ReactFlow
        key={flowKey}
        nodes={nodes}
        nodeTypes={nodeTypes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
      >
        <Controls />
        {/* <MiniMap /> */}
        <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
      </ReactFlow>
    </div>
  );
}
