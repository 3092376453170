import React, { useEffect, useState } from 'react';
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { useQuery } from '@apollo/client';

import { WeeklyInventoryItem } from 'src/@types/Inventory';
import { styled } from '@mui/material/styles';
import { GET_WEEKLY_INVENTORY } from 'src/graphql/queries';
import { BATTERY_CATEGORIES } from 'src/constants/inventory';
import { BATTERY_WEIGHTS, CELL_WEIGHTS } from 'src/constants/inventory';

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: theme.palette.primary.dark,
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
}));

interface SeriesType {
  name: string;
  data: { x: number; y: number }[];
}

const InventoryTrackingChart: React.FC = () => {
  const [selectedBatteryCategory, setSelectedBatteryCategory] = useState<string>(
    BATTERY_CATEGORIES[0]
  );
  const [granularity, setGranularity] = useState<string>('Battery Pack');
  const [selectedModel, setSelectedModel] = useState<string>('Total');
  const [batteryModels, setBatteryModels] = useState<string[]>([]);
  const [selectedToggle, setSelectedToggle] = useState('Quantity');
  const [series, setSeries] = useState<SeriesType[]>([]);

  const { data } = useQuery(GET_WEEKLY_INVENTORY);

  const theme = useTheme();

  const updateSeriesData = ({
    newBatteryCategory = selectedBatteryCategory,
    newGranularity = granularity,
    newModel = selectedModel,
    newToggle = selectedToggle,
  }) => {
    let batteryCategoryRatio = 1;
    let weight = 1;

    if (newBatteryCategory !== 'Total') {
      batteryCategoryRatio =
        data?.getInventoryShare[newBatteryCategory]?.reduce(
          (acc: number, item: { capacity: number }) => acc + item.capacity,
          0
        ) || 1;
      if (newModel !== 'Total') {
        batteryCategoryRatio =
          data?.getInventoryShare[newBatteryCategory]?.find(
            (item: { name: string }) => item.name === newModel
          )?.capacity || 1;
      }
    }

    if (newToggle === 'Weight') {
      weight =
        newGranularity === 'Battery Pack'
          ? BATTERY_WEIGHTS[newBatteryCategory as keyof typeof BATTERY_WEIGHTS]
          : CELL_WEIGHTS[newBatteryCategory as keyof typeof CELL_WEIGHTS];
    }

    const granularityKey = newGranularity === 'Battery Pack' ? 'battery' : 'cell';
    const selectedCategoryData = data?.getInventoryByWeeks.map((item: WeeklyInventoryItem) => ({
      x: item.week,
      y: item[granularityKey] * batteryCategoryRatio * weight,
    }));

    setSeries([
      {
        name: newGranularity,
        data: selectedCategoryData || [],
      },
    ]);
  };

  const handleBatteryCategoryChange = (event: SelectChangeEvent) => {
    setSelectedBatteryCategory(event.target.value);
    updateSeriesData({ newBatteryCategory: event.target.value });
    const batteryModels =
      data?.getInventoryShare[event.target.value]?.map((item: { name: string }) => item.name) || [];
    setBatteryModels(batteryModels);
    setSelectedModel('Total');
  };

  const handleGranularityChange = (event: SelectChangeEvent) => {
    setGranularity(event.target.value);
    setSelectedBatteryCategory('Total');
    setSelectedModel('Total');
    setBatteryModels([]);
  };

  const handleToggleChange = (event: React.MouseEvent<HTMLElement>, newView: string) => {
    if (newView !== null) {
      setSelectedToggle(newView);
      updateSeriesData({ newToggle: newView });
    }
  };

  const handleModelChange = (event: SelectChangeEvent) => {
    setSelectedModel(event.target.value);
    updateSeriesData({ newModel: event.target.value });
  };

  useEffect(() => {
    if (data?.getInventoryByWeeks) {
      const inventoryData = data.getInventoryByWeeks as WeeklyInventoryItem[];

      const seriesData: SeriesType[] = [
        {
          name: granularity,
          data: inventoryData.map(item => ({
            x: item.week,
            y: granularity === 'Battery Pack' ? item.battery : item.cell,
          })),
        },
      ].filter(series => series.data.length > 0);

      setSeries(seriesData);
      setSelectedToggle('Quantity');
    }
  }, [data?.getInventoryByWeeks, granularity]);

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      type: 'numeric',
      labels: {
        formatter: (val: string | number) => {
          return Math.round(Number(val)).toString();
        },
      },
      title: {
        text: 'Week Number',
      },
    },
    yaxis: {
      title: {
        text: 'Units',
      },
      labels: {
        formatter: (val: number) => {
          return val.toFixed(0);
        },
      },
    },
    title: {
      text: 'Inventory By Weeks',
      align: 'left',
    },
    markers: {
      size: 4,
    },
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={6} lg={3}>
          <FormControl
            variant="filled"
            sx={{
              width: '100%',
            }}
          >
            <InputLabel id="battery-type-label">Battery Category</InputLabel>
            <Select
              labelId="battery-type-label"
              label="Commodity"
              value={selectedBatteryCategory}
              onChange={handleBatteryCategoryChange}
              variant="filled"
              sx={{
                flex: 1,
                marginRight: theme.spacing(2),
              }}
            >
              {BATTERY_CATEGORIES.map((category, index) => (
                <MenuItem key={index} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={3}>
          <FormControl
            variant="filled"
            sx={{
              width: '100%',
            }}
          >
            <InputLabel id="battery-type-label">Granularity</InputLabel>
            <Select
              labelId="granularity-label"
              label="Commodity"
              value={granularity}
              onChange={handleGranularityChange}
              variant="filled"
              sx={{
                flex: 1,
                marginRight: theme.spacing(2),
              }}
            >
              <MenuItem value="Battery Pack">Battery Pack</MenuItem>
              <MenuItem value="Cell">Cell</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={3}>
          <FormControl
            variant="filled"
            sx={{
              width: '100%',
            }}
          >
            <InputLabel id="model-label">Model</InputLabel>
            <Select
              labelId="model-label"
              label="Model"
              value={selectedModel}
              onChange={handleModelChange}
              variant="filled"
              sx={{
                flex: 1,
                marginRight: theme.spacing(2),
              }}
            >
              <MenuItem value="Total">Total</MenuItem>
              {batteryModels.map((model, index) => (
                <MenuItem key={index} value={model}>
                  {model}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={3}>
          <ToggleButtonGroup exclusive value={selectedToggle} onChange={handleToggleChange}>
            <StyledToggleButton value="Weight">Weight (KG)</StyledToggleButton>
            <StyledToggleButton value="Quantity">Quantity</StyledToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <ReactApexChart options={options} series={series} type="line" height={350} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default InventoryTrackingChart;
