import React from 'react';
import { Grid, Box, useTheme, alpha } from '@mui/material';
import { ChartContainer } from 'src/components/Structure';
import { useDataInsights } from 'src/components/hooks';
import { CellCondition } from 'src/@types/DataTypes';
import { roundToTwoDecimals } from 'src/utils/Number';

interface CellProps {
  condition: number;
}

const generateUniqueRandomNumbers = (min: number, max: number, count: number) => {
  if (max - min + 1 < count) {
    throw new Error('Range too small for the number of unique numbers requested.');
  }

  const uniqueNumbers = new Set();

  while (uniqueNumbers.size < count) {
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
    uniqueNumbers.add(randomNum);
  }

  return Array.from(uniqueNumbers);
};

const Cell: React.FC<CellProps> = ({ condition }) => {
  const theme = useTheme();
  const color =
    condition === 0
      ? alpha(theme.palette.primary.light, 0.5)
      : alpha(theme.palette.error.light, 0.5);
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: '4px',
        backgroundColor: color,
        padding: { xs: '11.5px 9px', sm: '11.5px 9px', md: '11.5px 9px', lg: '11.5px 20px' },
        margin: '2px 0px',
      }}
    />
  );
};

interface ClusterProps {
  clusterIndex: number;
  recyclableCells: number[];
}

const CellCluster: React.FC<ClusterProps> = ({ clusterIndex, recyclableCells }) => {
  const numRows = 10;
  const numColumns = 10;

  return (
    <Grid
      container
      spacing={0.5}
      sx={{
        display: 'flex',
        flexGrow: 1,
        height: '100%',
      }}
    >
      {Array.from(Array(numRows)).map((_, rowIndex) =>
        Array.from(Array(numColumns)).map((_, colIndex) => {
          const cellIndexWithinCluster = rowIndex * numColumns + colIndex;
          const cellIndex = clusterIndex * numRows * numColumns + cellIndexWithinCluster;
          const condition = recyclableCells.includes(cellIndex) ? 1 : 0;
          return (
            <Grid
              item
              xs={1.2}
              key={`${rowIndex}-${colIndex}`}
              sx={{ display: 'flex', flexGrow: 1 }}
            >
              <Cell condition={condition} />
            </Grid>
          );
        })
      )}
    </Grid>
  );
};

const Condition: React.FC<{
  aggregateConditionData?: Omit<CellCondition, 'client' | 'batteryCategory' | 'batteryType'>;
}> = ({ aggregateConditionData }) => {
  const { individualCellConditions } = useDataInsights();
  const theme = useTheme();
  const chartHeight = theme.spacing(50);
  const recyclablePct = aggregateConditionData
    ? roundToTwoDecimals(
        aggregateConditionData?.recycleablePct / aggregateConditionData?.totalCells
      )
    : 0;
  const recyclableIndices = generateUniqueRandomNumbers(
    1,
    100,
    Math.floor((recyclablePct * 100) / 100)
  ) as number[];
  return (
    <ChartContainer height={chartHeight} dataNotAvailable={individualCellConditions.length === 0}>
      <CellCluster clusterIndex={0} recyclableCells={recyclableIndices} />
    </ChartContainer>
  );
};

export default Condition;
