import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  List,
  ListItemButton,
  ListItemText,
  Box,
  useTheme,
  Stack,
  Dialog,
  FormControl,
  IconButton,
  Slide,
  CircularProgress,
  MenuItem,
  Select,
  InputLabel,
  SelectChangeEvent,
  useMediaQuery,
  DialogTitle,
  Divider,
  DialogContent,
  Link,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';

import { TEXT_CONTENTS } from 'src/constants';
import { isOffice365Url, toTitleCase } from 'src/utils/Text';
import { useQuery } from '@apollo/client';
import { BatteryDatabase, BatteryPackModelType } from 'src/@types/BatteryDatabase';
import { GET_BATTERY_PACK_WITH_ALL_FIELDS } from 'src/graphql/queries';
import { COLUMNS_TO_SHOW, MASTER_DATA_TABLE_HEADER_MAPPING } from 'src/constants/masterData';
import { isFloat, roundToTwoDecimals } from 'src/utils/Number';

interface BatteryPackDetailsProps {
  oemName: string;
  handleClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BatteryPackDetails: React.FC<BatteryPackDetailsProps> = ({ oemName, handleClose }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { loading, error, data } = useQuery<{ getBatteryDatabase: BatteryDatabase[] }>(
    GET_BATTERY_PACK_WITH_ALL_FIELDS,
    { variables: { filter: { oemName: oemName || '' } } }
  );
  const [oemData, setOemData] = useState<BatteryDatabase[]>([]);
  const [selectedBatteryPackIndex, setSelectedBatteryPackIndex] = useState(0);

  useEffect(() => {
    setSelectedBatteryPackIndex(0);
    if (data?.getBatteryDatabase) {
      const sortedData = [...data.getBatteryDatabase].sort((a, b) => {
        const nameA = a.batteryPackModelName || '';
        const nameB = b.batteryPackModelName || '';
        return nameA.localeCompare(nameB);
      });
      setOemData(sortedData);
    }
  }, [oemName, data?.getBatteryDatabase]);

  const getBatteryPackDetails = (index: number, showEmptyValues: boolean) => {
    const batteryPack = data?.getBatteryDatabase[index];
    if (!batteryPack) return [];

    const { batteryPackModelType } = batteryPack;
    return Object.entries(batteryPack).filter(([key, value]) => {
      const isRelevantColumn =
        batteryPackModelType &&
        COLUMNS_TO_SHOW[batteryPackModelType as BatteryPackModelType].includes(key);

      if (!isRelevantColumn) return false;

      const isEmptyValue = value === null || value === '' || value === undefined;
      return showEmptyValues ? isEmptyValue : !isEmptyValue;
    });
  };

  const onClickBatteryPack = (index: number) => () => {
    setSelectedBatteryPackIndex(index);
  };

  const onChangeBatteryPack = (event: SelectChangeEvent<string>) => {
    const index = parseInt(event.target.value);
    setSelectedBatteryPackIndex(index);
  };

  const onClickViewInTable = () => {
    const filter = data?.getBatteryDatabase[selectedBatteryPackIndex]?.batteryPackModelName;
    const url = filter
      ? `/${oemData[selectedBatteryPackIndex]?.batteryPackModelType}-master-data?filter=${encodeURIComponent(filter)}`
      : `/${oemData[selectedBatteryPackIndex]?.batteryPackModelType}-master-data`;
    navigate(url);
  };

  const getFormattedValue = (value: string | number | boolean) => {
    if (typeof value === 'number') return roundToTwoDecimals(value);
    if (typeof value === 'string' && isFloat(value)) {
      return roundToTwoDecimals(parseFloat(value));
    }
    if (typeof value === 'string' && isOffice365Url(value))
      return (
        <Link
          target="_blank"
          href={TEXT_CONTENTS.MASTER_DATA.CIRCU_LI_ION_SHAREPOINT_PREFIX + value}
        >
          View
        </Link>
      );
    return value;
  };

  if (loading) return <CircularProgress color="primary" />;

  return (
    <Dialog
      maxWidth="md"
      fullScreen={fullScreen}
      fullWidth
      TransitionComponent={Transition}
      open={oemName !== ''}
      onClose={handleClose}
    >
      <DialogTitle>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography sx={{ flex: 1 }} variant="h4" component="div">
            {oemName}
          </Typography>
          <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ position: 'relative', width: '100%', overflow: 'auto', p: 0 }}>
        {error && <Typography variant="body1">{TEXT_CONTENTS.COMMON.ERROR}</Typography>}
        {data?.getBatteryDatabase && (
          <Box
            sx={{
              height: { xs: theme.spacing(95), sm: theme.spacing(85) },
              bgcolor: 'background.paper',
              borderRadius: theme.spacing(1),
            }}
            display="flex"
          >
            <Box
              sx={{
                height: '100%',
                display: { xs: 'none', sm: 'block' },
              }}
            >
              <List
                sx={{
                  maxWidth: '225px',
                  width: 'max-content',
                  height: '100%',
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                  scrollbarWidth: 'none',
                  '-ms-overflow-style': 'none',
                  borderRight: `5px solid ${theme.palette.primary.main}`,
                }}
                aria-label="battery-pack-list"
              >
                {oemData.map((battery, index) => (
                  <Box
                    key={index}
                    sx={{
                      color:
                        selectedBatteryPackIndex === index
                          ? theme.palette.primary.main
                          : theme.palette.secondary.main,
                    }}
                  >
                    <ListItemButton
                      onClick={onClickBatteryPack(index)}
                      selected={selectedBatteryPackIndex === index}
                      divider={true}
                    >
                      <ListItemText primary={battery?.batteryPackModelName} />
                    </ListItemButton>
                  </Box>
                ))}
              </List>
            </Box>
            <Box
              sx={{
                height: '100%',
                width: '100%',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                scrollbarWidth: 'none',
                '-ms-overflow-style': 'none',
                padding: theme.spacing(2),
              }}
            >
              <Box sx={{ marginBottom: theme.spacing(2) }}>
                <FormControl fullWidth sx={{ display: { xs: 'flex', sm: 'none' }, mb: 2 }}>
                  <InputLabel id="battery-pack-select-label">
                    {TEXT_CONTENTS.MASTER_DATA.BATTERY_PACK_DROPDOWN_LABEL}
                  </InputLabel>
                  <Select
                    labelId="battery-pack-select-label"
                    label={TEXT_CONTENTS.MASTER_DATA.BATTERY_PACK_DROPDOWN_LABEL}
                    value={selectedBatteryPackIndex.toString()}
                    onChange={onChangeBatteryPack}
                  >
                    {oemData.map((battery, index) => (
                      <MenuItem key={index} value={index} divider={true}>
                        {battery?.batteryPackModelName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography variant="h3" color="primary">
                  {oemData[selectedBatteryPackIndex]?.batteryPackModelName}
                </Typography>
                <Typography variant="subtitle1" color="secondary">
                  {TEXT_CONTENTS.MASTER_DATA.BATTERY_PACK_TYPE} -{' '}
                  {oemData[selectedBatteryPackIndex]?.batteryPackModelType}
                </Typography>
              </Box>
              <Box>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={onClickViewInTable}
                  sx={{ mb: theme.spacing(2) }}
                >
                  {TEXT_CONTENTS.MASTER_DATA.VIEW_IN_TABLE}
                </Button>
                <Typography variant="h5" color="secondary" mb={2}>
                  {TEXT_CONTENTS.MASTER_DATA.BATTERY_PACK_DETAILS}
                </Typography>
                {getBatteryPackDetails(selectedBatteryPackIndex, false).map(([key, value]) => (
                  <Stack
                    key={key}
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    sx={{ marginBottom: theme.spacing(1) }}
                  >
                    <Typography variant="body1" color="black">
                      {MASTER_DATA_TABLE_HEADER_MAPPING[
                        key as keyof typeof MASTER_DATA_TABLE_HEADER_MAPPING
                      ] || toTitleCase(key)}
                    </Typography>
                    <Typography key={key} variant="body1" color="black">
                      {getFormattedValue(value)}
                    </Typography>
                  </Stack>
                ))}
                <Typography variant="h6" color="secondary" mb={2}>
                  {TEXT_CONTENTS.MASTER_DATA.MISSING_VALUES}
                </Typography>
                {getBatteryPackDetails(selectedBatteryPackIndex, true).map(([key, value]) => (
                  <Stack
                    key={key}
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    sx={{ marginBottom: theme.spacing(1) }}
                  >
                    <Typography variant="body1" color="black">
                      {MASTER_DATA_TABLE_HEADER_MAPPING[
                        key as keyof typeof MASTER_DATA_TABLE_HEADER_MAPPING
                      ] || toTitleCase(key)}
                    </Typography>
                    <Typography key={key} variant="body1" color="black">
                      {value || '-'}
                    </Typography>
                  </Stack>
                ))}
              </Box>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default BatteryPackDetails;
