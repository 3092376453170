import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PrivateRoute } from 'src/components/Auth';
import { HeadProvider } from 'src/components/Contexts';
import { TEXT_CONTENTS } from 'src/constants';
import { Login, Signup, ForgotPassword, NotFound } from 'src/containers';
import AuthenticatedRoutes from 'src/routes/authenticatedRoutes';
import PreviewRoutes from 'src/routes/previewRoutes';
import { useGoogleAnalytics } from 'src/components/hooks';
import { ClickProvider } from 'src/components/Contexts/FeedbackContext';
import { DrawerProvider } from 'src/components/Contexts/DrawerContext';
import FeedbackModal from './components/Structure/FeedbackModal';

const App = () => {
  const { trackPageView } = useGoogleAnalytics();

  useEffect(() => {
    trackPageView(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Helmet>
        <meta name="title" content={TEXT_CONTENTS.META.TITLE} />
        <meta name="description" content={TEXT_CONTENTS.META.DESCRIPTION} />
        <meta name="theme-color" content="#ecedee" />
      </Helmet>
      <HeadProvider>
        <BrowserRouter>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <ClickProvider>
              <DrawerProvider>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path={'/signup'} element={<Signup />} />
                  <Route path={'/forgot-password'} element={<ForgotPassword />} />
                  <Route
                    path="/*"
                    element={
                      <PrivateRoute>
                        <React.Fragment>
                          <PreviewRoutes />
                          <AuthenticatedRoutes />
                        </React.Fragment>
                      </PrivateRoute>
                    }
                  />
                  <Route path="*" element={<NotFound />} />
                </Routes>
                <FeedbackModal />
              </DrawerProvider>
            </ClickProvider>
          </QueryParamProvider>
        </BrowserRouter>
      </HeadProvider>
    </Fragment>
  );
};

export default App;
