import React, { ChangeEvent, FormEvent } from 'react';
import { Box, Button, Link, Paper, TextField, Typography, useTheme } from '@mui/material';
import { TEXT_CONTENTS } from 'src/constants';
import { SignInFormData } from 'src/@types/AuthTypes';
import ShowPasswordButton from 'src/components/Auth/ShowPasswordButton';

interface LoginFormProps {
  signInFormData: SignInFormData;
  setSignInFormData: React.Dispatch<React.SetStateAction<SignInFormData>>;
  handleSubmit: (event: FormEvent) => void;
}

const LoginForm = ({ signInFormData, setSignInFormData, handleSubmit }: LoginFormProps) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSignInFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: { xs: '85vh', sm: '65vh', md: '80vh', lg: '60vh' },
        width: { xs: '90%', sm: '70%', md: 400, lg: 400 },
      }}
    >
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography
          variant="h4"
          gutterBottom
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {TEXT_CONTENTS.LOGIN.SIGNIN_HEADER}
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            name="email"
            label="Email"
            type="email"
            variant="filled"
            color="primary"
            value={signInFormData.email}
            onChange={handleChange}
            required
          />

          <TextField
            fullWidth
            margin="normal"
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            variant="filled"
            color="primary"
            value={signInFormData.password}
            onChange={handleChange}
            required
            InputProps={{
              endAdornment: (
                <ShowPasswordButton showPassword={showPassword} setShowPassword={setShowPassword} />
              ),
            }}
          />

          <Link
            href={'/forgot-password'}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            <Typography
              variant="body2"
              sx={{
                textDecoration: 'underline',
                color: theme.palette.primary.main,
              }}
            >
              {TEXT_CONTENTS.LOGIN.FORGOT_PASSWORD}
            </Typography>
          </Link>

          <Box mt={3} mb={2}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Login
            </Button>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Link href={'/signup'}>
              <Typography
                variant="body2"
                sx={{
                  textDecoration: 'underline',
                  color: theme.palette.primary.main,
                }}
              >
                {TEXT_CONTENTS.SIGNUP.LINK_TEXT}
              </Typography>
            </Link>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default LoginForm;
