import { gql } from '@apollo/client';

// Define fragments
export const CELL_INSIGHTS_FRAGMENT = gql`
  fragment CellInsights on CellInsights {
    cellCondition {
      client
      batteryCategory
      batteryType
      reusablePct
      recycleablePct
      totalCells
    }
    types {
      client
      batteryCategory
      batteryType
      label
      minimum
      q1
      median
      q3
      maximum
      outliers
    }
    testedCells {
      client
      batteryCategory
      batteryType
      manufacturer
      types {
        label
        value
      }
    }
  }
`;

export const MATERIAL_INSIGHTS_FRAGMENT = gql`
  fragment MaterialInsights on MaterialInsights {
    metalsPrice {
      name
      price
      timestamp
    }
    batteryComponents {
      batteryCategory
      label
      value
    }
    cellMaterials {
      batteryCategory
      label
      value
    }
  }
`;

export const BATTERY_INSIGHTS_FRAGMENT = gql`
  fragment BatteryInsights on BatteryInsights {
    yearly {
      label
      value
    }
    monthly {
      categories
      series {
        name
        data
      }
    }
    batteryDataInsights {
      year
      month
      batteryCategory
      batteryType
      numberBatteryPacks
      weightKg
    }
  }
`;

export const FACILITY_INSIGHTS_FRAGMENT = gql`
  fragment FacilityInsights on FacilityInsights {
    facilitiesCount {
      active
      total
    }
    facilitiesLocation {
      facilityId
      facilityName
      latitude
      longitude
      status
      openingDate
      isEvProcessible
      isPtProcessible
      isMmProcessible
      capacityPerMonth
      isActive
    }
  }
`;
