import App from './App';
import React from 'react';
import ApolloProvider from './graphql/ApolloProvider';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from 'src/styles/theme';
import ReactDOM from 'react-dom/client';
import { Firebase, FirebaseProvider, MessageProvider } from './components/Contexts';
import { ConfigProvider } from './config';
import { UserSettingsProvider } from 'src/components/Contexts/SettingsContext';
import ReactGA from 'react-ga4';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID as string);
// Mock Firebase Auth
const useFirebaseAuthMock = process.env.REACT_APP_FIREBASE_USE_EMULATOR as string;
const firebase = new Firebase(useFirebaseAuthMock === 'true' ? true : false);

// Get GraphQL endpoint from environment variable
const graphqlEndpoint = process.env.REACT_APP_GQL_ENDPOINT || 'http://localhost:4000/graphql';
// @ts-expect-error("Something that is unsafe happens here")
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <DevSupport>
  <ConfigProvider>
    <ThemeProvider theme={theme}>
      <ApolloProvider firebase={firebase} graphqlEndpoint={graphqlEndpoint}>
        <FirebaseProvider firebase={firebase}>
          <UserSettingsProvider>
            <MessageProvider>
              <CssBaseline />
              <App />
            </MessageProvider>
          </UserSettingsProvider>
        </FirebaseProvider>
      </ApolloProvider>
    </ThemeProvider>
  </ConfigProvider>
  // </DevSupport>
);
