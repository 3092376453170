import { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_SETTINGS } from 'src/graphql/queries';
import UserSettingsContext from 'src/components/Contexts/SettingsContext';

const useUserSettings = () => {
  const { loading, data } = useQuery(GET_SETTINGS);
  const { currency, darkMode, setCurrency, setDarkMode } = useContext(UserSettingsContext);
  useEffect(() => {
    if (!loading && data?.getUserSettings) {
      setCurrency(data.getUserSettings.currency);
      setDarkMode(data.getUserSettings.darkMode);
    }
  }, [loading, data, setCurrency, setDarkMode]);

  return { loading, currency, darkMode, setCurrency, setDarkMode };
};

export default useUserSettings;
