import React, { useState } from 'react';
import {
  Grid,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  useTheme,
  SelectChangeEvent,
} from '@mui/material';

import { ChartWrapper, PageContainer, Title } from 'src/components/Structure';
import TopEvSalesChart from 'src/components/Charts/MarketInsights/TopEvSalesChart';
import { TEXT_CONTENTS } from 'src/constants';
import { BRAND_LEVEL_SALES } from 'src/constants/marketInsights';
import ModelSalesChart from 'src/components/Charts/MarketInsights/BrandSalesPerModel';

const MarketInsights = () => {
  const theme = useTheme();
  const brands = Object.keys(BRAND_LEVEL_SALES);
  const [selectedBrand, setSelectedBrand] = useState(brands[0]);

  const handleBrandChange = (event: SelectChangeEvent<string>) => {
    setSelectedBrand(event.target.value as string);
  };

  return (
    <PageContainer>
      <Title variant="h1">{TEXT_CONTENTS.CHARTS.MARKET_INSIGHTS.TITLE}</Title>
      <Grid container spacing={2} sx={{ display: 'flex', marginBottom: theme.spacing(2) }}>
        <Grid item xs={12} sm={6} md={12} lg={12}>
          <ChartWrapper title={TEXT_CONTENTS.CHARTS.MARKET_INSIGHTS.TOP_BRANDS_SALES.TITLE}>
            <TopEvSalesChart />
          </ChartWrapper>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <ChartWrapper
            title={`${TEXT_CONTENTS.CHARTS.MARKET_INSIGHTS.SELECTED_BRAND_SALES.TITLE}`}
          >
            <FormControl sx={{ width: '50%', ml: theme.spacing(2) }}>
              <InputLabel id="brand-select">Brand</InputLabel>
              <Select
                labelId="brand-select"
                id="brand-select"
                value={selectedBrand}
                variant="filled"
                label="Brand"
                onChange={handleBrandChange}
              >
                {brands.map(brand => (
                  <MenuItem key={brand} value={brand}>
                    {brand}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <ModelSalesChart brand={selectedBrand} />
          </ChartWrapper>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default MarketInsights;
