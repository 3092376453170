import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Grid, Typography, Pagination, Box } from '@mui/material';
import { TEXT_CONTENTS } from 'src/constants';
import { useTheme } from '@mui/material/styles';
import { GET_OEM_META_DATA } from 'src/graphql/queries';
import { BatteryPackModelType, OemMetaData } from 'src/@types/BatteryDatabase';
import {
  EV_BRAND_LOGO_MAPPING,
  MM_BRAND_LOGO_MAPPING,
  PT_BRAND_LOGO_MAPPING,
} from 'src/constants/batteryDetails';
import Circu_li_ion_logo from 'src/assets/svgs/circuli-ion.svg';
import MasterDataOemCard from 'src/components/MasterData/MasterDataOemCard';
import BatteryPackDetails from 'src/components/MasterData/BatteryPackDetails';

const ITEMS_PER_PAGE = 18;

interface CardViewProps {
  batteryPackModelType: BatteryPackModelType;
}

const CardView: React.FC<CardViewProps> = ({ batteryPackModelType }) => {
  const theme = useTheme();
  const { loading, error, data } = useQuery<{ getBatteryOemMetaData: OemMetaData[] }>(
    GET_OEM_META_DATA,
    { variables: { filter: { batteryPackModelType } } }
  );
  const [currentData, setCurrentData] = useState<OemMetaData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (data?.getBatteryOemMetaData) {
      const sortedData = [...data.getBatteryOemMetaData]
        .sort((a, b) => {
          return a.oemName.localeCompare(b.oemName);
        })
        .filter(oem => oem.batteryPacksCount > 0 && oem.oemName);
      setCurrentData(sortedData);
    }
  }, [data?.getBatteryOemMetaData]);

  const getLogo = (oemName: string) => {
    let logo;
    if (batteryPackModelType === BatteryPackModelType.EV) {
      logo = EV_BRAND_LOGO_MAPPING[oemName as keyof typeof EV_BRAND_LOGO_MAPPING];
    }
    if (batteryPackModelType === BatteryPackModelType.MM) {
      logo = MM_BRAND_LOGO_MAPPING[oemName as keyof typeof MM_BRAND_LOGO_MAPPING];
    }
    if (batteryPackModelType === BatteryPackModelType.PT) {
      logo = PT_BRAND_LOGO_MAPPING[oemName as keyof typeof PT_BRAND_LOGO_MAPPING];
    }
    return logo || Circu_li_ion_logo;
  };
  const [selectedOem, setSelectedOem] = useState<string>('');

  const handleCardClick = (manufacturer: string) => () => {
    setSelectedOem(manufacturer);
  };

  const handleCloseBatteryPackDetails = () => {
    setSelectedOem('');
  };

  if (loading) return <Typography variant="body1">{TEXT_CONTENTS.COMMON.LOADING}</Typography>;

  if (currentData.length === 0)
    return <Typography variant="body1">{TEXT_CONTENTS.MASTER_DATA.NO_DATA_FOUND}</Typography>;

  if (error) return <Typography variant="body1">{TEXT_CONTENTS.COMMON.ERROR}</Typography>;

  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = currentData.slice(indexOfFirstItem, indexOfLastItem);

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <Box>
      <Grid
        container
        spacing={1}
        sx={{
          marginTop: theme.spacing(2),
          display: 'flex',
          justifyContent: 'flex-start',
          flexGrow: 1,
        }}
      >
        {currentItems.map(({ oemName, batteryPacksCount }, index) => (
          <Grid item xs={6} sm={6} md={2} lg={2} key={index}>
            <MasterDataOemCard
              name={oemName}
              count={batteryPacksCount}
              logo={getLogo(oemName)}
              handleClick={handleCardClick(oemName)}
            />
          </Grid>
        ))}
      </Grid>
      {currentData.length > ITEMS_PER_PAGE && (
        <Pagination
          count={Math.ceil(currentData.length / ITEMS_PER_PAGE)}
          page={currentPage}
          onChange={handleChangePage}
          sx={{ marginTop: theme.spacing(3), justifyContent: 'center', display: 'flex' }}
        />
      )}
      <BatteryPackDetails oemName={selectedOem} handleClose={handleCloseBatteryPackDetails} />
    </Box>
  );
};

export default CardView;
