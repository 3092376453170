import { useQuery } from '@apollo/client';
import { GET_DATA_INSIGHTS } from 'src/graphql/queries';
import { CellCondition, DataInsights } from 'src/@types/DataTypes';
import seedrandom from 'seedrandom';

const NUMBER_OF_INDIVIDUAL_CELLS_TO_BE_DISPLAYED = 360;

interface DataInsightsValues {
  cellInsights?: DataInsights['cellInsights'];
  materialInsights?: DataInsights['materialInsights'];
  batteryInsights?: DataInsights['batteryInsights'];
  facilityInsights?: DataInsights['facilityInsights'];
  individualCellConditions: number[];
  currencyRates?: DataInsights['currencyRates'];
  loading: boolean;
}

const generatePseudoRandomSeries = (
  seed: string,
  length: number,
  minValue: number,
  maxValue: number
) => {
  const rng = seedrandom(seed);
  const series = [];

  for (let i = 0; i < length; i++) {
    const randomNumber = rng() * (maxValue - minValue) + minValue;
    series.push(randomNumber);
  }

  return series;
};

const useDataInsights = (): DataInsightsValues => {
  const {
    data: { cellInsights, materialInsights, batteryInsights, facilityInsights, currencyRates } = {},
    loading,
  } = useQuery<DataInsights>(GET_DATA_INSIGHTS, {
    // fetchPolicy: 'cache-only', // Fetch from cache only
  });

  const accumulatedData = cellInsights?.cellCondition.reduce(
    (acc, curr) => {
      if (!curr) return acc;

      acc.reusablePct += +curr.reusablePct * curr.totalCells;
      acc.recycleablePct += +curr.recycleablePct * curr.totalCells;
      acc.totalCells += +curr.totalCells;

      return acc;
    },
    {
      reusablePct: 0,
      recycleablePct: 0,
      totalCells: 0,
    } as Omit<CellCondition, 'client' | 'batteryCategory' | 'batteryType'>
  );

  // calculate ratio of reusable cells
  let toRecycle = 0;
  let toReuse = 0;
  let reuseRatio = 0;

  if (accumulatedData) {
    toRecycle = parseInt(`${accumulatedData.recycleablePct / accumulatedData.totalCells}`, 10);
    toReuse = parseInt(`${accumulatedData.reusablePct / accumulatedData.totalCells}`);
    reuseRatio = toReuse / (toRecycle + toReuse);
  }

  // calculate seed based on reuseRatio
  const seed = `seed${reuseRatio.toFixed(2)}`;

  // generate pseudo random series
  const pseudoRandomSeries = generatePseudoRandomSeries(
    seed,
    NUMBER_OF_INDIVIDUAL_CELLS_TO_BE_DISPLAYED,
    0,
    1
  );

  return {
    cellInsights,
    materialInsights,
    batteryInsights,
    facilityInsights,
    currencyRates,
    individualCellConditions: pseudoRandomSeries.map(value => {
      return value < reuseRatio ? 0 : 1;
    }),
    loading,
  };
};

export default useDataInsights;
