import React, { useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import { ChartProps } from 'src/@types/DataTypes';
import { ToggleButtonValues } from 'src/components/Charts/Filters/BatteryFilters';
import YearlyProcessed from './Yearly';
import MonthlyProcessed from './Monthly';
import { ChartWrapper } from 'src/components/Structure';
import { TEXT_CONTENTS } from 'src/constants';

interface Props extends ChartProps {
  selectedBatteryType: string;
  selectedBatteryCategory: string;
  selectedToggleValue: ToggleButtonValues;
}

enum TimeGranularity {
  YEARLY = 'Yearly',
  MONTHLY = 'Monthly',
}

const BatteryPacksProcessed: React.FC<Props> = ({
  selectedBatteryType,
  selectedBatteryCategory,
  selectedToggleValue,
}) => {
  const [timeGranularity, setTimeGranularity] = useState(TimeGranularity.YEARLY);

  const handleTimeGranularityChange = (
    event: React.MouseEvent<HTMLElement>,
    newTimeGranularity: TimeGranularity | null
  ) => {
    if (newTimeGranularity !== null) {
      setTimeGranularity(newTimeGranularity);
    }
  };

  const getChartTitle = () => {
    if (TimeGranularity.MONTHLY === timeGranularity) {
      return selectedToggleValue === ToggleButtonValues.COUNT
        ? TEXT_CONTENTS.CHARTS.BATTERY_INSIGHTS.MONTHLY.TITLE
        : TEXT_CONTENTS.CHARTS.BATTERY_INSIGHTS.MONTHLY.TITLE_KG;
    }

    return selectedToggleValue === ToggleButtonValues.COUNT
      ? TEXT_CONTENTS.CHARTS.BATTERY_INSIGHTS.YEARLY.TITLE
      : TEXT_CONTENTS.CHARTS.BATTERY_INSIGHTS.YEARLY.TITLE_KG;
  };

  const getChartId = () => {
    return TimeGranularity.YEARLY === timeGranularity
      ? TEXT_CONTENTS.CHARTS.BATTERY_INSIGHTS.YEARLY.ID
      : TEXT_CONTENTS.CHARTS.BATTERY_INSIGHTS.MONTHLY.ID;
  };

  return (
    <ChartWrapper title={getChartTitle()} chartId={getChartId()}>
      <ToggleButtonGroup
        value={timeGranularity}
        exclusive
        onChange={handleTimeGranularityChange}
        aria-label="time granularity"
        sx={{ marginBottom: 2 }}
      >
        <ToggleButton value={TimeGranularity.YEARLY} aria-label={TimeGranularity.YEARLY}>
          {TimeGranularity.YEARLY}
        </ToggleButton>
        <ToggleButton value={TimeGranularity.MONTHLY} aria-label={TimeGranularity.MONTHLY}>
          {TimeGranularity.MONTHLY}
        </ToggleButton>
      </ToggleButtonGroup>
      {TimeGranularity.YEARLY === timeGranularity ? (
        <YearlyProcessed
          chartId={getChartId()}
          selectedBatteryType={selectedBatteryType}
          selectedBatteryCategory={selectedBatteryCategory}
          selectedToggleValue={selectedToggleValue}
        />
      ) : (
        <MonthlyProcessed
          detailedView={true}
          chartId={getChartId()}
          selectedBatteryType={selectedBatteryType}
          selectedBatteryCategory={selectedBatteryCategory}
          selectedToggleValue={selectedToggleValue}
        />
      )}
    </ChartWrapper>
  );
};

export default BatteryPacksProcessed;
