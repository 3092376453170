const CELL_DETAILS = [
  {
    name: 'Samsung INR18650-25R',
    model: 'INR18650-25R',
    composition: 'Li-ion',
    company: 'Samsung',
    image:
      'https://static.wixstatic.com/media/92f430_e01c0ca27d6f449f80032743d082fcb9~mv2.png/v1/fill/w_244,h_718,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Samsung%2025R-1.png',
    data: {
      overview: [
        { name: 'Manufacturer', value: 'Samsung' },
        { name: 'Model', value: 'INR18650-25R' },
        { name: 'Type', value: 'Li-ion' },
        { name: 'Form factor', value: 'Cylindrical 18650' },
        { name: 'Country of origin', value: 'South Korea' },
        { name: 'Release year', value: '2014' },
      ],
      electrical: [
        { name: 'Nominal Voltage [V]', value: '3.6' },
        { name: 'Energy capacity [Wh]', value: '9' },
        { name: 'Capacity [Ah]', value: '2.5' },
        { name: 'Continuous charge [A]', value: '1.25' },
        { name: 'Max continuous charge [A]', value: '4' },
        { name: 'Continuous discharge [A]', value: '0.5' },
        { name: 'Max continuous discharge [A]', value: '20' },
        { name: 'Charge cut-off [V]', value: '4.2' },
        { name: 'Discharge cut-off [V]', value: '2.5' },
      ],
      geometry: [
        { name: 'Height [mm]', value: '64.85' },
        { name: 'Diameter [mm]', value: '18.33' },
        { name: 'Volume [cm³]', value: '17.11' },
        { name: 'Mass [g]', value: '45' },
      ],
      chemistry: [
        { name: 'Positive electrode (cathode)', value: '-' },
        { name: 'Negative electrode (anode)', value: '-' },
      ],
    },
  },
  {
    name: 'LithiumWerks APR18650M1B',
    model: 'APR18650M1B',
    composition: 'Li-ion',
    company: 'LithiumWerks',
    image:
      'https://static.wixstatic.com/media/92f430_6775325ae85646d7a9f8968c00af31b6~mv2.png/v1/fill/w_196,h_718,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Lithiumwerks%20M1B%20(18650)%20-%202.png',
    data: {
      overview: [
        { name: 'Manufacturer', value: 'LithiumWerks' },
        { name: 'Model', value: 'APR18650M1B' },
        { name: 'Type', value: 'Li-ion' },
        { name: 'Form factor', value: 'Cylindrical 18650' },
        { name: 'Country of origin', value: 'China' },
        { name: 'Release year', value: '2019' },
      ],
      electrical: [
        { name: 'Nominal Voltage [V]', value: '3.3' },
        { name: 'Energy capacity [Wh]', value: '3.63' },
        { name: 'Capacity [Ah]', value: '1.2' },
        { name: 'Continuous charge [A]', value: '1.5' },
        { name: 'Max continuous charge [A]', value: '4' },
        { name: 'Continuous discharge [A]', value: '-' },
        { name: 'Max continuous discharge [A]', value: '30' },
        { name: 'Charge cut-off [V]', value: '3.6' },
        { name: 'Discharge cut-off [V]', value: '2' },
      ],
      geometry: [
        { name: 'Height [mm]', value: '64.95' },
        { name: 'Diameter [mm]', value: '18.3' },
        { name: 'Volume [cm³]', value: '17.1' },
        { name: 'Mass [g]', value: '39.5' },
      ],
      chemistry: [
        { name: 'Positive electrode (cathode)', value: 'LFP' },
        { name: 'Negative electrode (anode)', value: 'Graphite' },
      ],
    },
  },
  {
    name: 'Samsung INR21700-50E',
    model: 'INR21700-50E',
    composition: 'Li-ion',
    company: 'Samsung',
    image:
      'https://static.wixstatic.com/media/92f430_1f7c4649aa4547de95c3b07911dab4c0~mv2.png/v1/fill/w_205,h_718,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Samsung%2050E%20(21700)-2.png',
    data: {
      overview: [
        { name: 'Manufacturer', value: 'Samsung' },
        { name: 'Model', value: 'INR21700-50E' },
        { name: 'Type', value: 'Li-ion' },
        { name: 'Form factor', value: 'Cylindrical 21700' },
        { name: 'Country of origin', value: 'Korea' },
        { name: 'Release year', value: '2017' },
      ],
      electrical: [
        { name: 'Nominal Voltage [V]', value: '3.6' },
        { name: 'Energy capacity [Wh]', value: '17.64' },
        { name: 'Capacity [Ah]', value: '4.9' },
        { name: 'Continuous charge [A]', value: '2.45' },
        { name: 'Max continuous charge [A]', value: '4.9' },
        { name: 'Continuous discharge [A]', value: '0.98' },
        { name: 'Max continuous discharge [A]', value: '9.8' },
        { name: 'Charge cut-off [V]', value: '4.2' },
        { name: 'Discharge cut-off [V]', value: '2.5' },
      ],
      geometry: [
        { name: 'Height [mm]', value: '70.8' },
        { name: 'Diameter [mm]', value: '20.25' },
        { name: 'Volume [cm³]', value: '22.8' },
        { name: 'Mass [g]', value: '69' },
      ],
      chemistry: [
        { name: 'Positive electrode (cathode)', value: '-' },
        { name: 'Negative electrode (anode)', value: '-' },
      ],
    },
  },
  {
    name: 'Sanyo NCR18650G-H09NA',
    model: 'NCR18650G-H09NA',
    composition: 'Li-ion',
    company: 'Sanyo',
    image: 'https://www.fogstar.co.uk/cdn/shop/products/Batteries_Pics_5_1078x1078.png',
    data: {
      overview: [
        { name: 'Manufacturer', value: 'Sanyo' },
        { name: 'Model', value: 'NCR18650G-H09NA' },
        { name: 'Type', value: 'Li-ion' },
        { name: 'Form factor', value: 'Cylindrical 18650' },
        { name: 'Country of origin', value: 'Japan' },
        { name: 'Release year', value: '2015' },
      ],
      electrical: [
        { name: 'Nominal Voltage [V]', value: '3.6' },
        { name: 'Energy capacity [Wh]', value: '12.42' },
        { name: 'Capacity [Ah]', value: '3.35' },
        { name: 'Continuous charge [A]', value: '1.675' },
        { name: 'Max continuous charge [A]', value: '-' },
        { name: 'Continuous discharge [A]', value: '0.67' },
        { name: 'Max continuous discharge [A]', value: '10' },
        { name: 'Charge cut-off [V]', value: '4.2' },
        { name: 'Discharge cut-off [V]', value: '2.5' },
      ],
      geometry: [
        { name: 'Height [mm]', value: '65.1' },
        { name: 'Diameter [mm]', value: '18.5' },
        { name: 'Volume [cm³]', value: '17.5' },
        { name: 'Mass [g]', value: '48' },
      ],
      chemistry: [
        { name: 'Positive electrode (cathode)', value: '-' },
        { name: 'Negative electrode (anode)', value: '-' },
      ],
    },
  },
  {
    name: 'Samsung INR21700-48X',
    model: 'INR21700-48X',
    composition: 'Li-ion',
    company: 'Samsung',
    image:
      'https://static.wixstatic.com/media/92f430_10ed14b76696408bb804b186946f1ac8~mv2.png/v1/fill/w_192,h_718,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/INR21700-48X%20SAMSUNG%20SDI%202I81%20-%202.png',
    data: {
      overview: [
        { name: 'Manufacturer', value: 'Samsung' },
        { name: 'Model', value: 'INR21700-48X' },
        { name: 'Type', value: 'Li-ion' },
        { name: 'Form factor', value: 'Cylindrical 21700' },
        { name: 'Country of origin', value: 'Korea' },
        { name: 'Release year', value: '2019' },
      ],
      electrical: [
        { name: 'Nominal Voltage [V]', value: '3.64' },
        { name: 'Energy capacity [Wh]', value: '17.472' },
        { name: 'Capacity [Ah]', value: '4.8' },
        { name: 'Continuous charge [A]', value: '1.584' },
        { name: 'Max continuous charge [A]', value: '4.8' },
        { name: 'Continuous discharge [A]', value: '0.96' },
        { name: 'Max continuous discharge [A]', value: '9.6' },
        { name: 'Charge cut-off [V]', value: '4.2' },
        { name: 'Discharge cut-off [V]', value: '2.5' },
      ],
      geometry: [
        { name: 'Height [mm]', value: '70.8' },
        { name: 'Diameter [mm]', value: '21.25' },
        { name: 'Volume [cm³]', value: '25.1' },
        { name: 'Mass [g]', value: '69.5' },
      ],
      chemistry: [
        { name: 'Positive electrode (cathode)', value: '-' },
        { name: 'Negative electrode (anode)', value: '-' },
      ],
    },
  },
  {
    name: 'DLG NCM18650-260',
    model: 'NCM18650-260',
    composition: 'Li-ion',
    company: 'DLG',
    image:
      'https://static.wixstatic.com/media/92f430_8d241d650e9240ff894ea13e2b234044~mv2.png/v1/fill/w_226,h_718,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/DLG%20NCM-1.png',
    data: {
      overview: [
        { name: 'Manufacturer', value: 'Samsung' },
        { name: 'Model', value: 'NCM18650-260' },
        { name: 'Type', value: 'Li-ion' },
        { name: 'Form factor', value: 'Cylindrical 18650' },
        { name: 'Country of origin', value: 'China' },
        { name: 'Release year', value: '2019' },
      ],
      electrical: [
        { name: 'Nominal Voltage [V]', value: '3.6' },
        { name: 'Energy capacity [Wh]', value: '9.36' },
        { name: 'Capacity [Ah]', value: '2.6' },
        { name: 'Continuous charge [A]', value: '1.3' },
        { name: 'Max continuous charge [A]', value: '2.6' },
        { name: 'Continuous discharge [A]', value: '0.52' },
        { name: 'Max continuous discharge [A]', value: '7.8' },
        { name: 'Charge cut-off [V]', value: '4.2' },
        { name: 'Discharge cut-off [V]', value: '2.75' },
      ],
      geometry: [
        { name: 'Height [mm]', value: '65' },
        { name: 'Diameter [mm]', value: '18.3' },
        { name: 'Volume [cm³]', value: '17.1' },
        { name: 'Mass [g]', value: '46' },
      ],
      chemistry: [
        { name: 'Positive electrode (cathode)', value: 'NMC' },
        { name: 'Negative electrode (anode)', value: '-' },
      ],
    },
  },
  {
    name: 'LG Chem INR21700-M50',
    model: 'INR21700-M50',
    composition: 'Li-ion',
    company: 'LG Chem',
    image:
      'https://static.wixstatic.com/media/92f430_bed96dcf6c5d4f9ab8ce147fc9db8da6~mv2.png/v1/fill/w_201,h_718,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/LGDBM502170%20Q345L111A1%20-%202.png',
    data: {
      overview: [
        { name: 'Manufacturer', value: 'LG Chem' },
        { name: 'Model', value: 'INR21700-M50' },
        { name: 'Type', value: 'Li-ion' },
        { name: 'Form factor', value: 'Cylindrical 21700' },
        { name: 'Country of origin', value: 'Korea' },
        { name: 'Release year', value: '2016' },
      ],
      electrical: [
        { name: 'Nominal Voltage [V]', value: '3.63' },
        { name: 'Energy capacity [Wh]', value: '17.6' },
        { name: 'Capacity [Ah]', value: '4.85' },
        { name: 'Continuous charge [A]', value: '1.455' },
        { name: 'Max continuous charge [A]', value: '3.395' },
        { name: 'Continuous discharge [A]', value: '0.97' },
        { name: 'Max continuous discharge [A]', value: '7.275' },
        { name: 'Charge cut-off [V]', value: '4.2' },
        { name: 'Discharge cut-off [V]', value: '2.5' },
      ],
      geometry: [
        { name: 'Height [mm]', value: '70.15' },
        { name: 'Diameter [mm]', value: '21.1' },
        { name: 'Volume [cm³]', value: '24.5' },
        { name: 'Mass [g]', value: '68' },
      ],
      chemistry: [
        { name: 'Positive electrode (cathode)', value: '-' },
        { name: 'Negative electrode (anode)', value: '-' },
      ],
    },
  },
  {
    name: 'Molicel INR-18650-P28A',
    model: 'INR-18650-P28A',
    composition: 'Li-ion',
    company: 'Molicel',
    image:
      'https://static.wixstatic.com/media/92f430_185430b89521434f8a28b3973a130a4e~mv2.png/v1/fill/w_195,h_718,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Molicel%20P28A%20(18650)-Recovered.png',
    data: {
      overview: [
        { name: 'Manufacturer', value: 'Molicel' },
        { name: 'Model', value: 'INR-18650-P28A' },
        { name: 'Type', value: 'Li-ion' },
        { name: 'Form factor', value: 'Cylindrical 18650' },
        { name: 'Country of origin', value: 'Taiwan' },
        { name: 'Release year', value: '2018' },
      ],
      electrical: [
        { name: 'Nominal Voltage [V]', value: '3.6' },
        { name: 'Energy capacity [Wh]', value: '10.08' },
        { name: 'Capacity [Ah]', value: '2.8' },
        { name: 'Continuous charge [A]', value: '2.8' },
        { name: 'Max continuous charge [A]', value: '8.4' },
        { name: 'Continuous discharge [A]', value: '0.56' },
        { name: 'Max continuous discharge [A]', value: '35' },
        { name: 'Charge cut-off [V]', value: '4.2' },
        { name: 'Discharge cut-off [V]', value: '2.5' },
      ],
      geometry: [
        { name: 'Height [mm]', value: '65.05' },
        { name: 'Diameter [mm]', value: '18.3' },
        { name: 'Volume [cm³]', value: '17.1' },
        { name: 'Mass [g]', value: '48' },
      ],
      chemistry: [
        { name: 'Positive electrode (cathode)', value: '-' },
        { name: 'Negative electrode (anode)', value: '-' },
      ],
    },
  },
  {
    name: 'Molicel INR21700-P42A',
    model: 'INR21700-P42A',
    composition: 'Li-ion',
    company: 'Molicel',
    image:
      'https://static.wixstatic.com/media/92f430_df28987f0c704076891c1d3a395836f1~mv2.png/v1/fill/w_203,h_718,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/INR-21700-P42A%20MOLICEL%20092%202LC21%2003%20-%202.png',
    data: {
      overview: [
        { name: 'Manufacturer', value: 'Molicel' },
        { name: 'Model', value: 'INR18650-25R' },
        { name: 'Type', value: 'Li-ion' },
        { name: 'Form factor', value: 'Cylindrical 21700' },
        { name: 'Country of origin', value: 'Taiwan' },
        { name: 'Release year', value: '2018' },
      ],
      electrical: [
        { name: 'Nominal Voltage [V]', value: '3.6' },
        { name: 'Energy capacity [Wh]', value: '15.12' },
        { name: 'Capacity [Ah]', value: '4.2' },
        { name: 'Continuous charge [A]', value: '4.2' },
        { name: 'Max continuous charge [A]', value: '8.4' },
        { name: 'Continuous discharge [A]', value: '0.84' },
        { name: 'Max continuous discharge [A]', value: '45' },
        { name: 'Charge cut-off [V]', value: '4.2' },
        { name: 'Discharge cut-off [V]', value: '2.5' },
      ],
      geometry: [
        { name: 'Height [mm]', value: '70' },
        { name: 'Diameter [mm]', value: '21.4' },
        { name: 'Volume [cm³]', value: '25.2' },
        { name: 'Mass [g]', value: '70' },
      ],
      chemistry: [
        { name: 'Positive electrode (cathode)', value: 'NCA' },
        { name: 'Negative electrode (anode)', value: 'Graphite' },
      ],
    },
  },
  {
    name: 'LG Chem INR18650-MJ1',
    model: 'INR18650-MJ1',
    composition: 'Li-ion',
    company: 'LG Chem',
    image:
      'https://static.wixstatic.com/media/92f430_0c237a297d4a4d839e65184cc6715b6a~mv2.png/v1/fill/w_196,h_718,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/LG%20MJ1%20(18650)-Recovered.png',
    data: {
      overview: [
        { name: 'Manufacturer', value: 'Samsung' },
        { name: 'Model', value: 'INR18650-MJ1' },
        { name: 'Type', value: 'Li-ion' },
        { name: 'Form factor', value: 'Cylindrical 18650' },
        { name: 'Country of origin', value: 'Korea' },
        { name: 'Release year', value: '2014' },
      ],
      electrical: [
        { name: 'Nominal Voltage [V]', value: '3.635' },
        { name: 'Energy capacity [Wh]', value: '12.72' },
        { name: 'Capacity [Ah]', value: '3.4' },
        { name: 'Continuous charge [A]', value: '1.7' },
        { name: 'Max continuous charge [A]', value: '3.4' },
        { name: 'Continuous discharge [A]', value: '0.68' },
        { name: 'Max continuous discharge [A]', value: '10' },
        { name: 'Charge cut-off [V]', value: '4.2' },
        { name: 'Discharge cut-off [V]', value: '2.5' },
      ],
      geometry: [
        { name: 'Height [mm]', value: '65' },
        { name: 'Diameter [mm]', value: '18.4' },
        { name: 'Volume [cm³]', value: '17.3' },
        { name: 'Mass [g]', value: '49' },
      ],
      chemistry: [
        { name: 'Positive electrode (cathode)', value: '-' },
        { name: 'Negative electrode (anode)', value: '-' },
      ],
    },
  },
  {
    name: 'Samsung INR18650-30Q',
    model: 'INR18650-30Q',
    composition: 'Li-ion',
    company: 'Samsung',
    image:
      'https://static.wixstatic.com/media/92f430_8d35b151a57347c99450de2c361de870~mv2.png/v1/fill/w_194,h_718,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Samsung%2030Q%20(18650)-2.png',
    data: {
      overview: [
        { name: 'Manufacturer', value: 'Samsung' },
        { name: 'Model', value: 'INR18650-30Q' },
        { name: 'Type', value: 'Li-ion' },
        { name: 'Form factor', value: 'Cylindrical 18650' },
        { name: 'Country of origin', value: 'Korea' },
        { name: 'Release year', value: '2015' },
      ],
      electrical: [
        { name: 'Nominal Voltage [V]', value: '3.6' },
        { name: 'Energy capacity [Wh]', value: '10.8' },
        { name: 'Capacity [Ah]', value: '3' },
        { name: 'Continuous charge [A]', value: '1.5' },
        { name: 'Max continuous charge [A]', value: '4' },
        { name: 'Continuous discharge [A]', value: '0.6' },
        { name: 'Max continuous discharge [A]', value: '15' },
        { name: 'Charge cut-off [V]', value: '4.2' },
        { name: 'Discharge cut-off [V]', value: '2.5' },
      ],
      geometry: [
        { name: 'Height [mm]', value: '65' },
        { name: 'Diameter [mm]', value: '18.4' },
        { name: 'Volume [cm³]', value: '17.3' },
        { name: 'Mass [g]', value: '48' },
      ],
      chemistry: [
        { name: 'Positive electrode (cathode)', value: '-' },
        { name: 'Negative electrode (anode)', value: '-' },
      ],
    },
  },
  {
    name: 'Murata US21700VTC6A',
    model: 'US21700VTC6A',
    composition: 'Li-ion',
    company: 'Murata',
    image:
      'https://static.wixstatic.com/media/92f430_cc4f0073694c44c4b3bb5e35be0970c7~mv2.png/v1/fill/w_398,h_684,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Asset%202cell_20x.png',
    data: {
      overview: [
        { name: 'Manufacturer', value: 'Murata' },
        { name: 'Model', value: 'US21700VTC6A' },
        { name: 'Type', value: 'Li-ion' },
        { name: 'Form factor', value: 'Cylindrical 21700' },
        { name: 'Country of origin', value: 'Japan' },
        { name: 'Release year', value: '-' },
      ],
      electrical: [
        { name: 'Nominal Voltage [V]', value: '3.6' },
        { name: 'Energy capacity [Wh]', value: '14.44' },
        { name: 'Capacity [Ah]', value: '4.1' },
        { name: 'Continuous charge [A]', value: '4.5' },
        { name: 'Max continuous charge [A]', value: '9' },
        { name: 'Continuous discharge [A]', value: '4' },
        { name: 'Max continuous discharge [A]', value: '40' },
        { name: 'Charge cut-off [V]', value: '4.2' },
        { name: 'Discharge cut-off [V]', value: '2.5' },
      ],
      geometry: [
        { name: 'Height [mm]', value: '69.8' },
        { name: 'Diameter [mm]', value: '21.35' },
        { name: 'Volume [cm³]', value: '25' },
        { name: 'Mass [g]', value: '72.7' },
      ],
      chemistry: [
        { name: 'Positive electrode (cathode)', value: '-' },
        { name: 'Negative electrode (anode)', value: '-' },
      ],
    },
  },
  {
    name: 'A123 A123_18650_M1A',
    model: 'A123_18650_M1A',
    composition: 'Li-ion',
    company: 'A123',
    image:
      'https://static.wixstatic.com/media/92f430_50575597c0e64215ac7806f8834c084f~mv2.png/v1/fill/w_192,h_718,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/A123%20M1A%20(18650)%20-%202.png',
    data: {
      overview: [
        { name: 'Manufacturer', value: 'A123' },
        { name: 'Model', value: 'A123_18650_M1A' },
        { name: 'Type', value: 'Li-ion' },
        { name: 'Form factor', value: 'Cylindrical 18650' },
        { name: 'Country of origin', value: 'China' },
        { name: 'Release year', value: '-' },
      ],
      electrical: [
        { name: 'Nominal Voltage [V]', value: '3.3' },
        { name: 'Energy capacity [Wh]', value: '3.63' },
        { name: 'Capacity [Ah]', value: '1.1' },
        { name: 'Continuous charge [A]', value: '1.5' },
        { name: 'Max continuous charge [A]', value: '5' },
        { name: 'Continuous discharge [A]', value: '-' },
        { name: 'Max continuous discharge [A]', value: '30' },
        { name: 'Charge cut-off [V]', value: '3.6' },
        { name: 'Discharge cut-off [V]', value: '2' },
      ],
      geometry: [
        { name: 'Height [mm]', value: '65' },
        { name: 'Diameter [mm]', value: '18' },
        { name: 'Volume [cm³]', value: '16.54' },
        { name: 'Mass [g]', value: '39' },
      ],
      chemistry: [
        { name: 'Positive electrode (cathode)', value: 'LFP' },
        { name: 'Negative electrode (anode)', value: '-' },
      ],
    },
  },
  {
    name: 'Sony US18650VTC6',
    model: 'US18650VTC6',
    composition: 'Li-ion',
    company: 'Sony',
    image:
      'https://static.wixstatic.com/media/92f430_c93b1be5c2584c29b34c688578abd806~mv2.png/v1/fill/w_238,h_718,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Sony%2018650%20VTC6-2%20-%20murata_.png',
    data: {
      overview: [
        { name: 'Manufacturer', value: 'Sony' },
        { name: 'Model', value: 'US18650VTC6' },
        { name: 'Type', value: 'Li-ion' },
        { name: 'Form factor', value: 'Cylindrical 18650' },
        { name: 'Country of origin', value: 'Japan' },
        { name: 'Release year', value: '2015' },
      ],
      electrical: [
        { name: 'Nominal Voltage [V]', value: '3.6' },
        { name: 'Energy capacity [Wh]', value: '-' },
        { name: 'Capacity [Ah]', value: '3.12' },
        { name: 'Continuous charge [A]', value: '3' },
        { name: 'Max continuous charge [A]', value: '5' },
        { name: 'Continuous discharge [A]', value: '3' },
        { name: 'Max continuous discharge [A]', value: '30' },
        { name: 'Charge cut-off [V]', value: '4.2' },
        { name: 'Discharge cut-off [V]', value: '2' },
      ],
      geometry: [
        { name: 'Height [mm]', value: '65' },
        { name: 'Diameter [mm]', value: '18.35' },
        { name: 'Volume [cm³]', value: '17.19' },
        { name: 'Mass [g]', value: '46.6' },
      ],
      chemistry: [
        { name: 'Positive electrode (cathode)', value: '-' },
        { name: 'Negative electrode (anode)', value: '-' },
      ],
    },
  },
  {
    name: 'LG Chem INR21700-M50LT',
    model: 'INR21700-M50LT',
    composition: 'Li-ion',
    company: 'LG Chem',
    image:
      'https://static.wixstatic.com/media/92f430_ed1d2cffb7e14effb70b45a76dd1618f~mv2.png/v1/fill/w_196,h_718,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/INR21700%20M50LT%20FA186G059N1-Recovered.png',
    data: {
      overview: [
        { name: 'Manufacturer', value: 'LG Chem' },
        { name: 'Model', value: 'INR21700-M50LT' },
        { name: 'Type', value: 'Li-ion' },
        { name: 'Form factor', value: 'Cylindrical 21700' },
        { name: 'Country of origin', value: 'Korea' },
        { name: 'Release year', value: '2020' },
      ],
      electrical: [
        { name: 'Nominal Voltage [V]', value: '3.69' },
        { name: 'Energy capacity [Wh]', value: '18.2' },
        { name: 'Capacity [Ah]', value: '4.8' },
        { name: 'Continuous charge [A]', value: '1.44' },
        { name: 'Max continuous charge [A]', value: '3.36' },
        { name: 'Continuous discharge [A]', value: '0.96' },
        { name: 'Max continuous discharge [A]', value: '14.4' },
        { name: 'Charge cut-off [V]', value: '4.2' },
        { name: 'Discharge cut-off [V]', value: '2.5' },
      ],
      geometry: [
        { name: 'Height [mm]', value: '70.65' },
        { name: 'Diameter [mm]', value: '21.44' },
        { name: 'Volume [cm³]', value: '25.5' },
        { name: 'Mass [g]', value: '67.5' },
      ],
      chemistry: [
        { name: 'Positive electrode (cathode)', value: '-' },
        { name: 'Negative electrode (anode)', value: '-' },
      ],
    },
  },
];

export default CELL_DETAILS;
