import React, { useCallback, useEffect, useState } from 'react';
import { GridColDef, GridFilterModel } from '@mui/x-data-grid';
import { useQuery } from '@apollo/client';
import { Typography } from '@mui/material';

import StyledDataGrid from 'src/components/Structure/SyledDataGrid';
import { GET_BATTERY_LIBRARY } from 'src/graphql/queries';
import { camelCaseToTitleCase } from 'src/utils/Text';
import { renderTableCell } from 'src/components/Structure/SyledDataGrid';
import { BatteryLibrary } from 'src/@types/BatteryLibrary';
import { ChartWrapper, PageContainer } from 'src/components/Structure';
import { TEXT_CONTENTS } from 'src/constants';

interface EvBatteryLibraryProps {
  batteryCategory: string;
  title: string;
  description: string;
}

const EvBatteryLibrary: React.FC<EvBatteryLibraryProps> = ({
  batteryCategory,
  title,
  description,
}) => {
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<BatteryLibrary[]>([]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });

  const { loading, error, data } = useQuery<{ getBatteryLibrary: BatteryLibrary[] }>(
    GET_BATTERY_LIBRARY,
    {
      variables: { filter: { batteryPackCategory: batteryCategory } },
    }
  );

  const defineColumns = useCallback((dataSample: BatteryLibrary): GridColDef[] => {
    const tableKeys = Object.keys(dataSample).filter(key => key !== '__typename' && key !== 'id');
    const tableKeysWithId = [
      { field: 'id', headerName: 'ID', minWidth: 175, flex: 0.5, renderTableCell },
    ].concat(
      tableKeys.map(key => ({
        field: key,
        headerName: camelCaseToTitleCase(key),
        flex: 1,
        minWidth: 175,
        renderTableCell,
      }))
    );
    return tableKeysWithId;
  }, []);

  useEffect(() => {
    if (data && data.getBatteryLibrary) {
      const initialRows = data.getBatteryLibrary.map((item, index) => ({
        ...item,
        id: index + 1,
      }));
      setColumns(defineColumns(data.getBatteryLibrary[0]));
      setRows(initialRows);
    }
  }, [data, defineColumns]);

  if (loading) return <Typography variant="body1">{TEXT_CONTENTS.COMMON.LOADING}</Typography>;

  if (error) return <Typography variant="body1">{TEXT_CONTENTS.COMMON.ERROR}</Typography>;

  return (
    <PageContainer>
      <ChartWrapper title={title}>
        <Typography variant="body1" mb={2} gutterBottom>
          {description}
        </Typography>
        <StyledDataGrid
          rows={rows}
          columns={columns}
          filterModel={filterModel}
          onFilterModelChange={model => {
            setFilterModel(model);
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5, 10, 25]}
          disableRowSelectionOnClick
        />
      </ChartWrapper>
    </PageContainer>
  );
};

export default EvBatteryLibrary;
