import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import * as XLSX from 'xlsx';

import { BatteryDatabase, BatteryPackModelType } from 'src/@types/BatteryDatabase';
import { TEXT_CONTENTS } from 'src/constants';
import { COLUMNS_TO_SHOW, MASTER_DATA_TABLE_HEADER_MAPPING } from 'src/constants/masterData';
import { camelCaseToTitleCase } from 'src/utils/Text';
import { isFloat, roundToTwoDecimals } from 'src/utils/Number';

interface ExportMasterDataProps {
  batteryMasterData: BatteryDatabase[];
  batteryPackType: BatteryPackModelType;
}

const ExportMasterDataButton: React.FC<ExportMasterDataProps> = ({
  batteryMasterData,
  batteryPackType,
}) => {
  const handleExport = (data: BatteryDatabase[]) => () => {
    const getHeaderName = (key: string) =>
      MASTER_DATA_TABLE_HEADER_MAPPING[key as keyof typeof MASTER_DATA_TABLE_HEADER_MAPPING] ||
      camelCaseToTitleCase(key);

    const dataToExport = data.map(batteryDatabaseItem =>
      Object.entries(batteryDatabaseItem)
        .filter(([key]) => COLUMNS_TO_SHOW[batteryPackType].includes(key))
        .reduce((acc, [key, value]) => {
          const header = getHeaderName(key);

          if (typeof value === 'number') return { ...acc, [header]: roundToTwoDecimals(value) };
          if (typeof value === 'string' && isFloat(value)) {
            return { ...acc, [header]: roundToTwoDecimals(parseFloat(value)) };
          }
          return { ...acc, [header]: value };
        }, {})
    );
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${batteryPackType} Master Data.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Tooltip title={TEXT_CONTENTS.MASTER_DATA.EXPORT_MASTER_DATA}>
      <IconButton onClick={handleExport(batteryMasterData)}>
        <DownloadIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ExportMasterDataButton;
