import React from 'react';
import { Box, Link, Typography, styled, useTheme } from '@mui/material';
import { TEXT_CONTENTS } from 'src/constants';
import { ChevronRight } from '@mui/icons-material';

const TitleBox = styled('div')(({ theme }) => ({
  position: 'relative',
  paddingTop: theme.spacing(1.5),
  width: '100%',
  textTransform: 'uppercase',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '145px',
    borderTop: `${theme.spacing(0.5)} solid ${theme.palette.primary.main}`,
  },
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

interface Props {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
  link?: string;
}

const HomeCard: React.FC<Props> = ({ title, subtitle, link, children }) => {
  const theme = useTheme();
  return (
    <Box sx={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(4) }}>
      <TitleBox>
        <Typography variant="h1" sx={{ paddingTop: theme.spacing(1) }}>
          {title}
        </Typography>
        {link && (
          <Link
            href={link}
            underline="hover"
            sx={{ color: 'text.primary', textDecoration: 'none', cursor: 'pointer' }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}
            >
              <Typography
                variant="body1"
                sx={{ paddingTop: theme.spacing(1), textDecoration: 'none', cursor: 'pointer' }}
              >
                {TEXT_CONTENTS.HOME.LINK_TEXT}
              </Typography>
              <ChevronRight sx={{ fontSize: '1.6rem' }} />
            </Box>
          </Link>
        )}
      </TitleBox>
      {subtitle && (
        <Typography variant="body2" color="text.secondary">
          {subtitle}
        </Typography>
      )}
      <Box sx={{ paddingTop: theme.spacing(2) }}>{children}</Box>
    </Box>
  );
};

export default HomeCard;
