import React, { ReactNode } from 'react';
import { Box, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import LoginBackground from 'src/assets/svgs/login_background.svg';
import Logo from 'src/assets/svgs/circuli-ion.svg';
import { TEXT_CONTENTS } from 'src/constants';

interface Props {
  children: ReactNode;
}

const AuthLayout: React.FC<Props> = ({ children }): JSX.Element => {
  const theme = useTheme();
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        height: '100vh',
        minHeight: '100%',
      }}
    >
      <Box
        component="div"
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: '1',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          padding: '4% 8%',
          width: '50%',
        }}
      >
        <Box
          component="div"
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: '1',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <Box
            component="img"
            src={Logo}
            alt="Circu Li-ion Logo"
            sx={{
              position: 'relative',
              display: 'flex',
              paddingBottom: theme.spacing(5),
              width: 146,
            }}
          />
          <Box
            component="div"
            sx={{
              width: '100%',
            }}
          >
            {children}
          </Box>
        </Box>
        <Typography variant="body2">
          <Link
            href={TEXT_CONTENTS.LOGIN.LINK_TO_MAIN_PAGE_URL}
            underline="hover"
            sx={{ color: 'text.secondary' }}
          >
            {TEXT_CONTENTS.LOGIN.LINK_TO_MAIN_PAGE_TEXT}
          </Link>
        </Typography>
      </Box>
      <Box
        component="div"
        sx={{
          position: 'relative',
          display: { xs: 'none', sm: 'none', md: 'flex' },
          justifyContent: 'center',
          alignItems: 'center',
          width: '50%',
          height: '100%',
          backgroundImage: `url(${LoginBackground})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      />
    </Box>
  );
};

export default AuthLayout;
