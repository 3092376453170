import React from 'react';
import { Box, Divider, Link, Typography, useTheme } from '@mui/material';

import { TEXT_CONTENTS } from 'src/constants';
import { COLUMNS_TO_SHOW } from 'src/constants/masterData';
import { BatteryDatabase, BatteryPackModelType } from 'src/@types/BatteryDatabase';
import { roundToTwoDecimals } from 'src/utils/Number';
import { isOffice365Url } from 'src/utils/Text';

interface MasterDataDrawerProps {
  selectedRow: BatteryDatabase | null;
  batteryPackType: BatteryPackModelType;
  getHeaderName: (key: string) => string;
}

const MasterDataDrawerContent: React.FC<MasterDataDrawerProps> = ({
  selectedRow,
  batteryPackType,
  getHeaderName,
}) => {
  const theme = useTheme();

  const columnFilter =
    (row: BatteryDatabase, batteryPackType: BatteryPackModelType, showEmptyValues: boolean) =>
    (key: string) => {
      if (!COLUMNS_TO_SHOW[batteryPackType].includes(key)) return false;

      const value = row[key as keyof typeof row];
      const isEmptyValue = value === null || value === undefined || value === '';

      return showEmptyValues ? isEmptyValue : !isEmptyValue;
    };

  const getFormattedValue = (selectedRow: BatteryDatabase, key: string) => {
    const value = selectedRow[key as keyof typeof selectedRow];
    if (value === null || value === '') return '-';
    if (typeof value === 'number') return roundToTwoDecimals(value);
    if (typeof value === 'string') {
      try {
        const parsedValue = parseFloat(value);
        if (!isNaN(parsedValue)) return roundToTwoDecimals(parsedValue);
        if (isOffice365Url(value)) {
          return (
            <Link
              target="_blank"
              href={TEXT_CONTENTS.MASTER_DATA.CIRCU_LI_ION_SHAREPOINT_PREFIX + value}
            >
              View
            </Link>
          );
        }
        return value;
      } catch (e) {
        return value;
      }
    }
  };

  return (
    <Box
      sx={{
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
      }}
    >
      <Typography variant="h6" color="primary">
        {TEXT_CONTENTS.MASTER_DATA.DETAILED_INFORMATION}
      </Typography>
      {selectedRow &&
        Object.keys(selectedRow)
          .filter(columnFilter(selectedRow, batteryPackType, false))
          .map(key => (
            <Box
              key={key}
              sx={{
                mt: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                {getHeaderName(key)}
              </Typography>
              <Typography variant="body1">{getFormattedValue(selectedRow, key)}</Typography>
            </Box>
          ))}
      <Divider sx={{ margin: 2 }} />
      <Typography variant="h6" color="primary" sx={{ mt: 2 }}>
        {TEXT_CONTENTS.MASTER_DATA.MISSING_VALUES}
      </Typography>
      {selectedRow &&
        Object.keys(selectedRow)
          .filter(columnFilter(selectedRow, batteryPackType, true))
          .map(key => (
            <Box
              key={key}
              sx={{
                mt: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                {getHeaderName(key)}
              </Typography>
              <Typography variant="body1">-</Typography>
            </Box>
          ))}
    </Box>
  );
};

export default MasterDataDrawerContent;
