import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import { NumberBox } from 'src/components/Structure/NumberBox';
import batteryFull from 'src/assets/svgs/battery-full-icon.svg';
import batteryEmpty from 'src/assets/svgs/battery-empty-icon.svg';
import { CellCondition } from 'src/@types/DataTypes';
import { roundToTwoDecimals } from 'src/utils/Number';
import { TEXT_CONTENTS } from 'src/constants';

interface Props {
  client?: string;
  batteryCategory?: string;
  batteryType?: string;
  aggregatedConditionData?: Omit<CellCondition, 'client' | 'batteryCategory' | 'batteryType'>;
}

const ConditionBox: React.FC<Props> = ({ aggregatedConditionData }) => {
  const theme = useTheme();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <NumberBox>
          <Grid height="100%" container direction="row" justifyContent="space-between">
            <Grid item>
              <Grid container spacing={1} direction="column" justifyItems="space-between">
                <Grid item xs={2} marginTop={1}>
                  <Box
                    component="img"
                    src={batteryFull}
                    alt="Full Battery Icon"
                    sx={{
                      width: 20,
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h6">
                    {TEXT_CONTENTS.CELL.CONDITION_BOX.REUSABLE_CELLS}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item alignSelf="center">
              <Typography variant="h3" color={'grey'}>
                {aggregatedConditionData
                  ? roundToTwoDecimals(
                      aggregatedConditionData?.reusablePct / aggregatedConditionData?.totalCells
                    )
                  : 0}
                %
              </Typography>
            </Grid>
          </Grid>
        </NumberBox>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <NumberBox>
          <Grid height="100%" container direction="row" justifyContent="space-between">
            <Grid item>
              <Grid container spacing={1} direction="column" justifyItems="space-between">
                <Grid item xs={2} marginTop={1}>
                  <Box
                    component="img"
                    src={batteryEmpty}
                    alt="Empty Battery Icon"
                    sx={{
                      width: 20,
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h6">
                    {TEXT_CONTENTS.CELL.CONDITION_BOX.RECYCLABLE_CELLS}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item alignSelf="center">
              <Typography variant="h3" color={theme.palette.error.main}>
                {aggregatedConditionData
                  ? roundToTwoDecimals(
                      aggregatedConditionData?.recycleablePct / aggregatedConditionData?.totalCells
                    )
                  : 0}
                %
              </Typography>
            </Grid>
          </Grid>
        </NumberBox>
      </Grid>
    </Grid>
  );
};

export default ConditionBox;
