import React, { FormEvent, useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { FirebaseContext } from 'src/components/Contexts';
import { FirebaseContextInterface } from 'src/components/Contexts/FirebaseContext';
import { FirebaseError, SignUpFormData } from 'src/@types/AuthTypes';
import { getFirebaseErrorMessage } from 'src/utils/Firebase';
import useMessages from 'src/components/hooks/useMessages';
import { SIGN_UP } from 'src/graphql/mutations/auth';
import CommonLayout from 'src/components/Auth/CommonLayout';
import SignUpForm from 'src/components/Auth/SignUpForm';
import ROUTES from 'src/constants/routes';
import { TEXT_CONTENTS } from 'src/constants';

const Signup: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const { setDialog } = useMessages();
  const [formData, setFormData] = useState<SignUpFormData>({
    role: '',
    data: {
      choices: [],
      freeText: '',
    },
    frequency: {
      useCase: '',
      frequency: '',
    },
    company: '',
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    userType: 'data_client',
    approved: false,
  });
  // const navigate = useNavigate();
  const context = useContext(FirebaseContext) as FirebaseContextInterface;
  const { firebase, onSuccessfulAuthentication } = context;
  const [updateUserDetails] = useMutation(SIGN_UP, {
    onError: () => {
      console.error('Issues storing company');
    },
  });

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    try {
      const userCredential = await firebase.signUp(formData.email, formData.password);
      if (userCredential && userCredential.email) {
        onSuccessfulAuthentication(userCredential.email);
        await updateUserDetails({
          variables: {
            userDetails: {
              name: formData.name,
              company: formData.company,
              email: formData.email,
              role: formData.role,
              frequency: formData.frequency.frequency,
              usecase: formData.frequency.useCase,
              dataInterest: formData.data.choices.join(', '),
              dataInterestOther: formData.data.freeText,
              userType: 'data_client',
              approved: false,
            },
          },
        });
        // setDialog({
        //   type: 'success',
        //   title: 'Account created successfully',
        //   message:
        //     'Your sign up was successful! We will verify your account shortly and approve it. Once approved, we will notify you via email. Be sure to check spam or junk folders.',
        // });
        navigate(ROUTES.BATTERY_MAP);
      }
    } catch (error) {
      console.error('Error signing up:', error);
      const firebaseErrorMessage = getFirebaseErrorMessage(error as FirebaseError);
      setDialog({
        type: 'error',
        title: TEXT_CONTENTS.SIGNUP.ERROR,
        message: firebaseErrorMessage,
      });
    }
  };

  return (
    <CommonLayout>
      <SignUpForm formData={formData} setFormData={setFormData} handleSubmit={handleSubmit} />
    </CommonLayout>
  );
};

export default Signup;
