import React, { ChangeEvent, FormEvent } from 'react';
import {
  TextField,
  Button,
  Box,
  Typography,
  Paper,
  Link,
  AccordionSummary,
  useTheme,
  Accordion,
  Chip,
  Stack,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { TEXT_CONTENTS } from 'src/constants';
import { SignUpFormData } from 'src/@types/AuthTypes';
import ShowPasswordButton from 'src/components/Auth/ShowPasswordButton';
import { chipOptions, frequencyOptions } from 'src/constants/signUp';

interface Props {
  formData: SignUpFormData;
  setFormData: React.Dispatch<React.SetStateAction<SignUpFormData>>;
  handleSubmit: (event: FormEvent) => void;
}

const SignUpForm: React.FC<Props> = ({ formData, setFormData, handleSubmit }) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const [expandedPanel, setExpandedPanel] = React.useState<string>('panel1');

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedPanel(newExpanded ? panel : '');
    };

  const handleChipClick = (chip: string) => {
    setFormData(prev => {
      const currentIndex = prev.data.choices.indexOf(chip);
      const newSelectedChips = [...prev.data.choices];

      if (currentIndex === -1) {
        newSelectedChips.push(chip);
      } else {
        newSelectedChips.splice(currentIndex, 1);
      }

      return {
        ...prev,
        data: {
          ...prev.data,
          choices: newSelectedChips,
        },
      };
    });
  };

  const handleSelectFrequency = (option: string) => {
    setFormData(prev => ({
      ...prev,
      frequency: {
        ...prev.frequency,
        frequency: option,
      },
    }));
  };

  const handleDatachange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData(prevData => ({
      ...prevData,
      data: {
        ...prevData.data,
        freeText: event.target.value,
      },
    }));
  };

  const handleUseCaseChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData(prevData => ({
      ...prevData,
      frequency: {
        ...prevData.frequency,
        useCase: event.target.value,
      },
    }));
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: { xs: '85vh', sm: '65vh', md: '80vh', lg: '60vh' },
        width: { xs: '90%', sm: '70%', md: 400, lg: 400 },
      }}
    >
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography
          variant="h4"
          gutterBottom
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {TEXT_CONTENTS.SIGNUP.SIGNUP_HEADER}
        </Typography>

        <form onSubmit={handleSubmit}>
          <Accordion
            elevation={5}
            sx={{ mb: theme.spacing(1) }}
            expanded={expandedPanel === 'panel1'}
            onChange={handleAccordionChange('panel1')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body1">
                {TEXT_CONTENTS.SIGNUP.PERSONAL_INFORMATION_GROUP_HEADER}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                margin="normal"
                name="name"
                label="Full Name"
                type="text"
                variant="filled"
                color="primary"
                value={formData.name}
                onChange={handleChange}
                required
              />

              <TextField
                fullWidth
                margin="normal"
                name="email"
                label="Email"
                type="email"
                variant="filled"
                color="primary"
                value={formData.email}
                onChange={handleChange}
                required
              />

              <TextField
                fullWidth
                margin="normal"
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                variant="filled"
                color="primary"
                value={formData.password}
                onChange={handleChange}
                required
                InputProps={{
                  endAdornment: (
                    <ShowPasswordButton
                      showPassword={showPassword}
                      setShowPassword={setShowPassword}
                    />
                  ),
                }}
              />

              <TextField
                fullWidth
                margin="normal"
                name="confirmPassword"
                label="Confirm Password"
                type={showConfirmPassword ? 'text' : 'password'}
                variant="filled"
                color="primary"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
                InputProps={{
                  endAdornment: (
                    <ShowPasswordButton
                      showPassword={showConfirmPassword}
                      setShowPassword={setShowConfirmPassword}
                    />
                  ),
                }}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={5}
            sx={{ mb: theme.spacing(1) }}
            expanded={expandedPanel === 'panel2'}
            onChange={handleAccordionChange('panel2')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body1">
                {TEXT_CONTENTS.SIGNUP.COMPANY_DETAILS_GROUP_HEADER}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                margin="normal"
                name="company"
                label="Company Name"
                type="text"
                variant="filled"
                color="primary"
                value={formData.company}
                onChange={handleChange}
                required
              />
              <TextField
                fullWidth
                margin="normal"
                name="role"
                label="Role"
                type="text"
                variant="filled"
                color="primary"
                value={formData.role}
                onChange={handleChange}
                required
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={5}
            sx={{ mb: theme.spacing(1) }}
            expanded={expandedPanel === 'panel3'}
            onChange={handleAccordionChange('panel3')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body1">{TEXT_CONTENTS.SIGNUP.DATA_GROUP_HEADER}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant={'body2'}>{TEXT_CONTENTS.SIGNUP.KIND_OF_DATA}</Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                {chipOptions.map(chip => (
                  <Chip
                    key={chip}
                    label={chip}
                    onClick={() => handleChipClick(chip)}
                    color={formData.data.choices.includes(chip) ? 'primary' : 'default'}
                  />
                ))}
              </Box>
              <Typography variant={'body2'} sx={{ mt: 1 }}>
                {TEXT_CONTENTS.SIGNUP.INTERESTED_DATA}
              </Typography>
              <TextField
                fullWidth
                margin="normal"
                name="data"
                label="Make a wish"
                type="text"
                variant="filled"
                color="primary"
                value={formData.data.freeText}
                onChange={handleDatachange}
                required
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={5}
            sx={{ mb: theme.spacing(1) }}
            expanded={expandedPanel === 'panel4'}
            onChange={handleAccordionChange('panel4')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body1">
                {TEXT_CONTENTS.SIGNUP.USAGE_AND_FREQUENCY_GROUP_HEADER}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant={'body2'}>{TEXT_CONTENTS.SIGNUP.DESCRIBE_USECASE}</Typography>
              <TextField
                fullWidth
                margin="normal"
                name="role"
                label="Use case"
                type="frequency"
                variant="filled"
                color="primary"
                value={formData.frequency.useCase}
                onChange={handleUseCaseChange}
                required
              />
              <Stack direction="column" spacing={1}>
                {frequencyOptions.map(option => (
                  <Chip
                    key={option}
                    label={option}
                    onClick={() => handleSelectFrequency(option)}
                    color={formData.frequency.frequency === option ? 'primary' : 'default'}
                  />
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>
          <Box mt={3}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Sign Up
            </Button>
          </Box>
        </form>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: theme.spacing(2),
          }}
        >
          <Link href={'/login'}>
            <Typography
              variant="body2"
              sx={{
                textDecoration: 'underline',
                color: theme.palette.primary.main,
              }}
            >
              {TEXT_CONTENTS.LOGIN.LINK_TO_LOGIN_TEXT}
            </Typography>
          </Link>
        </Box>
      </Paper>
    </Box>
  );
};

export default SignUpForm;
